import { Grid } from '@mui/material'
import React, { useState, useEffect, useContext } from 'react'
import { SkeletonView } from '../../../../common/Components/SkeletonView'
import ModulesGraphContext from '../../../../common/Contexts/GraphContext'
import { useGraph } from '../../../../hooks/useGraph'
import RadarControlService from '../../../../services/RadarControlService'
import { TablesTitles } from '../../components/TablesTitles'
import { TableInfoGeneral } from '../components/TableInfoGeneral'
import { TablesActions } from '../components/TablesActions'
import { CardInfo } from './CardInfo'
import { TableRanking } from './TableRanking'

const dataGraphs = {
    SKU: [],
    VENTS: [],
    BREAK: [],
    TRANSIT: [],
    RANKING: [],
}

export const GraphsHead = () => {

    const { data, handleData, isAppReady, setIsAppReady } = useGraph(dataGraphs);
    const { ENUM_FILTERS, filters, queryFilter } = useContext(ModulesGraphContext);
    const [filterType, setFilterType] = useState(true);
    const type = filterType ? "vendedor" : "cliente";

    const getRanking = async () => {
        const queryCod = filters ? `/${filters[ENUM_FILTERS.cod]}/${type}` : "";
        try {
            const dataRanking = await RadarControlService.getRankingVentas(queryCod);
            // console.log("dataRanking", dataRanking?.data);
            handleData("RANKING", dataRanking?.data);
        } catch (error) {
            console.log("getRanking", error);
        } finally {
            // setIsAppReady(false);
        }
    }

    const getAllPromises = async () => {
        setIsAppReady(true);
        const query = queryFilter ? `?${queryFilter}` : "";
        const queryCod = filters ? `/${filters[ENUM_FILTERS.cod]}` : "";
        const promises = [
            RadarControlService.getHiddenSKU(query),
            RadarControlService.getPorcentajeVenta(query),
            RadarControlService.getProximoQuiebre(query),
            RadarControlService.getTransitos(query),
            filters[ENUM_FILTERS.cod] != "" && RadarControlService.getRankingVentas(`${queryCod}/${type}`)
        ];
        try {
            const [dataSKU, dataVENTS, dataBREAK, dataTRANSIT, dataRANKING] = await Promise.all(promises);
            console.log("dataSKU", dataSKU);
            console.log("dataVENTS", dataVENTS);
            console.log("dataBREAK", dataBREAK);
            console.log("dataTRANSIT", dataTRANSIT);
            console.log("dataRANKING", dataRANKING);
            handleData("SKU", dataSKU?.data);
            handleData("VENTS", dataVENTS?.data);
            handleData("BREAK", dataBREAK?.data);
            handleData("TRANSIT", dataTRANSIT?.data);
            handleData("RANKING", dataRANKING?.data);

        } catch (error) {
            console.log("getPromises", error);
        } finally {
            setIsAppReady(false);
        }
    }
    useEffect(() => {
        // console.log('queryFilter', queryFilter, filters);
        if (queryFilter) {
            getAllPromises();
        }
    }, [queryFilter]);

    useEffect(() => {
        if (filters[ENUM_FILTERS.cod] != "") {
            getRanking();
        }
    }, [filterType]);

    
    return (
        <Grid container spacing={2}>
            <Grid item xs={3.5}>
                {
                    data?.SKU
                        ? <TableInfoGeneral hiddenSKU={data?.SKU} />
                        : <SkeletonView />
                }
            </Grid>

            <Grid item xs={3.5}>
                {
                    !isAppReady && (data?.VENTS || data?.BREAK || data?.TRANSIT)
                        ? <CardInfo data={data} />
                        :
                        (
                            <React.Fragment>
                                <SkeletonView />
                                <SkeletonView />
                                <SkeletonView />
                            </React.Fragment>
                        )
                }
            </Grid>

            <Grid item xs={5}>
                {
                    !isAppReady && data.RANKING ?
                        (
                            <React.Fragment>
                                <TablesTitles title={"Ranking Vendedor 3 Ultimos Meses"} />
                                <TablesActions
                                    seller={true}
                                    graph={false}
                                    type={false}
                                    handleSwitch={setFilterType}
                                />
                                <TableRanking rankingsVents={data.RANKING} />
                            </React.Fragment>
                        )
                        : <SkeletonView height={300} />
                }
            </Grid>
        </Grid>
    )
}
