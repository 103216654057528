import React, { useContext, useState } from 'react'
import BranchsContext from '../../../common/Contexts/BranchContext';
import MediaContext from '../../../common/Contexts/MediaContext';
import ModulesFormContext from '../../../common/Contexts/ModulesFormContext';
import checkoutFormModel from '../../../model/constantsOfWeek/constantsFormModel';
import { FormTextInput } from '../../../common/Components/FormTextInput';
import { ButtonSave } from '../../../common/Components/ButtonSave';
import { ContainerForm } from '../../../common/Components/ContainerForm';
import CustomModal from '../../../common/Components/CustomModal';
import { CToast } from '../../../common/Components/ToastNotify';
import { validateFormWeekConstants } from '../../../model/constantsOfWeek/constants.schema';
import ContantsService from '../../../services/ContantsOfWeeksService';
import { Formik } from 'formik';
import { Box } from '@mui/material';
import { ModalOpenEnum } from '../../../common/utils/ModalOpenEnum';

export const ConstanstWeeksForm = () => {
    const { isMobile, isTablet } = useContext(MediaContext);
    const { branchID } = useContext(BranchsContext);
    const { isOpen, toggleModal, rowData: constants, getDataTable } = useContext(ModulesFormContext);
    const [isLoading, setIsLoading] = useState(false)
    const { formId, formField: { week_number, constant } } = checkoutFormModel;

    const initialValue = {
        week_number: constants?.week_number || "",
        constant: constants?.constant || constant.default,
        isEditing: constants ? true : false,
        id_constantesDeSemanas: constants?.id_constantesDeSemanas,
    };
    const onSave = async (value) => {
        setIsLoading(true);
        const req = {
            company_id: branchID,
            week_number: value?.week_number,
            constant: value?.constant || constant.default,
        }
        value.isEditing && (Object.assign(req, { id_constantesDeSemanas: value.id_constantesDeSemanas }));
        try {
            const { data } = value.isEditing
                ? await ContantsService.updateConstantWeek(req)
                : await ContantsService.createConstantWeek(req);
            await getDataTable();
            toggleModal(ModalOpenEnum.EDIT);
            CToast(`Constante ${value.isEditing ? 'editada' : 'creada'}`, "success", true);
        } catch ({ response }) {
            console.log(response);
            CToast(`Error al crear ${JSON.stringify(response?.data.description)}`, "error", true);
        } finally {
            setIsLoading(false);
        }
    }
    return (
        <CustomModal
            isOpen={isOpen[ModalOpenEnum.EDIT]}
            toggleModal={() => toggleModal(ModalOpenEnum.EDIT)}
            moduleLabel={'constant_week'}
            actionLabel={constants ? "edit" : "create"}
            descriptionLabel={constants ? `${constants.week_number}` : ''}
        >
            <Formik
                initialValues={initialValue}
                validationSchema={validateFormWeekConstants}
                onSubmit={(values, actions) => {
                    onSave(values);
                    actions.setSubmitting(false);
                }}
            >
                {({ handleSubmit }) => (
                    <ContainerForm formID={formId}>
                        <Box width={isTablet ? "100%" : '50%'}>
                            <FormTextInput
                                name={week_number.name}
                                label={week_number.label}
                                type={"number"}
                                style={{ width: "95%" }}
                                fullWidth={true}
                                disabled={constants ? true : false}
                            />
                        </Box>
                        <Box width={isTablet ? "100%" : '50%'}>
                            <FormTextInput
                                name={constant.name}
                                label={constant.label}
                                type={"number"}
                                inputProps={{ step: 0.01, default: 0.25 }}
                                fullWidth={true}
                            />
                            <ButtonSave
                                onClick={handleSubmit}
                                isLoading={isLoading}
                                edit={constants ? true : false}
                            />
                        </Box>
                    </ContainerForm>
                )}
            </Formik>
        </CustomModal>
    )
}
