import { createStyles } from '@material-ui/styles'
import { CalendarMonth } from '@mui/icons-material'
import { Box } from '@mui/material'
import React from 'react'
import { FormTextInput } from '../../../../common/Components/FormTextInput'
import { formatDate } from '../../../../helpers/formatDate'

export const InputCalendar = ({ fecha_excluida, nameFormInput, nameInput, isOnlyCurrentYear, handleChange }) => {
    return (
        <FormTextInput
            name={nameInput}
            type="text"
            value={fecha_excluida ? formatDate(fecha_excluida, false, isOnlyCurrentYear) : ''}
            fullWidth={true}
            autoComplete="off"
            InputProps={{
                contentEditable: false,
                endAdornment: (
                    <div style={{ ...formStyles.uploadButtons }}>
                        <label htmlFor='input_date' style={formStyles.labelInputFile}>
                            <span id={'input_date'} style={formStyles.spanInputFile}>
                                <input
                                    hidden={false}
                                    type="date"
                                    name={nameInput}
                                    id={'input_date'}
                                    style={formStyles.btnInputFile}
                                    onChange={handleChange}
                                />
                                <Box style={formStyles.containerLink}>
                                    <CalendarMonth style={formStyles.icon} />
                                </Box>
                            </span>
                        </label>
                    </div>
                )
            }}
        />
    )
}

const formStyles = createStyles({
    containerLink: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        cursor: 'pointer',
        textDecoration: 'none',
    },
    icon: {
        background: 'gray',
        color: 'white',
        borderRadius: '50%',
        padding: '5px',
        width: '24px',
        height: '24px'
    },
    txtLabel: {
        fontSize: '17px',
        fontWeight: 'bold',
        marginLeft: '5px',
    },
    uploadButtons: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginLeft: 20,
        display: 'flex'
    },
    labelInputFile: {
        cursor: 'pointer',
        // padding: '6px 12px',
        display: 'inline-block',
        // paddingTop: 20,
    },
    spanInputFile: {
        position: 'relative',
        overflow: 'hidden',
    },
    btnInputFile: {
        position: 'absolute',
        top: 0,
        right: 10,
        left: 0,
        width: 100,
        height: 30,
        minWidth: 50,
        minHeight: 20,
        fontSize: '100px',
        textAlign: 'right',
        filter: 'alpha(opacity=0)',
        translateX: '50%',
        opacity: 0,
        outline: 'none',
        background: 'white',
        cursor: 'pointer',
        display: 'block'
    }
})