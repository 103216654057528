import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { TablesTitles } from "../../components/TablesTitles";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { months } from "../../../../helpers/months";
import { formatMoney, formatNumber } from "../../../../helpers/formatNumber";



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    border: 2
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const TableUnidTrans = ({ dataTransacction }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: 700,

      }}
    >
      <TablesTitles title={"Unidades vs Transacciones"} />

      <TableContainer sx={{ border: 1, height: 600, width: '100%' }} component={Paper}>
        <Table style={{ paddingInline: 2 }} aria-label="customized table">
          <TableHead style={{ ...styles.tableHead }}>
            <TableRow style={{ ...styles.tableRow }}>
              {
                dataTransacction && dataTransacction.length > 0 &&
                (
                  <TableHead>
                    <StyledTableCell style={{}} align="left" >Mes de Fecha</StyledTableCell>
                    {
                      months.map((m, i) => (
                        < StyledTableRow
                          style={{
                            ...styles.tableRow,
                            height: 40
                          }}
                          key={`${m?.month_name}-${i}`}
                        >
                          <StyledTableCell style={{ width: '100%' }} align="left" >{m.month_name}</StyledTableCell>
                        </StyledTableRow>
                      ))
                    }
                  </TableHead>
                )
              }

            </TableRow>
            <TableRow style={{ ...styles.tableRow }} >
              {
                dataTransacction && dataTransacction.map((row, index) => (
                  <TableHead key={`${row?.year}-${index}`}>
                    <TableRow style={{ ...styles.tableRow, justifyContent: 'center' }} >
                      <StyledTableCell style={{ width: '100%', height: '10%' }} align="center" >{row.year}</StyledTableCell>
                    </TableRow>
                    <TableRow style={{ ...styles.tableRow }} >
                      <StyledTableCell style={{ width: '50%' }} align="center" >Stock</StyledTableCell>
                      <StyledTableCell style={{ width: '50%' }} align="center" >Trans</StyledTableCell>
                    </TableRow>
                    {
                      row.data.map((item, i) => (
                        <StyledTableRow key={i} style={{ ...styles.tableRow, height: 40 }} >
                          <StyledTableCell style={{ width: '50%' }} align="center" >{formatNumber(item.stock)}</StyledTableCell>
                          <StyledTableCell style={{ width: '50%' }} align="center" >{formatNumber(item.trans)}</StyledTableCell>
                        </StyledTableRow>
                      ))
                    }
                  </TableHead>
                ))
              }
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    </Box >
  );
};

const styles = {
  tableHead: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  tableRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

}