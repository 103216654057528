import { Grid, Box } from '@mui/material'
import React, { useContext, useState, useEffect } from 'react'
import { SkeletonView } from '../../../../common/Components/SkeletonView'
import ModulesGraphContext from '../../../../common/Contexts/GraphContext'
import { useGraph } from '../../../../hooks/useGraph'
import RadarControlService from '../../../../services/RadarControlService'
import { TablesTitles } from '../../components/TablesTitles'
import { TablesActions } from '../components/TablesActions'
import { EvoForecastGraphic } from './GraphEvoForecast'
import { LinearGraphic } from './GraphLinear'
import { TableUnidTrans } from './TableUnidTrans'

const dataGraphs = {
    TRANSACCTION: [],
    FORECAST: [],
    EVOLUTIVO: [],
}


export const GraphsFooter = () => {

    const { data, handleData, isAppReady, setIsAppReady } = useGraph(dataGraphs);
    const { ENUM_FILTERS, filters, queryFilter } = useContext(ModulesGraphContext);
    const [graphEvoLinear, setGraphEvoLinear] = useState(false);

    const getAllPromises = async () => {
        setIsAppReady(true);
        const query = queryFilter ? `?${queryFilter}` : "";
        const queryCod = filters ? `${filters[ENUM_FILTERS.cod]}` : "";
        try {
            const promises = [
                RadarControlService.getTransaccionVentas(query),
                RadarControlService.getForecast(queryCod),
                RadarControlService.getRadarControlGraph(queryCod),
            ];
            const [dataTransacction, dataForecast, dataEvolutivo] = await Promise.all(promises);
            console.log("dataTransacction", dataTransacction?.data);
            console.log("dataForecast", dataForecast?.data);
            console.log("dataEvolutivo", dataEvolutivo?.data);

            handleData("TRANSACCTION", dataTransacction?.data);
            handleData("FORECAST", dataForecast?.data);
            handleData("EVOLUTIVO", dataEvolutivo?.data);
        } catch (error) {
            console.log("getPromises", error);
        } finally {
            setIsAppReady(false);
        }
    }
    useEffect(() => {
        // console.log('queryFilter', queryFilter, filters);
        if (queryFilter) {
            getAllPromises();
        }
    }, [queryFilter]);

    return (
        <React.Fragment>
            <Grid container spacing={2} sx={{ marginTop: 2 }}>
                <Grid item xs={5}>
                    {
                        !isAppReady || data.TRANSACCTION.length > 0
                            ? <TableUnidTrans dataTransacction={data.TRANSACCTION} />
                            : <SkeletonView height={400} />
                    }
                </Grid>
                <Grid item xs={7}>
                    <Box sx={{ width: "100%", height: 400, }}>
                        {
                            !isAppReady || (data.EVOLUTIVO.length > 0 && data.FORECAST)
                                ? (
                                    <React.Fragment>
                                        <TablesTitles title={"Inventario vs Ventas"} />
                                        <TablesActions
                                            graph={true}
                                            type={true}
                                            seller={false}
                                            handleSwitch={setGraphEvoLinear}
                                        />
                                        {
                                            graphEvoLinear && data.FORECAST.length > 0
                                                ? <EvoForecastGraphic dataGraph={data.FORECAST} />
                                                : <LinearGraphic dataGraph={data.EVOLUTIVO} />
                                        }
                                    </React.Fragment>
                                )
                                : <SkeletonView height={400} />

                        }
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
