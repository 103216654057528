import React, { useState, useContext, useEffect, useMemo } from 'react'
import { Box } from '@mui/material'
import Title from '../../../common/Components/Title'
import { t } from 'i18next'
import { ModulesProvider } from '../../../common/Providers/ModulesProvider';
import RolService from '../../../services/RolesService';
import { LayoutTable } from '../../../common/Components/LayoutTable';
import { TableRoles } from './components/TableRoles';
import { RolesForm } from './RolesForm';
import { CustomModalDelete } from '../../../common/Components/CustomModalDelete';
import BranchsContext from '../../../common/Contexts/BranchContext';
import { useForm } from '../../../hooks/useForm';
import { CToast } from '../../../common/Components/ToastNotify';
import ModulesFormContext from '../../../common/Contexts/ModulesFormContext';
import { ModalOpenEnum } from '../../../common/utils/ModalOpenEnum';
import { styleSheet } from '../../../common/styles/StylesCommon';

const Roles = () => {

    const overallStyle = useMemo(() => styleSheet(), []);
    const { companyID } = useContext(BranchsContext);
    //LOGIC FORM
    const {
        isOpen,
        rowData,
        toggleModal,
        dataTable,
        setDataTable,
        isTableLoading,
        setIsTableLoading
    } = useForm();
    const [isLoadingDelet, setIsLoadingDelet] = useState(false);

    const getDataTable = async () => {
        setIsTableLoading(true);
        try {
            const { data } = await RolService.getRoles();
            setDataTable(data);
        } catch (error) {
            console.log(error);
            CToast(t('messages.error_get_dataTable'), "error", true);
        } finally {
            setIsTableLoading(false);
        }
    }


    const handleDeletRoles = async () => {
        setIsLoadingDelet(true);
        const req = {
            id_rol: rowData?.id_rol
        }
        try {
            const { data } = await RolService.deleteRol(req);
            CToast(t('messages.success_delete'), "success", true);
            getDataTable();
            toggleModal(ModalOpenEnum.DELETE);
        } catch ({ response }) {
            if (response?.data?.error) return CToast(response?.data?.error, "error", true);
            CToast(t('messages.error_delete'), "error", true);
        } finally {
            setIsLoadingDelet(false);
        }
    }

    useEffect(() => {
        companyID.length > 0 && getDataTable();
    }, [companyID]);

    return (
        <ModulesProvider value={{
            getDataTable,
            handleDelete: handleDeletRoles,
            isOpen,
            rowData,
            toggleModal,
            dataTable, isTableLoading,
            setDataTable,
        }}>
            <Box sx={overallStyle?.container}>
                <Title name={t('titles.roles')} />
                <LayoutTable labelAction={'Agregar Rol'}>
                    {
                        isOpen[ModalOpenEnum.EDIT] && (<RolesForm />)
                    }
                    {
                        isOpen?.delete && (
                            <CustomModalDelete
                                moduleLabel={'role'}
                                descriptionLabel={rowData && rowData.rol_name}
                                isLoading={isLoadingDelet}
                            />
                        )
                    }
                    <TableRoles />
                </LayoutTable>
            </Box>
        </ModulesProvider>
    )
}


export default Roles