import { LoadingButton } from '@mui/lab';
import { Box, Stack } from '@mui/material';
import React, { useContext, useMemo } from 'react'
import ModulesFormContext from '../Contexts/ModulesFormContext';
import { styleSheet } from '../styles/StylesCommon';
import { ModalOpenEnum } from '../utils/ModalOpenEnum';
import CustomModal from './CustomModal';
import { TitleStep } from './TitleStep';

export const CustomModalDelete = ({ moduleLabel, descriptionLabel, isLoading,hiddenModule }) => {
    const { isOpen, toggleModal, rowData, handleDelete, getDataTable, overallStyle } = useContext(ModulesFormContext);

    // const overallStyle = useMemo(() => styleSheet(), []);
    return !isOpen[ModalOpenEnum.DELETE] ? null : (
        <CustomModal
            isOpen={isOpen[ModalOpenEnum.DELETE]}
            toggleModal={() => toggleModal(ModalOpenEnum.DELETE)}
            moduleLabel={moduleLabel || 'register'}
            actionLabel='delete'
            descriptionLabel={`${descriptionLabel || 'el registro'}`}
            hiddenModuleLabel={hiddenModule}
        >
            <TitleStep
                title={`¿Seguro desea eliminar el registro?. Esta acción es permanente y no se puede deshacer`}
                size={"22px"}
                color={"common.button"}
                marginBottom={"40px"}
            />
            <Stack direction={"row"} marginX={10}>
                <LoadingButton
                    onClick={() => { toggleModal(ModalOpenEnum.DELETE); getDataTable() }}
                    fullWidth
                    variant="contained"
                    sx={{
                        ...overallStyle.buttonForm, marginTop: 2, marginRight: 10,
                    }}

                >Salir
                </LoadingButton>
                <LoadingButton
                    onClick={async () => {
                        await handleDelete();
                        toggleModal(ModalOpenEnum.DELETE);
                    }}
                    fullWidth
                    variant="contained"
                    sx={{
                        ...overallStyle.buttonFormDelete,
                        marginTop: 2,
                    }}
                    loading={isLoading}
                >
                    Borrar
                </LoadingButton>
            </Stack>

        </CustomModal>
    )
}
