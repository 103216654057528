import { FormControl, MenuItem, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react'
import { CustomSelect } from '../CustomSelect';

export const CustomSelectBranch = ({ formField, isLoading, companys }) => {
    const { branchID, branchs_selected } = formField;
    const { setFieldValue, values, errors } = useFormikContext();
    const branchs = companys.filter(company => company.company_id === values.companyID)[0]?.branchs;

    const handleChange = (data) => {
        const dataArr = new Set([...values.branchs_selected, data]);
        // setBranchsSelected([...dataArr]);
        setFieldValue(branchs_selected.name, [
            ...[...dataArr]
        ]);
    };
    return (
        <>
            <Typography mb={1} mt={2} color={"common.title"} variant={"h6"}>
                Seleccionar Sucursal
            </Typography>
            <FormControl
                fullWidth
                sx={{ mb: 5, mt: 0 }}
            >
                <CustomSelect
                    id={branchID.name}
                    label={branchID.label}
                    labelId={branchID.label}
                    name={branchID.name}
                    disabled={isLoading || values.companyID?.length === 0}
                    sx={{
                        textAlign: 'left',
                        borderRadius: 20,
                        height: "50px",
                        borderColor:
                            "linear-gradient(90deg, rgba(7,64,107,1) 0%, rgba(7,64,107,1) 35%, rgba(0,184,255,1) 100%)",
                    }}
                >
                    {branchs && branchs.map((item, index) => (
                        <MenuItem key={index} value={item.company_id} onClick={() => handleChange(item)}>
                            {item?.company_name}
                        </MenuItem>
                    ))}
                </CustomSelect>
            </FormControl>
        </>
    );
}
