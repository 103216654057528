import { Typography, Box } from "@mui/material";
import React, { useContext } from "react";
import MediaContext from "../Contexts/MediaContext";
import { styleSheet } from "../styles/StylesCommon";

export const CDetails = ({ label = "S/N", value = "S/N" }) => {
  const { isMobile, isTablet } = useContext(MediaContext);
  const styles = styleSheet(isMobile, isTablet);

  return (
    <Box
      sx={{
        ...styles.boxDetails,
      }}
    >
      <Box
        sx={{
          ...styles.columnDetails,
        }}
      >
        <Typography
          sx={{
            ...styles.typographyDetails,
          }}
        >
          {label}:
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography> {value}</Typography>
      </Box>
    </Box>
  );
};
