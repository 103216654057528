import React, { useEffect, useState, useContext } from "react";
import CompanysService from "../../services/CompanysService";
import { CompanyContextProvider } from "../Contexts/CompanysContext";
import Sidebar from "../Sidebar/Sidebar";
import {
  Box,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { Outlet } from "react-router-dom";
import { ThemeProvider as ThemeMaterialUI } from "@mui/material/styles";
import ThemeContext from "../Contexts/ThemeContext";
import themes from "../styles/createTheme";
import SideBarContext from "../Contexts/SidebarContext";
import { PermissionContextProvider } from "../Contexts/PermissionContext";
import { SidebarService } from "../../services/SidebarService";
import UsersService from "../../services/UsersService";
import UserContext from "../Contexts/UserContext";
import { BranchsContextProvider } from "../Contexts/BranchContext";
import { styleSheet } from "../styles/StylesCommon";
import AuthContext from "../Contexts/AuthContext";
import { Footer } from "../Sidebar/Footer";
import { CDrawer } from "../Sidebar/Drawer";
import MediaContext from "../Contexts/MediaContext";
import isEmpty from "lodash/isEmpty";
import { getTokenTime } from "../Auth/getTokenTime";
import RadarControlService from "../../services/RadarControlService";
import { SwitchTheme } from "../Components/SwitchTheme";


var store = require("store");

export const SideboardRoutes = () => {
  const user = store.get("user");
  const { refreshToken, tokenTime } = useContext(AuthContext);
  const { getSidebar, setIsLoadingSidebar } = useContext(SideBarContext);
  const { isTablet } = useContext(MediaContext);
  const { state } = useContext(ThemeContext);
  const { setLight, setDark } = useContext(ThemeContext);
  const { refreshUserStore } = useContext(UserContext);
  const [company, setCompany] = useState("");
  const [branchID, setBranchID] = useState("");
  const [branchs, setBranchs] = useState([]);
  const [companyID, setCompanyID] = useState("");
  const [companys, setCompanys] = useState([]);
  const [datas, setDatas] = useState({});
  const [configs, setConfigs] = useState([]);
  const [models, setModels] = useState([]);
  const [systemRules, setSystemRules] = useState([]);
  const styles = styleSheet();

  const getCompanys = async () => {
    try {
      const { data } = await UsersService.getCompaniesByUser();
      setCompanys(data);
      const filterBranchs = data.filter((comp) => comp.company_id === companyID);
      setBranchs(filterBranchs[0]?.branchs);
      setCompanyID(companyID ? companyID : data[0]?.company_id);
    } catch (error) {
      console.log(error);
    }
  };

  const getDataChart = async () => {
    try {
      const { data } = await RadarControlService.getLinerChar();
      setDatas(data);
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  const getModuleCompany = async () => {
    try {
      const { data } = await SidebarService.getElementsLogic();
      setConfigs(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getModule = async () => {
    try {
      const { data } = await SidebarService.getModel();
      setModels(data.data);
    } catch (error) {
      console.log(error);
    }
  };


  const getAllPromises = async () => {
    try {
      setIsLoadingSidebar(true);
      // await getDataChart();
      await getSidebar();
      await getCompanys();
      await getModuleCompany();
      await getModule();
    } catch (error) {
      setIsLoadingSidebar(false)

    }
  }


  useEffect(() => {
    getAllPromises();
  }, []);


  useEffect(() => {
    console.log('REFRESCAR TOKEN', tokenTime);
    const interval = (tokenTime > 0) && setInterval(async () => {
      await refreshToken(user);
    }, (tokenTime * 950));
    return () => {
      isEmpty(user) && clearInterval(interval);
    };
  }, [refreshToken, tokenTime]);

  const toggleTheme = async (e) => {
    const checked = e.target.checked;
    const req = {
      email: user.email,
      prefs: checked ? 0 : 1,
    };

    try {
      const { data } = await UsersService.changePref(req);
      data.prefs === 0 ? setDark(0) : setLight(1);
      refreshUserStore(data);
    } catch (error) {
      console.log(error);
    }
  };

  // const handleModules = async () => {
  //   (!isEmpty(company) && isEmpty(branchID))
  //     ? setSystemRules(company?.elements_cluster)
  //     : (!isEmpty(company) && !isEmpty(branchID))
  //       ? setSystemRules(
  //         branchs?.filter((branch) => branch.company_id === branchID)[0]
  //           ?.elements_cluster
  //       )
  //       : setSystemRules(user?.roles);
  // }

  const handleJoinRoles = async () => {
    const userRol = user?.roles;

    const mode = userRol?.map((rol) => rol.modulo_cluster);
    const elementRol = (!isEmpty(company) && isEmpty(branchID))
      ? company?.elements_cluster
      : (!isEmpty(company) && !isEmpty(branchID))
        ? branchs?.filter((branch) => branch.company_id === branchID)[0]
          ?.elements_cluster
        : user?.roles;
    // console.log(elementRol);
    const filterRoles = elementRol.map((rol, i) => userRol.find((role) =>
      (role?.modulo_id === rol.modulo_id && role?.active === rol.active)
      && (
        rol.modulo_cluster.filter((m, j) =>
          (m.element_id === mode[i][j]?.element_id && m.active === mode[i][j]?.active) &&
          m.actions.filter((a, k) =>
            (a.action_id === mode[i][j].actions[k]?.action_id)
            && a.active === mode[i][j].actions[k]?.active
          )
        )
      )
    )
    ).filter((rol) => !!rol);
    // console.log("ELEMENT ROL", filterRoles)
    setSystemRules(filterRoles);
  }

  useEffect(() => {
    handleJoinRoles();
  }, [companyID, branchID]);

  return (
    <Grid
      container
      height={'100vh'}
      overflow={'hidden'}
      sx={{ display: 'flex' }}
    >
      <CompanyContextProvider
        value={{ companys, setCompanys, company, setCompany, getAllCompanys: getCompanys, datas, systemRules }}
      >
        <ThemeMaterialUI theme={themes[state.theme]}>
          <PermissionContextProvider
            value={{
              configs,
              getModuleCompany,
              models,
            }}
          >
            <BranchsContextProvider
              value={{
                branchID,
                setBranchID,
                branchs,
                setBranchs,
                companyID,
                setCompanyID,
              }}
            >
              {!isTablet && (
                <Grid item xs={2}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "102vh",
                      justifyContent: "space-between",
                      backgroundColor: "background.paper",
                    }}
                  >
                    <Sidebar />
                    <Footer />
                  </Box>
                </Grid>
              )}

              <Grid
                item
                xs={isTablet ? 12 : 10}
                sx={
                  state.theme === 1
                    ? {
                      backgroundColor: "background.default",
                      display: "flex",
                      flexDirection: "column",
                      overflow: 'auto',
                      height: "100vh",
                      maxHeight: "100rem",
                      paddingBottom: 20
                    }
                    : {
                      background:
                        "linear-gradient(90deg, rgba(3,42,59,1)  0%, rgba(1,8,11,1) 35%, rgba(1,8,1,1) 100%)",
                      height: "100vh",
                      maxHeight: "100rem",
                      paddingBottom: 20,
                      overflow: 'auto'
                    }
                }
              >
                <Box
                  sx={{
                    ...styles.boxBody,
                    m: 1,
                    justifyContent: isTablet ? "space-between" : "flex-end",
                  }}
                >

                  {isTablet && <CDrawer />}

                  <FormControlLabel
                    control={
                      <SwitchTheme
                        size="medium"
                        checked={state.theme === 0 ? true : false}
                        onClick={(e) => e.stopPropagation()}
                        onChange={toggleTheme}
                      />
                    }
                  />
                </Box>
                <Outlet />
              </Grid>
            </BranchsContextProvider>
          </PermissionContextProvider>
        </ThemeMaterialUI>
      </CompanyContextProvider>
    </Grid>
  );
};
