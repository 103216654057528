import { Box, Stack } from '@mui/material';
import { Formik } from 'formik';
import React from 'react'
import { useState } from 'react';
import { useContext } from 'react';
import { ButtonSave } from '../../../common/Components/ButtonSave';
import { ContainerForm } from '../../../common/Components/ContainerForm';
import CustomModal from '../../../common/Components/CustomModal';
import { FormTextInput } from '../../../common/Components/FormTextInput';
import { CToast } from '../../../common/Components/ToastNotify';
import BranchsContext from '../../../common/Contexts/BranchContext';
import MediaContext from '../../../common/Contexts/MediaContext';
import ModulesFormContext from '../../../common/Contexts/ModulesFormContext';
import { ModalOpenEnum } from '../../../common/utils/ModalOpenEnum';
import { weekdays } from '../../../helpers/weekdays';
import { validateFormCalendar } from '../../../model/calendar/calendar.schema';
import checkoutFormModel from '../../../model/calendar/calendarFormModel'
import CalendarService from '../../../services/CalendarService';
import { SelectDay } from './components/SelectDay';
import { SelectDispachtDay } from './components/SelectDispachtDay';
import { SelectWarehouse } from './components/SelectWarehouse';

export const CalendarForm = () => {
    const { isOpen, toggleModal, rowData: calendar, getDataTable } = useContext(ModulesFormContext);
    const { branchID } = useContext(BranchsContext);
    const { isMobile, isTablet } = useContext(MediaContext);
    const [isLoading, setIsLoading] = useState(false)
    const [warehouses, setWarehouses] = useState([]);
    const { formId, formField: { replacement_day, dispatch_day, lead_time, id_bodega, delivery_time } } = checkoutFormModel;

    const initialValue = {
        dispatch_day: calendar?.dispatch_day || "",
        lead_time: calendar?.lead_time || 0,
        delivery_time: calendar?.delivery_time || 0,
        replacement_day: calendar?.replacement_day || "",
        id_bodega: calendar?.id_bodega || "",
        isEditing: calendar ? true : false,
        id_calendario: calendar?.id_calendario,
    };
    const onSave = async (value) => {
        setIsLoading(true);
        const req = {
            dispatch_day: value?.dispatch_day,
            lead_time: value?.lead_time,
            delivery_time: value?.delivery_time || 0,
            replacement_day: value?.replacement_day,
            company_id: branchID,
            id_bodega: value?.id_bodega,
        }
        value.isEditing && (req.id_calendario = value.id_calendario);
        try {
            const { data } = value.isEditing
                ? await CalendarService.updateCalendars(req)
                : await CalendarService.createCalendars(req);
            await getDataTable();
            toggleModal(ModalOpenEnum.EDIT);
            CToast(`Fecha ${value.isEditing ? 'editada' : 'creada'} correctamente`, "success", true);
        }
        catch ({ response }) {
            console.log('error', response)
            CToast(`Error al crear ${JSON.stringify(response?.data.error)}`, "error", true);
        }
        finally {
            setIsLoading(false);
        }
    }
    return (
        <CustomModal
            isOpen={isOpen[ModalOpenEnum.EDIT]}
            toggleModal={() => toggleModal(ModalOpenEnum.EDIT)}
            moduleLabel={'calendar'}
            actionLabel={calendar ? "edit" : "create"}
            descriptionLabel={calendar ? `${weekdays.find(w => w.day_id === calendar.dispatch_day)?.day_name}` : ''}
        >
            <Formik
                initialValues={initialValue}
                validationSchema={validateFormCalendar}
                onSubmit={(values, actions) => {
                    onSave(values);
                    actions.setSubmitting(false);
                }}
            >
                {({ handleSubmit, values, errors, setFieldValue, setFieldError }) => (
                    <ContainerForm formID={formId}>
                        <Box px={8} py={5}>
                            <SelectWarehouse
                                name={id_bodega.name}
                                label={id_bodega.label}
                                warehouses={warehouses}
                                setWarehouses={setWarehouses}
                            />
                            <Stack
                                py={5}
                                direction={isTablet ? "column" : "row"}
                                justifyContent={"space-around"}
                            >
                                <Box width={isTablet ? "100%" : '50%'}>
                                    <SelectDay name={replacement_day.name} label={replacement_day.label} />
                                    <FormTextInput
                                        label={lead_time.label}
                                        name={lead_time.name}
                                        inputProps={{ min: lead_time.min, max: lead_time.max, step: 1 }}
                                        type={"number"}
                                        style={{ width: "95%" }}
                                    />
                                </Box>
                                <Box width={isTablet ? "100%" : '50%'} >
                                    <SelectDispachtDay
                                        label={dispatch_day.label}
                                        name={dispatch_day.name}
                                    />
                                    <FormTextInput
                                        label={delivery_time.label}
                                        name={delivery_time.name}
                                        inputProps={{ min: lead_time.min, step: 1 }}
                                        type={"number"}
                                        style={{ width: "95%" }}
                                    />
                                </Box>
                            </Stack>
                            <Box width={"100%"}>
                                <ButtonSave
                                    onClick={handleSubmit}
                                    isLoading={isLoading}
                                    edit={values.isEditing ? true : false}
                                />
                            </Box>
                        </Box>
                    </ContainerForm>
                )}
            </Formik>
        </CustomModal>
    )
}
