import { Box, Divider, Modal } from '@mui/material'
import React from 'react'
import { useContext } from 'react';
import { useMemo } from 'react';
import MediaContext from '../Contexts/MediaContext';
import { styleSheet } from '../styles/StylesCommon';
import { ButtonCloseModal } from './ButtonCloseModal';
import { CustomTitleModal } from './CustomTitleModal';

const CustomModal = ({ children, isOpen, toggleModal, styles, moduleLabel, actionLabel, descriptionLabel, hiddenModuleLabel = false }) => {
    const { isMobile, isTablet } = useContext(MediaContext);
    const overallStyle = useMemo(() => styleSheet(isMobile, isTablet), []);
    return (
        <Modal
            open={isOpen}
            onClose={(e, reason) => {
                if (reason === "backdropClick") {
                    console.log("backdrop");
                } else {
                    toggleModal();
                }
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                overflow: "auto",
            }}
        >
            <Box sx={{ ...overallStyle.box, ...styles }}>
                <CustomTitleModal
                    module={moduleLabel}
                    action={actionLabel}
                    description={descriptionLabel}
                    hiddenModuleLabel={hiddenModuleLabel}
                />
                <Divider color="#00b8ff" />
                <ButtonCloseModal handleClick={toggleModal} />
                {children}
            </Box>
        </Modal>
    )
}

export default CustomModal