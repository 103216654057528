export default {
    formId: "genericCheckoutForm",
    formField: {
        email: {
            name: "email",
            label: "Email del usuario",
            requiredErrorMsg: "El email es requerido",
            invalidErrorMsg: "La dirección email es invalido"
        },
        password: {
            name: "password",
            label: "Contraseña de usuario",
            min: 8,
            max: 32,
            minErrorMsg: "La contraseña debe tener al menos 8 caracteres",
            maxErrorMsg: "La contraseña debe tener menos de 32 caracteres",
            regex: /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
            regexErrorMsg: "La contraseña debe tener al menos una mayúscula, una minúscula y un caracter especial",
            requiredErrorMsg: "La contraseña es requerida",
        },
        password_confirmation: {
            name: "password_confirmation",
            label: "Confirmación de contraseña",
            requiredErrorMsg: "La confirmación de contraseña es requerida",
            matchErrorMsg: "Las contraseñas no coinciden"
        },
    }
}