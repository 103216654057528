import React, { useContext, useState, useEffect } from "react";
import { Box } from "@mui/material";
import Title from "../../../common/Components/Title";
import { t } from 'i18next'
import { ButtonsFileCSV } from "../../../common/Components/ButtonsFileCSV";
import DistributionService from "../../../services/DistributionCentresService";
import BranchsContext from "../../../common/Contexts/BranchContext";
import { LayoutTable } from "../../../common/Components/LayoutTable";
import { ModulesProvider } from "../../../common/Providers/ModulesProvider";
import { DistributionCenterForm } from "./DistributionCenterForm";
import { CustomModalDelete } from "../../../common/Components/CustomModalDelete";
import { DetailsDistributionCenter } from "./components/DetailDistributionCenter";
import { useForm } from "../../../hooks/useForm";
import { TableDistribution } from "./components/TableDistribution";
import ModulesFormContext from "../../../common/Contexts/ModulesFormContext";
import { CToast } from "../../../common/Components/ToastNotify";
import { ModalOpenEnum } from "../../../common/utils/ModalOpenEnum";



const DistributionCenter = () => {
    const { overallStyle } = useContext(ModulesFormContext);
    const { branchID } = useContext(BranchsContext);
    //LOGIC FORM
    const {
        isOpen,
        rowData,
        toggleModal,
        dataTable,
        setDataTable,
        isTableLoading,
        setIsTableLoading
    } = useForm();
    const [isLoadingDelet, setIsLoadingDelet] = useState(false);

    const getDataTable = async () => {
        setIsTableLoading(true);
        const req = {
            company_id: String(branchID),
        };
        try {
            const { data } = await DistributionService.getDistributionCenterByBranch(req);
            setDataTable(data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsTableLoading(false);
        }
    };

    const handleDeleteCenter = async () => {
        setIsLoadingDelet(true);
        const req = {
            company_id: String(branchID),
            id_centrodedistribucion: String(rowData?.id_centrodedistribucion),
        };
        try {
            const { data } = await DistributionService.deleteDistributionCenter(req);
            getDataTable();
            CToast(t('messages.success_delete'), "success", true);
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoadingDelet(false);
        }
    }

    useEffect(() => {
        branchID.length > 0 && getDataTable();
    }, [branchID]);

    return (
        <ModulesProvider value={{
            getDataTable,
            handleDelete: handleDeleteCenter,
            isOpen,
            rowData,
            toggleModal,
            dataTable,
            setDataTable,
            isTableLoading,
        }}>
            <Box sx={overallStyle?.container}>
                <Title name={t('titles.distribution_centers')} />
                <ButtonsFileCSV />
                <LayoutTable labelAction={'Agregar Centro'}
                    disabledButon={branchID.length === 0 ? true : false}>
                    {
                        isOpen[ModalOpenEnum.EDIT] && (
                            <DistributionCenterForm />
                        )
                    }
                    {
                        isOpen[ModalOpenEnum.DETAIL] &&
                        (<DetailsDistributionCenter />)
                    }
                    {isOpen[ModalOpenEnum.DELETE] && (<CustomModalDelete
                        descriptionLabel={rowData?.cd ? rowData.cd : ''}
                        isLoading={isLoadingDelet}
                        moduleLabel={'distribution_center'}
                    />)}
                    <TableDistribution />
                </LayoutTable>
            </Box>
        </ModulesProvider>

    )
}


export default DistributionCenter