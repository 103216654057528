import { Chip } from "@mui/material";
import React from "react";
import { CustomTable } from "../../../../common/Components/CustomTable";

export const TableLogs = () => {

  const headers = [
    { field: "ip_publica", headerName: "Ip publica ", width: 130 },
    {
      field: "categoria",
      headerName: "Categoria",
      width: 120,
      cellRenderer: (p) => {
        switch (p.value) {
          case "ERROR":
            return (
              <Chip
                label={p.value}
                sx={{ border: "1px solid red", backgroundColor: "transparent" }}
              />
            );
          case "INFO":
            return (
              <Chip
                label={p.value}
                sx={{ border: "1px solid ", backgroundColor: "transparent" }}
              />
            );
          default:
            break;
        }
      },
    },
    { field: "username", headerName: "Email", width: 180 },
    { field: "descripcion", headerName: "Descripcion", width: 500 },
    { field: "fechaCorta", headerName: "Fecha", width: 130 },
    { field: "hora", headerName: "Hora", width: 130 },
  ];

  return (<CustomTable headers={headers}  />);
};
