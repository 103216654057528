import { Box, FormControlLabel, Switch, Typography } from "@mui/material";
import { useField } from 'formik';
import { styleSheet } from "../styles/StylesCommon";

export const CSwitchForm = ({ label,title = "Estado", ...props }) => {
 
  const [ field ] = useField(props)
  const { value } = field;
  const styles = styleSheet()
  return (
    <Box
      display={'flex'}
      width={'100%'}
    
    >
       <Typography color={"common.title"} variant={"h6"} sx={{marginRight:2}}>
        {title}
      </Typography>
      <FormControlLabel
        control={
          <Switch
            id={props.id}
            value={value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            size="medium"
            sx={{
           
            ...styles.checkState
          }}
            checked={field.value}
          
          />
        }
        label={`${field.value ?'Activo' : 'Inactivo'}`}
      />
    </Box>
  );
};
