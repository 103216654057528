import React, { useContext, useEffect, useState } from "react";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Skeleton,
  IconButton,
} from "@mui/material";
import BranchsContext from "../Contexts/BranchContext";
import CancelIcon from '@mui/icons-material/Cancel';

export const SelectBranchsSidebar = ({ companyID }) => {
  const { branchID, setBranchID, branchs, setBranchs } = useContext(BranchsContext);

  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (event) => {
    setBranchID(event.target.value);
  };

  const cleanBranch = () => {
    setBranchID([]);
  }

  return (
    <>
      {isLoading === true ? (
        <Skeleton animation="wave" height={90} width='100%' style={{ borderRadius: 35, }} />
      ) : (
        <FormControl 
        fullWidth 
        
        style={{
          
          justifyContent: 'center',
          
          // width: '100%',
          // marginInline: 20,
        }}>
          <InputLabel id="demo-simple-select-label">Sucursales</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={branchID}
         
            label="Sucursales"
            onChange={handleChange}
            sx={{
              
       
              borderRadius: 20,
              height: "50px",
              borderColor:
                "linear-gradient(90deg, rgba(7,64,107,1) 0%, rgba(7,64,107,1) 35%, rgba(0,184,255,1) 100%)",
            }}
          >
            {branchs?.map((branch, index) => (
              <MenuItem key={index} value={branch?.company_id} 
              sx={{
               
              }}>
                {branch?.company_name}
              </MenuItem>
            ))}
          </Select>
          {
            branchID.length > 0 && (
              <IconButton onClick={cleanBranch} style={{ position: 'absolute', right: -5, top: -20, }}>
                <CancelIcon style={{ color: 'red', fontSize: 30 }} />
              </IconButton>
            )
          }
        </FormControl>
      )}
    </>
  );
};
