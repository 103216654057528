import React, { useState, useEffect, useContext } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { SidebarService } from "../../services/SidebarService";
import { useFormikContext } from "formik";
import { CustomSelect } from "./CustomSelect";
import isEmpty from "lodash/isEmpty";
import { t } from "i18next";

var store = require("store");

export const SelectConfigAccess = (props) => {
  const { label = "S/A", code, name, configsAccess, name_elements_checks, isLoading, nameConfigEqual }
    = props;
  // const cod_element = !isEmpty(code) ? configs.filter(item=>item.cod_element_logic === code) : configs
  const { values, setFieldValue, handleChange } = useFormikContext();

  // useEffect(() => {
  //   // getConfigsxCompany();
  //   console.log("values", values.select_permission);
  // }, [values.select_permission]);

  return (
    <>
      <Typography mb={1} variant={"h6"} color={'common.button'}>
        {t('labels_actions.select_config')}
      </Typography>
      <FormControl fullWidth={props.fullWidth}>
        <CustomSelect
          sx={{
            borderRadius: 20,
            height: "50px",
            borderColor: "linear-gradient(90deg, rgba(7,64,107,1) 0%, rgba(7,64,107,1) 35%, rgba(0,184,255,1) 100%)",
          }}
          labelId={label}
          id={name}
          name={name}
          label={label}
          isLoading={isLoading}
          disabled={isLoading}
          onChange={handleChange}
        >
          {configsAccess?.map((element, index) => (
            <MenuItem
              key={element.id_element_logic}
              onClick={() => setFieldValue(name_elements_checks.name, element[name_elements_checks.cluster])}
              value={element.id_element_logic}
            >
              {element.cluster_name}
            </MenuItem>
          ))}
        </CustomSelect>
      </FormControl>
    </>
  );
};
