import React from "react";
import { CustomTable } from "../../../../common/Components/CustomTable";
import checkoutForm from "../../../../model/suggestedParameters/suggestedFormModel";

export const TableSuggestedParameters = () => {

  const { formField: {
    reference_days,
    classificationA,
    classificationB,
    classificationC,
    description,
    constantf1f2,
    constantvalor1,
    constantvalor2,
    soft_limit,
    quantity_fraction,
    quantity_fraction2
  } } = checkoutForm;

  const headers = [
    { field: "reference_days", headerName: reference_days.headerTable, width: 180 },
    { field: "classificationA", headerName: classificationA.headerTable, width: 30 },
    { field: "classificationB", headerName: classificationB.headerTable, width: 30 },
    { field: "classificationC", headerName: classificationC.headerTable, width: 30 },
    { field: "description", headerName: description.headerTable },
    { field: "constantf1f2", headerName: constantf1f2.headerTable, width: 100 },
    { field: "constantvalor1", headerName: constantvalor1.headerTable, width: 100 },
    { field: "constantvalor2", headerName: constantvalor2.headerTable, width: 100 },
    { field: "soft_limit", headerName: soft_limit.headerTable, width: 100 },
    { field: "quantity_fraction", headerName: quantity_fraction.headerTable, width: 100 },
    { field: "quantity_fraction2", headerName: quantity_fraction2.headerTable, width: 100 },
  ];

  return (<CustomTable headers={headers} />);
};
