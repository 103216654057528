import { types } from "../../Types/Types";


export const authReducer = (state = {}, action) => {
  switch (action.type) {
    case types.login:
      return {
        ...state,
        logged: true,
        ...action.payload,
      };

    case types.logout:
      return {
        logged: false,
      };

    case types.refreshUser:
    
      return {
        ...state,
        user: {
          ...state.user,
          user: action.payload,
        },
      };

    default:
      return state;
  }
};
