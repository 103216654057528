import React from "react";
import { CustomTable } from "../../../../common/Components/CustomTable";
import checkoutForm from "../../../../model/constantsOfWeek/constantsFormModel";

export const TableConstantWeek = () => {

  const { formField: {
    week_number,
    constant
  } } = checkoutForm;

  const headers = [
    { field: "week_number", headerName: week_number.headerTable },
    { field: "constant", headerName: constant.headerTable },
  ];

  return (<CustomTable headers={headers} />);
};
