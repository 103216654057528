export default {
    formId: 'formSuggested',
    formField: {
        reference_days: {
            name: 'reference_days',
            label: 'Semana de referencia',
            headerTable: 'Semana de referencia',
            requiredErrorMsg: 'Las semanas de referencia son requeridas',
        },
        classificationA: {
            name: 'classificationA',
            label: 'Clasificación A',
            headerTable: 'A',
            requiredErrorMsg: 'La clasificación A es requerida',
            min: 1,
            max: 98,
            minErrorMsg: 'El valor debe ser mayor a 0',
            maxErrorMsg: 'El valor debe ser menor a 99',
        },
        classificationB: {
            name: 'classificationB',
            label: 'Clasificación B',
            headerTable: 'B',
            requiredErrorMsg: 'La clasificación B es requerida',
            min: 1,
            max: 99,
            minErrorMsg: 'El valor debe ser mayor a 0',
            maxErrorMsg: 'El valor debe ser menor a 100',
        },
        classificationC: {
            name: 'classificationC',
            label: 'Clasificación C',
            headerTable: 'C',
            requiredErrorMsg: 'La clasificación C es requerida',
            default: 100,
        },
        description: {
            name: 'description',
            label: 'Descripción',
            headerTable: 'Descripción',
            requiredErrorMsg: 'La descripción es requerida',
        },
        constantf1f2: {
            name: 'constantf1f2',
            label: 'Constantes F1 F2',
            headerTable: 'F1 F2',
            requiredErrorMsg: 'La constante F1 F2 es requerida',
            min: 0,
            max: 100,
            minErrorMsg: 'El valor debe ser mayor o igual a 0',
            maxErrorMsg: 'El valor debe ser menor o igual a 100',
            default: 2,
        },
        constantvalor1: {
            name: 'constantvalor1',
            label: 'Valor constante 1',
            headerTable: 'Valor 1',
            requiredErrorMsg: 'La constante valor1 es requerida',
            min: 0,
            max: 100,
            minErrorMsg: 'El valor debe ser mayor o igual a 0',
            maxErrorMsg: 'El valor debe ser menor o igual a 100',
            default: 3.7,
        },
        constantvalor2: {
            name: 'constantvalor2',
            label: 'Valor constante 2',
            headerTable: 'Valor 2',
            requiredErrorMsg: 'La constante valor2 es requerida',
            min: 0,
            max: 100,
            minErrorMsg: 'El valor debe ser mayor o igual a 0',
            maxErrorMsg: 'El valor debe ser menor o igual a 100',
            default: 2.7,
        },
        soft_limit: {
            name: 'soft_limit',
            label: 'Limites de Soft',
            headerTable: 'Limite',
            requiredErrorMsg: 'El límite de soft es requerido',
            min: 1,
            minErrorMsg: 'El valor debe ser mayor a 0',
            default: 6,
        },
        quantity_fraction: {
            name: 'quantity_fraction',
            label: 'Quantity Fraction',
            headerTable: 'Q-F1',
            requiredErrorMsg: 'La fracción es requerida',
            min: 1,
            minErrorMsg: 'El valor debe ser mayor a 0',
            default: 2,
        },
        quantity_fraction2: {
            name: 'quantity_fraction2',
            label: 'Quantity Fraction 2',
            headerTable: 'Q-F2',
            requiredErrorMsg: 'La fracción 2 es requerida',
            min: 1,
            minErrorMsg: 'El valor debe ser mayor a 0',
            default: 2,
        },
        confirmed: {
            name: 'confirmed',
            label: 'Confirmado',
            headerTable: 'Confirmado',
            requiredErrorMsg: 'Debe seleccionar una opción',
            default: false,
        },
    },
}       