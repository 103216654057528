export const dataTestGraph = [
    {
        id: "Venta",
        data: [
            {
                id: "2016-1",
                month: 1,
                year: 2016,
                venta: 100,
                prom: 100
            },
            {
                id: "2016-2",
                month: 2,
                year: 2016,
                venta: 200,
                prom: 100
            },
            {
                id: "2016-3",
                month: 3,
                year: 2016,
                venta: 300,
                prom: 100
            },
            {
                id: "2016-4",
                month: 4,
                year: 2016,
                venta: 265,
                prom: 100
            },
            {
                id: "2016-5",
                month: 5,
                year: 2016,
                venta: 370,
                prom: 100
            },
            {
                id: "2017-1",
                month: 1,
                year: 2017,
                venta: 100,
                prom: 150
            },
            {
                id: "2017-2",
                month: 2,
                year: 2017,
                venta: 200,
                prom: 150
            },
            {
                id: "2017-3",
                month: 3,
                year: 2017,
                venta: 300,
                prom: 150
            },
            {
                id: "2017-4",
                month: 4,
                year: 2017,
                venta: 265,
                prom: 150
            },
            {
                id: "2017-5",
                month: 5,
                year: 2017,
                venta: 370,
                prom: 150
            },
            {
                id: "2018-1",
                month: 1,
                year: 2018,
                venta: 150,
                prom: 270
            },
            {
                id: "2018-2",
                month: 2,
                year: 2018,
                venta: 250,
                prom: 270
            },
            {
                id: "2018-3",
                month: 3,
                year: 2018,
                venta: 350,
                prom: 270
            },
        ],
    },
    {
        id: "Costo",
        data: [
            {
                id: "2016-1",
                month: 1,
                year: 2016,
                costo: 140,
                prom: 120
            },
            {
                id: "2016-2",
                month: 2,
                year: 2016,
                costo: 320,
                prom: 120
            },
            {
                id: "2016-3",
                month: 3,
                year: 2016,
                costo: 120,
                prom: 120
            },
            {
                id: "2016-4",
                month: 4,
                year: 2016,
                costo: 265,
                prom: 120
            },
            {
                id: "2016-5",
                month: 5,
                year: 2016,
                costo: 360,
                prom: 120
            },
            {
                id: "2017-1",
                month: 1,
                year: 2017,
                costo: 90,
                prom: 100
            },
            {
                id: "2017-2",
                month: 2,
                year: 2017,
                costo: 45,
                prom: 100
            },
            {
                id: "2017-3",
                month: 3,
                year: 2017,
                costo: 350,
                prom: 100
            },
            {
                id: "2017-4",
                month: 4,
                year: 2017,
                costo: 380,
                prom: 100
            },
            {
                id: "2017-5",
                month: 5,
                year: 2017,
                costo: 400,
                prom: 100
            },
            {
                id: "2018-1",
                month: 1,
                year: 2018,
                costo: 135,
                prom: 160
            },
            {
                id: "2018-2",
                month: 2,
                year: 2018,
                costo: 230,
                prom: 160
            },
            {
                id: "2018-3",
                month: 3,
                year: 2018,
                costo: 300,
                prom: 160
            },
        ],
    },
    {
        id: "Margen",
        data: [
            {
                id: "2016-1",
                month: 1,
                year: 2016,
                margen: 100,
                prom: 100
            },
            {
                id: "2016-2",
                month: 2,
                year: 2016,
                margen: 200,
                prom: 100
            },
            {
                id: "2016-3",
                month: 3,
                year: 2016,
                margen: 300,
                prom: 100
            },
            {
                id: "2016-4",
                month: 4,
                year: 2016,
                margen: 265,
                prom: 100
            },
            {
                id: "2016-5",
                month: 5,
                year: 2016,
                margen: 370,
                prom: 100
            },
            {
                id: "2017-1",
                month: 1,
                year: 2017,
                margen: 100,
                prom: 150
            },
            {
                id: "2017-2",
                month: 2,
                year: 2017,
                margen: 200,
                prom: 150
            },
            {
                id: "2017-3",
                month: 3,
                year: 2017,
                margen: 300,
                prom: 150
            },
            {
                id: "2017-4",
                month: 4,
                year: 2017,
                margen: 265,
                prom: 150
            },
            {
                id: "2017-5",
                month: 5,
                year: 2017,
                margen: 370,
                prom: 150
            },
            {
                id: "2018-1",
                month: 1,
                year: 2018,
                margen: 150,
                prom: 270
            },
            {
                id: "2018-2",
                month: 2,
                year: 2018,
                margen: 250,
                prom: 270
            },
            {
                id: "2018-3",
                month: 3,
                year: 2018,
                margen: 350,
                prom: 270
            },
        ],
    },
]