import authAxios from "../common/Auth/authAxios";

const ContantsService = {
    getConstantWeekByBranch: async (branch) => {
        return await authAxios.post('/inputs-generales/getConstantesDeSemana', branch)
    },
    createConstantWeek: async (constant) => {
        return await authAxios.post('/inputs-generales/addConstantesDeSemana', constant)
    },
    updateConstantWeek: async (constant) => {
        return await authAxios.patch('/inputs-generales/editConstantesDeSemana', constant)
    },
    deleteConstantWeek: async (constant) => {
        return await authAxios.delete('/inputs-generales/deleteConstantesDeSemana',
            { data: constant }
        )
    },
}
export default ContantsService