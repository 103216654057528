import React, { useContext, useState, useEffect, useMemo } from "react";
import { Box, Stack } from "@mui/material";
import { styleSheet } from "../../../common/styles/StylesCommon";
import CompanyContext from "../../../common/Contexts/CompanysContext";
import { Form, Formik } from "formik";
import PermissionContext from "../../../common/Contexts/PermissionContext";
import CompanysService from "../../../services/CompanysService";
import { CToast } from "../../../common/Components/ToastNotify";
import { SidebarService } from "../../../services/SidebarService";
import companyFormModel from "../../../model/company/companyFormModel";
import branchFormModel from "../../../model/branch/branchFormModel";
import { CStepper } from "../../../common/Components/stepper/CStepper";
import { CSteps } from "../../../common/Components/stepper/CSteps";
import { DataCompany } from "./screen_steps/DataCompany";
import { validateFormCompany } from "../../../model/company/company.schema";
import { DataBranch } from "./screen_steps/DataBranch";
import { DataAccessCompany } from "./screen_steps/DataAccessCompany";
import { DataAccessBranch } from "./screen_steps/DataAccessBranch";
import { DataProcessCompany } from "./screen_steps/DataProcessCompany";
import CustomModal from "../../../common/Components/CustomModal";
import BranchsContext from "../../../common/Contexts/BranchContext";
import { DataProcessBranch } from "./screen_steps/DataProcessBranch";
import ModulesFormContext from "../../../common/Contexts/ModulesFormContext";
import { ModalOpenEnum } from "../../../common/utils/ModalOpenEnum";
var store = require("store");

export const CompanyForm = () => {

  const {
    steps,
    handleResetStep,
    handleNextStep,
    activeStep,
    handleSetActiveStep,
    rowData: company,
    isLastStep,
    isOpen,
    toggleModal,
    getDataTable
  } = useContext(ModulesFormContext);
  const { getAllCompanys, companys } = useContext(CompanyContext);
  const { branchID } = useContext(BranchsContext);
  const { models } = useContext(PermissionContext);
  const elements = store.get("sidebar");
  let currentValidationSchema = validateFormCompany[activeStep];
  let currentStep = steps[activeStep];
  const { formField } = companyFormModel;
  const [search, setSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nameConfigEqual, setNameConfigEqual] = useState("");
  const [configAccess, setConfigAccess] = useState({ configCompany: [], configBranch: [] });
  const [configMethods, setConfigMethods] = useState({ configCompany: [], configBranch: [] });
  const overallStyle = useMemo(() => styleSheet(), []);

  const initialValue = {
    company_name: company?.company_name || "",
    description: company?.description || "",
    company_phone: company?.company_phone || "",
    contact_name: company?.contact_name || "",
    contact_phone: company?.contact_phone || "",
    contact_email: company?.contact_email || "",

    select_permission: company?.id_rol || "",
    elements_module: company?.elements_cluster || elements,

    model_logic_id: company?.model_logic_id || "",
    model_logic_company: company?.methods_cluster || models,

    branch_name: company?.branch_name || "",
    branch_description: company?.branch_description || "",
    branch_phone: company?.branch_phone || "",
    branch_contact_name: company?.branch_contact_name || "",
    branch_contact_phone: company?.branch_contact_phone || "",
    branch_contact_email: company?.branch_contact_email || "",

    select_permission_branch: "",
    elements_cluster: company?.elements_cluster ? company.elements_cluster : elements,

    model_logic_id_branch: "",
    model_logic_branch: company?.branchs.filter(b => b.company_id === branchID)[0]?.methods_cluster || models,

    select_process: "",
    select_model_branch: "",
    config_permission: [],
    active: true,
    config_equals: false,
    config_equals_process: false,
    isSubmit: false,
    isEditing: company ? true : false,
    company_id: company?.company_id,
  };

  const onSave = async (value) => {
    setIsLoading(true);
    const req = {
      company_name: value.company_name,
      description: value.description,
      company_phone: value.company_phone,
      contact_name: value.contact_name,
      contact_phone: value.contact_phone,
      contact_email: value.contact_email,
      elements_cluster: value.elements_module,
      // methods_cluster: value.model_logic_company,
      active: value.active,
      id_rol: value.select_permission,
      model_logic_id: value.model_logic_id,
    };
    const dataBranch = [
      {
        company_name: value.branch_name,
        description: value.branch_description,
        company_phone: value.branch_phone,
        contact_name: value.branch_contact_name,
        contact_phone: value.branch_contact_phone,
        contact_email: value.branch_contact_email,
        elements_cluster: value.elements_cluster,
        methods_cluster: value.model_logic_branch,
        id_rol: value.select_permission_branch,
        model_logic_id: value.model_logic_id_branch,
      },
    ];

    Object.assign(req, value.isEditing ? { company_id: value.company_id } : { branchs: dataBranch });
    console.log(req);
    setIsLoading(false);
    try {
      const { data } = value.isEditing
        ? await CompanysService.updateCompany(req)
        : await CompanysService.createCompany(req);
      await getDataTable();
      handleResetStep();
      await getAllCompanys();// traemos nuevamente las compañias para actualizar el sidebar
      toggleModal(ModalOpenEnum.EDIT);
      CToast(`Empresa ${value.isEditing ? 'editada' : 'creada'} correctamente`, "success", true);
    } catch ({ response }) {
      console.log(response)
      if (response.data?.message) return (CToast(response.data?.message, "error", true))
      CToast(`Error al crear ${JSON.stringify(response?.data.error)}`, "error", true);
    } finally {
      setIsLoading(false);
    }
  };


  const getConfigsxCompany = async (code) => {
    setIsLoading(true);
    try {
      const { data } = await SidebarService.getElementsLogic();
      const configCompany = data.filter(item => item.cod_element_logic === 0);
      const configBranch = data.filter(item => item.cod_element_logic === 1);
      setConfigAccess({ configCompany, configBranch });
      setIsLoading(false);
      // console.log(data)
      return data;
    } catch (error) {
      setIsLoading(false);
      console.log(error.response.data.statusCode);
    }
  };

  const getConfigsMethods = async () => {
    setIsLoading(true);
    try {
      const { data } = await SidebarService.getModelLogic();
      const configCompany = data?.data.filter(item => item.cod_model_logic === 0);
      const configBranch = data?.data.filter(item => item.cod_model_logic === 1);
      setConfigMethods({ configCompany, configBranch });
      setIsLoading(false);
      return data.data;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getConfigsxCompany();
    getConfigsMethods();
  }, []);

  useEffect(() => {
    // console.log('activeStep', activeStep)
  }, [activeStep])

  return (
    <CustomModal
      isOpen={isOpen[ModalOpenEnum.EDIT]}
      toggleModal={() => { handleResetStep(); toggleModal(ModalOpenEnum.EDIT); }}
      moduleLabel={'company'}
      actionLabel={company ? "edit" : "create"}
      descriptionLabel={company ? `${company.company_name}` : ''}
    >
      <Formik
        initialValues={initialValue}
        validationSchema={currentValidationSchema}
        onSubmit={(values, actions) => {
          (isLastStep) ? onSave(values) : handleNextStep()
          actions.setSubmitting(false);
        }}
      >
        {({ errors, handleSubmit, setFieldValue, isSubmitting, values }) => {
          return (
            <Form>
              <CStepper activeStep={activeStep} steps={steps} setActiveStep={handleSetActiveStep} errors={errors} isLoading={isLoading} isSubmitting={isSubmitting}>
                <CSteps label={steps[0]} onNext={!isSubmitting && errors.company_name !== '' ? handleSubmit : () => { }} errors={errors} isSubmitting={isSubmitting} isLoading={isLoading}>
                  <DataCompany />
                </CSteps>
                <CSteps label={steps[1]} onNext={handleSubmit} errors={errors} isSubmitting={isSubmitting} >
                  <DataAccessCompany configAccess={configAccess.configCompany} isLoading={isLoading} getConfigsxCompany={getConfigsxCompany} />
                </CSteps>
                <CSteps label={steps[2]} onNext={handleSubmit} onSubmit={handleSubmit} errors={errors} isSubmitting={isSubmitting} isEditing={values.isEditing}>
                  <DataProcessCompany
                    checkOutModel={companyFormModel}
                    configsMethods={configMethods.configBranch}
                    isLoading={isLoading}
                    getConfigsMethods={getConfigsMethods}
                  />
                </CSteps>
                <CSteps label={steps[3]} onNext={handleSubmit} errors={errors} isSubmitting={isSubmitting}>
                  <DataBranch />
                </CSteps>
                <CSteps label={steps[4]} onNext={handleSubmit} errors={errors} isSubmitting={isSubmitting}>
                  <DataAccessBranch
                    configAccess={configAccess.configBranch}
                    isLoading={isLoading}
                    getConfigsxCompany={getConfigsxCompany}
                  />
                </CSteps>
                <CSteps label={steps[5]} onSubmit={handleSubmit} errors={errors} isEditing={values.isEditing}>
                  <DataProcessBranch
                    checkOutModel={companyFormModel}
                    configsMethods={configMethods.configBranch}
                    isLoading={isLoading}
                    getConfigsMethods={getConfigsMethods}
                  />
                </CSteps>
              </CStepper>
              <Box justifyContent={"center"} display={"flex"}></Box>
              <Stack width={"100%"} direction={"row"}></Stack>
              <Stack
                direction={"row"}
                justifyContent={"space-around"}
                paddingX={8}
                marginTop={3}
              >
                <Box width={"50%"}>
                  <Box width={"50%"} />
                </Box>
                <Box width={"50%"}></Box>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </CustomModal>
  );
};
