import { Box, Tooltip } from '@mui/material'
import React from 'react'
// ICONS
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AddIcon from "@mui/icons-material/Add";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import DownloadIcon from '@mui/icons-material/Download';
import QueueOutlinedIcon from "@mui/icons-material/QueueOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export const ActionsCheckListGroup = () => {
    return (
        <Box sx={{ mr: 1 }}>
            <Tooltip title="Listar">
                <FormatListBulletedIcon
                    sx={{ mt: 1, color:"common.title", fontSize: 30 }}
                />
            </Tooltip>
            <Tooltip title="Agregar">
                <AddIcon
                    sx={{ mt: 1, ml: 1.5, color:"common.title", fontSize: 30 }}
                />
            </Tooltip>
            <Tooltip title="Detalle">
                <AnalyticsOutlinedIcon
                    sx={{ mt: 1, ml: 1.5,  color:"common.title", fontSize: 30 }}
                />
            </Tooltip>
            <Tooltip title="Eliminar">
                <DeleteForeverIcon
                    sx={{ mt: 1, ml: 1.5,  color:"common.title", fontSize: 30 }}
                />
            </Tooltip>
            <Tooltip title="Editar">
                <EditIcon
                    sx={{ mt: 1, ml: 1.5,  color:"common.title", fontSize: 30 }}
                />
            </Tooltip>
            <Tooltip title="Descargar Datos">
                <SimCardDownloadIcon
                    sx={{ mt: 1, ml: 1.5,  color:"common.title", fontSize: 30 }}
                />
            </Tooltip>
            <Tooltip title="Descargar Formato">
                <DownloadIcon
                    sx={{ mt: 1, ml: 1.5,  color:"common.title", fontSize: 30 }}
                />
            </Tooltip>
            <Tooltip title="Carga masiva de Remplazo">
                <QueueOutlinedIcon
                    sx={{ mt: 1, ml: 1.5,  color:"common.title", fontSize: 30 }}
                />
            </Tooltip>
            <Tooltip title="Carga masiva con Actualizacion">
                <EditOutlinedIcon
                    sx={{ mt: 1, ml: 1.5,  color:"common.title", fontSize: 30 }}
                />
            </Tooltip>
            <Tooltip title="Carga masiva para eliminar">
                <DeleteOutlineIcon
                    sx={{ mt: 1, ml: 1,  color:"common.title", fontSize: 30 }}
                />
            </Tooltip>
        </Box>
    )
}
