import { Stack } from '@mui/material';
import { Form } from 'formik'
import React from 'react'
import { useContext } from 'react';
import MediaContext from '../Contexts/MediaContext';
import { styleSheet } from '../styles/StylesCommon';

export const ContainerForm = ({ children, formID }) => {
    const { isMobile, isTablet } = useContext(MediaContext);

    return (
        <Form id={formID || 'form'}>
            <Stack
                py={3}
                direction={isTablet ? "column" : "row"}
                justifyContent={isTablet ? "center" : "space-around"}
                // alignItems="center"
                spacing={isTablet ? 2 : 5}
                paddingX={isTablet ? 2 : 5}
            >
                {children}
            </Stack>
        </Form>
    )
}
