import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';


function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

export const CAutocomplete = ({ label, data, handleChange, valueDefault, isOptionEqual = 'cod', filters }) => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const isLoading = open && options.length === 0;

    useEffect(() => {
        let active = true;

        if (!isLoading) return undefined;
        // if(filters[isOptionEqual].length === 0) return undefined;
        // if (!filters) return undefined;
        const dataFiltered = filters && getDataFiltered();
        (async () => {
            await sleep(1e3); // For demo purposes.
            if (active) setOptions(dataFiltered ? [...dataFiltered] : [...data]);
        })();

        return () => {
            active = false;
        };
    }, [isLoading, filters]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const getDataFiltered = () => {
        const filter = data.filter((item) => {
            const filterKeys = Object.keys(filters).filter((key) => key !== isOptionEqual);
            return filterKeys.every((eachKey) => {
                if (!filters[eachKey].length) return true;
                return filters[eachKey].includes(item[eachKey]);
            });
        });
        console.log("filter", filter);
        return filter;
    };


    return (
        <Autocomplete
            id="asynchronous"
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onChange={(event, newValue) => {
                console.log(newValue);
                handleChange(newValue);
            }}
            isOptionEqualToValue={(option, value) => option[isOptionEqual] === value[isOptionEqual]}
            getOptionLabel={(option) => option.label}
            options={options}
            loading={isLoading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    defaultValue={valueDefault}
                    value={valueDefault}
                    InputProps={{
                        ...params.InputProps,
                        style: {
                            borderRadius: 50,
                            height: 50,
                            width: '80%',
                            marginBottom: 1.4,
                            display: "flex",
                            borderColor:
                                "linear-gradient(90deg, rgba(7,64,107,1) 0%, rgba(7,64,107,1) 35%, rgba(0,184,255,1) 100%)",
                        },
                        endAdornment: (
                            <React.Fragment>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}
