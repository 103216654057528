import { Button } from '@mui/material';
import React, { useEffect } from 'react'
import { createStyles } from '@material-ui/styles';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { LoadingButton } from '@mui/lab';
import { t } from 'i18next';
import { CStepButtons } from './CStepButtons';

export const CSteps = (props) => {

    const onNextStep = async () => {
        props.onNext && (await props.onNext());
    };

    const onPreviousStep = () => {
        props.onPrevious && props.onPrevious();
        props.setActiveStep(props.activeStep - 1);
    };

    const onSubmit = async () => {
        await props.onSubmit();
    };

    const renderNextButton = () => {
        return (props.activeStep === props.stepCount - 1) ? (
            <LoadingButton
                disabled={Object.keys(props.errors).length > 0 && props.isSubmitting}
                onClick={onSubmit}
                fullWidth
                variant="contained"
                sx={{ ...styles.containerArrow, ...styles.buttonSave }}
                loading={(props?.isLoading || props?.isSubmitting) ? true : false}
            >
                {props?.isEditing ? t('labels_actions.edit') : t('labels_actions.create')}
            </LoadingButton>
        ) : (
            <LoadingButton
                disabled={Object.keys(props.errors).length > 0 && props.isSubmitting}
                onClick={props.activeStep === props.stepCount - 1 ? onSubmit : onNextStep}
                color="inherit"
                sx={{ ...styles.containerArrow }}
                loading={(props?.isLoading || props?.isSubmitting) ? true : false}
            >
                <ChevronRightIcon sx={{ fontSize: 35 }} />
            </LoadingButton>
        )
    }

    const renderPreviousButton = () => {
        return (
            <Button
                color="inherit"
                disabled={props.disabled || props.isFirstStep}
                onClick={onPreviousStep}
                sx={{ ...styles.containerArrow, }}
            >
                <ChevronLeftIcon sx={{ fontSize: 35 }} />
            </Button>
        )
    }

    useEffect(() => {
        props.onPreviousStep && onPreviousStep()
    }, [props.onPreviousStep, props.onNextStep])

    return (
        <>
            {props.children}
            {
                props.removeBtnRow ? null : (
                    <CStepButtons
                        removeBtnNext={props?.removeBtnNext}
                        removeBtnBack={props?.removeBtnBack}
                        renderNextButton={renderNextButton}
                        renderPreviousButton={renderPreviousButton}
                        isLastStep={props.isLastStep}
                        isFirstStep={props.isFirstStep}
                    />
                )
            }
        </>
    )
}

const styles = createStyles({
    containerArrow: {
        height: 60,
        background:
            "linear-gradient(90deg, rgba(7,64,107,1) 0%, rgba(7,64,107,1) 35%, rgba(0,184,255,1) 100%)",
        borderRadius: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        color: "white",
    },
    buttonSave: {
        background:
            "linear-gradient(90deg, rgba(7,64,107,1) 0%, rgba(7,64,107,1) 35%, rgba(0,184,255,1) 100%)",
        p: 0,
        height: 70,
        width: 70,
        boxShadow: "0px 3px 6px #00000029",
    }
})