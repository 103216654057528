import {
  Box,
  FormControlLabel,
  Stack,
  Checkbox,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import React, { useContext, useEffect, useState } from "react";

import ModulesFormContext from "../../../../common/Contexts/ModulesFormContext";

export const ActionsElements = () => {
  const { values, setFieldValue } = useFormikContext();
  //const { actions } = useContext(ModulesFormContext);

  const handleChange = async (e, action_id) => {
    let arrayCluster = values?.actions?.map((action) => {
      if (action.action_id === action_id) {
        action.active = e.target.checked;
      }
      return action;
    });

    setFieldValue("actionsSection", arrayCluster);
  };

  return (
    <>
      <Typography color={"common.title"} variant={"h6"}>
        Acciones
      </Typography>
      <Stack
        direction={"row"}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: 1,
        }}
      >
        {values?.actions?.map((action, index) => {
          return (
            <>
              <Box
                key={index}
                sx={{ width: "80px", height: "80px", marginBottom: 5 }}
              >
                <FormControlLabel
                  label={action?.action_name}
                  labelPlacement="bottom"
                  control={
                    <Box>
                      <Checkbox
                        id={action.action_id}
                        name={action?.action_name}
                        label={action?.action_name}
                        value={action?.active}
                        sx={{
                          "&.Mui-checked": {
                            color: "common.title",
                          },
                        }}
                        onChange={(e) => handleChange(e, action.action_id)}
                        checked={action?.active}
                      />
                    </Box>
                  }
                />
              </Box>
            </>
          );
        })}
      </Stack>
    </>
  );
};
