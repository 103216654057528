export const dataScatter = [
    {
      "id": "group A",
      "data": [
        {
          "x": 99,
          "y": 23
        },
        {
          "x": 78,
          "y": 63
        },
        {
          "x": 24,
          "y": 1
        },
        {
          "x": 2,
          "y": 97
        },
        {
          "x": 31,
          "y": 101
        },
        {
          "x": 6,
          "y": 65
        },
        {
          "x": 13,
          "y": 87
        },
        {
          "x": 1,
          "y": 86
        },
        {
          "x": 63,
          "y": 57
        },
        {
          "x": 42,
          "y": 95
        },
        {
          "x": 94,
          "y": 33
        },
        {
          "x": 69,
          "y": 107
        },
        {
          "x": 34,
          "y": 36
        },
        {
          "x": 42,
          "y": 26
        },
        {
          "x": 61,
          "y": 24
        },
        {
          "x": 6,
          "y": 16
        },
        {
          "x": 99,
          "y": 64
        },
        {
          "x": 99,
          "y": 107
        },
        {
          "x": 82,
          "y": 37
        },
        {
          "x": 62,
          "y": 76
        },
        {
          "x": 34,
          "y": 27
        },
        {
          "x": 70,
          "y": 0
        },
        {
          "x": 2,
          "y": 37
        },
        {
          "x": 81,
          "y": 64
        },
        {
          "x": 46,
          "y": 41
        },
        {
          "x": 16,
          "y": 54
        },
        {
          "x": 74,
          "y": 11
        },
        {
          "x": 5,
          "y": 67
        },
        {
          "x": 57,
          "y": 114
        },
        {
          "x": 94,
          "y": 40
        },
        {
          "x": 45,
          "y": 70
        },
        {
          "x": 0,
          "y": 82
        },
        {
          "x": 22,
          "y": 97
        },
        {
          "x": 37,
          "y": 98
        },
        {
          "x": 92,
          "y": 55
        },
        {
          "x": 74,
          "y": 31
        },
        {
          "x": 70,
          "y": 82
        },
        {
          "x": 57,
          "y": 106
        },
        {
          "x": 51,
          "y": 115
        },
        {
          "x": 32,
          "y": 2
        },
        {
          "x": 12,
          "y": 98
        },
        {
          "x": 69,
          "y": 63
        },
        {
          "x": 68,
          "y": 100
        },
        {
          "x": 0,
          "y": 87
        },
        {
          "x": 35,
          "y": 110
        },
        {
          "x": 40,
          "y": 55
        },
        {
          "x": 12,
          "y": 30
        },
        {
          "x": 28,
          "y": 84
        },
        {
          "x": 12,
          "y": 26
        },
        {
          "x": 73,
          "y": 103
        }
      ]
    },
    {
      "id": "group B",
      "data": [
        {
          "x": 89,
          "y": 15
        },
        {
          "x": 85,
          "y": 41
        },
        {
          "x": 63,
          "y": 15
        },
        {
          "x": 69,
          "y": 26
        },
        {
          "x": 35,
          "y": 27
        },
        {
          "x": 92,
          "y": 42
        },
        {
          "x": 92,
          "y": 105
        },
        {
          "x": 54,
          "y": 45
        },
        {
          "x": 34,
          "y": 48
        },
        {
          "x": 57,
          "y": 108
        },
        {
          "x": 48,
          "y": 25
        },
        {
          "x": 11,
          "y": 69
        },
        {
          "x": 1,
          "y": 71
        },
        {
          "x": 68,
          "y": 74
        },
        {
          "x": 39,
          "y": 16
        },
        {
          "x": 60,
          "y": 119
        },
        {
          "x": 72,
          "y": 99
        },
        {
          "x": 82,
          "y": 21
        },
        {
          "x": 58,
          "y": 66
        },
        {
          "x": 2,
          "y": 8
        },
        {
          "x": 12,
          "y": 99
        },
        {
          "x": 39,
          "y": 28
        },
        {
          "x": 13,
          "y": 85
        },
        {
          "x": 24,
          "y": 64
        },
        {
          "x": 53,
          "y": 32
        },
        {
          "x": 5,
          "y": 38
        },
        {
          "x": 24,
          "y": 15
        },
        {
          "x": 89,
          "y": 103
        },
        {
          "x": 30,
          "y": 18
        },
        {
          "x": 62,
          "y": 15
        },
        {
          "x": 99,
          "y": 95
        },
        {
          "x": 54,
          "y": 28
        },
        {
          "x": 12,
          "y": 5
        },
        {
          "x": 1,
          "y": 76
        },
        {
          "x": 42,
          "y": 74
        },
        {
          "x": 87,
          "y": 40
        },
        {
          "x": 58,
          "y": 38
        },
        {
          "x": 43,
          "y": 73
        },
        {
          "x": 44,
          "y": 65
        },
        {
          "x": 56,
          "y": 70
        },
        {
          "x": 89,
          "y": 104
        },
        {
          "x": 21,
          "y": 76
        },
        {
          "x": 56,
          "y": 39
        },
        {
          "x": 30,
          "y": 77
        },
        {
          "x": 66,
          "y": 10
        },
        {
          "x": 12,
          "y": 13
        },
        {
          "x": 19,
          "y": 84
        },
        {
          "x": 88,
          "y": 43
        },
        {
          "x": 62,
          "y": 19
        },
        {
          "x": 61,
          "y": 81
        }
      ]
    },
    {
      "id": "group C",
      "data": [
        {
          "x": 55,
          "y": 19
        },
        {
          "x": 70,
          "y": 109
        },
        {
          "x": 44,
          "y": 65
        },
        {
          "x": 92,
          "y": 74
        },
        {
          "x": 46,
          "y": 28
        },
        {
          "x": 14,
          "y": 21
        },
        {
          "x": 82,
          "y": 45
        },
        {
          "x": 68,
          "y": 104
        },
        {
          "x": 32,
          "y": 118
        },
        {
          "x": 15,
          "y": 69
        },
        {
          "x": 91,
          "y": 23
        },
        {
          "x": 98,
          "y": 12
        },
        {
          "x": 65,
          "y": 12
        },
        {
          "x": 92,
          "y": 72
        },
        {
          "x": 4,
          "y": 51
        },
        {
          "x": 59,
          "y": 112
        },
        {
          "x": 66,
          "y": 86
        },
        {
          "x": 86,
          "y": 95
        },
        {
          "x": 3,
          "y": 119
        },
        {
          "x": 50,
          "y": 41
        },
        {
          "x": 5,
          "y": 74
        },
        {
          "x": 63,
          "y": 58
        },
        {
          "x": 53,
          "y": 56
        },
        {
          "x": 53,
          "y": 90
        },
        {
          "x": 66,
          "y": 8
        },
        {
          "x": 2,
          "y": 8
        },
        {
          "x": 74,
          "y": 31
        },
        {
          "x": 32,
          "y": 16
        },
        {
          "x": 13,
          "y": 87
        },
        {
          "x": 35,
          "y": 80
        },
        {
          "x": 72,
          "y": 8
        },
        {
          "x": 38,
          "y": 23
        },
        {
          "x": 68,
          "y": 89
        },
        {
          "x": 35,
          "y": 56
        },
        {
          "x": 18,
          "y": 59
        },
        {
          "x": 71,
          "y": 84
        },
        {
          "x": 45,
          "y": 113
        },
        {
          "x": 68,
          "y": 11
        },
        {
          "x": 54,
          "y": 89
        },
        {
          "x": 33,
          "y": 67
        },
        {
          "x": 50,
          "y": 45
        },
        {
          "x": 87,
          "y": 49
        },
        {
          "x": 14,
          "y": 58
        },
        {
          "x": 37,
          "y": 18
        },
        {
          "x": 53,
          "y": 89
        },
        {
          "x": 100,
          "y": 100
        },
        {
          "x": 4,
          "y": 71
        },
        {
          "x": 10,
          "y": 98
        },
        {
          "x": 27,
          "y": 63
        },
        {
          "x": 2,
          "y": 78
        }
      ]
    },
    {
      "id": "group D",
      "data": [
        {
          "x": 8,
          "y": 53
        },
        {
          "x": 26,
          "y": 94
        },
        {
          "x": 56,
          "y": 42
        },
        {
          "x": 3,
          "y": 52
        },
        {
          "x": 71,
          "y": 22
        },
        {
          "x": 29,
          "y": 93
        },
        {
          "x": 27,
          "y": 58
        },
        {
          "x": 91,
          "y": 115
        },
        {
          "x": 96,
          "y": 96
        },
        {
          "x": 24,
          "y": 106
        },
        {
          "x": 58,
          "y": 112
        },
        {
          "x": 41,
          "y": 89
        },
        {
          "x": 89,
          "y": 58
        },
        {
          "x": 19,
          "y": 57
        },
        {
          "x": 24,
          "y": 84
        },
        {
          "x": 54,
          "y": 99
        },
        {
          "x": 16,
          "y": 39
        },
        {
          "x": 66,
          "y": 11
        },
        {
          "x": 56,
          "y": 66
        },
        {
          "x": 53,
          "y": 93
        },
        {
          "x": 75,
          "y": 118
        },
        {
          "x": 36,
          "y": 12
        },
        {
          "x": 8,
          "y": 72
        },
        {
          "x": 57,
          "y": 40
        },
        {
          "x": 61,
          "y": 103
        },
        {
          "x": 85,
          "y": 45
        },
        {
          "x": 81,
          "y": 16
        },
        {
          "x": 10,
          "y": 56
        },
        {
          "x": 53,
          "y": 91
        },
        {
          "x": 54,
          "y": 83
        },
        {
          "x": 8,
          "y": 27
        },
        {
          "x": 41,
          "y": 103
        },
        {
          "x": 100,
          "y": 102
        },
        {
          "x": 54,
          "y": 1
        },
        {
          "x": 21,
          "y": 25
        },
        {
          "x": 67,
          "y": 25
        },
        {
          "x": 69,
          "y": 36
        },
        {
          "x": 37,
          "y": 32
        },
        {
          "x": 33,
          "y": 17
        },
        {
          "x": 2,
          "y": 92
        },
        {
          "x": 98,
          "y": 95
        },
        {
          "x": 56,
          "y": 103
        },
        {
          "x": 47,
          "y": 45
        },
        {
          "x": 82,
          "y": 106
        },
        {
          "x": 15,
          "y": 67
        },
        {
          "x": 85,
          "y": 11
        },
        {
          "x": 26,
          "y": 3
        },
        {
          "x": 10,
          "y": 90
        },
        {
          "x": 20,
          "y": 82
        },
        {
          "x": 57,
          "y": 56
        }
      ]
    },
    {
      "id": "group E",
      "data": [
        {
          "x": 9,
          "y": 69
        },
        {
          "x": 12,
          "y": 118
        },
        {
          "x": 96,
          "y": 27
        },
        {
          "x": 38,
          "y": 71
        },
        {
          "x": 21,
          "y": 87
        },
        {
          "x": 89,
          "y": 29
        },
        {
          "x": 36,
          "y": 18
        },
        {
          "x": 75,
          "y": 5
        },
        {
          "x": 23,
          "y": 39
        },
        {
          "x": 34,
          "y": 114
        },
        {
          "x": 81,
          "y": 14
        },
        {
          "x": 43,
          "y": 20
        },
        {
          "x": 87,
          "y": 54
        },
        {
          "x": 49,
          "y": 65
        },
        {
          "x": 75,
          "y": 82
        },
        {
          "x": 61,
          "y": 69
        },
        {
          "x": 18,
          "y": 87
        },
        {
          "x": 31,
          "y": 26
        },
        {
          "x": 22,
          "y": 24
        },
        {
          "x": 8,
          "y": 46
        },
        {
          "x": 59,
          "y": 119
        },
        {
          "x": 94,
          "y": 57
        },
        {
          "x": 71,
          "y": 86
        },
        {
          "x": 56,
          "y": 79
        },
        {
          "x": 21,
          "y": 50
        },
        {
          "x": 78,
          "y": 80
        },
        {
          "x": 45,
          "y": 24
        },
        {
          "x": 39,
          "y": 7
        },
        {
          "x": 72,
          "y": 9
        },
        {
          "x": 33,
          "y": 55
        },
        {
          "x": 97,
          "y": 45
        },
        {
          "x": 14,
          "y": 115
        },
        {
          "x": 6,
          "y": 39
        },
        {
          "x": 96,
          "y": 92
        },
        {
          "x": 81,
          "y": 80
        },
        {
          "x": 58,
          "y": 54
        },
        {
          "x": 71,
          "y": 15
        },
        {
          "x": 82,
          "y": 34
        },
        {
          "x": 21,
          "y": 78
        },
        {
          "x": 62,
          "y": 89
        },
        {
          "x": 82,
          "y": 116
        },
        {
          "x": 88,
          "y": 8
        },
        {
          "x": 18,
          "y": 109
        },
        {
          "x": 3,
          "y": 72
        },
        {
          "x": 18,
          "y": 64
        },
        {
          "x": 61,
          "y": 119
        },
        {
          "x": 78,
          "y": 40
        },
        {
          "x": 65,
          "y": 8
        },
        {
          "x": 21,
          "y": 117
        },
        {
          "x": 17,
          "y": 61
        }
      ]
    }
  ]