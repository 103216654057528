import { Divider, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import { HeadCardInfo } from "../components/HeadCardInfo";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { t } from "i18next";
import { BodyCardInfo } from "../components/BodyCardInfo";
import { formatDateToUTC } from "../../../../helpers/formatDate";
import { formatNumber } from "../../../../helpers/formatNumber";
import { getMonth } from "../../../../helpers/months";
import { styleSheet } from "../../../../common/styles/StylesCommon";

export const CardInfo = ({ data }) => {

  const overallStyle = styleSheet();

  return (
    <Stack flexDirection={"column"} spacing={1}>
      {/* Porcentaje de venta */}
      <Box
        sx={{
          ...overallStyle.cardRadar,
        }}
      >
        <Stack flexDirection={"column"}>
          <HeadCardInfo cardLabel={t('graph.sku.percent_vents')} />
          <BodyCardInfo
            headers={[t('graph.sku.units_vents'), t('graph.sku.percent')]}
            rows={[data?.VENTS?.unidades_venta, data?.VENTS?.porcentaje]}
          />
        </Stack>
      </Box>



      {/* Fecha quiebre */}
      <Box
        sx={{
          ...overallStyle.cardRadar,
        }}
      >
        <Stack flexDirection={"column"}>
          <HeadCardInfo cardLabel={t('graph.sku.break_date')} />
          <BodyCardInfo
            headers={[t('graph.sku.min_date'), t('graph.sku.units_break')]}
            rows={[
              formatDateToUTC(data?.BREAK?.proximo_quiebre),
              formatNumber(Math.abs(data?.BREAK?.unds))
            ]}
            isNumbers={false}
          />
        </Stack>
      </Box>

      {/* Transito*/}
      <Box
        sx={{
          ...overallStyle.cardRadar,
        }}
      >
        <Stack>
          <HeadCardInfo cardLabel={t('graph.sku.transit')} />
          <Box
            sx={{
              height: 115,
              overflowX: "auto",
              overflowY: "hidden",
              
              // width: "100%",
            }}
          >
            <Stack sx={{
              marginTop:-2,
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              
            }}>
              {
                data?.TRANSIT?.map((item, index) => (
                  <Box>
                    <Box
                      key={`${item["año"]}-${item["mes"]}-${index}`}
                      sx={{
                        // width: "100%",
                        // height: "100%",
                        display: "flex",
                        justifyContent: "center",

                        mt: 1,
                      }}
                    >
                      <Typography>{item["año"]}</Typography>
                    </Box>
                    <Box
                      key={`${item["mes"]}-${index}`}
                      sx={{
                        width: "100%",
                        height: 30,
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Stack
                        flexDirection={"row"}
                        display={"flex"}
                        justifyContent={
                          data?.TRANSIT.length > 1
                            ? "space-between"
                            : "center"}
                        alignItems={"center"}
                        marginX={1}
                        mt={1.5}
                      >
                        <Stack flexDirection={"column"}>
                          <Typography align="center" >{getMonth(item?.mes)?.month_name}</Typography>
                          <Typography
                            align="center"
                            sx={{
                              marginTop: 2,
                            }}
                          >
                            {item?.cantidad}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Box>
                  </Box>
                ))
              }
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
};
