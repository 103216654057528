import { Box, IconButton, Stack } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import '../styles/styleTable.css';
import { t } from 'i18next';
import React, { useCallback, useContext, useMemo, useEffect, useRef } from 'react'
import ThemeContext from '../Contexts/ThemeContext';
import { styleSheet } from '../styles/StylesCommon';
import { DeleteIcon, EditIcon, SeeIcon, WDeleteIcon, WEditIcon, WSeeIcon } from '../utils/Icons';
import MediaContext from '../Contexts/MediaContext';
import ModulesFormContext from '../Contexts/ModulesFormContext';
import { useState } from 'react';
import { ModalOpenEnum } from '../utils/ModalOpenEnum';
import { LoadingButton } from '@mui/lab';

export const CustomTable = ({ headers, actions, colParametersDef, disabledButton }) => {
    const { state } = useContext(ThemeContext);
    const { toggleModal, isTableLoading, dataTable, actionsEnabled, actionsEnabledModule } = useContext(ModulesFormContext);
    const { isMobile, isTablet } = useContext(MediaContext);
    const overallStyle = styleSheet(isMobile, isTablet);
    
    const gridRef = useRef(null);
    const IconDetail = () => state.theme === 1 ? <SeeIcon /> : <WSeeIcon />;
    const IconEdit = () => state.theme === 1 ? <EditIcon /> : <WEditIcon />;
    const IconDelete = () => state.theme === 1 ? <DeleteIcon /> : <WDeleteIcon />;
    const [columnDef, setColumnDef] = useState([
        actionsEnabled && actionsEnabled.length > 0 && {
            headerName: "Acciones",
            pinned: 'right',
            cellRenderer: (params) => {
                return (
                    <Stack key={params.rowIndex} direction={"row"} justifyContent='flex-start' alignItems={'center'}>
                        {
                            actionsEnabled.filter(a => (actionsEnabledModule && actionsEnabledModule.length > 0)
                                ? actionsEnabledModule.includes(a.action_name) : a)
                                .map((act, index) => {
                                    return (
                                        <IconButton
                                            key={`action-${act.action_id}-${index}`}
                                            onClick={() => handleCellAction(act.action_name, params)}
                                            disabled={!act.active || actionsEnabledModule.length === 0 || disabledButton ? true : false}
                                        >
                                            {act.action_name === ModalOpenEnum.DETAIL && IconDetail()}
                                            {act.action_name === ModalOpenEnum.EDIT && IconEdit()}
                                            {act.action_name === ModalOpenEnum.DELETE && IconDelete()}
                                        </IconButton>
                                    )
                                })
                        }
                    </Stack>
                );
            },
        },
        ...headers,
    ])
    const handleCellAction = useCallback((act, params) => {
        toggleModal(act, params);
    }, [toggleModal]);

    const paginationNumberFormatter = useCallback((params) => {
        return "[" + params.value.toLocaleString() + "]";
    }, []);


    useEffect(() => {

        if (isTableLoading) {
            gridRef.current?.api?.showLoadingOverlay();

        } else {
            gridRef.current?.api?.hideOverlay();
        }

        // return () => {
        //     gridRef.current?.api?.hideOverlay();
        // }
    }, [isTableLoading])


    const defaultColDef = useMemo(
        () => ({
            enableRowGroup: true,
            enablePivot: true,
            enableValue: true,
            sortable: true,
            resizable: true,
            filter: true,
            flex: 1,
            width: 150,
            minWidth: 100,
            ...colParametersDef
        }), [colParametersDef]
    );

    return (
        <Box className="ag-theme-alpine" sx={
            state.theme === 1
                ? { ...overallStyle.tableLight }
                : { ...overallStyle.tableDark }
        }>
            <AgGridReact
                ref={gridRef}
                rowData={dataTable}
                columnDefs={columnDef}
                defaultColDef={defaultColDef}
                paginationNumberFormatter={paginationNumberFormatter}
                animateRows={true}
                suppressRowClickSelection={true}
                groupSelectsChildren={true}
                overlayLoadingTemplate={
                    `
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',width:'100%' }}>
                    <Typography class="ag-overlay-loading-center">
                    ${t('labels_actions.loadingRowsTable')}
                    </Typography>
                    <LoadingButton variant='contained' loading={${isTableLoading}} />
                    </Box>`
                }
                rowSelection={"multiple"}
                rowGroupPanelShow={"always"}
                pivotPanelShow={"always"}
                paginationPageSize={10}
                paginationAutoPageSize={true}
                pagination={true}

            />
        </Box>
    );
}
