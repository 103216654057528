import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import '../../../../common/styles/styleTable.css';
import { Box, Stack, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import React, { useContext, useMemo, useState } from "react";

import MediaContext from "../../../../common/Contexts/MediaContext";
import { styleSheet } from "../../../../common/styles/StylesCommon";


import ThemeContext from "../../../../common/Contexts/ThemeContext";
import { LayoutTable } from "../../../../common/Components/LayoutTable";
import { BoxTitle } from "../../components/BoxTitle";
import { LayoutTableGraphs } from "../../components/LayoutTableGraphs";
import { formatMoney } from "../../../../helpers/formatNumber";

const dataTable = [
  {
    type: "Venta año anterior",
    enero: [574627],
    febrero: [574627],
    marzo: [574627],
    abril: [574627],
    mayo: [574627],
    junio: [574627],
    julio: [574627],
    agosto: [574627],
    septiembre: [574627],
    octubre: [574627],
    noviembre: [574627],
    diciembre: [574627],
  },
  {
    type: "Año Actual",
    enero: [574627],
    febrero: [574627],
    marzo: [574627],
    abril: [574627],
    mayo: [574627],
    junio: [574627],
    julio: [574627],
    agosto: [574627],
    septiembre: [574627],
    octubre: [574627],
    noviembre: [574627],
    diciembre: [574627],
  },
  {
    type: "Var",
    enero: [574627],
    febrero: [574627],
    marzo: [574627],
    abril: [574627],
    mayo: [574627],
    junio: [574627],
    julio: [574627],
    agosto: [574627],
    septiembre: [574627],
    octubre: [574627],
    noviembre: [574627],
    diciembre: [574627],
  },
];

const testTable = [
  {
    fecha: "Enero",
    año_actual: [574627],
    año_anterior: [814326],
  },
  {
    fecha: "Febrero",
    año_actual: [561201],
    año_anterior: [691648],
  },
  {
    fecha: "Marzo",
    año_actual: [983752],
    año_anterior: [1310122],
  },
  {
    fecha: "Abril",
    año_actual: [930372],
    año_anterior: [689719],
  },
  {
    fecha: "Mayo",
    año_actual: [1229995],
    año_anterior: [745414],
  },
  {
    fecha: "Junio",
    año_actual: [910011],
    año_anterior: [1193097],
  },
  {
    fecha: "Julio",
    año_actual: [812201],
    año_anterior: [950351],
  },
  {
    fecha: "Agosto",
    año_actual: [755618],
    año_anterior: [863991],
  },
  {
    fecha: "Septiembre",
    año_actual: [549598],
    año_anterior: [647669],
  },
  {
    fecha: "Octubre",
    año_actual: [584125],
    año_anterior: [723804],
  },
  {
    fecha: "Noviembre",
    año_actual: [],
    año_anterior: [801622],
  },
  {
    fecha: "Diciembre",
    año_actual: [],
    año_anterior: [468113],
  },
];

export const TableMonthlyProjection = ({ data }) => {
  const { isMobile, isTablet } = useContext(MediaContext);
  const { state } = useContext(ThemeContext);
  const overallStyle = styleSheet(isMobile, isTablet);

  const [columnDef, setColumnDef] = useState([
    { field: "type", headerName: "Tipo", width: 150, valueFormatter: ({ value }) => value },
    { field: "1", headerName: "enero", },
    { field: "2", headerName: "febrero", },
    { field: "3", headerName: "marzo", },
    { field: "4", headerName: "abril", },
    { field: "5", headerName: "mayo", },
    { field: "6", headerName: "junio", },
    { field: "7", headerName: "julio", },
    { field: "8", headerName: "agosto", },
    { field: "9", headerName: "septiembre", },
    { field: "10", headerName: "octubre", },
    { field: "11", headerName: "noviembre", },
    { field: "12", headerName: "diciembre", },
  ]);

  const defaultColDef = useMemo(
    () => ({
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
      // flex: 1,
      width: 120,
      minWidth: 100,
      maxWidth: 300,
      valueFormatter: ({ value }) => formatMoney(value)
    }),
    []
  );

  return (

    <LayoutTableGraphs otherStyle={{ height: 220 }}>

      <Box className="ag-theme-alpine" sx={
        state.theme === 1
          ? { ...overallStyle.tableLight, height: '100%' }
          : { ...overallStyle.tableDark, height: '100%' }
      }>

        <AgGridReact
          rowData={!data ? dataTable : data}
          columnDefs={columnDef}
          defaultColDef={defaultColDef}
          animateRows={true}
          suppressRowClickSelection={true}
          groupSelectsChildren={true}
          rowSelection={"multiple"}
          rowGroupPanelShow={"always"}
          pivotPanelShow={"always"}
          paginationPageSize={10}
          paginationAutoPageSize={true}
          pagination={true}
        />
      </Box>
    </LayoutTableGraphs>

  );
};
