import React, { useContext, useMemo } from "react";
import Title from "../../../common/Components/Title";
import { Box } from "@mui/material";
import { t } from "i18next";
import BranchsContext from "../../../common/Contexts/BranchContext";
import { styleSheet } from "../../../common/styles/StylesCommon";
import { BoxTitle } from "../components/BoxTitle";
import { useFilters } from "../../../hooks/useFilters";
import { GraphProviders } from "../../../common/Providers/GraphProviders";
import { GraphsHead } from "./graphs/GraphsHead";
import { FilterSku } from "./components/FilterSku";
import { GraphsFooter } from "./graphs/GraphsFooter";

const ENUM_FILTERS = {
  brand: "marca",
  family: "familia",
  subClass: "subclase",
  cat: "descripcion_categoria",
  cod: "codigo_producto",
  desc: "descripcion_producto",
  type: "tipo",
  abc: "abc",
}



const RadarSKUScreen = () => {

  const { branchID } = useContext(BranchsContext);
  const overallStyle = useMemo(() => styleSheet(), []);
  const { queryFilter, filters, handleFilters } = useFilters(ENUM_FILTERS);

  return (
    <GraphProviders value={{
      ENUM_FILTERS,
      queryFilter,
      filters,
      handleFilters,
    }}>

      <Box sx={{ ...overallStyle?.container, height: "50vh" }}>
        <Title name={t("titles.radarSku")} />
        <Box marginX={3} sx={{ marginTop: 8 }}>
          {/* {FILTROS} */}
          <FilterSku />
          <BoxTitle
            title={"Producto"}
            subtitle={
              `Producto: ${filters[ENUM_FILTERS.cod]} 
              - Categoria: ${filters[ENUM_FILTERS.cat]} 
              - Subclase: ${filters[ENUM_FILTERS.subClase]} 
              - ABC: ${filters[ENUM_FILTERS.abc]}`
            }
          />
          {/* {GRAFICOS....} */}
          <GraphsHead />
          <GraphsFooter />
        </Box>
      </Box>
    </GraphProviders>
  );
};

export default RadarSKUScreen;
