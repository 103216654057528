import { Box } from '@mui/material';
import React from 'react'
import { useContext } from 'react';
import { CustomSelectBranch } from '../../../../common/Components/Select/CustomSelectBranch';
import { CustomSelectCompany } from '../../../../common/Components/Select/CustomSelectCompany';
import BranchsContext from '../../../../common/Contexts/BranchContext';
import CompanyContext from '../../../../common/Contexts/CompanysContext';
import userFormModel from '../../../../model/user/userFormModel';
import ListSelectedBranchs from '../components/ListSelectedBranchs';
import { t } from 'i18next'
import { useFormikContext } from 'formik';
import { TitleStep } from '../../../../common/Components/TitleStep';
import checkoutForm from '../../../../model/user/userFormModel';

export const BranchsUser = () => {

    const { companys, company, setCompany } = useContext(CompanyContext);
    const { branchID, setBranchID, branchs } = useContext(BranchsContext);
    const { values } = useFormikContext();
    const { formField } = checkoutForm;



    return (
        <Box sx={{ px: 5, py: 2 }}>
            <TitleStep title={t('steps.user.step3')} />
            <CustomSelectCompany
                companys={companys}
                formField={formField}
            />
            <CustomSelectBranch
                companys={companys}
                formField={formField}
            />
            <ListSelectedBranchs formField={formField} />
        </Box>
    )
}
