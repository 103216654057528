import React from "react";
import { CustomTable } from "../../../../common/Components/CustomTable";
import checkoutForm from "../../../../model/centerOfDistribution/centerDistributionFormModel";

export const TableDistribution = () => {

  const { formField: {
    cod_cd,
    cd,
    priority,
    region,
    comuna,
    ciudad,
    direccion,
    latitude,
    longitud
  } } = checkoutForm;

  const headers = [
    { field: "cod_cd", headerName: cod_cd.headerTable },
    { field: "cd", headerName: cd.headerTable },
    { field: "priority", headerName: priority.headerTable },
    { field: "region", headerName: region.headerTable },
    { field: "comuna", headerName: comuna.headerTable },
    { field: "ciudad", headerName: ciudad.headerTable },
    { field: "direccion", headerName: direccion.headerTable },
    { field: "latitude", headerName: latitude.headerTable },
    { field: "longitud", headerName: longitud.headerTable },
  ];

  const actions = ["Detalle", "Editar", "Eliminar"];

  return (
    <CustomTable
      actions={actions}
      headers={headers}
    />
  );
};
