import { Box, Stack } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useContext, useState, useMemo } from 'react'
import CustomModal from '../../../common/Components/CustomModal';
import { CStepper } from '../../../common/Components/stepper/CStepper';
import { CSteps } from '../../../common/Components/stepper/CSteps';
import { CToast } from '../../../common/Components/ToastNotify';
import BranchsContext from '../../../common/Contexts/BranchContext';
import CompanyContext from '../../../common/Contexts/CompanysContext';
import ModulesFormContext from '../../../common/Contexts/ModulesFormContext';
import UserContext from '../../../common/Contexts/UserContext';
import { ModalOpenEnum } from '../../../common/utils/ModalOpenEnum';
import { validateFormUser } from '../../../model/user/validation.schema';
import UsersService from '../../../services/UsersService';
import { BranchsUser } from './screen_steps/BranchsUser';
import { DataUser } from './screen_steps/DataUser';
import { RolesUser } from './screen_steps/RolesUser';
var store = require("store");

export const UserForm = () => {

    const {
        steps,
        handleResetStep,
        handleNextStep,
        activeStep,
        handleSetActiveStep,
        rowData: user,
        isLastStep,
        isOpen,
        toggleModal,
        getDataTable
    } = useContext(ModulesFormContext);

    const { userGlobal, getRefreshUser } = useContext(UserContext);
    const elements = store.get("sidebar");
    const { branchs } = useContext(BranchsContext);
    const { companys } = useContext(CompanyContext);
    const [companyID, setCompanyID] = useState("");
    const [isLoading, setIsLoading] = useState(false);;
    let currentValidationSchema = validateFormUser[activeStep];

    const initialValue = {
        first_name: user?.first_name || "",
        last_name: user?.last_name || "",
        rut: user?.rut || "",
        email: user?.email || "",
        gender: user?.gender || "",
        password: user ? null : "",
        password_confirmation: user ? null : "",
        modulo_cluster: user?.roles || elements,
        branchs_selected: [],
        company_user: user?.company_user || [],
        select_rol_name: user?.rol_id || "",
        isSubmit: false,
        isEditing: user ? true : false,
        companyID: "",
        branch: "",
        isSuperAdmin: user?.isSuperAdmin || false,
        user_id: user?.user_id
    };

    const onSave = async (value) => {
        setIsLoading(true);
        const req = {
            company_user: value.branchs_selected.map(item => item.company_id),
            first_name: value.first_name,
            last_name: value.last_name,
            email: value.email,
            gender: value.gender,
            roles: value.modulo_cluster,
            rut: value.rut,
            rol_id: value.select_rol_name,
            isSuperAdmin: value.isSuperAdmin,
        };
        !value.isEditing && (Object.assign(req, { password: value.password }));
        value.isEditing && (Object.assign(req, { user_id: value.user_id }));
        try {
            const { data } = value.isEditing
                ? await UsersService.updateUser(req)
                : await UsersService.createUser(req);
            await getDataTable();
            handleResetStep();
            toggleModal(ModalOpenEnum.EDIT);
            if (value.isEditing && userGlobal.user_id === value.user_id) await getRefreshUser(userGlobal.user_id);
            CToast(`El usuario se ${value.isEditing ? 'edito' : 'creo'} correctamente`, "success", true);
        } catch ({ response }) {
            console.log(response)
            if (response.data?.message) return (CToast(response.data?.message, "error", true))
            return CToast("Error al crear el usuario", "error", true)
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <CustomModal
            isOpen={isOpen[ModalOpenEnum.EDIT]}
            toggleModal={() => { handleResetStep(); toggleModal(ModalOpenEnum.EDIT); }}
            moduleLabel={'user'}
            actionLabel={user ? "edit" : "create"}
            descriptionLabel={user ? `${user.first_name} ${user.last_name}` : ''}
        >
            <Formik
                initialValues={initialValue}
                validationSchema={currentValidationSchema}
                onSubmit={(values, actions) => {
                    (isLastStep || values.isSuperAdmin) ? onSave(values) : handleNextStep();
                    actions.setSubmitting(false);
                }}
            >
                {({ errors, handleSubmit, isSubmitting, values, isValid }) => {
                    return (
                        <Form>
                            <CStepper activeStep={activeStep} steps={steps} setActiveStep={handleSetActiveStep} errors={errors} isLoading={isLoading} isSubmitting={isSubmitting}>
                                <CSteps
                                    label={steps[0]}
                                    onSubmit={values.isSuperAdmin === true ? handleSubmit : null}
                                    onNext={(!errors.email || !values.isSuperAdmin) ? handleSubmit : () => { }}
                                    errors={errors}
                                    isSubmitting={isSubmitting}
                                >
                                    <DataUser actionLabel={user ? "edit" : "create"} />
                                </CSteps>
                                <CSteps
                                    label={steps[1]}
                                    onNext={handleSubmit} errors={errors}
                                    isSubmitting={isSubmitting}
                                >
                                    <RolesUser />
                                </CSteps>
                                <CSteps
                                    label={steps[2]}
                                    onSubmit={handleSubmit}
                                    errors={errors}
                                    isEditing={values.isEditing}
                                >
                                    <BranchsUser companyID={companyID} setCompany={setCompanyID} />
                                </CSteps>
                            </CStepper>
                            <Box justifyContent={"center"} display={"flex"}></Box>
                            <Stack width={"100%"} direction={"row"}></Stack>
                            <Stack
                                direction={"row"}
                                justifyContent={"space-around"}
                                paddingX={8}
                                marginTop={3}
                            >
                                <Box width={"50%"}>
                                    <Box width={"50%"} />
                                </Box>
                                <Box width={"50%"}></Box>
                            </Stack>
                        </Form>
                    );
                }}
            </Formik>
        </CustomModal>
    )
}
