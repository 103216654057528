import { line } from 'd3-shape';
import React from 'react'
import { formatN } from '../../../helpers/formatNumber';

const lineColor = "rgba(200, 30, 15, 1)";
const circleColor = "rgba(11,59,80,1)";

export const LineBar = (itemX, itemY, { bars, xScale, yScale }) => {
    console.log("LineBar",bars);
    const lineGenerator = line()
        .x(bar => xScale(bar.data.indexValue) + bar.width / 2)
        .y(bar => yScale(formatN(bar.data.data[itemY])));

    return (
        <React.Fragment >
            <path
                d={lineGenerator(bars)}
                fill="none"
                stroke={lineColor}
                style={{ pointerEvents: "none" }}
            />
            {/* {bars?.map(bar => {
                return (
                    <circle
                        key={bar.key}
                        cx={xScale(bar.data.indexValue) + bar.width / 2}
                        cy={yScale(formatN(bar.data.data[itemY]))}
                        r={3}
                        fill={'white'}
                        stroke={circleColor}
                        style={{ pointerEvents: "none" }}
                    />
                )
            })} */}
        </React.Fragment>
    );
}
