import * as Yup from 'yup';

export const CronJobchema = Yup.object().shape({
    job_name: Yup.string().required('El nombre del job es requerido'),
    description: Yup.string().required('La descripción del job es requerida'),
    command: Yup.string().required('El comando del job es requerido'),
});

export const validateLogin = Yup.object({
    email: Yup.string().email('Dirección email invalido').required('El email es requerido'),
    password: Yup.string()
    .min(8, 'La contraseña debe tener al menos 8 caracteres')
    .max(32, 'La contraseña debe tener como máximo 32 caracteres')
    .matches(/((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/, 'La contraseña debe tener al menos una letra mayúscula, una letra minúscula y un caracter especial')
    .required("Debe ingresar la contraseña"),
});

export const validateSendEmail = Yup.object({
    email: Yup.string().email('Dirección email invalido').required('El email es requerido'),
});

export const validateChangePass = Yup.object({
    password: Yup.string()
    .min(8, 'La contraseña debe tener al menos 8 caracteres')
    .max(32, 'La contraseña debe tener como máximo 32 caracteres')
    .matches(/((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/, 'La contraseña debe tener al menos una letra mayúscula, una letra minúscula y un caracter especial')
    .required("Debe ingresar la contraseña"),
})

export const validateWarehouse = Yup.object({
    cod_bodega: Yup.string().required('El código de la bodega es requerido'),
    bodega: Yup.string().required('El nombre de la bodega es requerido'),
    canal: Yup.string().required('El canal de la bodega es requerido'),
    region: Yup.string().required('La región de la bodega es requerida'),
    latitude: Yup.string().notRequired(),
    longitude: Yup.string().notRequired(),
    direccion: Yup.string().notRequired(),
    // company_id: Yup.number(4).required('La compañía de la bodega es requerida'),
});

export const validateDistributionCenter = Yup.object({
    cod_cd: Yup.string().required('El código del centro de distribución es requerido'),
    cd: Yup.string().required('El nombre del centro de distribución es requerido'),
    priority: Yup.number().required('La prioridad del centro de distribución es requerida').default(1),
    ciudad: Yup.string().required('La ciudad del centro de distribución es requerida'),
    comuna: Yup.string().required('La comuna del centro de distribución es requerida'),
    region: Yup.string().required('La región del centro de distribución es requerida'),
    latitude: Yup.number().required('La latitud del centro de distribución es requerida'),
    longitud: Yup.number().required('La longitud del centro de distribución es requerida'),
    direccion: Yup.string().required('La dirección del centro de distribución es requerida'),
});

export const validateCalendar = Yup.object({
    dispatch_day: Yup.number().required('El día de despacho es requerido'),
    lead_time: Yup.number().required('El lead time es requerido').min(1, 'El lead time debe ser mayor a 0'),
    replacement_day: Yup.number().required('El día de reposición es requerido'),
    id_bodega: Yup.string().required('La bodega es requerida'),
});

export const validateDailyWeights = Yup.object({
    dia: Yup.number().required('El día es requerido'),
    peso: Yup.number().required('El peso es requerido'),
});

export const validateHomologation = Yup.object({
    old_su: Yup.string().required('El ultimo SU es requerido'),
    new_sku: Yup.string().required('El nuevo SKU es requerido'),
});

export const validateWeekConstants = Yup.object({
    week_number: Yup.number().required('El número de semana es requerido').max(24, 'El número de semana debe ser menor o igual 24'),
    constant: Yup.number().required('La constante es requerida').default(0.25),
});

export const validateLevelService = Yup.object({
    letra: Yup.string().required('La letra es requerida'),
    nivel_servicio: Yup.number().required('El nivel de servicio es requerido').min(1, 'El nivel de servicio debe ser mayor a 0'),
    descripcion: Yup.string().required('La descripción es requerida'),
});

export const validateFormSuggested = Yup.object({
    reference_days: Yup.number().required('Las semanas de referencia son requeridas'),
    classificationA: Yup.number()
        .required("La clasificación A es requerida")
        .min(1, "el valor debe ser mayor a 0")
        .max(98, "el valor debe ser menor a 99"),
    classificationB: Yup.number()
        .required("La clasificación B es requerida")
        .min(1, "el valor debe ser mayor a 0")
        .max(99, "el valor debe ser menor a 100"),
    classificationC: Yup.number().required("La clasificación C es requerida").default(100),
    description: Yup.string().required('La descripción es requerida'),
    constantf1f2: Yup.number()
        .required("La constante f1f2 es requerida")
        .min(0, "el valor debe ser mayor o igual a 0")
        .max(100, "el valor debe ser menor o igual a 100").default(2),
    constantvalor1: Yup.number()
        .required("La constante valor1 es requerida")
        .min(0, "el valor debe ser mayor o igual a 0")
        .max(100, "el valor debe ser menor o igual a 100").default(3.7),
    constantvalor2: Yup.number()
        .required("La constante valor2 es requerida")
        .min(0, "el valor debe ser mayor o igual a 0")
        .max(100, "el valor debe ser menor o igual a 100").default(2.7),
    soft_limit: Yup.number()
        .required("El límite suave es requerido")
        .min(1, "el valor debe ser mayor a 0").default(6),
    quantity_fraction: Yup.string()
        .required("La fracción es requerida").min(1, "el valor debe ser mayor a 0").default(2),
    quantity_fraction2: Yup.string().required("La fracción es requerida").default(2),
    confirmed: Yup.number().required('Debe seleccionar una opción'),
    // default: Yup.boolean().required("").default(true),
});

export const validateDateExcluded = Yup.object({
    fecha_excluida: Yup.string().required('La fecha es requerida'),
    date_excluded: Yup.string().required('La fecha es requerida'),
});

export const validateGuideTransit = Yup.object({
    transfer_id: Yup.string().required('El id de trasnferencia es requerido'),
});