export default {
    formId: 'formServiceLevel',
    formField: {
        letra: {
            name: 'letra',
            label: 'Letra',
            headerTable: 'Letra',
            requiredErrorMsg: 'La letra es requerida',
        },
        nivel_servicio: {
            name: 'nivel_servicio',
            label: 'Nivel de servicio',
            headerTable: 'Nivel de servicio',
            min: 1,
            requiredErrorMsg: 'El nivel de servicio es requerido',
            minErrorMsg: 'El nivel de servicio debe ser mayor a 0',
        },
        descripcion: {
            name: 'descripcion',
            label: 'Descripción',
            headerTable: 'Descripción',
            requiredErrorMsg: 'La descripción es requerida',
        },
    },
}