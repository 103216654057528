import { useContext, useState, useEffect } from 'react'
import { Box } from "@mui/material";
import { t } from 'i18next'
import Title from "../../../common/Components/Title";
import { ButtonsFileCSV } from "../../../common/Components/ButtonsFileCSV";
import CalendarService from '../../../services/CalendarService';
import { CustomModalDelete } from "../../../common/Components/CustomModalDelete";
import BranchsContext from '../../../common/Contexts/BranchContext';
import { useForm } from '../../../hooks/useForm';
import ModulesFormContext from '../../../common/Contexts/ModulesFormContext';
import { CalendarForm } from './CalendarForm';
import { TableCalendar } from './components/TableCalendar';
import { ModulesProvider } from '../../../common/Providers/ModulesProvider';
import { LayoutTable } from '../../../common/Components/LayoutTable';
import { CToast } from '../../../common/Components/ToastNotify';
import { ModalOpenEnum } from '../../../common/utils/ModalOpenEnum';
import { weekdays } from '../../../helpers/weekdays';


const Calendar = () => {
    const { overallStyle } = useContext(ModulesFormContext);
    const { branchID } = useContext(BranchsContext);
    //LOGIC FORM
    const {
        isOpen,
        rowData,
        toggleModal,
        dataTable,
        setDataTable,
        isTableLoading,
        setIsTableLoading
    } = useForm();
    const [isLoadingDelet, setIsLoadingDelet] = useState(false);

    const getDataTable = async () => {
        setIsTableLoading(true);
        const req = {
            company_id: branchID,
        };
        try {
            const { data } = await CalendarService.getCalendarsByBranch(req);
            setDataTable(data);
        } catch ({ response }) {
            console.log(response);
        } finally {
            setIsTableLoading(false);
        }
    };

    const hadleDeleteCalendar = async () => {
        setIsLoadingDelet(true);
        const req = {
            id_calendario: rowData?.id_calendario,
            company_id: branchID,
        };
        try {
            await CalendarService.deleteCalendars(req);
            await getDataTable();
            toggleModal(ModalOpenEnum.DELETE);
            CToast(`Fecha eliminada correctamente`, "success", true);
        } catch (error) {
            console.log(error);
            CToast(`Error al eliminar fecha`, "error", true);
        } finally {
            setIsLoadingDelet(false);
        }
    }

    useEffect(() => {
        branchID.length > 0 && getDataTable();
    }, [branchID]);

    return (
        <ModulesProvider value={{
            getDataTable,
            handleDelete: hadleDeleteCalendar,
            isOpen,
            rowData,
            toggleModal,
            dataTable,
            setDataTable,
        }}>
            <Box sx={overallStyle?.container}>
                <Title name={t('titles.calendar')} />
                <ButtonsFileCSV />
                <LayoutTable labelAction={'Agregar Fecha'}
                    disabledButon={branchID.length === 0 ? true : false}>
                    {
                        isOpen[ModalOpenEnum.EDIT] && (
                            <CalendarForm />

                        )
                    }
                    {isOpen[ModalOpenEnum.DELETE] && (<CustomModalDelete
                        descriptionLabel={rowData?.dispatch_day
                            ? `${weekdays.find(w => w.day_id === rowData.dispatch_day)?.day_name}`
                            : ''}
                        isLoading={isLoadingDelet}
                        moduleLabel={'calendar'}
                    />)}
                    <TableCalendar />
                </LayoutTable>
            </Box>
        </ModulesProvider>

    )
}


export default Calendar