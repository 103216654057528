import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useContext, useState, useEffect } from "react";
import { FormTextInput } from "../../../common/Components/FormTextInput";
import { CToast } from "../../../common/Components/ToastNotify";
import AuthContext from "../../../common/Contexts/AuthContext";
import { styleSheet } from "../../../common/styles/StylesCommon";
import { validateEmail } from "../../../helpers/validateEmail";
import { validateSendEmail } from "../../../helpers/validationScreen.schema";
import LoginService from "../../../services/LoginService";
import ChangePassConfirm from "./ChangePassConfirm";

const ChangePass = ({setchangePassOk}) => {
  const { login } = useContext(AuthContext);

  const [flagSendEmail, setFlagSendEmail] = useState(false);
  const [email, setEmail] = useState("");

  const [errorEmpty, setErrorEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  
  const styles = styleSheet();
  const initialValue = {
    email: "",
  };
  const onSubmit = async (data) => {
    setIsLoading(true);
    const req = {
      email: data.email,
    };

    setEmail(data.email);

    try {
      const { data } = await LoginService.sendMailPass(req);
      
      CToast("Usuario encontrado correctamente", "success");
      data && setFlagSendEmail(true);
      setIsLoading(false);
    } catch (error) {
      setErrorEmpty(false);
      setTimeout(() => {
        setErrorEmpty(false);
      }, 4000);
      console.log(error);
      CToast(error.response.data.error, "error");
      setIsLoading(false);
    }
  };

  return flagSendEmail === false ? (
    <Formik
      initialValues={initialValue}
      validationSchema={validateSendEmail}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {() => {
        return (
          <Box width={"100%"} display={"flex"} sx={{ flexDirection: "column" }}>

            <Box width={"100%"} display="flex" justifyContent={"center"}>
              <Typography style={styles.titleWelcome}>
                Ingrese su email
              </Typography>
            </Box>
            <Form>
              <Box
                mx={8}
                mt={2}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <FormTextInput
                  label={"Email del contacto"}
                  name={"email"}
                  type="text"
                  fullWidth={true}
                />

                <Button
                  type="submit"
                  variant="contained"
                  disabled={isLoading ? true : false}
                  sx={{
                    ...styles.buttonLogin,
                  }}
                >
                  {isLoading ? <CircularProgress /> : "Enviar correo"}
                </Button>
              </Box>
            </Form>
          </Box>
        );
      }}
    </Formik>
  ) : (
    <ChangePassConfirm email={email} setchangePassOk={setchangePassOk} />
  );
};

export default ChangePass;
