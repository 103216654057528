import { Box } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import Title from '../../../common/Components/Title';
import { t } from 'i18next'
import { useForm } from '../../../hooks/useForm';
import { useContext } from 'react';
import { CronJobsForm } from './CronJobsForm';
import BranchsContext from '../../../common/Contexts/BranchContext';
import { CToast } from '../../../common/Components/ToastNotify';
import CronjobsService from '../../../services/CronjobsService';
import { CronJobsTable } from './components/CronJobsTable';
import { LayoutTable } from '../../../common/Components/LayoutTable';
import { ModulesProvider } from '../../../common/Providers/ModulesProvider';
import ModulesFormContext from '../../../common/Contexts/ModulesFormContext';
import { ModalOpenEnum } from '../../../common/utils/ModalOpenEnum';
import { styleSheet } from '../../../common/styles/StylesCommon';
import CompanyContext from '../../../common/Contexts/CompanysContext';


export const Cronjobs = () => {

    const { branchID } = useContext(BranchsContext);
    const { company } = useContext(CompanyContext);
    const overallStyle = useMemo(() => styleSheet(), []);
    //LOGIC FORM
    const {
        isOpen,
        rowData,
        toggleModal,
        dataTable,
        setDataTable,
        isTableLoading,
        setIsTableLoading
    } = useForm();


    const getDataTable = async () => {
        setIsTableLoading(true);
        const req = {
            company_id: branchID.length != 0 ? String(branchID) : company.company_id,
        };
        try {
            const { data } = await CronjobsService.getCronByBranch(req);
            setDataTable(data);
            console.log('CRON JOB', data)
        } catch (error) {
            console.log(error);
            CToast(t('messages.error_get_dataTable'), "error", true);
        } finally {
            setIsTableLoading(false);
        }
    }

    useEffect(() => {
        getDataTable();
    }, [branchID, company]);

    return (
        <ModulesProvider value={{
            getDataTable,
            isOpen,
            rowData,
            toggleModal,
            dataTable,
            setDataTable,
        }}>
            <Box display='flex' flexDirection={'column'} sx={overallStyle?.container}>
                <Title name={t('titles.cron_jobs')} />
                <LayoutTable labelAction={'Agregar Cronjob'}>
                    {
                        isOpen[ModalOpenEnum.EDIT] && (
                            <CronJobsForm />
                        )
                    }
                    <CronJobsTable />
                </LayoutTable>
            </Box>
        </ModulesProvider >
    )
}
