import { FormControl, MenuItem, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useEffect, useContext } from 'react'
import BranchsContext from '../../Contexts/BranchContext';
import CompanyContext from '../../Contexts/CompanysContext';
import { CustomSelect } from '../CustomSelect';

export const CustomSelectCompany = ({ formField, isLoading, companys }) => {


    // const { branchID, setBranchID, branchs, setBranchs } = useContext(BranchsContext);
    const { companyID, branchID } = formField;
    const { setFieldValue } = useFormikContext();
    // useEffect(() => {
    // let newCompany = companys.filter(company => company.company_id === companyID)[0];
    // setCompany(companys.filter(company => company.company_id === companyID)[0]);
    // setBranchID([]);
    // setBranchs(companys.filter(company => company.company_id === companyID)[0]?.branchs);
    // }, [companyID]);

    const handleChange = (value) => {
        setFieldValue(companyID.name, value.company_id);
        // setFieldValue(branchID.name, []);
        // setFieldValue(branchID.name, companys.filter(company => company.company_id === companyID)[0]?.branchs);
    }

    return (
        <>
            <Typography mb={1} mt={2} color={"common.title"} variant={"h6"}>
                Seleccionar una empresa
            </Typography>
            <FormControl
                fullWidth
                sx={{ mb: 5, mt: 0 }}
            >
                <CustomSelect
                    id={companyID.name}
                    label={companyID.label}
                    labelId={companyID.label}
                    name={companyID.name}
                    disabled={isLoading}
                    sx={{
                        textAlign: 'left',
                        borderRadius: 20,
                        height: "50px",
                        borderColor:
                            "linear-gradient(90deg, rgba(7,64,107,1) 0%, rgba(7,64,107,1) 35%, rgba(0,184,255,1) 100%)",
                    }}
                >
                    {companys.map((item, index) => (
                        <MenuItem key={index} value={item.company_id} onClick={() => handleChange(item)}>
                            {item?.company_name}
                        </MenuItem>
                    ))}
                </CustomSelect>
            </FormControl>
        </>
    );
}
