
import authAxios from "../common/Auth/authAxios";

const LoginService = {
  login: async (dataForm) => {
    return await authAxios.post('/auth/signin', dataForm)
  },
  sendMailPass: async (email) => {
    return await authAxios.patch('/auth/changepass', email)
  },
  changePass: async (dataForm) => {
    return await authAxios.patch('/auth/changepass', dataForm)
  },

}

export default LoginService

