import React, { useState } from 'react'
import { t } from 'i18next';
import { Alert, Box, Stack } from '@mui/material';
import { SelectConfigAccess } from '../../../../common/Components/SelectConfigAccess';
import companyFormModel from '../../../../model/company/companyFormModel';
import { styleSheet } from '../../../../common/styles/StylesCommon';
import { useFormikContext } from 'formik';
import { ListCheckRoles } from '../../../../common/Components/ListCheckRoles';
import { TitleStep } from '../../../../common/Components/TitleStep';

export const DataAccessBranch = ({ configAccess, getConfigsxCompany, isLoading }) => {
    const { formField } = companyFormModel;
    const {
        elements_cluster,
        select_permission_branch
    } = formField;
    const { values } = useFormikContext();
    const [nameConfigEqual, setNameConfigEqual] = useState("");
    const styles = styleSheet();

    return (
        <React.Fragment>
            <TitleStep title={t('steps.company.step5')} />
            {values[select_permission_branch.name] === "" && (
                <Box sx={{ marginX: 8 }}>
                    <Alert
                        severity="warning"
                        style={{
                            ...styles.baseAlert,
                        }}
                    >
                        Seleccione una configuración antes de continuar
                    </Alert>
                </Box>
            )}
            <Stack py={5} justifyContent={"space-evenly"}>
                <Box paddingX={8}>
                    <SelectConfigAccess
                        name_elements_checks={elements_cluster}
                        fullWidth
                        name={select_permission_branch.name}
                        label={select_permission_branch.label}
                        isLoading={isLoading}
                        configsAccess={configAccess}
                        nameConfigEqual={nameConfigEqual}
                    />
                    <Box>
                        <ListCheckRoles
                            nameConfigEqual={nameConfigEqual}
                            setNameConfigEqual={setNameConfigEqual}
                            name_elements={elements_cluster}
                            name_permission={select_permission_branch.name}
                            configsAccess={configAccess}
                            getConfigsxCompany={getConfigsxCompany}
                        />
                    </Box>
                </Box>
            </Stack>
        </React.Fragment>
    )
}
