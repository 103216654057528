export default {
    formId: 'centerDistributionForm',
    formField: {
        cod_cd: {
            name: 'cod_cd',
            label: 'Código',
            headerTable: 'Código',
            requiredErrorMsg: 'El código del centro de distribución es requerido'
        },
        cd: {
            name: 'cd',
            label: 'Nombre',
            headerTable: 'Nombre',
            requiredErrorMsg: 'El nombre del centro de distribución es requerido'
        },
        priority: {
            name: 'priority',
            label: 'Prioridad',
            headerTable: 'Prioridad',
            default: 1,
            requiredErrorMsg: 'La prioridad del centro de distribución es requerida'
        },
        region: {
            name: 'region',
            label: 'Región',
            headerTable: 'Región',
            requiredErrorMsg: 'La región del centro de distribución es requerida'
        },
        comuna: {
            name: 'comuna',
            label: 'Comuna',
            headerTable: 'Comuna',
            requiredErrorMsg: 'La comuna del centro de distribución es requerida'
        },
        ciudad: {
            name: 'ciudad',
            label: 'Ciudad',
            headerTable: 'Ciudad',
            requiredErrorMsg: 'La ciudad del centro de distribución es requerida'
        },
        direccion: {
            name: 'direccion',
            label: 'Dirección',
            headerTable: 'Dirección',
            requiredErrorMsg: 'La dirección del centro de distribución es requerida'
        },
        latitude: {
            name: 'latitude',
            label: 'Latitud',
            headerTable: 'Latitud',
            requiredErrorMsg: 'La latitud del centro de distribución es requerida'
        },
        longitud: {
            name: 'longitud',
            label: 'Longitud',
            headerTable: 'Longitud',
            requiredErrorMsg: 'La longitud del centro de distribución es requerida'
        },
    }
}