import { useContext, useEffect, useState } from 'react'
import { Box } from "@mui/material";
import { t } from 'i18next'
import Title from "../../../common/Components/Title";
import { ButtonsFileCSV } from "../../../common/Components/ButtonsFileCSV";
import DailyMoneyService from '../../../services/DailyMoneyService';
import BranchsContext from '../../../common/Contexts/BranchContext';
import { useForm } from '../../../hooks/useForm';
import { LayoutTable } from '../../../common/Components/LayoutTable';
import { TableDailyMoney } from './components/TableDailyMoney';
import { DailyMoneyForm } from './DailyMoneyForm';
import ModulesFormContext from '../../../common/Contexts/ModulesFormContext';
import { ModulesProvider } from '../../../common/Providers/ModulesProvider';
import { CToast } from '../../../common/Components/ToastNotify';
import { CustomModalDelete } from '../../../common/Components/CustomModalDelete';
import { ModalOpenEnum } from '../../../common/utils/ModalOpenEnum';



const DailyMoney = () => {

    const { branchID } = useContext(BranchsContext);
    const { overallStyle } = useContext(ModulesFormContext);
    //LOGIC FORM
    const {
        isOpen,
        rowData,
        toggleModal,
        dataTable,
        setDataTable,
        isTableLoading,
        setIsTableLoading
    } = useForm();
    const [isLoadingDelet, setIsLoadingDelet] = useState(false)

    const getDataTable = async () => {
        setIsTableLoading(true);
        const req = {
            company_id: branchID,
        };
        try {
            const { data } = await DailyMoneyService.getDailyMoneyByBranch(req);
            console.log(data);
            setDataTable(data);
        } catch (error) {
            console.log(error)
        } finally {
            setIsTableLoading(false);
        }
    };

    const handleDeleteDailyMoney = async () => {
        setIsLoadingDelet(true);
        const req = {
            id_pesosDiarios: rowData.id_pesosDiarios,
            company_id: branchID,
        }
        try {
            const { data } = await DailyMoneyService.deleteDailyMoney(req);
            await getDataTable();
            toggleModal(ModalOpenEnum.DELETE);
            CToast(t('messages.success_delete'), "success");
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoadingDelet(false);
        }
    }

    useEffect(() => {
        branchID.length > 0 && getDataTable();
    }, [branchID]);


    return (
        <ModulesProvider value={{
            getDataTable,
            handleDelete: handleDeleteDailyMoney,
            isOpen,
            rowData,
            toggleModal,
            dataTable,
            setDataTable,
        }}>
            <Box sx={overallStyle?.container}>
                <Title name={t('titles.weights_daily')} />
                <ButtonsFileCSV />
                <LayoutTable labelAction={'Agregar Peso diario'} disabledButon={branchID.length > 0 ? false : true}>
                    {isOpen[ModalOpenEnum.EDIT] && (<DailyMoneyForm />)}
                    {isOpen[ModalOpenEnum.DELETE] && (<CustomModalDelete
                        descriptionLabel={rowData?.peso ? rowData.peso : ''}
                        isLoading={isLoadingDelet}
                        moduleLabel={'weights_daily'}
                    />)}
                    <TableDailyMoney />
                </LayoutTable>
            </Box>
        </ModulesProvider>

    )
}


export default DailyMoney