import authAxios from "../common/Auth/authAxios";

const DailyMoneyService = {
    getDailyMoneyByBranch: async (branch) => {
        return await authAxios.post('/inputs-generales/getPesosDiarios', branch)
    },
    createDailyMoney: async (dailyMoney) => {
        return await authAxios.post('/inputs-generales/addPesosDiarios', dailyMoney)
    },
    updateDailyMoney: async (dailyMoney) => {
        return await authAxios.patch('/inputs-generales/editPesosDiarios', dailyMoney)
    },
    deleteDailyMoney: async (dailyMoney) => {
        return await authAxios.delete('/inputs-generales/deletePesosDiarios',
            { data: dailyMoney }
        )
    }
}

export default DailyMoneyService