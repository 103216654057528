import "react-toastify/dist/ReactToastify.css";
import {
  toast,
  ToastContainer,
  ToastPosition,
  TypeOptions,
} from "react-toastify";

export const ShowToast = () => {
  return (
    <div>
      <ToastContainer
        autoClose={3000}
     
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={5}
      />
    </div>
  );
};

export const CToast = (
  msg,
  type,
  hideProgressBar = false,
  position = "top-center"
) => {
  return toast(msg, {
    position: position,
    autoClose: 4000,

    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type: type,
  });
};
