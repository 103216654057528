import React, { useState, useContext, useEffect } from "react";
import {
  Alert,
  CircularProgress,
  Box,
  Button,
  Typography,
} from "@mui/material";
import LoginService from "../../services/LoginService";
import AuthContext from "../../common/Contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { styleSheet } from "../../common/styles/StylesCommon";
import UserContext from "../../common/Contexts/UserContext";
import UsersService from "../../services/UsersService";
import { FormTextInput } from "../../common/Components/FormTextInput";
import { Form, Formik } from "formik";
import { validateLogin } from "../../helpers/validationScreen.schema";
import { InputPassword } from "../../common/Components/InputPassword";
import { CToast } from "../../common/Components/ToastNotify";
import { t } from 'i18next'

var store = require("store");

const LoginForm = ({ userRemember, setUserRemember, remember }) => {
  const { login } = useContext(AuthContext);

  const { create } = useContext(UserContext);

  const styles = styleSheet();

  const [isLoadingConfirmButton, setIsLoadingConfirmButton] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const [error, setError] = useState(null);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleChange = (event) => {
    if (!isValidEmail(event.target.value) && event.target.value.length > 4) {
      setError("Error con el formato del email");
    } else {
      setError(null);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const userRememberStore = store.get("userRemember");
  const initialValues = {
    email: userRememberStore ? userRememberStore.email : "",
    password: userRememberStore ? userRememberStore.password : "",
  };
  const onSubmit = async (data) => {
    
    setIsLoadingConfirmButton(true);

    remember === true && store.set("userRemember", data);

    const req = {
      email: data.email,
      password: data.password,
    };

    try {
      const { data } = await LoginService.login(req);
      setIsLoadingConfirmButton(false);
      login(data);

      try {
        const userId = data.user_id;
        const reqUser = {
          user_id: userId,
        };
        const { data: user } = await UsersService.getUserById(reqUser);
        setIsLoadingConfirmButton(false);
        create(user);
      } catch ({ response }) {
        CToast(response.data.error || t('messages.error_login'), "error");
      }

      navigate("/", { replace: true });
    } catch (error) {
      console.log(error);
      CToast(error.response.data.error, "error");
      setIsLoadingConfirmButton(false);
    }
  };

  return (
    <Box width={"100%"} display={"flex"} sx={{ flexDirection: "column" }}>
      <Box width={"100%"} display="flex" justifyContent={"center"}>
        <Typography style={styles.titleWelcome}>Bienvenido</Typography>
      </Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validateLogin}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {() => {
          return (
            <Form>

              <Box
                mx={8}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <FormTextInput
                  name={"email"}
                  type="text"
                  label="Email"
                  fullWidth={true}
                />

                <InputPassword />

                <Button
                  type="submit"
                  variant="contained"
                  disabled={isLoadingConfirmButton ? true : false}
                  style={{
                    ...styles.buttonLogin,
                    marginTop: 20,
                  }}
                >
                  {isLoadingConfirmButton ? <CircularProgress /> : "INGRESAR"}
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default LoginForm;
