import { Box } from '@mui/material';
import { Formik } from 'formik';
import React, { useState, useContext } from 'react'
import { ButtonSave } from '../../../common/Components/ButtonSave';
import { ContainerForm } from '../../../common/Components/ContainerForm';
import CustomModal from '../../../common/Components/CustomModal';
import { FormTextInput } from '../../../common/Components/FormTextInput';
import { CToast } from '../../../common/Components/ToastNotify';
import BranchsContext from '../../../common/Contexts/BranchContext';
import MediaContext from '../../../common/Contexts/MediaContext';
import ModulesFormContext from '../../../common/Contexts/ModulesFormContext';
import { ModalOpenEnum } from '../../../common/utils/ModalOpenEnum';
import checkoutFormModel from '../../../model/transitGuide/transit.model';
import { validateFormGuideTransit } from '../../../model/transitGuide/transit.schema';
import ExludeGIDService from '../../../services/ExcludeGidService';

export const ExcludeGidForm = () => {
    const { isMobile, isTablet } = useContext(MediaContext);
    const { branchID } = useContext(BranchsContext);
    const { isOpen, toggleModal, rowData: excludeGid, getDataTable } = useContext(ModulesFormContext);
    const [isLoading, setIsLoading] = useState(false)
    const { formId, formField: { transfer_id } } = checkoutFormModel;
    const initialValue = {
        transfer_id: excludeGid?.transfer_id || "",
        isEditing: excludeGid ? true : false,
        id_guiasDeTransito: excludeGid?.id_guiasDeTransito,
    }
    const onSave = async (value) => {
        setIsLoading(true);
        const req = {
            transfer_id: value.transfer_id,
            company_id: branchID,
        };
        value.isEditing && (Object.assign(req, { id_guiasDeTransito: value.id_guiasDeTransito }));
        try {
            const { data } = value.isEditing
                ? await ExludeGIDService.updateExcludeGID(req)
                : await ExludeGIDService.createExcludeGID(req);
            await getDataTable();
            toggleModal(ModalOpenEnum.EDIT);
            CToast(`Guía de Transito ${value.isEditing ? 'editado' : 'creado'} correctamente`, "success", true);
        } catch ({ response }) {
            console.log(response);
            CToast(`Error al ${value.isEditing ? 'editar' : 'crear'} ${JSON.stringify(response?.data.error)}`, "error", true);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <CustomModal
            isOpen={isOpen[ModalOpenEnum.EDIT]}
            toggleModal={() => toggleModal(ModalOpenEnum.EDIT)}
            moduleLabel={'guide_transit'}
            actionLabel={excludeGid ? "edit" : "create"}
            descriptionLabel={excludeGid ? `${excludeGid.transfer_id}` : ''}
        >
            <Formik
                initialValues={initialValue}
                validationSchema={validateFormGuideTransit}
                onSubmit={(values, actions) => {
                    onSave(values);
                    actions.setSubmitting(false);
                }}
            >
                {({ handleSubmit }) => (
                    <ContainerForm formID={formId}>
                        <Box width={isTablet ? "100%" : "50%"}>
                            <FormTextInput
                                label={transfer_id.label}
                                name={transfer_id.name}
                                fullWidth={true}
                            />
                        </Box>
                        <Box width={isTablet ? "100%" : "50%"}>
                            <ButtonSave
                                onClick={handleSubmit}
                                isLoading={isLoading}
                                edit={excludeGid ? true : false}
                            />
                        </Box>
                    </ContainerForm>
                )}
            </Formik>
        </CustomModal>
    )
}
