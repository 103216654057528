import React, { useState, useEffect } from 'react'
import { styled } from '@material-ui/styles';
import { Grid, Typography, List, ListItem, IconButton, ListItemText, Skeleton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useFormikContext } from 'formik';
import CompanysService from '../../../../services/CompanysService';
import { CToast } from '../../../../common/Components/ToastNotify';


const ListSelectedBranchs = ({ formField }) => {

  const { branchID, branchs_selected } = formField;
  const { setFieldValue, values } = useFormikContext();
  const [isLoading, setIsLoading] = useState(false);

  const removeItemFromArr = (arr, item) => {
    var i = arr.indexOf(item);
    if (i !== -1) {
      arr.splice(i, 1);
    }
    setFieldValue(branchs_selected.name, arr)
  }

  const getBranchsUser = async () => {
    setIsLoading(true);
    try {
      const req = {
        company_user: values.company_user
      }
      const { data } = await CompanysService.geBranchsCompanyUser(req);
      setFieldValue(branchs_selected.name, data);
      setIsLoading(false);
    } catch ({ response }) {
      setIsLoading(false);
      if (response?.data?.error) return CToast(response?.data?.error, 'error', true);
      CToast('Error al obtener las sucursales', 'error', true);
    }
  }

  useEffect(() => {
    (values.isEditing && values.branchs_selected.length === 0) && getBranchsUser();
  }, [])


  return (
    <Grid item xs={12} md={6}>
      <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div" color={"common.title"}>
        Sucursales Agregadas
      </Typography>

      <List dense={false}>
        {
          isLoading && values.isEditing ?
            <Skeleton variant="text" width={'100%'} height='100%' />
            : values?.branchs_selected?.map((branch, index) => {
              return (
                <ListItem
                  key={index}
                  secondaryAction={
                    <IconButton edge="end" onClick={() => {
                      removeItemFromArr(values.branchs_selected, branch)
                    }}>
                      <DeleteIcon />
                    </IconButton>
                  }
                >

                  <ListItemText
                    primary={branch.company_name}
                    secondary={false}
                  />
                </ListItem>
              );

            })}
      </List>

    </Grid>
  )
}

export default ListSelectedBranchs