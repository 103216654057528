import authAxios from "../common/Auth/authAxios";

const ExludeDayService = {
    getExcludeDayByBranch: async (branch) => {
        return await authAxios.post('/inputs-generales/getFechasExcluidas', branch)
    },
    createExcludeDay: async (day) => {
        return await authAxios.post('/inputs-generales/addFechasExcluidas', day)
    },
    updateExcludeDay: async (day) => {
        return await authAxios.patch('/inputs-generales/editFechasExcluidas', day)
    },
    deleteExcludeDay: async (day) => {
        return await authAxios.delete('/inputs-generales/deleteFechasExcluidas',
            { data: day }
        )
    },
}

export default ExludeDayService