import * as Yup from 'yup';
import checkoutFormModel from "./prefsFormModel";
const {
    formField: {
        email,
        first_name,
        last_name,
        rut, gender,
        password,
        password_confirmation
    }
} = checkoutFormModel;

export const validateFormPrefs = Yup.object().shape({
    [email.name]: Yup.string()
        .required(email.requiredErrorMsg)
        .email(email.invalidErrorMsg),
    [first_name.name]: Yup.string()
        .min(first_name.min, first_name.minErrorMsg)
        .max(first_name.max, first_name.maxErrorMsg)
        .required(first_name.requiredErrorMsg),
    [last_name.name]: Yup.string()
        .min(last_name.min, last_name.minErrorMsg)
        .max(last_name.max, last_name.maxErrorMsg)
        .required(last_name.requiredErrorMsg),
    [rut.name]: Yup.string().required(rut.requiredErrorMsg),
    [gender.name]: Yup.string().required(gender.requiredErrorMsg).oneOf(['M', 'F']),
    [password.name]: Yup.string()
        .min(password.min, password.minErrorMsg)
        .max(password.max, password.maxErrorMsg)
        .matches(password.regex, password.regexErrorMsg)
        .required(password.requiredErrorMsg)
        .transform((value, originalValue) => originalValue === null ? 'T3mpor@lly' : value),
    [password_confirmation.name]: Yup.string()
        .required(password_confirmation.requiredErrorMsg)
        .oneOf([Yup.ref(password.name), null], password_confirmation.matchErrorMsg)
        .transform((value, originalValue) => originalValue === null ? 'T3mpor@lly' : value),
});