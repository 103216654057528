import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { ButtonSave } from './ButtonSave'
import { FormTextInput } from './FormTextInput'
import { t } from 'i18next'

export const CreateConfig = ({ name, label, handleSubmit, isLoading, disabled,isEditing }) => {
    return (
        <Box mt={3} >
            <Typography color={"common.txtLabels"} variant={"h6"}>
                {isEditing ? t('labels_modals.edit_config') : t('labels_modals.create_config')}
            </Typography>
            <Stack direction={"row"} justifyContent='flex-start' display="flex">
                <Box width={'80%'} pr={3}>
                    <FormTextInput
                        label={isLoading ? t('labels_actions.loading') : label || 'Nombre de la configuración'}
                        name={name || 'rol_name'}
                        disabled={isLoading}
                        fullWidth={true}
                    />
                </Box>
                <Box width={'20%'}>
                    <ButtonSave
                        onClick={handleSubmit}
                        sx={{
                            mt: 2,
                            ml: 1,
                            width: 200,
                            boxShadow: "0px 3px 6px #00000029",
                        }}
                        isLoading={isLoading}
                        disabled={disabled}
                        edit={isEditing}
                    />
                </Box>
            </Stack>
        </Box>
    )
}
