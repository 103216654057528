export default {
    formId: 'form-prefs',
    formField: {
        email: {
            name: "email",
            label: "Email del usuario",
            requiredErrorMsg: "El email es requerido",
            invalidErrorMsg: "La dirección email es invalido"
        },
        first_name: {
            name: "first_name",
            label: "Nombre del usuario",
            min: 4,
            max: 30,
            minErrorMsg: "El nombre debe tener al menos 4 caracteres",
            maxErrorMsg: "El nombre debe tener menos de 30 caracteres",
            requiredErrorMsg: "El nombre es requerido",
        },
        last_name: {
            name: "last_name",
            label: "Apellido del usuario",
            min: 2,
            max: 30,
            minErrorMsg: "El apellido debe tener al menos 2 caracteres",
            maxErrorMsg: "El apellido debe tener menos de 30 caracteres",
            requiredErrorMsg: "El apellido es requerido"
        },
        gender: {
            name: "gender",
            label: "Género",
            requiredErrorMsg: "Debe seleccionar un género",
        },
        rut: {
            name: "rut",
            label: "RUT del usuario",
            requiredErrorMsg: "El rut es requerido",
        },
        password: {
            name: "password",
            label: "Contraseña de usuario",
            min: 8,
            max: 32,
            minErrorMsg: "La contraseña debe tener al menos 8 caracteres",
            maxErrorMsg: "La contraseña debe tener menos de 32 caracteres",
            regex: /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
            regexErrorMsg: "La contraseña debe tener al menos una mayúscula, una minúscula y un caracter especial",
            requiredErrorMsg: "La contraseña es requerida",
        },
        password_confirmation: {
            name: "password_confirmation",
            label: "Confirmación de contraseña",
            requiredErrorMsg: "La confirmación de contraseña es requerida",
            matchErrorMsg: "Las contraseñas no coinciden"
        },
    }
}