import React, { useContext } from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Branchs from "../../screens/Admin/Companys/Branchs";
import Company from "../../screens/Admin/Companys/Company";
import { Cronjobs } from "../../screens/Admin/CronJobs/Cronjobs";
import ElementsScreen from "../../screens/Admin/Elements/ElementsScreen";
import LogsScreen from "../../screens/Admin/Logs/Logs";
import ModuleScreen from "../../screens/Admin/Modules/ModuleScreen";
import Roles from "../../screens/Admin/Roles/Roles";
import Users from "../../screens/Admin/Users/Users";
import HomeScreen from "../../screens/Home/HomeScreen";
import { Login } from "../../screens/Login/Login";
import { NotFound } from "../../screens/NotFound/NotFound";
import { Prefs } from "../../screens/Prefs/Prefs";
import ForecastScreen from "../../screens/RadarControl/Forecast/ForecastScreen";
import RadarSKUScreen from "../../screens/RadarControl/RadarSku/RadarSkuScreen";
import { VCCScreen } from "../../screens/RadarControl/VentaCostoContribucion/VCCScreen";
import Calendar from "../../screens/SuggestedDistributions/Calendar/Calendar";
import ConstantsWeek from "../../screens/SuggestedDistributions/ContantsOfWeeks/ContantsOfWeeks";
import DailyMoney from "../../screens/SuggestedDistributions/DailyMoney/DailyMoney";
import DistributionCenter from "../../screens/SuggestedDistributions/DistributionCentres/DistrubutionCenter";
import ExcludeGID from "../../screens/SuggestedDistributions/ExcludeGid/ExcludeGid";
import { ExcludeInvoice } from "../../screens/SuggestedDistributions/ExcludeInvoice/ExcludeInvoice";
import ExecutionSuggested from "../../screens/SuggestedDistributions/ExecutionOfTheSuggested/ExecutionOfTheSuggested";
import ExcludeDate from "../../screens/SuggestedDistributions/ExludeDay/ExcludeDay";
import Homologation from "../../screens/SuggestedDistributions/Homologations/Homologations";
import Mix from "../../screens/SuggestedDistributions/Mix/Mix";
import ServiceLevel from "../../screens/SuggestedDistributions/ServiceLevel/ServiceLevel";
import SKU from "../../screens/SuggestedDistributions/SKUForCD/SKUForCD";
import SuggestedList from "../../screens/SuggestedDistributions/SuggestedList/SuggestedList";
import SuggestedParameters from "../../screens/SuggestedDistributions/SuggestedParameters/SuggestedParameters";
import WarehouseScreen from "../../screens/SuggestedDistributions/Warehouse/Warehouse";
import AuthContext from "../Contexts/AuthContext";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import { SideboardRoutes } from "./SidebarRoutes";

export const AppRouter = () => {
  const { auth } = useContext(AuthContext);
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={
            <PublicRoute isAuthenticated={auth.logged}>
              <Login />
            </PublicRoute>
          }
        />

        <Route
          path="/*"
          element={
            <PrivateRoute isAuthenticated={auth.logged}>
              <Routes>
                <Route path="/" element={<SideboardRoutes />}>
                  <Route path="" element={<HomeScreen />} />
                  <Route path="company" element={<Company />} />
                  <Route path="branch" element={<Branchs />} />
                  <Route path="roles" element={<Roles />} />
                  <Route path="logs" element={<LogsScreen />} />
                  <Route path="users" element={<Users />} />
                  <Route path="prefs" element={<Prefs />} />
                  <Route path="cronjobs" element={<Cronjobs />} />
                  <Route path="modules" element={<ModuleScreen />} />
                  <Route path="sections" element={<ElementsScreen />} />
                  <Route path="not-found" element={<NotFound />} />
                  <Route path="warehouse" element={<WarehouseScreen />} />
                  <Route path="graficoForecast" element={<ForecastScreen />} />
                  <Route path="radarSku" element={<RadarSKUScreen />} />
                  <Route path="sale-cost-contribution" element={<VCCScreen />} />
                  <Route
                    path="distribution-centers"
                    element={<DistributionCenter />}
                  />
                  <Route path="sku-for-cd" element={<SKU />} />
                  <Route path="calendar" element={<Calendar />} />
                  <Route path="daily-money" element={<DailyMoney />} />
                  <Route path="homologation" element={<Homologation />} />
                  <Route
                    path="constants-of-weeks"
                    element={<ConstantsWeek />}
                  />
                  <Route path="service-level" element={<ServiceLevel />} />
                  <Route path="mix" element={<Mix />} />
                  <Route
                    path="suggested-parameters"
                    element={<SuggestedParameters />}
                  />
                  <Route path="excluded-dates" element={<ExcludeDate />} />
                  <Route path="exclude-gid" element={<ExcludeGID />} />
                  <Route path="external-forecast" element={<NotFound />} />
                  <Route path="exclude-invoice" element={<ExcludeInvoice />} />
                  <Route
                    path="execution-of-the-suggested"
                    element={<ExecutionSuggested />}
                  />
                  <Route path="suggested-list" element={<SuggestedList />} />
                </Route>
              </Routes>
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
