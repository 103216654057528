import { line } from 'd3-shape';
import React from 'react'
import { formatN } from '../../../helpers/formatNumber';


const lineColor = "rgba(200, 30, 15, 1)";
const circleColor = "rgba(11,59,80,1)";

export const CLine = ({ bars, xScale, yScale }) => {
    // console.log('Line', bars);
    if (!bars) return null;
    const newBars = bars.filter(bar => bar.data.id === 'año_actual');
    const lineGenerator = line()
        .x(bar => xScale(bar.data.data.fecha) + bar.width / 2)
        .y(bar => yScale(formatN(bar.data.data.año_anterior)));

    return (
        <React.Fragment >
            <path
                d={lineGenerator(newBars)}
                fill="none"
                stroke={lineColor}
                style={{ pointerEvents: "none" }}
            />
            {newBars.map(bar => {
                return (
                    <circle
                        key={bar.key}
                        cx={xScale(bar.data.data.fecha) + bar.width / 2}
                        cy={yScale(formatN(bar.data.data.año_anterior))}
                        r={3}
                        fill={'white'}
                        stroke={circleColor}
                        style={{ pointerEvents: "none" }}
                    />
                )
            })}
        </React.Fragment>
    );
}
