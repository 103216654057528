export default {
    formId: "mixForm",
    formField: {
        minimo: {
            name: "minimo",
            label: "Mínimo",
            headerTable: "Mínimo",
            requiredErrorMsg: "El mínimo es requerido.",
        },
        estilo_color: {
            name: "estilo",
            label: "Estilo",
            headerTable: "Estilo-Color",
            requiredErrorMsg: "El estilo es requerido.",
        },
        talla: {
            name: "talla",
            label: "Talla",
            headerTable: "Talla",
            requiredErrorMsg: "La talla es requerida.",
        },
        tienda: {
            name: "tienda",
            label: "Tienda",
            headerTable: "Tienda",
            requiredErrorMsg: "La tienda es requerida.",
        },
        sku: {
            name: "sku",
            label: "SKU",
            headerTable: "SKU",
            requiredErrorMsg: "El SKU es requerido",
        },
        cod_art: {
            name: "cod_art",
            label: "Código",
            headerTable: "Código",
            requiredErrorMsg: "El código es requerido",
        },
        delivery: {
            name: "delivery",
            label: "Delivery",
            headerTable: "Delivery",
            requiredErrorMsg: "El delivery es requerido",
        }
    }
}