import { useContext, useState, useEffect } from 'react'
import { Box } from "@mui/material";
import Title from "../../../common/Components/Title";
import { ButtonsFileCSV } from "../../../common/Components/ButtonsFileCSV";
import BranchsContext from '../../../common/Contexts/BranchContext';
import CompanyContext from '../../../common/Contexts/CompanysContext';
import ModulesFormContext from '../../../common/Contexts/ModulesFormContext';
import { useForm } from '../../../hooks/useForm';
import { ModulesProvider } from '../../../common/Providers/ModulesProvider';
import { LayoutTable } from '../../../common/Components/LayoutTable';
import { MixForm } from './MixForm';
import { DetailMix } from './components/DetailMix';
import { TableMix } from './components/TableMix';
import MixService from '../../../services/MixService';
import { CToast } from '../../../common/Components/ToastNotify';
import { t } from 'i18next';
import { CustomModalDelete } from '../../../common/Components/CustomModalDelete';
import { ModalOpenEnum } from '../../../common/utils/ModalOpenEnum';



const Mix = () => {
    const { companys } = useContext(CompanyContext);
    const { branchID } = useContext(BranchsContext);
    const { overallStyle } = useContext(ModulesFormContext);
    //LOGIC FORM
    const {
        isOpen,
        rowData,
        toggleModal,
        dataTable,
        setDataTable,
        isTableLoading,
        setIsTableLoading
    } = useForm();
    const [isLoadingDelet, setIsLoadingDelet] = useState(false)

    const getDataTable = async () => {
        const req = {
            company_id: branchID,
        };
        try {
            setIsTableLoading(true);
            const { data } = await MixService.getMixByBranch(req);
     
            setDataTable(data);
        } catch ({ response }) {
            console.log(response);
        } finally {
            setIsTableLoading(false);
        }
    }
    const handleDeleteMix = async () => {
        setIsLoadingDelet(true);
        const req = {
            id_cMasivaMix: rowData.id_cMasivaMix,
            company_id: branchID,
        }
        try {
            const { data } = await MixService.deletehMix(req);
            await getDataTable();
            CToast(t('messages.success_delete'), 'success', true);
        } catch ({ response }) {
            console.log(response)
            CToast(t('messages.error_delete'), 'error', true);
        } finally {
            setIsLoadingDelet(false);
        }
    }

    useEffect(() => {
        branchID.length > 0 && getDataTable();
    }, [branchID]);

    return (
        <ModulesProvider value={{
            getDataTable,
            handleDelete: handleDeleteMix,
            isOpen,
            rowData,
            toggleModal,
            dataTable,
            setDataTable, isTableLoading,
        }}>
            <Box sx={overallStyle?.container}>
                <Title name={t('titles.mix')} />
                <ButtonsFileCSV />
                <LayoutTable labelAction={'Agregar Mix'}>
                    {isOpen[ModalOpenEnum.EDIT] && <MixForm />}
                    {isOpen[ModalOpenEnum.DETAIL] && <DetailMix />}
                    {isOpen[ModalOpenEnum.DELETE] && (
                        <CustomModalDelete
                            descriptionLabel={rowData?.cod_art ? rowData.cod_art : ''}
                            isLoading={isLoadingDelet}
                            moduleLabel={'mix'}
                        />)
                    }
                    <TableMix />
                </LayoutTable>
            </Box>
        </ModulesProvider>
    )
}


export default Mix