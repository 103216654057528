import React, { useState } from 'react'
import { t } from 'i18next';
import { Alert, Box, Stack } from '@mui/material';
import { SelectConfigAccess } from '../../../../common/Components/SelectConfigAccess';
import companyFormModel from '../../../../model/company/companyFormModel';
import { styleSheet } from '../../../../common/styles/StylesCommon';
import { useFormikContext } from 'formik';
import { ListCheckRoles } from '../../../../common/Components/ListCheckRoles';
import { TitleStep } from '../../../../common/Components/TitleStep';

export const DataAccessCompany = ({ configAccess,isLoading,getConfigsxCompany }) => {
    const { formField } = companyFormModel;
    const {
        elements_module,
        select_permission
    } = formField;
    const { values } = useFormikContext();
    const [nameConfigEqual, setNameConfigEqual] = useState("");
    const overallStyle = styleSheet();
    
    return (
        <React.Fragment>
            <TitleStep title={t('steps.company.step2')} />
            {values[select_permission.name] === "" && (
                <Box sx={{ marginX: 8 }}>
                    <Alert
                        severity="warning"
                        style={{
                            ...overallStyle.baseAlert,
                        }}
                    >{t('labels_modals.select_one_config')}</Alert>
                </Box>
            )}
            <Stack py={5} justifyContent={"space-evenly"}>
                <Box paddingX={8}>
                    <SelectConfigAccess
                        name_elements_checks={elements_module}
                        fullWidth
                        name={select_permission.name}
                        label={select_permission.label}
                        isLoading={isLoading}
                        configsAccess={configAccess}
                        nameConfigEqual={nameConfigEqual}
                    />
                    <Box>
                        <ListCheckRoles
                            nameConfigEqual={nameConfigEqual}
                            setNameConfigEqual={setNameConfigEqual}
                            name_elements={elements_module}
                            name_permission={select_permission.name}
                            configsAccess={configAccess}
                            getConfigsxCompany={getConfigsxCompany}
                        />
                    </Box>
                </Box>
            </Stack>
        </React.Fragment>
    )
}
