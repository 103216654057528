import { createStyles, styled } from '@material-ui/styles';
import { Box, Step, StepConnector, stepConnectorClasses, StepIcon, StepLabel, Stepper } from '@mui/material';
import { times } from 'lodash';
import React, { useState, useEffect, useRef, cloneElement, Children } from 'react'

export const CStepper = (props) => {

    const [stepCount, setStepCount] = useState(0);
    const [activeStep, setActiveStep] = useState(props.activeStep);
    const [isErrors, setErrors] = useState(props?.errors);

    // const getChildProps = () => {
    //     return { ...props, activeStep, stepCount };
    // }

    const prevActiveStep = useRef();

    useEffect(() => {
        prevActiveStep.current = activeStep; // Updating the ref to latest/current value
    }, []);

    const isMounted = useRef(true);

    useEffect(() => {
        isMounted.current = true;
        return () => (isMounted.current = false);
    }, []);

    useEffect(() => {
        setStepCount(Children.count(props.steps))//cuenta los screen hijos
    }, [props.children])

    useEffect(() => {
        if (prevActiveStep.current !== props.activeStep) {
            handleActiveStep(props.activeStep);
        }
    }, [props.activeStep, activeStep])

    useEffect(() => {
        setErrors(props.errors);
    }, [props?.errors])

    // Callback function from ProgressStep that passes current step.
    const handleActiveStep = (step) => {
        if (props.isSubmitting) return;
        if (step >= stepCount - 1) {
            props.setActiveStep(stepCount - 1);
            setActiveStep(stepCount - 1)
        };
        if (step > -1 && step < stepCount - 1) {
            props.setActiveStep(step);
            setActiveStep(step)
        }
    };


    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: { ...styles.activeLine },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: { ...styles.completedLine },
        },
        [`&.${stepConnectorClasses.line}`]: { ...styles.defaultLine },
    }));

    const ColorStepIconRoot = styled('div')(({ theme, state }) => ({
        // ...styles.iconRoot,
        ...(state.active && { ...styles.stateActive }),
        ...(state.completed && { ...styles.stateCompleted }),
    }));

    const ColorStepIconChange = (props) => {
        const { active, completed, className, error } = props;
        return (
            <ColorStepIconRoot state={{ completed, active }} style={styles.iconRoot} className={className}>
                <>
                    <StepIcon icon={props.icon} error={error} active={active} completed={completed} style={{ color: completed ? 'green' : error ? 'red' : 'primary' }} />
                </>
            </ColorStepIconRoot>
        )
    }

    return (
        <React.Fragment>
            <Box style={styles.container} spacing={1}>
                <Stepper
                    sx={styles.stepper}
                    activeStep={activeStep}
                    alternativeLabel
                    connector={<ColorlibConnector />}
                >
                    {
                        times(stepCount, (i) => {
                            // let completed = props.isComplete ? true : i < activeStep;
                            let active = props.isComplete ? false : i === activeStep;
                            let errors = Object.keys(isErrors).length > 0 && active;
                            return (
                                <Step key={i} >
                                    <StepLabel
                                        // completed={isCompletedStep}
                                        // active={isActiveStep}
                                        error={errors}
                                        StepIconComponent={ColorStepIconChange}>
                                        {props.steps[i]}
                                    </StepLabel>
                                </Step>
                            )
                        })
                    }
                </Stepper>
            </Box>
            {
                cloneElement(props.children[activeStep || props.activeStep], {
                    setActiveStep: handleActiveStep,
                    activeStep: activeStep || props.activeStep,
                    stepCount: stepCount,
                    isLastStep: activeStep === stepCount - 1,
                    isFirstStep: activeStep === 0,
                    errors: isErrors,
                    isSubmitting: props.isSubmitting,
                    isLoading:props.isLoading
                })
            }
        </React.Fragment>
    )
}



const styles = createStyles({
    container: {
        margin: 'auto'
    },
    stepper: {
        marginTop: 5, px: 20
    },
    activeLine: {
        backgroundColor: 'gray',
    },
    completedLine: {
        backgroundColor: 'green',
    },
    defaultLine: {
        height: 1,
        border: 0,
        backgroundColor: 'gray',
    },
    iconCompleted: {
        color: 'green',
    },
    iconRoot: {
        borderRadius: 100, border: 'none'
    },
    iconActive: {
        color: 'primary',
        width: '100%',
    },
    stateActive: {

        border: '2px solid #707070',
        boxShadow: '0px 3px 6px #00000029',
    },
    stateCompleted: {
        backgroundColor: '#E9E9E9',
        boxShadow: '0px 3px 6px #00000029',
    },
    arrowButtons: {
        display: "flex",
        flexDirection: "row",
        pt: 2,
        position: "absolute",
        top: 120,
        width: "100%",
    },
    containerArrow: {
        height: 60,
        background:
            "linear-gradient(90deg, rgba(7,64,107,1) 0%, rgba(7,64,107,1) 35%, rgba(0,184,255,1) 100%)",
        borderRadius: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        color: "white",
    },
    buttonSave: {
        background:
            "linear-gradient(90deg, rgba(7,64,107,1) 0%, rgba(7,64,107,1) 35%, rgba(0,184,255,1) 100%)",
        p: 0,
        height: 70,
        width: 70,
        boxShadow: "0px 3px 6px #00000029",
    }
})