import authAxios from "../common/Auth/authAxios";

const LevelService = {
    getServiceLevelByBranch: async (branch) => {
        return await authAxios.post('/inputs-generales/getNivelDeServicios', branch)
    },
    createServiceLevel: async (serviceLevel) => {
        return await authAxios.post('/inputs-generales/addNivelDeServicios', serviceLevel)
    },
    updateServiceLevel: async (serviceLevel) => {
        return await authAxios.patch('/inputs-generales/editNivelDeServicios', serviceLevel)
    },
    deleteServiceLevel: async (serviceLevel) => {
        return await authAxios.delete('/inputs-generales/deleteNivelDeServicios',
            { data: serviceLevel }
        )
    },
}

export default LevelService