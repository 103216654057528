import { Box } from '@mui/material';
import { Formik } from 'formik';
import React, { useContext, useState } from 'react'
import { ButtonSave } from '../../../common/Components/ButtonSave';
import { ContainerForm } from '../../../common/Components/ContainerForm';
import CustomModal from '../../../common/Components/CustomModal';
import { FormTextInput } from '../../../common/Components/FormTextInput';
import { CToast } from '../../../common/Components/ToastNotify';
import BranchsContext from '../../../common/Contexts/BranchContext';
import MediaContext from '../../../common/Contexts/MediaContext';
import ModulesFormContext from '../../../common/Contexts/ModulesFormContext';
import { ModalOpenEnum } from '../../../common/utils/ModalOpenEnum';
import { validateFormLevelService } from '../../../model/serviceLevel/servicelevel.schema';
import checkoutFormModel from '../../../model/serviceLevel/serviceLevelFormModel';
import LevelService from '../../../services/ServiceLevelService';

export const ServiceLevelForm = () => {
    const { branchID } = useContext(BranchsContext);
    const { isMobile, isTablet } = useContext(MediaContext);
    const { isOpen, toggleModal, rowData: servLevel, getDataTable } = useContext(ModulesFormContext);
    const [isLoading, setIsLoading] = useState(false)
    const { formId, formField: { letra, nivel_servicio, descripcion } } = checkoutFormModel;

    const initialValue = {
        letra: servLevel?.letra || "",
        nivel_servicio: servLevel?.nivel_servicio || "",
        descripcion: servLevel?.descripcion || "",
        isEditing: servLevel ? true : false,
        id_nivelDeServicio: servLevel?.id_nivelDeServicio,
    }
    const onSave = async (value) => {
        setIsLoading(true);
        const req = {
            company_id: branchID,
            letra: value?.letra,
            nivel_servicio: value?.nivel_servicio,
            descripcion: value?.descripcion,
        };

        console.log('req', req)
        value.isEditing && Object.assign(req, { id_nivelDeServicio: value.id_nivelDeServicio });

        try {
            const { data } = value.isEditing
                ? await LevelService.updateServiceLevel(req)
                : await LevelService.createServiceLevel(req)
            await getDataTable();
            toggleModal(ModalOpenEnum.EDIT);
            CToast(`Nivel de servicio ${value.isEditing ? 'editado' : 'creado'} con exito`, "success", true);
        }
        catch ({ response }) {
            console.log('error', response)
            return CToast(`Error al ${value.isEditing ? 'editar' : 'crear'} el nivel de servicio`, "error", true)
        }
        finally {
            setIsLoading(false);
        }
    }
    return (
        <CustomModal
            isOpen={isOpen[ModalOpenEnum.EDIT]}
            toggleModal={() => toggleModal(ModalOpenEnum.EDIT)}
            moduleLabel={'level_service'}
            actionLabel={servLevel ? "edit" : "create"}
            descriptionLabel={servLevel ? `${servLevel.letra}` : ''}
        >
            <Formik
                initialValues={initialValue}
                validationSchema={validateFormLevelService}
                onSubmit={(values, actions) => {
                    onSave(values);
                    actions.setSubmitting(false);
                }}
            >
                {({ handleSubmit }) => (
                    <ContainerForm formID={formId}>
                        <Box width={isTablet ? "100%" : '50%'}>
                            <FormTextInput
                                label={letra.label}
                                name={letra.name}
                                style={{ width: "95%" }}
                                fullWidth={true}
                                disabled={servLevel ? true : false}
                            />
                            <FormTextInput
                                label={nivel_servicio.label}
                                name={nivel_servicio.name}
                                type={"number"}
                                style={{ width: "95%" }}
                                fullWidth={true}
                            />
                        </Box>

                        <Box width={isTablet ? "100%" : '50%'}>
                            <FormTextInput
                                label={descripcion.label}
                                name={descripcion.name}
                                fullWidth={true}
                            />

                            <ButtonSave
                                onClick={handleSubmit}
                                isLoading={isLoading}
                                edit={servLevel ? true : false}
                            />
                        </Box>
                    </ContainerForm>
                )}
            </Formik>
        </CustomModal>
    )
}
