import { Form, Formik } from "formik";
import React, { useState, useContext, useEffect } from 'react'
import { Box, Grid, Stack } from "@mui/material";
import { CAutocomplete } from '../../../../common/Components/CAutocomplete';
import ModulesGraphContext from '../../../../common/Contexts/GraphContext';
import RadarControlService from '../../../../services/RadarControlService';
import { SelectType } from "./SelectType";
import { SelectDescriptionCategory } from "./SelectCategory";
import { TypesForecast } from "../../utils/types";
import { SelectBrand } from "./SelectBrand";

const initialValues = {
    type: "unds",
    category: "",
    product: '',
};

export const FilterSku = () => {

    const {
        ENUM_FILTERS, filters,
        queryFilter, handleFilters
    } = useContext(ModulesGraphContext);

    const [dataFilter, setDataFilter] = useState({
        product: [],
        type: TypesForecast,
        category: [],
        brand: [],
        family: [],
        subClass: [],
    });

    const getDescriptionCategory = async () => {
        const filter = "descripcion_categoria";
        try {
            const { data } = await RadarControlService.getFilterSuggester(filter);
            setDataFilter((prev) => ({ ...prev, category: data }));
        } catch (error) {
            console.log("getCodeProduct", error);
        }
    };

    const getProducts = async () => {
        // const query = `unds?codigo_producto=${filters[ENUM_FILTERS.cod]}`;
        try {
            const { data } = await RadarControlService.getProducts();
            // console.log("getProducts", data);
            const parseData = data.map((item, index) => ({
                key: `${item.codigo_producto}-${index}`,
                codigo_producto: item.codigo_producto,
                label: item.descripcion_producto,
                ...item
            }));
            const uniqueData = parseData.filter(
                (thing, index, self) =>
                    index === self.findIndex((t) => t.label === thing.label)
            );
            setDataFilter((prev) => ({ ...prev, product: uniqueData }));
            console.log("getProducts", uniqueData);
        } catch (error) {
            console.log("getProducts", error);
        }
    }

    useEffect(() => {
        console.log("queryFilter", queryFilter);
        // console.log("filters", filters);
        console.log("ENUM_FILTERS", ENUM_FILTERS);
        const getAllFilters = async () => {
            try {
                await getDescriptionCategory();
                await getProducts();
            } catch (error) {
                console.log("getAllFilters", error)
            }
        }
        getAllFilters();
    }, []);

    return (
        <React.Fragment>
            <Formik
                initialValues={initialValues}
            >
                {() => (
                    <Form style={{
                        ...styles.root
                    }}>
                        <Stack display={'flex'} width='100%'
                            flexDirection={'column'}
                            justifyContent='space-between'
                        >
                            <Stack flexDirection={'row'}
                                justifyItems={'space-between'}
                                alignItems={'center'}
                            >
                                <Grid item width={'100%'}>
                                    <SelectBrand
                                        dataCodeProduct={dataFilter.brand}
                                        label={'Marca'}
                                        valueDefault={filters[ENUM_FILTERS.brand]}
                                        handleChange={(value) => handleFilters(ENUM_FILTERS.brand, value)}
                                    />
                                </Grid>
                                <Grid item width={'100%'}>
                                    <SelectBrand
                                        dataCodeProduct={dataFilter.family}
                                        label={'Familia'}
                                        valueDefault={filters[ENUM_FILTERS.family]}
                                        handleChange={(value) => handleFilters(ENUM_FILTERS.family, value)}
                                    />
                                </Grid>
                                <Grid item width={'100%'}>
                                    <SelectBrand
                                        dataCodeProduct={dataFilter.subClass}
                                        label={'SubClase'}
                                        valueDefault={filters[ENUM_FILTERS.subClass]}
                                        handleChange={(value) => handleFilters(ENUM_FILTERS.subClass, value)}
                                    />
                                </Grid>
                            </Stack>
                            <Stack flexDirection={'row'}
                                justifyItems={'space-between'}
                                alignItems={'center'}>
                                <Grid item width={'100%'}>
                                    <SelectDescriptionCategory
                                        dataCodeProduct={dataFilter.category}
                                        label={'Categoria'}
                                        handleChange={(value) => handleFilters(ENUM_FILTERS.cat, value)}
                                    />
                                </Grid>
                                <Grid item width={'100%'}>
                                    <CAutocomplete
                                        label={'Productos'}
                                        data={dataFilter.product}
                                        valueDefault={filters[ENUM_FILTERS.cod]}
                                        isOptionEqual={ENUM_FILTERS.cod}
                                        filters={filters}
                                        handleChange={(value) => handleFilters(ENUM_FILTERS.cod, value)}
                                    />
                                </Grid>
                                <Grid item width={'100%'}>
                                    {/* <SelectType
                                        dataCodeProduct={dataFilter.type}
                                        label={'Tipo'}
                                        valueDefault={filters[ENUM_FILTERS.type]}
                                        handleChange={(value) => handleFilters(ENUM_FILTERS.type, value)}
                                    /> */}
                                </Grid>
                            </Stack>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </React.Fragment>
    )
}

const styles = {
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        // flexWrap: 'wrap',
    }
}