export default {
    formId: 'formConstants',
    formField: {
        week_number: {
            name: 'week_number',
            label: 'Número de semana',
            headerTable: 'Número de semana',
            requiredErrorMsg: 'El número de semana es requerido',
            max: 24,
            maxErrorMsg: 'El número de semana debe ser menor o igual 24',
        },
        constant: {
            name: 'constant',
            label: 'Constante',
            headerTable: 'Constante',
            requiredErrorMsg: 'La constante es requerida',
            default: 0.25,
        },
    },
}