import React, { useContext } from "react";
import { CustomTable } from "../../../../common/Components/CustomTable";

export const TableRoles = () => {
  const headers = [
    { field: "rol_name", headerName: "Nombre Rol", width: 200 },
  ];

  const actions = ["Editar", "Eliminar"]

  return (
    <CustomTable
      actions={actions}
      headers={headers}
    />
  );
};
