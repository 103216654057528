import { Key } from '@mui/icons-material';
import React, { useState, useRef, useEffect } from 'react';

export const useFilters = (ENUM_FILTERS) => {

    const isMounted = useRef(false)
    const initialValues = Object.values(ENUM_FILTERS).reduce((acc, key) => {
        acc[key] = "";
        return acc;
    }, {});

    const [filters, setFilters] = useState(initialValues);
    const [queryFilter, setQueryFilter] = useState("");

    const handleQueryFilter = async () => {
        // queryFilter && setQueryFilter("");
        const query = Object.keys(filters).filter(key => filters[key] != "").map((key) => {
            return `${key}=${filters[key]}`;
        }).join('&').toString();
        console.log(query);
        setQueryFilter(query);
    };

    const handleFilters = async (key, value) => {
        if (value instanceof Object) (value = value[key]);
        console.log(key, value);
        setFilters({
            ...filters,
            [key]: value ? value : "",
        });
    };

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        }
    }, []);

    useEffect(() => {
        handleQueryFilter();
    }, [filters]);

    return {
        queryFilter,
        filters,
        handleFilters
    }
}