import React from 'react'
import { CustomTable } from '../../../../common/Components/CustomTable';

export const TableBranchs = () => {
    const headers = [
        { field: "company_name", headerName: "Nombre Sucursal", width: 250 },
        { field: "contact_name", headerName: "Nombre Contacto", width: 150 },
        { field: "dataBase", headerName: "DB", width: 130 },
    ];

    const actions = ["Editar", "Eliminar"]

    return (
        <CustomTable
            actions={actions}
            headers={headers}
        />
    );
}
