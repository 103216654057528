import React, { useState, useContext } from "react";
import { Avatar, Stack, Typography } from "@mui/material";
import { stringAvatar } from "../../helpers/formatString";
import { Prefs } from "../../screens/Prefs/Prefs";
import { styleSheet } from "../styles/StylesCommon";
import AuthContext from "../Contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { ConfigIcon, LogOutIcon } from "../utils/Icons";
import { useForm } from "../../hooks/useForm";
import { ModalOpenEnum } from "../utils/ModalOpenEnum";
var store = require("store");
export const Footer = () => {
  // const [isOpen, setIsOpen] = useState(false);
  // const handleModal = () => setIsOpen(!isOpen);
  //LOGIC FORM
  const {
    isOpen,
    rowData,
    toggleModal,
  } = useForm();

  const payload = store.get("user");
  const userName = payload?.first_name;
  const userLastname = payload?.last_name;
  const rolUser = payload?.roles[0]?.rol_name;
  const styles = styleSheet();
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const onLogout = () => {
    navigate("/login", { remplace: true });
    logout();
  };

  return (
    <Stack direction={"row"} sx={{ ...styles.footer }}>
      <Avatar
        sx={{ bgcolor: "#246394", height: 60, width: 60 }}
        {...stringAvatar(userName + " " + userLastname)}
      />
      <Stack marginLeft={2}>
        <Typography
          variant="h6"
          sx={{ color: "common.white", fontSize: "15px" }}
        >
          {userName} {userLastname}
        </Typography>
        <Typography
          variant="h7"
          sx={{ color: "common.white", fontSize: "13px" }}
        >
          {rolUser}
        </Typography>
        <Stack direction={"row"}>
          <ConfigIcon onClick={() => toggleModal(ModalOpenEnum.EDIT, payload)} />
          {
            isOpen[ModalOpenEnum.EDIT] && (
              <Prefs isOpen={isOpen} toggleModal={toggleModal} rowData={payload} />
            )
          }
          <LogOutIcon onClick={onLogout} style={{ marginLeft: 7 }} />
        </Stack>
      </Stack>
    </Stack>
  );
};
