import { useFormikContext } from 'formik';
import React, { useState, useEffect, useContext } from 'react'
import { AddRolesUser } from '../components/AddRolesUser';
import { t } from 'i18next'
import userFormModel from '../../../../model/user/userFormModel';
import { styleSheet } from '../../../../common/styles/StylesCommon';
import { Alert, Box, Stack } from '@mui/material';
import { SelectRolesForm } from '../components/SelectedRolesForm';
import RolService from '../../../../services/RolesService';
import ModulesFormContext from '../../../../common/Contexts/ModulesFormContext';
import { TitleStep } from '../../../../common/Components/TitleStep';

export const RolesUser = () => {
    const { formField } = userFormModel;
    const { rol_name, rol_clusters, select_rol_name, modulo_cluster } = formField;
    const { values, setFieldValue } = useFormikContext()
    const { rowData: user } = useContext(ModulesFormContext);
    const [nameConfigEqual, setNameConfigEqual] = useState("");
    const [rolesAccess, setRolesAccess] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const overallStyle = styleSheet();


    const getRoles = async () => {
        setIsLoading(true);
        try {
            const { data } = await RolService.getRoles();
            // console.log(data)
            setRolesAccess(data);
            setIsLoading(false);
            return data;
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    };



    useEffect(() => {
        getRoles();
    }, []);



    return (
        <React.Fragment>
            <TitleStep title={t('steps.user.step2')} />
            {values[select_rol_name.name] === "" && (
                <Box sx={{ marginX: 8 }}>
                    <Alert
                        severity="warning"
                        style={{
                            ...overallStyle.baseAlert,
                        }}
                    >{t('labels_modals.select_one_config')}</Alert>
                </Box>
            )}
            <Stack py={5} justifyContent={"space-evenly"}>
                <Box paddingX={8}>
                    <SelectRolesForm
                        name_elements_checks={modulo_cluster}
                        fullWidth
                        name={select_rol_name.name}
                        label={select_rol_name.label}
                        isLoading={isLoading}
                        rolesAccess={rolesAccess}
                        getRolesAccess={getRoles}
                        nameConfigEqual={nameConfigEqual}
                    />
                    <Box>
                        <AddRolesUser
                            nameConfigEqual={nameConfigEqual}
                            setNameConfigEqual={setNameConfigEqual}
                            name_elements={modulo_cluster}
                            cod_element_logic={modulo_cluster.cod_element_logic}
                            name_permission={select_rol_name.name}
                            rolesAccess={rolesAccess}
                            getRolesAccess={getRoles}
                        />
                    </Box>
                </Box>
            </Stack>
        </React.Fragment>
    )
}
