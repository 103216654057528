
import authAxios from "../common/Auth/authAxios";

const RolService = {
    getRoles: async () => {
        return await authAxios.get('/element-logic/getRoles')
    },
    createRol: async (rol) => {
        return await authAxios.post('/element-logic/addRol', rol)
    },
    updateRol: async (rol) => {
        return await authAxios.patch('/element-logic/editRol', rol)
    },
    deleteRol: async (rol) => {
        return await authAxios.delete('/element-logic/deleteRol', {
            data: rol
        })
    }
}

export default RolService
