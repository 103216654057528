import * as Yup from 'yup';
import checkoutFormModel from "./roles.model";
const {
    formField: {
        rol_name,
        select_rol_name,
        modulo_cluster
    }
} = checkoutFormModel;

export const validateFormRol = Yup.object({
    [rol_name.name]: Yup.string()
        .min(rol_name.min, rol_name.minErrorMsg)
        .max(rol_name.max, rol_name.maxErrorMsg)
        .required(rol_name.requiredErrorMsg),
    [select_rol_name.name]: Yup.string()
        .when(['rol_name', 'modulo_cluster'], {
            is: (rol_name, modulo_cluster) => modulo_cluster?.filter(m => m.active).length === 0,
            then: Yup.string().required(select_rol_name.requiredErrorMsg),
        }),
    [modulo_cluster.name]: Yup.array().min(0, modulo_cluster.requiredErrorMsg)
})