export const dataLinechar = [
    {
      "id": "japan",
      "color": "hsl(228, 70%, 50%)",
      "data": [
        {
          "x": "plane",
          "y": 29
        },
        {
          "x": "helicopter",
          "y": 87
        },
        {
          "x": "boat",
          "y": 106
        },
        {
          "x": "train",
          "y": 239
        },
        {
          "x": "subway",
          "y": 275
        },
        {
          "x": "bus",
          "y": 117
        },
        {
          "x": "car",
          "y": 54
        },
        {
          "x": "moto",
          "y": 80
        },
        {
          "x": "bicycle",
          "y": 205
        },
        {
          "x": "horse",
          "y": 120
        },
        {
          "x": "skateboard",
          "y": 114
        },
        {
          "x": "others",
          "y": 170
        }
      ]
    },
    {
      "id": "france",
      "color": "hsl(59, 70%, 50%)",
      "data": [
        {
          "x": "plane",
          "y": 288
        },
        {
          "x": "helicopter",
          "y": 30
        },
        {
          "x": "boat",
          "y": 68
        },
        {
          "x": "train",
          "y": 99
        },
        {
          "x": "subway",
          "y": 124
        },
        {
          "x": "bus",
          "y": 220
        },
        {
          "x": "car",
          "y": 120
        },
        {
          "x": "moto",
          "y": 33
        },
        {
          "x": "bicycle",
          "y": 51
        },
        {
          "x": "horse",
          "y": 100
        },
        {
          "x": "skateboard",
          "y": 115
        },
        {
          "x": "others",
          "y": 106
        }
      ]
    },
    {
      "id": "us",
      "color": "hsl(292, 70%, 50%)",
      "data": [
        {
          "x": "plane",
          "y": 25
        },
        {
          "x": "helicopter",
          "y": 40
        },
        {
          "x": "boat",
          "y": 273
        },
        {
          "x": "train",
          "y": 57
        },
        {
          "x": "subway",
          "y": 214
        },
        {
          "x": "bus",
          "y": 128
        },
        {
          "x": "car",
          "y": 176
        },
        {
          "x": "moto",
          "y": 173
        },
        {
          "x": "bicycle",
          "y": 17
        },
        {
          "x": "horse",
          "y": 147
        },
        {
          "x": "skateboard",
          "y": 72
        },
        {
          "x": "others",
          "y": 186
        }
      ]
    },
    {
      "id": "germany",
      "color": "hsl(209, 70%, 50%)",
      "data": [
        {
          "x": "plane",
          "y": 96
        },
        {
          "x": "helicopter",
          "y": 153
        },
        {
          "x": "boat",
          "y": 41
        },
        {
          "x": "train",
          "y": 112
        },
        {
          "x": "subway",
          "y": 46
        },
        {
          "x": "bus",
          "y": 228
        },
        {
          "x": "car",
          "y": 94
        },
        {
          "x": "moto",
          "y": 211
        },
        {
          "x": "bicycle",
          "y": 166
        },
        {
          "x": "horse",
          "y": 4
        },
        {
          "x": "skateboard",
          "y": 266
        },
        {
          "x": "others",
          "y": 75
        }
      ]
    },
    {
      "id": "norway",
      "color": "hsl(103, 70%, 50%)",
      "data": [
        {
          "x": "plane",
          "y": 94
        },
        {
          "x": "helicopter",
          "y": 299
        },
        {
          "x": "boat",
          "y": 110
        },
        {
          "x": "train",
          "y": 168
        },
        {
          "x": "subway",
          "y": 66
        },
        {
          "x": "bus",
          "y": 72
        },
        {
          "x": "car",
          "y": 178
        },
        {
          "x": "moto",
          "y": 26
        },
        {
          "x": "bicycle",
          "y": 88
        },
        {
          "x": "horse",
          "y": 51
        },
        {
          "x": "skateboard",
          "y": 290
        },
        {
          "x": "others",
          "y": 213
        }
      ]
    }
  ]