import { Box, Button } from '@mui/material'
import React, { useState } from 'react'
import ModalChars from './ModalChars'
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { styleSheet } from '../../../common/styles/StylesCommon';
import { useForm } from '../../../hooks/useForm';
import { ModalOpenEnum } from '../../../common/utils/ModalOpenEnum';

export const CharBox = ({ char }) => {

  const styles = styleSheet();
  const { isOpen, toggleModal } = useForm();
  // const [isOpen, setIsOpen] = useState(false);
  // const handleModal = () => setIsOpen(!isOpen);

  return (
    <Box
      sx={{ ...styles.boxChar }}
    >
      <Button onClick={() => toggleModal(ModalOpenEnum.GRAPHICS)}>
        <FullscreenIcon sx={{ fontSize: 30 }} />
        {
          isOpen[ModalOpenEnum.GRAPHICS] && (
            <ModalChars
              isOpen={isOpen[ModalOpenEnum.GRAPHICS]}
              toggleModal={toggleModal}
              children={char}
            />
          )
        }
      </Button>
      {char}
    </Box>)
}
