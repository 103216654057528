import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";

import { t } from "i18next";
import BranchsContext from "../../../common/Contexts/BranchContext";
import ModulesFormContext from "../../../common/Contexts/ModulesFormContext";
import { ModulesProvider } from "../../../common/Providers/ModulesProvider";
import { useForm } from "../../../hooks/useForm";
import ElementLogicService from "../../../services/ElementsLogicService";
import { LayoutTable } from "../../../common/Components/LayoutTable";
import { TableModules } from "./components/ModuleTable";
import Title from "../../../common/Components/Title";
import { ModuleForm } from "./components/ModuleForm";
import { CustomModalDelete } from "../../../common/Components/CustomModalDelete";
import { styleSheet } from "../../../common/styles/StylesCommon";
import { CToast } from "../../../common/Components/ToastNotify";

const ModuleScreen = () => {
  const { branchID,companyID} = useContext(BranchsContext);

  const overallStyle = useMemo(() => styleSheet(), []);

  const {
    isOpen,
    rowData,
    toggleModal,
    dataTable,
    setDataTable,
    isTableLoading,
    setIsTableLoading,
  } = useForm();

  const [isLoadingDelet, setIsLoadingDelet] = useState(false);

  const getDataTable = async () => {
    setIsTableLoading(true);

    try {
      const { data } = await ElementLogicService.getModules();
      setDataTable(data);
    } catch ( response ) {
      console.log(response);
    } finally {
      setIsTableLoading(false);
    }
  };

  const handleDeleteModule = async () => {
    setIsLoadingDelet(true);

    try {
        const { data } = await ElementLogicService.deleteModules(rowData.modulo_id);
        CToast(t('messages.success_delete'), "success");
        getDataTable();
    } catch (error) {
      console.log(error);
        CToast("Error al eliminar el modulo", "error");
    } finally {
        setIsLoadingDelet(false);
    }

}

  useEffect(() => {
    companyID.length > 0 && getDataTable();
}, [companyID]);


  return (
    <ModulesProvider
      value={{
        getDataTable,
        handleDelete: handleDeleteModule,
        isOpen,
        rowData,
        toggleModal,
        dataTable,
        setDataTable,
      }}
    >
      <Box sx={{
    
        ...overallStyle.container
        }}>
        <Title name={t("titles.modules")} />
        <LayoutTable hasAdd={true} labelAction={"Agregar Modulo"}>
          {isOpen?.EDIT && <ModuleForm />}

           {isOpen.DELETE && (<CustomModalDelete
            descriptionLabel={ rowData?.modulo_name ? rowData.modulo_name : ""}
            isLoading={isLoadingDelet}
            moduleLabel={'modules'}
            />)}

          <TableModules />
        </LayoutTable>
      </Box>
    </ModulesProvider>
  );
};

export default ModuleScreen;
