import React, { useContext, useEffect, useMemo, useState } from "react";
import Title from "../../../common/Components/Title";
import { Box, Stack } from "@mui/material";
import { t } from "i18next";
import BranchsContext from "../../../common/Contexts/BranchContext";
import ModulesFormContext from "../../../common/Contexts/ModulesFormContext";
import { ModulesProvider } from "../../../common/Providers/ModulesProvider";
import { styleSheet } from "../../../common/styles/StylesCommon";
import { ForecastGraphic } from "./components/ForecastGraph";
import { dataBar } from "./components/dataExample";
import { TableMonthlyProjection } from "./components/TableMonthlyProjection";
import { CBoxSaleMonth } from "./components/CBoxSaleMonth";
import { TableSaleWeek } from "./components/TableSaleWeek";
import RadarControlService from "../../../services/RadarControlService";
import { BoxTitle } from "../components/BoxTitle";
import { formatMoney } from "../../../helpers/formatNumber";
import { getMonth } from "../../../helpers/months";
import { groupByYears, orderByMonths } from "../../../helpers/formatArrays";

const ForecastScreen = () => {
  const { branchID } = useContext(BranchsContext);

  const overallStyle = useMemo(() => styleSheet(), []);

  const [dataGraph, setDataGraph] = useState([]);
  const [dataSaleMonth, setDataSaleMonth] = useState([]);
  const [dataProjectionMonth, setDataProjectionMonth] = useState([]);
  const [dataProjectionYear, setDataProjectionYear] = useState({
    products: [],
    groupByYear: [],
    dinamycHeaders: [],
  });

  const getDataGraph = async () => {
    try {
      const { data } = await RadarControlService.getForecastGraph("unds"); //?tipo=stock_unds&codigo_producto=S-180
      setDataGraph(data);
      console.log("getForecastGraph", data);
    } catch (error) {
      console.log("getForecastGraph", error);
    }
  };

  const getDataTableSaleMonth = async () => {
    try {
      const { data } = await RadarControlService.getSaleMonth("unds");
      setDataSaleMonth(data.unds_val);
      console.log("getSaleMonth", data.unds_val);
    } catch (error) {
      console.log("getSaleMonth", error);
    }
  };

  const getDataProjectionMonth = async () => {
    try {
      const { data } = await RadarControlService.getProjectionMonth("unds");
      setDataProjectionMonth(data);
      console.log("getProjectionMonth", data);
    } catch (error) {
      console.log("getProjectionMonth", error);
    }
  };

  const getDatarojectionInv = async (year = 2019) => {
    try {
      const { data } = await RadarControlService.getProyectionInv(
        `unds`//?year=${year}
      );

      const uniqueProduct = data.reduce((acc, current) => {
        const x = acc.find((item) => item.codigo_producto === current.codigo_producto);
        return x ? acc : acc.concat([current]);
      }, []);

      const orderByMonth = orderByMonths(uniqueProduct);
      const groupByYear = groupByYears(orderByMonth);

      const yearsKeys = Object.keys(groupByYear);

      const dinamycHeaders = yearsKeys.map(key => ({
        field: key,
        headerName: key,
        width: 100,
        marryChildren: true,
        children: groupByYear[key]?.map((items) => ({
          field: getMonth(items.month).month_name,
          headerName: getMonth(items.month).month_name,
          children: items.type_unds.map((subItems) => ({
            field: subItems.tipo,
            headerName: subItems.tipo,
            valueFormatter: ({ data }) => data.codigo_producto === items.codigo_producto ? formatMoney(subItems.unds_val) : 0,
          })),
        })),
      }));

      console.log("UNIQUE getProyectionInv", uniqueProduct);
      console.log("groupByYear getProyectionInv", groupByYear);
      setDataProjectionYear({
        products: uniqueProduct,
        groupByYear: groupByYear,
        dinamycHeaders: dinamycHeaders,
      });
      // console.log("getProyectionInv", data);
    } catch (error) {
      console.log("ERROR: getProyectionInv", error);
    }
  };

  useEffect(() => {
    getDataGraph();
    getDataTableSaleMonth();
    getDataProjectionMonth();
    getDatarojectionInv();
  }, []);

  return (
    <ModulesProvider
      value={{
        dataGraph,
        dataSaleMonth,
        dataProjectionMonth,
      }}
    >
      <Box sx={{ ...overallStyle?.container, }}>
        <Box>
          <Title name={t("titles.graficoForecast")} />
        </Box>
        <Box
          marginX={3}
          style={{
            overflow: "auto",
          }}
          display="flex"
          flexDirection="column"
          justifyContent={"space-between"}
          flex={1}
        >
          <CBoxSaleMonth saleMont={dataSaleMonth} />

          <BoxTitle title={"Forecast 2022 en Venta MM de pesos"} />
          <ForecastGraphic data={dataGraph} />

          <BoxTitle title={"Proyeccion mensual"} />
          <TableMonthlyProjection data={dataProjectionMonth} />

          <BoxTitle title={"Proyeccion semanal"} />
          <TableSaleWeek data={dataProjectionYear} />
        </Box>
      </Box>
    </ModulesProvider>
  );
};

export default ForecastScreen;
