import React from 'react'
import { useFormikContext } from 'formik'
import { FormTextInput } from '../../../../common/Components/FormTextInput'
import checkoutForm from '../../../../model/cronJobs/cronjobsFormModel'

export const FormSend = () => {
    const { formField } = checkoutForm;
    const { messageEmail, to, subjet, name:parametrosSend } = formField.parametrosSend;
    const { errors } = useFormikContext();
    return (
        <React.Fragment>
            <FormTextInput
                label={to.label}
                name={`${parametrosSend}.${to.name}`}
                fullWidth={true}
            />
            <FormTextInput
                label={subjet.label}
                name={`${parametrosSend}.${subjet.name}`}
                fullWidth={true}
            />
            <FormTextInput
                label={messageEmail.label}
                name={`${parametrosSend}.${messageEmail.name}`}
                fullWidth={true}
            />
        </React.Fragment>
    )
}
