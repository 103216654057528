export default {
    formId: 'warehouseCheckoutForm',
    formField: {
        cod_bodega: {
            name: 'cod_bodega',
            label: 'Código de bodega',
            headerTable: 'Código',
            requiredErrorMsg: 'El código de la bodega es requerido'
        },
        bodega: {
            name: 'bodega',
            label: 'Bodega',
            headerTable: 'Bodega',
            requiredErrorMsg: 'El nombre de la bodega es requerido'
        },
        canal: {
            name: 'canal',
            label: 'Canal',
            headerTable: 'Canal',
            requiredErrorMsg: 'El canal de la bodega es requerido'
        },
        region: {
            name: 'region',
            label: 'Región',
            headerTable: 'Región',
            requiredErrorMsg: 'La región de la bodega es requerido'
        },
        comuna: {
            name: 'comuna',
            label: 'Comuna',
            headerTable: 'Comuna',
            requiredErrorMsg: 'La comuna de la bodega es requerido'
        },
        ciudad: {
            name: 'ciudad',
            label: 'Ciudad',
            headerTable: 'Ciudad',
            requiredErrorMsg: 'La ciudad de la bodega es requerido'
        },
        direccion: {
            name: 'direccion',
            label: 'Dirección',
            headerTable: 'Dirección',
        },
        latitude: {
            name: 'latitude',
            label: 'Latitud',
            headerTable: 'Latitud',
        },
        longitud: {
            name: 'longitud',
            label: 'Longitud',
            headerTable: 'Longitud',
        },
    }
}