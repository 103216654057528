import React, { useContext, useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { CustomSelect } from "../../../../common/Components/CustomSelect";


export const SelectOptions = ({ name, label, disabled = false }) => {

  const options = [
    {
      option_id: 0,
      option_value: "Fracción",
    },
    {
      option_id: 1,
      option_value: "Valor único",
    },
  ];

  return (
    <>
      <FormControl fullWidth sx={{ mt: 1 }} disabled={disabled}>
        <CustomSelect
          sx={{
            borderRadius: 20,
            height: 50,
            marginBottom: 1.4,
            width: '95%',
            borderColor:
              "linear-gradient(90deg, rgba(7,64,107,1) 0%, rgba(7,64,107,1) 35%, rgba(0,184,255,1) 100%)",
          }}
          id={name || "confirmed"}
          label={label || "Opciones"}
          labelId={label || "Opciones"}
          name={name || "confirmed"}
        >
          {options.map((option) => (
            <MenuItem key={option.option_id} value={option?.option_id}>{option?.option_value}</MenuItem>
          ))}
        </CustomSelect>
      </FormControl>
    </>
  );
};
