import React, { useState, useEffect, useContext } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { SidebarService } from "../../services/SidebarService";
import { useFormikContext } from "formik";
import { CustomSelect } from "./CustomSelect";
import { t } from 'i18next'

export const SelectConfigMethods = ({ label = "S/A", configsMethods, isLoading, code, name, name_models_check, ...props }) => {

  const { values, setFieldValue, handleChange } = useFormikContext();

  return (
    <>
      <Typography mb={1} variant={"h6"} color={'common.button'}>
        {t('labels_actions.select_config')}
      </Typography>
      <FormControl fullWidth={props.fullWidth}>
        <CustomSelect
          sx={{
            borderRadius: 20,
            height: "50px",
            borderColor:
              "linear-gradient(90deg, rgba(7,64,107,1) 0%, rgba(7,64,107,1) 35%, rgba(0,184,255,1) 100%)",
          }}
          labelId={label}
          id={name}
          label={label}
          name={name}
          isLoading={isLoading}
          disabled={isLoading}
          onChange={handleChange}
        >
          {configsMethods?.map((model, index) => (
            <MenuItem
              key={model.model_logic_id}
              onClick={({ target }) =>
                setFieldValue(name_models_check.name, model[name_models_check.model])
              }
              value={model?.model_logic_id}
            >
              {model?.model_logic_name}
            </MenuItem>
          ))}
        </CustomSelect>
      </FormControl>
    </>
  );
};
