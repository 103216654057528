import React, { useState, useContext, useEffect } from "react";
import { Box, Button, Skeleton, Stack, Typography } from "@mui/material";
import Title from "../../common/Components/Title";
import { styleSheet } from "../../common/styles/StylesCommon";

import SideBarContext from "../../common/Contexts/SidebarContext";
import { t } from "i18next";
import MediaContext from "../../common/Contexts/MediaContext";
import { MyResponsiveBar } from "./components/NivoChar";
import { dataExample } from "./components/dataBarchar";
import { MyResponsiveHeatMap } from "./components/HeatMapChar";
import { dataExampleHeat } from "./components/dataHeatMap";
import { MyResponsiveScatterPlot } from "./components/ScatterChar";
import { dataScatter } from "./components/dataScatter";
import { MyResponsiveLine } from "./components/LineChar";
import { dataLinechar } from "./components/dataLineChar";
import RadarControlService from "../../services/RadarControlService";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CompanyContext from "../../common/Contexts/CompanysContext";
import ModalChars from "./components/ModalChars";
import { CharBox } from "./components/CharBox";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import '../../common/styles/styleTable.css';

const HomeScreen = () => {
  const { getSidebar, isLoadingSidebar } = useContext(SideBarContext);
  const { datas } = useContext(CompanyContext);
  const styles = styleSheet();
  const { isTablet } = useContext(MediaContext);
  const [guestToken, setGuestToken] = useState(null);

  const getToken = async () => {
    // const response = await fetch("http://localhost:3000/superset/guest-token")
    const response = await fetch(process.env.REACT_APP_API_LINK+"/superset/guest-token");
    const token = await response.json()
    console.log("🚀 ~ file: HomeScreen.jsx:34 ~ getToken ~ token:", token.token)
    return token.token
  }

  useEffect(() => {
    const embed = async () => {
      try {
        console.log("🚀 ~ file: HomeScreen.jsx:47 ~ embed ~ process.env.SUPERSET_APP_API_LINK:", process.env.REACT_APP_SUPERSET_API_LINK)
        const token = await getToken(); // * <-- Esperar a que se resuelva la promesa de getToken
        await embedDashboard({
          id: "06cd1e62-d50c-4aa6-b0f8-29f27ffb0c34", // * <--  identificador del panel de Superset
          // id: "e47e124d-df69-46da-9851-69243339e966",
          supersetDomain: process.env.REACT_APP_SUPERSET_API_LINK, // * <-- dominio de Superset
          mountPoint: document.getElementById("dashboard"), // * <-- elemento HTML en el cual se renderizará el panel de Superset
          fetchGuestToken: () => token, // * <-- obtener un token de invitado necesario para autenticar la solicitud al servidor
          dashboardUiConfig: { // * <-- objeto de configuración que permite personalizar la apariencia y el comportamiento del panel de Superset
            hideTitle: true, // * <-- ocultar el título
            hideChartControls: true, // * <-- ocultar los controles del gráfico
            hideTab: true, // * <-- ocultar las pestañas del panel
          },
        })
      } catch (error) {
        console.error("Error fetching and embedding Superset dashboard:", error);
      }
    }
    
    if (document.getElementById("dashboard")) {
      embed()
    }
  }, [])

  return isLoadingSidebar ? (
    <Box sx={{ width: "100%", height: "100vh", marginTop: -7 }}>
      <Skeleton height={300} />
      <Skeleton animation="wave" height={50} />
      <Skeleton animation="wave" height={50} />
      <Skeleton animation="wave" height={50} />
      <Skeleton animation="wave" height={50} />
      <Skeleton height={200} />
    </Box>
  ) : (
    <Box sx={{ ...styles.container }}>
      <Title name={t("titles.radar_control")} />
      {!isTablet && (
        <Box sx={{ width: "100%", height: "80vh", paddingTop: 10 }}>
          <Stack
            direction="row"
            spacing={1}
            marginBottom={1}
            sx={{ width: "100%" }}
          >
            <div className="superset" id="dashboard" style={styles.superset}/>
            {/* <CharBox
              char={<MyResponsiveScatterPlot data={dataScatter} />}
            />
            <CharBox
              char={<MyResponsiveHeatMap data={dataExampleHeat} />}
            /> */}
          </Stack>
          <Stack direction="row" spacing={1} sx={{ width: "100%", height: "100vh", marginBottom: 10 }} >
           

            {/* {
              datas && (
                <CharBox
                  char={<MyResponsiveBar
                    data={datas?.data ? datas.data : []}
                    keys={datas?.keys ? datas.keys : []}
                  />}
                />
              )
            } */}
            {/* <CharBox
              char={<MyResponsiveLine data={dataLinechar} />}
            /> */}
          </Stack>
        </Box>
      )}
    </Box>
  );
};

// const currentStyle = createStyles({
//   superset: {
//     width: "100%",
//     height: "700px",
//     border: "0",
//     overflow: "hidden"
//   },
  // containerGraph: {
  //   display: 'flex',
  //   flex: 1,
  //   flexDirection: 'column',
  //   alignItems: 'center',
  //   width: '100%',
  //   height: '100%'
  // },
  // containerGraphRow: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   height: '20rem',
  //   width: '100%'
  // }
// })

export default HomeScreen;
