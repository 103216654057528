import { Box, Stack } from '@mui/material';
import { Formik } from 'formik';
import { t } from 'i18next';
import Scheduler from 'material-ui-cron';
import React, { useContext, useState, useEffect } from 'react'
import { ButtonSave } from '../../../common/Components/ButtonSave';
import { ContainerForm } from '../../../common/Components/ContainerForm';
import CustomModal from '../../../common/Components/CustomModal';
import { FormTextInput } from '../../../common/Components/FormTextInput';
import { SelectCommand } from '../../../common/Components/SelectCommand';
import { CToast } from '../../../common/Components/ToastNotify';
import ModulesFormContext from '../../../common/Contexts/ModulesFormContext';
import { esLocale } from '../../../helpers/configLocale';
import { validateFormCronJobs } from '../../../model/cronJobs/cronjobs.schema';
import checkoutForm from '../../../model/cronJobs/cronjobsFormModel';
import CronjobsService from '../../../services/CronjobsService';
import { FormConsole } from './components/FormConsole';
import { FormSend } from './components/FormSend';
import BranchsContext from '../../../common/Contexts/BranchContext';
import { ModalOpenEnum } from '../../../common/utils/ModalOpenEnum';
import CompanyContext from '../../../common/Contexts/CompanysContext';

export const CronJobsForm = () => {
    const { isOpen, toggleModal, rowData: cron, getDataTable } = useContext(ModulesFormContext);
    const { branchID } = useContext(BranchsContext);
    const { company } = useContext(CompanyContext);

    const [isLoading, setIsLoading] = useState(false);
    const { formId, formField } = checkoutForm;
    const { job_name, description, command } = formField;
    const [commands, setCommands] = useState([]);
    console.log(company?.company_id)
    const initialValue = {
        job_name: cron?.job_name || "",
        description: cron?.description || "",
        command: cron?.command || "",
        parametrosConsole: {
            message: cron?.parameters?.message || "",
            hora: cron?.parameters?.hora || "",
            sucursal: cron?.parameters?.sucursal || "",
        },
        parametrosSend: {
            messageEmail: cron?.parameters?.messageEmail || "",
            to: cron?.parameters?.to || "",
            subjet: cron?.parameters?.subjet || "",
        },
        start_time: cron?.start_time || "0 0 * * *",
        cron_id: cron?.cron_id,
        company_id: cron?.company_id ? cron.company_id : (company?.company_id || branchID),
        isEditing: cron ? true : false,
    };

    const onSave = async (value) => {

        // setIsLoading(true);
        const req = {
            company_id: value.company_id,
            command: value.command,
            description: value.description,
            job_name: value.job_name,
            parameters:
                value.command === "send_email"
                    ? value.parametrosSend
                    : value.command === "console"
                        ? value.parametrosConsole
                        : '',
            start_time: value.start_time,
        };
        value.isEditing && (req.cron_id = value.cron_id)
        try {
            const { data } = value.isEditing
                ? await CronjobsService.updateCron(req)
                : await CronjobsService.createCron(req);
            await getDataTable();
            toggleModal(ModalOpenEnum.EDIT);
            CToast(`CronJob ${value.isEditing ? 'editado' : 'creado'} con exito`, "success", true);
        }
        catch ({ response }) {
            if (response?.data?.message) return (CToast(response?.data?.message, "error", true))
            if (response?.data.statusCode === 409) return (CToast("Ya existe un cron con ese nombre", "error", true))
            if (response?.data.statusCode === 400) return (CToast("Hubo un error 400 (ESTANDARIZAR)", "error", true))
            return CToast("Error al crear el cron", "error", true)
        } finally {
            setIsLoading(false);
        }
    }

    const getCommands = async () => {
        try {
            const { data } = await CronjobsService.getCronCommands();
            setCommands(data);
        } catch ({ response }) {
            console.log(response);
            CToast(t('messages.error_get_data'), "error", true);
        }
    };


    useEffect(() => {
        getCommands();
    }, []);

    return (
        <CustomModal
            isOpen={isOpen[ModalOpenEnum.EDIT]}
            toggleModal={() => toggleModal(ModalOpenEnum.EDIT)}
            moduleLabel={'cron_jobs'}
            actionLabel={cron ? "edit" : "create"}
            descriptionLabel={cron ? `${cron.job_name}` : ''}
        >
            <Formik
                initialValues={initialValue}
                validationSchema={validateFormCronJobs}
                onSubmit={(values, actions) => {
                    onSave(values);
                    actions.setSubmitting(false);
                }}
            >
                {({ handleSubmit, values, errors, setFieldValue, setFieldError }) => (
                    <ContainerForm formID={formId}>
                        <Box width={'100%'} py={5} px={8}>

                            <Stack direction={"row"}>
                                <Box width={"50%"} mx={5}>
                                    <FormTextInput
                                        label={job_name.label}
                                        name={job_name.name}
                                        fullWidth={true}
                                    />
                                    <FormTextInput
                                        label={description.label}
                                        name={description.name}
                                        style={{ marginBottom: "25px" }}
                                        fullWidth={true}
                                    />
                                    <SelectCommand name={command.name} label={command.label} commands={commands} />
                                </Box>
                                <Box width={"50%"}>
                                    {values.command && (values.command === "send_email" ? <FormSend /> : <FormConsole />)}
                                </Box>
                            </Stack>
                            <Box
                                width={"100%"}
                                alignItems={"center"}
                                justifyContent='flex-start'
                                display={"flex"}
                                mx={5}
                            >
                                <Scheduler
                                    cron={values.start_time}
                                    setCron={(value) => setFieldValue('start_time', value)}
                                    setCronError={(err) => setFieldError('start_time', err)}
                                    isAdmin={true}
                                    customLocale={esLocale}
                                />
                            </Box>
                            <Box
                                width={"100%"}
                                alignItems={"center"}
                                justifyContent='flex-end'
                                display={"flex"}
                                mx={5}
                            >
                                <Box width={"50%"}>
                                    <ButtonSave
                                        onClick={handleSubmit}
                                        isLoading={isLoading}
                                        edit={cron ? true : false}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </ContainerForm>
                )
                }
            </Formik>

        </CustomModal>
    )
}
