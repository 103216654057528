import authAxios from "../common/Auth/authAxios";

const UsersService = {
  getAllUser: async () => {
    return await authAxios.post("/auth/getAllUsers");
  },
  getUserById: async (user) => {
    return await authAxios.post("/auth/userid", user);
  },
  getUsersByCompany: async (company) => {
    return await authAxios.post("/auth/getUsersCompany", company);
  },
  getCompaniesByUser: async () => {
    return await authAxios.get("/auth/userCompanys");
  },
  createUser: async (user) => {
    return await authAxios.post("/auth/signup", user);
  },
  updateUser: async (user) => {
    return await authAxios.patch("/auth/editUser", user);
  },
  deleteUser: async (user) => {
    return await authAxios.delete("/auth/deleteUser",
      { data: user }
    );
  },
  changePref: async (pref) => {
    return await authAxios.patch("/auth/changePrefs", pref);
  },
};

export default UsersService;
