import authAxios from "../common/Auth/authAxios";

const MixService = {
    getMixByBranch: async (branch) => {
        return await authAxios.post('/inputs-generales/getCargaMasivaMix', branch)
    },
    createMix: async (mix) => {
        return await authAxios.post('/inputs-generales/addCargaMasivaMix', mix)
    },
    updateMix: async (mix) => {
        return await authAxios.patch('/inputs-generales/editCargaMasivaMix', mix)
    },
    deletehMix: async (mix) => {
        return await authAxios.delete('/inputs-generales/deleteCargaMasivaMix',
            { data: mix }
        )
    },
}

export default MixService