import { Box, IconButton, Stack, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "../../../../common/styles/styleTable.css";
import React, { useContext, useEffect, useMemo, useState } from "react";
import MediaContext from "../../../../common/Contexts/MediaContext";
import ThemeContext from "../../../../common/Contexts/ThemeContext";
import { styleSheet } from "../../../../common/styles/StylesCommon";
import { LayoutTableGraphs } from "../../components/LayoutTableGraphs";
import { SeeIcon, WSeeIcon } from "../../../../common/utils/Icons";
import { SaleWeekDetails } from "./SaleWeekDetails";



export const TableSaleWeek = ({ data }) => {
  const agGridRef = React.useRef(null);
  const { isMobile, isTablet } = useContext(MediaContext);
  const overallStyle = styleSheet(isMobile, isTablet);
  const IconDetail = () => (state.theme === 1 ? <SeeIcon /> : <WSeeIcon />);
  const [isOpen, setIsOpenModal] = useState(false);
  const [dataCell, setDataCell] = useState(false);
  const onCellClicked = (params) => setDataCell(params.data);
  const [columnDef, setColumnDef] = useState();
  const { state } = useContext(ThemeContext);
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
      width: 120,
      minWidth: 100,
      maxWidth: 300,
      lockPinned: true,

    }),
    []
  );

  const Headers = [
    {
      field: "codigo_producto", headerName: "Código (Producto)", pinned: "left",
      suppressMovable: true,
      lockPosition: 'left',
    },
    {
      field: "descripcion_categoria", headerName: "Descripción", pinned: "left",
      suppressMovable: true,
      lockPosition: 'left',
    },
    {
      headerName: "Detalle",
      suppressMovable: true,
      lockPosition: 'left',
      pinned: "left",
      // width: 100,
      cellRenderer: (params) => {
        return (
          <Stack
            direction={"row"}
            justifyContent="flex-start"
            alignItems={"center"}
          >
            <IconButton

              onClick={() => setIsOpenModal(true)}
            >
              {IconDetail()}
            </IconButton>
          </Stack>
        );
      },
    },
    ...data.dinamycHeaders,
  ];

  // set the column headers from the data
  useEffect(() => {
    setColumnDef(Headers);
  }, [data.dinamycHeaders]);

  return (
    <React.Fragment>
      <SaleWeekDetails
        isOpen={isOpen}
        setIsOpenModal={setIsOpenModal}
        dataCell={dataCell}
      />
      <LayoutTableGraphs>
        <Box
          className="ag-theme-alpine"
          sx={
            state.theme === 1
              ? { ...overallStyle.tableLight, height: '100%' }
              : { ...overallStyle.tableDark, height: '100%' }
          }
        >
          <AgGridReact
            ref={agGridRef}
            rowData={data?.products}
            onCellClicked={onCellClicked}
            columnDefs={columnDef}
            defaultColDef={defaultColDef}
            animateRows={true}
            suppressRowClickSelection={true}
            groupHeaderHeight={25}
            paginationPageSize={10}
            paginationAutoPageSize={true}
            pagination={true}
          />
        </Box>
      </LayoutTableGraphs>
    </React.Fragment>
  );
};
