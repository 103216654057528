import { Box, Stack } from '@mui/material'
import { Formik } from 'formik'
import React, { useState, useContext } from 'react'
import { ButtonSave } from '../../../common/Components/ButtonSave'
import { ContainerForm } from '../../../common/Components/ContainerForm'
import CustomModal from '../../../common/Components/CustomModal'
import { FormTextInput } from '../../../common/Components/FormTextInput'
import { CToast } from '../../../common/Components/ToastNotify'
import BranchsContext from '../../../common/Contexts/BranchContext'
import MediaContext from '../../../common/Contexts/MediaContext'
import ModulesFormContext from '../../../common/Contexts/ModulesFormContext'
import { ModalOpenEnum } from '../../../common/utils/ModalOpenEnum'
import { validateFormWarehouse } from '../../../model/warehouse/warehouse.schema'
import checkoutFormModel from '../../../model/warehouse/warehouseFormModel'
import WarehouseService from '../../../services/WarehouseService'
export const WarehouseForm = () => {
    const { branchID } = useContext(BranchsContext);
    const { isOpen, toggleModal, rowData: warehouse, getDataTable } = useContext(ModulesFormContext);
    const [isLoading, setIsLoading] = useState(false)
    const { formId, formField: {
        bodega,
        canal,
        cod_bodega,
        direccion,
        latitude,
        longitud,
        region,
        ciudad, comuna
    } } = checkoutFormModel;
    const { isMobile, isTablet } = useContext(MediaContext);
    const initialValue = {
        cod_bodega: warehouse?.cod_bodega.toString() || "",
        bodega: warehouse?.bodega.toString() || "",
        canal: warehouse?.canal.toString() || "",
        region: warehouse?.region.toString() || "",
        latitude: warehouse?.latitude || "",
        longitud: warehouse?.longitud || "",
        direccion: warehouse?.direccion.toString() || "",
        ciudad: warehouse?.ciudad.toString() || "",
        comuna: warehouse?.comuna.toString() || "",
        isEditing: warehouse ? true : false,
        id_bodega: warehouse?.id_bodega,
    };

    const onSave = async (value) => {
        setIsLoading(true);
        const req = {
            company_id: branchID,
            cod_bodega: value?.cod_bodega,
            bodega: value?.bodega,
            canal: value?.canal,
            region: value?.region,
            latitude: value?.latitude,
            longitud: value?.longitud,
            direccion: value?.direccion,
            ciudad: value?.ciudad,
            comuna: value?.comuna,
        };

        value.isEditing && (Object.assign(req, {
            id_bodega: value.id_bodega,
            company_id: warehouse.company_id
        }));
        
        try {
            const { data } = value.isEditing
                ? await WarehouseService.updateWarehouse(req)
                : await WarehouseService.createWarehouse(req)
            await getDataTable();
            toggleModal(ModalOpenEnum.EDIT);
            CToast(`Bodega ${value.isEditing ? 'editada' : 'creada'} con exito`, "success", true);
        }
        catch ({ response }) {
            console.log('error', response)
            if (response?.data?.message) return (CToast(response?.data?.message, "error", true))
            if (response?.data.statusCode === 409) return (CToast("Ya existe una bodega con ese nombre", "error", true))
            if (response?.data.statusCode === 400) return (CToast("Hubo un error 400 (ESTANDARIZAR)", "error", true))
            return CToast(`Error al ${value.isEditing ? 'editar' : 'crear'} la bodega`, "error", true)
        } finally {
            setIsLoading(false);
        }

    }


    return (
        <CustomModal
            isOpen={isOpen[ModalOpenEnum.EDIT]}
            toggleModal={() => toggleModal(ModalOpenEnum.EDIT)}
            moduleLabel={'warehouse'}
            actionLabel={warehouse ? "edit" : "create"}
            descriptionLabel={warehouse ? `${warehouse.bodega}` : ''}
        >
            <Formik
                initialValues={initialValue}
                validationSchema={validateFormWarehouse}
                onSubmit={(values, actions) => {
                    onSave(values);
                    actions.setSubmitting(false);
                }}
            >
                {({ handleSubmit }) => (
                    <ContainerForm formID={formId}>
                        <Box width={isTablet ? "100%" : "50%"}>
                            <FormTextInput
                                label={cod_bodega.label}
                                name={cod_bodega.name}
                                fullWidth={true}
                            />
                            <FormTextInput
                                label={bodega.label}
                                name={bodega.name}
                                fullWidth={true}
                            />
                            <FormTextInput
                                label={canal.label}
                                name={canal.name}
                                fullWidth={true}
                            />
                            <FormTextInput
                                label={ciudad.label}
                                name={ciudad.name}
                                fullWidth={true}
                            />
                            <FormTextInput
                                label={direccion.label}
                                name={direccion.name}
                                fullWidth={true}
                            />
                        </Box>
                        <Box width={isTablet ? "100%" : "50%"}>
                            <FormTextInput
                                label={region.label}
                                name={region.name}
                                fullWidth={true}
                            />
                            <FormTextInput
                                label={latitude.label}
                                name={latitude.name}
                                fullWidth={true}
                            />
                            <FormTextInput
                                label={longitud.label}
                                name={longitud.name}
                                fullWidth={true}
                            />
                            <FormTextInput
                                label={comuna.label}
                                name={comuna.name}
                                fullWidth={true}
                            />
                            <ButtonSave
                                onClick={handleSubmit}
                                isLoading={isLoading}
                                edit={warehouse ? true : false}
                            />
                        </Box>
                    </ContainerForm>
                )}
            </Formik>
        </CustomModal>
    )
}
