import authAxios from "../common/Auth/authAxios";

const HomologationsService = {
    getHomologationByBranch: async (branch) => {
        return await authAxios.post('/inputs-generales/getHomologacion', branch)
    },
    createHomologation: async (homologation) => {
        return await authAxios.post('/inputs-generales/addHomologacion', homologation)
    },
    updateHomologation: async (homologation) => {
        return await authAxios.patch('/inputs-generales/editHomologacion', homologation)
    },
    deleteHomologation: async (homologation) => {
        return await authAxios.delete('/inputs-generales/deleteHomologacion',
            { data: homologation }
        )
    },
}

export default HomologationsService
