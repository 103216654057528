import { useState, useCallback, useRef, useEffect } from "react";
import { ModalOpenEnum } from "../common/utils/ModalOpenEnum";

export const useForm = () => {

    const [dataTable, setDataTable] = useState([]);
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [rowData, setRowData] = useState(null);
    const [isOpen, setIsOpenModal] = useState(ModalOpenEnum);
    const toggleModal = (key, data) => {
        setIsOpenModal({ ...isOpen, [key]: !isOpen[key] })
        handleCellAction(data);
    }

    const handleCellAction = useCallback((e) => {
        setRowData(e?.data ? e.data : null);
    }, [setRowData]);

    const isMounted = useRef(true)

    useEffect(() => {
        return () => isMounted.current = false;
    }, []);

    return {
        isOpen,
        rowData, setRowData,
        dataTable, setDataTable,
        isTableLoading, setIsTableLoading,
        toggleModal, handleCellAction
    };
}