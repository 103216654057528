import { Box } from "@mui/material";
import Title from "../../../common/Components/Title";
import { ButtonsFileCSV } from "../../../common/Components/ButtonsFileCSV";
import { useContext, useState, useEffect } from 'react'
import BranchsContext from '../../../common/Contexts/BranchContext';
import { useForm } from "../../../hooks/useForm";
import ModulesFormContext from "../../../common/Contexts/ModulesFormContext";
import { ModulesProvider } from "../../../common/Providers/ModulesProvider";
import { SuggestedTable } from "./components/SuggestedTable";
import { SuggestedListForm } from "./SuggestedListForm";
import { DetailSuggestedList } from "./components/DetailSuggestedList";
import { LayoutTable } from "../../../common/Components/LayoutTable";
import { t } from 'i18next';
import { suggestedDistributionService } from "../../../services/suggestedDistributionService";
import { ModalOpenEnum } from "../../../common/utils/ModalOpenEnum";

const SuggestedList = () => {

    const { branchID } = useContext(BranchsContext);
    const { overallStyle } = useContext(ModulesFormContext);
    //LOGIC FORM
    const {
        isOpen,
        rowData,
        toggleModal,
        dataTable,
        setDataTable,
        isTableLoading,
        setIsTableLoading
    } = useForm();
    // const [isLoadingDelet, setIsLoadingDelet] = useState(false)

    const getDataTable = async () => {
        setIsTableLoading(true);
        const req = {
            "company_id": branchID
        };
        try {
            const { data } = await suggestedDistributionService.getSuggestedByHistorialId(req);
            setDataTable(data);
        } catch ({ response }) {
            console.log(response);
            setDataTable([])
        } finally {
            setIsTableLoading(false);
        }
    };

    useEffect(() => {
        branchID.length > 0 && getDataTable();
    }, [branchID]);

    return (
        <ModulesProvider value={{
            getDataTable,
            isOpen,
            rowData,
            toggleModal,
            dataTable,
            setDataTable, isTableLoading,
        }}>
            <Box sx={overallStyle?.container}>
                <Title name={t('titles.listSuggestions')} />
                <ButtonsFileCSV />
                <LayoutTable hasAdd={false} labelAction={'Ejecutar sugerido'}>
                    {
                        isOpen[ModalOpenEnum.EDIT] &&
                        (<SuggestedListForm />)
                    }
                    {
                        isOpen[ModalOpenEnum.DETAIL] && (
                            <DetailSuggestedList />
                        )
                    }
                    <SuggestedTable />
                </LayoutTable>
            </Box>
        </ModulesProvider>
    )
}


export default SuggestedList