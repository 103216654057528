import { Box } from '@mui/material';
import React from 'react'
import { useContext } from 'react'
import { CDetails } from '../../../../common/Components/CDetails';
import CustomModal from '../../../../common/Components/CustomModal';
import ModulesFormContext from '../../../../common/Contexts/ModulesFormContext'
import { ModalOpenEnum } from '../../../../common/utils/ModalOpenEnum';
import checkoutForm from "../../../../model/sku/sku.model";

export const DetailSKU = () => {
    const { rowData, isOpen, toggleModal } = useContext(ModulesFormContext);
    const { formField: { sku, center_distribution, isActive } } = checkoutForm;

    return (
        <CustomModal
            isOpen={isOpen[ModalOpenEnum.DETAIL]}
            toggleModal={() => toggleModal(ModalOpenEnum.DETAIL)}
            moduleLabel={'SKU_for_CD'}
            actionLabel='info'
            descriptionLabel={`de ${sku.headerTable} ${rowData?.sku}`}
            hiddenModuleLabel={true}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 2,
                }}
            >
                <CDetails label={sku.headerTable} value={rowData?.sku} />
                <CDetails label={center_distribution.headerTable} value={rowData?.nombreCentro} />
                <CDetails label={isActive.headerTable} value={`${rowData.active ? 'Activo' : 'Inactivo'}`} />
            </Box>
        </CustomModal>
    )
}
