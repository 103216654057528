import { Box } from "@mui/material";
import React, { useState, useContext, useEffect, useMemo } from "react";
import Title from "../../../common/Components/Title";
import BranchsContext from "../../../common/Contexts/BranchContext";
import ModulesFormContext from "../../../common/Contexts/ModulesFormContext";
import { ModulesProvider } from "../../../common/Providers/ModulesProvider";
import { t } from "i18next";
import { useForm } from "../../../hooks/useForm";
import LogsService from "../../../services/LogsService";
import { TableLogs } from "./components/TableLogs";
import { LayoutTable } from "../../../common/Components/LayoutTable";
import { styleSheet } from "../../../common/styles/StylesCommon";

const LogsScreen = () => {
  const { branchID } = useContext(BranchsContext);
  const overallStyle = useMemo(() => styleSheet(), []);
  //LOGIC FORM
  const {
    isOpen,
    rowData,
    toggleModal,
    dataTable,
    setDataTable,
    isTableLoading,
    setIsTableLoading
  } = useForm();
  const [isLoadingDelet, setIsLoadingDelet] = useState(false)

  const getDataTable = async () => {
    setIsTableLoading(true);
    try {
      const { data } = await LogsService.getAllLogs();
      setDataTable(data); //data.data
    } catch ({ response }) {
      console.log(response);
    } finally {
      setIsTableLoading(false);
    }
  }
  const handleDeleteLogs = async () => { }

  useEffect(() => {
    getDataTable();
  }, []);

  return (
    <ModulesProvider value={{
      getDataTable,
      isOpen,
      rowData,
      toggleModal,
      dataTable,
      setDataTable, isTableLoading,
    }}>
      <Box sx={overallStyle?.container}>
        <Title name={t('titles.logs')} />
        {/* <ButtonsFileCSV /> */}
        <LayoutTable labelAction={'Agregar Logs'}>
          <TableLogs />
        </LayoutTable>
      </Box>
    </ModulesProvider>
  );
};

export default LogsScreen;
