import React from 'react'
import { LoadingButton } from '@mui/lab'
import { t } from 'i18next';
import { styleSheet } from '../styles/StylesCommon';

export const ButtonSave = ({ isLoading, disabled, onClick, label, edit = false, props }) => {
    const styles = styleSheet();

    return (
        <LoadingButton
            {...props}
            onClick={onClick}
            fullWidth
            variant="contained"
            disabled={(isLoading || disabled) ? true : false}
            sx={{
                ...styles.buttonForm,
                marginTop: 2,
            }}
            loading={isLoading}
        >
            {label ? label : t(`labels_actions.${edit ? 'edit' : 'create'}`)}
        </LoadingButton>
    )
}
