export default {
    formId: "cronjobsCheckoutForm",
    formField: {
        job_name: {
            name: "job_name",
            label: "Nombre del job",
            min: 1,
            max: 20,
            minErrorMsg: "El nombre del job debe tener al menos 1 caracteres",
            maxErrorMsg: "El nombre del job debe tener menos de 20 caracteres",
            requiredErrorMsg: "El nombre del job es requerido",
        },
        description: {
            name: "description",
            label: "Descripción del job",
            requiredErrorMsg: "La descripción del job es requerida",
        },
        command: {
            name: "command",
            label: "Comando del job",
            requiredErrorMsg: "El comando del job es requerido",
            requiredParams:'Los parametros son requeridos'
        },
        parametrosConsole: {
            name: "parametrosConsole",
            label: "Parametros de consola",
            requiredErrorMsg: "Los parametros de consola son requeridos",
            message: {
                name: "message",
                label: "Mensaje",
                requiredErrorMsg: "El mensaje es requerido",
            },
            hora: {
                name: "hora",
                label: "Hora",
                requiredErrorMsg: "La hora es requerida",
            },
            sucursal: {
                name: "sucursal",
                label: "Sucursal",
                requiredErrorMsg: "La sucursal es requerida",
            },
        },
        parametrosSend: {
            name: "parametrosSend",
            label: "Parametros de envio",
            requiredErrorMsg: "Los parametros de envio son requeridos",
            messageEmail: {
                name: "messageEmail",
                label: "Mensaje de email",
                requiredErrorMsg: "El mensaje es requerido",
            },
            to: {
                name: "to",
                label: "Para",
                requiredErrorMsg: "El email es requerido",
                invalidErrorMsg: "La dirección email es invalido"
            },
            subjet: {
                name: "subjet",
                label: "Asunto",
                requiredErrorMsg: "El asunto es requerido",
            },
        },
        start_time: {
            name: "start_time",
            label: "Hora de inicio",
            requiredErrorMsg: "La hora de inicio es requerida",
        },
        cronExp: {
            name: "cronExp",
            label: "Expresión cron",
            requiredErrorMsg: "La expresión cron es requerida",
        }
    }
}