import React from "react";
import { CustomTable } from "../../../../common/Components/CustomTable";
import checkoutForm from "../../../../model/mix/mix.model";

export const TableMix = () => {

  const { formField: {
    minimo,
    estilo_color,
    talla,
    tienda,
    sku,
    cod_art,
    delivery
  } } = checkoutForm;

  const headers = [
    { field: "tienda", headerName: tienda.headerTable },
    { field: "cod_art", headerName: cod_art.headerTable },
    { field: "sku", headerName: sku.headerTable },
    { field: "estilo_color", headerName: estilo_color.headerTable },
    { field: "talla", headerName: talla.headerTable },
    { field: "minimo", headerName: minimo.headerTable },
    { field: "delivery", headerName: delivery.headerTable },
  ];

  return (<CustomTable headers={headers} />)
};
