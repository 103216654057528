import * as Yup from 'yup';
import checkoutFormModel from "./genericFormModel";
const { email, password, password_confirmation } = checkoutFormModel.formField;

export const validateFormGeneric = Yup.object({
    [email.name]: Yup.string()
        .required(email.requiredErrorMsg)
        .email(email.invalidErrorMsg),
    [password.name]: Yup.string()
        .min(password.min, password.minErrorMsg)
        .max(password.max, password.maxErrorMsg)
        .matches(password.regex, password.regexErrorMsg)
        .required(password.requiredErrorMsg)
        .transform((value, originalValue) => originalValue === null ? 'T3mpor@lly' : value),
    [password_confirmation.name]: Yup.string()
        .required(password_confirmation.requiredErrorMsg)
        .oneOf([Yup.ref(password.name), null], password_confirmation.matchErrorMsg)
        .transform((value, originalValue) => originalValue === null ? 'T3mpor@lly' : value),
});