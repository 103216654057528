export const TypesForecast = [
    {
        id: 1,
        name: 'Unds',
        cod: 'unds',
    },
    {
        id: 2,
        name: 'Venta',
        cod: 'venta',
    }
];