import React from "react";
import { CustomTable } from "../../../../common/Components/CustomTable";

export const TableUsers = () => {
  const headers = [
    { field: "first_name", headerName: "Nombre ", width: 130 },
    { field: "last_name", headerName: "Apellido", width: 150 },
    { field: "email", headerName: "Email", width: '250' },
    // { field: "roles", headerName: "rol", width: 130 },
    // { field: "company_user", headerName: "Empresas", width: 700 },
  ];

  const actions = ["Editar", "Eliminar"]

  return (
    <CustomTable
      actions={actions}
      headers={headers}
    />
  );
};
