import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";

import { t } from "i18next";
import BranchsContext from "../../../common/Contexts/BranchContext";
import ModulesFormContext from "../../../common/Contexts/ModulesFormContext";
import { ModulesProvider } from "../../../common/Providers/ModulesProvider";
import { useForm } from "../../../hooks/useForm";
import ElementLogicService from "../../../services/ElementsLogicService";
import { LayoutTable } from "../../../common/Components/LayoutTable";

import Title from "../../../common/Components/Title";

import { CustomModalDelete } from "../../../common/Components/CustomModalDelete";
import { TableElements } from "./components/ElementsTable";
import { ElementForm } from "./components/ElementsForm";
import { styleSheet } from "../../../common/styles/StylesCommon";

const ElementsScreen = () => {
  const { branchID,companyID} = useContext(BranchsContext);

  const overallStyle = useMemo(() => styleSheet(), []);

  const [isLoadingT, setIsLoadingT] = useState(false);
  const [actions, setActions] = useState([]);

  const {
    isOpen,
    rowData,
    toggleModal,
    dataTable,
    setDataTable,
    isTableLoading,
    setIsTableLoading,
  } = useForm();

  const [isLoadingDelet, setIsLoadingDelet] = useState(false);

  const getDataTable = async () => {
    setIsTableLoading(true);

    try {
      const { data } = await ElementLogicService.getElements();
     
      setDataTable(data);
    } catch ({ response }) {
      console.log(response);
    } finally {
      setIsTableLoading(false);
    }
  };

  useEffect(() => {
    companyID.length > 0 && getDataTable();
    getDataActions();
}, [companyID]);


const getDataActions = async () => {
  setIsLoadingT(true);

  try {
    const { data } = await ElementLogicService.getActions();
    setActions(data);
  } catch ({ response }) {
    console.log(response);
  } finally {
    setIsLoadingT(false);
  }
};




  return (
    <ModulesProvider
      value={{
        getDataTable,
        isOpen,
        rowData,
        toggleModal,
        dataTable,
        setDataTable,
        actions,
        getDataActions
      }}
    >
      <Box sx={overallStyle?.container}>
        <Title name={t("titles.sections")} />
        <LayoutTable hasAdd={true} labelAction={"Agregar Seccion"}>
          {
            isOpen.EDIT && (
              <ElementForm/>
            )
          }

          {isOpen.DELETE && (
            <CustomModalDelete
              descriptionLabel={
                rowData?.element_name ? rowData.element_name : ""
              }
              isLoading={isLoadingDelet}
              moduleLabel={"sections"}
            />
          )}

          <TableElements />
        </LayoutTable>
      </Box>
    </ModulesProvider>
  );
};

export default ElementsScreen;
