import { useMemo, useEffect, useState, useRef } from 'react';
import { io } from 'socket.io-client';

export const useSocket = (serverPath, auth) => {
    const token = auth?.accessToken || auth?.user?.accessToken;
    const isMounted = useRef(true)
    const socketIO = useMemo(() => io(serverPath, {
        extraHeaders: {
            authorization: token
        },
        auth: { "accessToken": token },
        withCredentials: true,
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        // reconnectionAttempts: 5,
    }), [serverPath]);

    const [isOnline, setIsOnline] = useState(false);

    // useEffect(() => {
    //     return () => {
    //         isMounted.current = false;
    //         socketIO.off('connection');
    //         socketIO.off('disconnection');
    //     }
    // }, [])


    useEffect(() => {
        setIsOnline(socketIO.connected);
    }, [socketIO])

    useEffect(() => {
        socketIO.once('connection', (res) => {
            socketIO.sendBuffer = [];
            setIsOnline(res);
        })
    }, [socketIO])

    // useEffect(() => {
    //     socketIO.on('disconnection', (res) => {
    //         setIsOnline(res);
    //     })
    // }, [socketIO])

    return {
        socketIO,
        isOnline
    }
}