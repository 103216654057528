import React, { useEffect, useContext } from "react";
import {
  Box,
  Alert,
  Divider
} from "@mui/material";
import { Formik, useFormikContext } from "formik";
import { styleSheet } from "../../../../common/styles/StylesCommon";
import RolService from "../../../../services/RolesService.js";
import { CToast } from "../../../../common/Components/ToastNotify";
import { useState } from "react";
import { validateCreatePermission } from "../../../../model/company/company.schema";
import { CreateConfig } from "../../../../common/Components/CreateConfig";
import { CheckListGroup } from "../../../../common/Components/CheckListGroup";
import { t } from "i18next";


export const AddRolesUser = ({ getRolesAccess, rolesAccess, name_elements, name_permission, nameConfigEqual, setNameConfigEqual }) => {
  const { values, setFieldValue } = useFormikContext();
  const [existe, setExiste] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const styles = styleSheet();
  const initialValues = {
    rol_name: "",
  };

  const onSave = async (dataForm) => {
    setIsLoading(true)
    const req = {
      rol_name: dataForm.rol_name,
      rol_cluster: values.modulo_cluster,
    };
    if (values.modulo_cluster.filter(item => item.active).length === 0) {
      CToast('Debe seleccionar al menos un módulo con sus roles', 'error')
      setIsLoading(false)
      return
    }
    try {
      const { data } = await RolService.createRol(req);
      CToast("Configuracion de permisos creada", "success", true);
      setExiste(null);
      getRolesAccess().then((res) => {
        const newRol = res.find(r => r.id_rol === data.id_rol);
        setFieldValue(name_permission, newRol.id_rol);
        setIsLoading(false);
      });
    } catch ({ response }) {
      console.log(response?.data.message);
      CToast(`Error al crear ${JSON.stringify(response?.data.error)}`, "error", true);
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      {
        (values[name_permission] === "" && existe) && (
          <Box>
            <Alert
              severity="info"
              style={{
                ...styles.baseAlert,
              }}
            >
              {t('messages.info_exist_config')} {nameConfigEqual}
            </Alert>
          </Box>
        )
      }
      {
        (values[name_permission] === ""
          && values[name_elements.name]
          && values[name_elements.name]?.filter((item) => item.active).length >= 0
          && !existe) ? (
          <Formik
            initialValues={initialValues}
            validationSchema={validateCreatePermission}
            onSubmit={(value, actions) => {
              onSave(value);
              !isLoading && actions.resetForm();
            }}>
            {({ handleSubmit }) => {
              return (
                <CreateConfig
                  name={'rol_name'}
                  label='Nombre del nuevo rol'
                  handleSubmit={handleSubmit}
                  isLoading={isLoading} />
              );
            }}
          </Formik>
        ) : (
          <Divider bgcolor="background.paper" style={{ paddingBlock: 60, border: 0 }} />
        )
      }
      <CheckListGroup
        name_permission={name_permission}
        name_elements={name_elements}
        compareFirstArray={rolesAccess}
        existe={existe}
        setExiste={setExiste}
        setNameConfigEqual={setNameConfigEqual}
        hasFilter={false}
      />
    </React.Fragment>
  );
};
