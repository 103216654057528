import * as Yup from 'yup';
import checkoutFormModel from './warehouseFormModel';
const {
    formField: {
        bodega,
        canal,
        cod_bodega,
        direccion,
        latitude,
        longitud,
        region,
        ciudad,
        comuna
    }
} = checkoutFormModel;

export const validateFormWarehouse = Yup.object({
    [cod_bodega.name]: Yup.string().required(cod_bodega.requiredErrorMsg),
    [bodega.name]: Yup.string().required(bodega.requiredErrorMsg),
    [canal.name]: Yup.string().required(canal.requiredErrorMsg),
    [region.name]: Yup.string().required(region.requiredErrorMsg),
    [comuna.name]: Yup.string().required(comuna.requiredErrorMsg),
    [ciudad.name]: Yup.string().required(ciudad.requiredErrorMsg),
    [direccion.name]: Yup.string().notRequired(),
    [latitude.name]: Yup.string().notRequired(),
    [longitud.name]: Yup.string().notRequired(),
});