import * as Yup from 'yup';
import { validateFormGeneric } from '../generic/generic.schema';
import checkoutFormModel from "./userFormModel";
const {
    formField: {
        first_name,
        last_name,
        cluster_name,
        email,
        gender,
        isSuperAdmin,
        password,
        password_confirmation,
        rut,
        select_rol_name,
        branchs_selected,
        modulo_cluster,
        rol_name,
        companyID,
        branchID
    }
} = checkoutFormModel;

export const validateFormUser = [
    Yup.object({
        [email.name]: Yup.string().email(email.invalidErrorMsg).required(email.requiredErrorMsg),
        [first_name.name]: Yup.string()
            .min(first_name.min, first_name.minErrorMsg)
            .max(first_name.max, first_name.maxErrorMsg)
            .required(first_name.requiredErrorMsg),
        [last_name.name]: Yup.string()
            .min(last_name.min, last_name.minErrorMsg)
            .max(last_name.max, last_name.maxErrorMsg)
            .required(last_name.requiredErrorMsg),
        [rut.name]: Yup.string().required(rut.requiredErrorMsg),
        [gender.name]: Yup.string().required(gender.requiredErrorMsg).oneOf(['M', 'F']),
        [isSuperAdmin.name]: Yup.boolean().oneOf([true, false]),
        [password.name]: Yup.string()
            .min(password.min, password.minErrorMsg)
            .max(password.max, password.maxErrorMsg)
            .matches(password.regex, password.regexErrorMsg)
            .required(password.requiredErrorMsg)
            .transform((value, originalValue) => originalValue === null ? 'T3mpor@lly' : value),
        [password_confirmation.name]: Yup.string()
            .required(password_confirmation.requiredErrorMsg)
            .oneOf([Yup.ref(password.name), null], password_confirmation.matchErrorMsg)
            .transform((value, originalValue) => originalValue === null ? 'T3mpor@lly' : value),
    }),
    Yup.object({
        [select_rol_name.name]: Yup.string()
            .when(['rol_name', 'modulo_cluster'], {
                is: (rol_name, modulo_cluster) => modulo_cluster?.filter(m => m.active).length === 0,
                then: Yup.string().required(select_rol_name.requiredErrorMsg),
            }),
        [rol_name.name]: Yup.string()
            .when(modulo_cluster.name, {
                is: (modulo_cluster) => modulo_cluster.length <= 0,
                then: Yup.string().required(modulo_cluster.requiredErrorMsg)
            })
            .min(rol_name.min, rol_name.minErrorMsg)
            .max(rol_name.max, rol_name.maxErrorMsg),
        [modulo_cluster.name]: Yup.array().min(0, modulo_cluster.requiredErrorMsg)
    }),
    Yup.object({
        [companyID.name]: Yup.string()
            .when(branchs_selected.name, {
                is: (branchs_selected) => branchs_selected.length <= 0,
                then: Yup.string().required(companyID.requiredErrorMsg),
            }),
        [branchID.name]: Yup.string()
            .when(branchs_selected.name, {
                is: (branchs_selected) => branchs_selected.length <= 0,
                then: Yup.string().required(branchID.requiredErrorMsg),
            }),
        [branchs_selected.name]: Yup.array()
            .min(0, branchs_selected.requiredErrorMsg),
    }),
];

export const validateCreateRol = Yup.object({
    [rol_name.name]: Yup.string().required(rol_name.requiredErrorMsg),
})


