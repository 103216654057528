export const types = {
  login: "[auth] Login",
  logout: "[auth] Logout",
  refresh: "[auth] Refesh Token",
  refreshUser: "[auth] Refesh User",
};

export const themes = {
  dark: "[theme] Dark",
  light: "[theme] Light",
};

export const typesUser = {
  credentials: "[user] create credentials",
  update: "[user] update user",
  refresh: "[user] refresh prefs",
};

export const branchs = {
  create: "[branch] create array branch",
}

export const sidebar = {
  get: "[sidebar] get sidebar",
}