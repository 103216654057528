import { Box } from "@mui/material";
import React, { useContext } from "react";
import { CDetails } from "../../../../common/Components/CDetails";
import CustomModal from "../../../../common/Components/CustomModal";
import ModulesFormContext from "../../../../common/Contexts/ModulesFormContext";
import { ModalOpenEnum } from "../../../../common/utils/ModalOpenEnum";
import checkoutForm from "../../../../model/centerOfDistribution/centerDistributionFormModel";

export const DetailsDistributionCenter = () => {

  const { rowData: distributionCenter, isOpen, toggleModal, overallStyle } = useContext(ModulesFormContext);

  const { formField: {
    cod_cd,
    cd,
    priority,
    region,
    comuna,
    ciudad,
    direccion,
    latitude,
    longitud
  } } = checkoutForm;

  return (
    <CustomModal
      isOpen={isOpen[ModalOpenEnum.DETAIL]}
      toggleModal={() => toggleModal(ModalOpenEnum.DETAIL)}
      moduleLabel={'distribution_center'}
      actionLabel='info'
      descriptionLabel={`de ${distributionCenter.cd}`}
      hiddenModuleLabel={true}
    >
      <Box sx={overallStyle?.boxModalDetailContainer}>
        <CDetails label={cod_cd.headerTable} value={distributionCenter.cod_cd} />
        <CDetails label={cd.headerTable} value={distributionCenter.cd} />
        <CDetails label={priority.headerTable} value={distributionCenter.priority} />
        <CDetails label={region.headerTable} value={distributionCenter.region} />
        <CDetails label={comuna.headerTable} value={distributionCenter.comuna} />
        <CDetails label={ciudad.headerTable} value={distributionCenter.ciudad} />
        <CDetails label={direccion.headerTable} value={distributionCenter.direccion} />
        <CDetails label={latitude.headerTable} value={distributionCenter.latitude} />
        <CDetails label={longitud.headerTable} value={distributionCenter.longitud} />
      </Box>
    </CustomModal>
  );
};
