import authAxios from "../common/Auth/authAxios";

const CalendarService = {
    getCalendarsByBranch: async (branch) => {
        return await authAxios.post('/inputs-generales/getCalendarios', branch)
    },
    createCalendars: async (calendar) => {
        return await authAxios.post('/inputs-generales/addCalendario', calendar)
    },
    updateCalendars: async (calendar) => {
        return await authAxios.patch('/inputs-generales/editCalendario', calendar)
    },
    deleteCalendars: async (calendar) => {
        return await authAxios.delete('/inputs-generales/deleteCalendario',
            { data: calendar }
        )
    },
}

export default CalendarService
