import React, { useMemo } from "react";
import { Box, Button, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { createStyles } from "@material-ui/styles";

import { useContext } from "react";
import ModulesFormContext from "../../../common/Contexts/ModulesFormContext";
import MediaContext from "../../../common/Contexts/MediaContext";
import { styleSheet } from "../../../common/styles/StylesCommon";
import { ModalOpenEnum } from "../../../common/utils/ModalOpenEnum";

export const LayoutTableGraphs = ({
  children,
  heightT,
  otherStyle,
}) => {


  const { isMobile, isTablet } = useContext(MediaContext);
  const overallStyle = styleSheet(isMobile, isTablet);

  return (
    <Box sx={{ ...styles.container, heightT, ...otherStyle }}>
      <Stack
        direction={"row"}
        display={"flex"}
        justifyContent={"space-between"}
      >
      </Stack>
      {children}
    </Box>
  );
};

const styles = createStyles({
  container: {
    backgroundColor: "transparent",
    border: "1px solid common.black",
    borderColor: "common.black",
    borderRadius: "30px",
    height: 450,
    width: "100%",
    maxHeight: 600,
    minHeight: 200,
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
    overflowY: "auto",
    overflowX: "hidden",
  },
  buttonAdd: {
    fontWeight: 800,
    fontSize: 13,
  },
});
