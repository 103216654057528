
import authAxios from "../common/Auth/authAxios";

const LogsService = {
    getLogs: async (params) => {
        const query = params ? `?${params}` : "";
        return await authAxios.get(`/logs/getSystemLogs${query}`)
    },
    getAllLogs: async () => {
        return await authAxios.get('/logs/getAllLogs')
    },
}

export default LogsService
