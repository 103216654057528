import { Box } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { ButtonsFileCSV } from "../../../common/Components/ButtonsFileCSV";
import { LayoutTable } from "../../../common/Components/LayoutTable";
import Title from "../../../common/Components/Title";
import BranchsContext from "../../../common/Contexts/BranchContext";
import { ModulesProvider } from "../../../common/Providers/ModulesProvider";
import { styleSheet } from "../../../common/styles/StylesCommon";
import WarehouseService from "../../../services/WarehouseService";
import { t } from 'i18next'
import { useForm } from "../../../hooks/useForm";
import { TableWarehouse } from "./components/TableWarehouse";
import { DetailsWarehouse } from "./components/DetailWarehouse";
import { CustomModalDelete } from "../../../common/Components/CustomModalDelete";
import { WarehouseForm } from "./WarehouseForm";
import { CToast } from "../../../common/Components/ToastNotify";
import ModulesFormContext from "../../../common/Contexts/ModulesFormContext";
import { ModalOpenEnum } from "../../../common/utils/ModalOpenEnum";

const WarehouseScreen = () => {

    const { branchID } = useContext(BranchsContext);
    const { overallStyle } = useContext(ModulesFormContext);
    //LOGIC FORM
    const {
        isOpen,
        rowData,
        toggleModal,
        dataTable,
        setDataTable,
        isTableLoading,
        setIsTableLoading
    } = useForm();
    const [isLoadingDelet, setIsLoadingDelet] = useState(false)


    const getDataTable = async () => {
        setIsTableLoading(true);
        const req = {
            company_id: branchID,
        };
        try {
            const { data } = await WarehouseService.getWarehouseByBranch(req);
            setDataTable(data);
        } catch ({ response }) {
            console.log(response)
        } finally {
            setIsTableLoading(false)
        }

    };

    const handleDeleteWarehouse = async () => {
        setIsLoadingDelet(true);
        const req = {
            "company_id": branchID,
            "id_bodega": rowData.id_bodega
        }
        try {
            const { data } = await WarehouseService.deleteWarehouse(req);
            CToast(t('messages.success_delete'), "success");
            getDataTable();
            setIsLoadingDelet(false);
        } catch (error) {
            CToast("Error al eliminar la bodega", "error");
            setIsLoadingDelet(false);
        }

    }

    useEffect(() => {
        branchID.length > 0 && getDataTable();
    }, [branchID]);

    return (
        <ModulesProvider value={{
            getDataTable,
            handleDelete: handleDeleteWarehouse,
            isOpen,
            rowData,
            toggleModal,
            dataTable,
            setDataTable,
        }}>
            <Box sx={overallStyle?.container}>
                <Title name={t('titles.wareouses')} />
                <ButtonsFileCSV />
                <LayoutTable hasAdd={true} labelAction={'Agregar bodega'} disabledButon={branchID.length > 0 ? false : true}>
                    {isOpen[ModalOpenEnum.EDIT] && (<WarehouseForm />)}
                    {isOpen[ModalOpenEnum.DETAIL] && (<DetailsWarehouse />)}
                    {isOpen[ModalOpenEnum.DELETE] && (<CustomModalDelete
                        descriptionLabel={rowData?.bodega ? rowData.bodega : ''}
                        isLoading={isLoadingDelet}
                        moduleLabel={'warehouse'}
                    />)}
                    <TableWarehouse />
                </LayoutTable>
            </Box>
        </ModulesProvider>
    )
}


export default WarehouseScreen