import React from "react";
import { CustomTable } from "../../../../common/Components/CustomTable";
import { getWeekday } from "../../../../helpers/weekdays";
import checkoutForm from "../../../../model/calendar/calendarFormModel";

export const TableCalendar = () => {
  const { formField: {
    id_bodega,
    replacement_day,
    dispatch_day,
    lead_time,
    delivery_time
  } } = checkoutForm;
  const headers = [
    { field: "id_bodega", headerName: id_bodega.headerTable },
    {
      field: "replacement_day", headerName: replacement_day.headerTable,
      valueFormatter: ({ data }) => getWeekday(data.replacement_day)
    },
    {
      field: "dispatch_day", headerName: dispatch_day.headerTable,
      valueFormatter: ({ data }) => getWeekday(data.dispatch_day),
    },
    { field: "lead_time", headerName: lead_time.headerTable },
    { field: "delivery_time", headerName: delivery_time.headerTable },
  ];

  return (<CustomTable headers={headers} />);
};
