import React, { useContext, useState } from 'react'
import MediaContext from '../../common/Contexts/MediaContext';
import UserContext from '../../common/Contexts/UserContext';
import { Formik } from 'formik';
import { t } from 'i18next';
import checkoutFormModel from '../../model/prefs/prefsFormModel';
import { validateFormPrefs } from '../../model/prefs/prefs.schema';
import { FormTextInput } from '../../common/Components/FormTextInput';
import { CToast } from '../../common/Components/ToastNotify';
import CustomModal from '../../common/Components/CustomModal';
import { ContainerForm } from '../../common/Components/ContainerForm';
import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import UsersService from '../../services/UsersService';
import { SelectGender } from '../../common/Components/SelectGender';
import { ButtonSave } from '../../common/Components/ButtonSave';
import { InputPassword } from '../../common/Components/InputPassword';
import { ModalOpenEnum } from '../../common/utils/ModalOpenEnum';
import { styleSheet } from '../../common/styles/StylesCommon';

export const Prefs = ({ isOpen, toggleModal, rowData: user }) => {


    const { isTablet } = useContext(MediaContext);
    const [isLoading, setIsLoading] = useState(false);
    const { refreshUserStore } = useContext(UserContext);
    const styles = styleSheet();

    const { formId,
        formField: {
            email,
            first_name,
            last_name,
            rut, gender,
            password, password_confirmation
        } } = checkoutFormModel;

    const initialValue = {
        email: user?.email || "",
        first_name: user?.first_name || "",
        last_name: user?.last_name || "",
        gender: user?.gender || "",
        rut: user?.rut || "",
        password: user ? null : "",
        password_confirmation: user ? null : "",
        user_id: user?.user_id,
        changePassword: false,
    }

    const onSave = async (value) => {
        setIsLoading(true);
        const req = {
            email: value.email,
            first_name: value.first_name,
            last_name: value.last_name,
            gender: value.gender,
            rut: value.rut,
            user_id: value?.user_id,
        };
        value.changePassword && Object.assign(req, { password: value.password });
        try {
            const { data } = await UsersService.updateUser(req);
            const newData = data.filter((item) => item.user_id === user.user_id);
            await refreshUserStore(newData[0]);
            toggleModal(ModalOpenEnum.EDIT);
            CToast(t("messages.user_success_update"), "success", true);
        } catch ({ response }) {
            console.log(response)
            CToast(response?.data?.error, "error", true);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <CustomModal
            isOpen={isOpen[ModalOpenEnum.EDIT]}
            toggleModal={() => toggleModal(ModalOpenEnum.EDIT)}
            moduleLabel={'user'}
            actionLabel={"edit"}
            descriptionLabel={''}
        >
            <Formik
                initialValues={initialValue}
                validationSchema={validateFormPrefs}
                onSubmit={(values, actions) => {
                    onSave(values);
                    actions.setSubmitting(false);
                }}
            >
                {({ handleSubmit, values, handleChange, handleBlur }) => (
                    <ContainerForm formID={formId}>
                        <Box width={isTablet ? '100%' : '50%'}>
                            <FormTextInput
                                label={email.label}
                                name={email.name}
                                style={{ width: "95%" }}
                                fullWidth={true}
                            />
                            <FormTextInput
                                label={first_name.label}
                                name={first_name.name}
                                style={{ width: "95%" }}
                                fullWidth={true}
                            />
                            <FormTextInput
                                label={last_name.label}
                                name={last_name.name}
                                style={{ width: "95%" }}
                                fullWidth={true}
                            />
                            <SelectGender
                                style={{ width: "95%" }}
                                name={gender.name}
                                label={gender.label}
                            />
                        </Box>
                        <Box width={isTablet ? '100%' : '50%'}>
                            <FormTextInput
                                label={rut.label}
                                name={rut.name}
                                autoComplete='off'
                                type='text'
                                style={{ width: "100%" }}
                                fullWidth={true}
                            />

                            <FormControlLabel
                                label={
                                    <Typography color={"black"} sx={{ marginRight: 2, fontWeight: 10 }}>
                                        {`${t('labels_modals.change_password')}?`}
                                    </Typography>
                                }
                                labelPlacement="start"
                                control={
                                    <Switch
                                        id={'changePassword'}
                                        name={'changePassword'}
                                        value={values.changePassword}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        size="medium"
                                        sx={{
                                            ...styles.switch,
                                        }}
                                        checked={values.changePassword}
                                    />
                                }
                            />
                            {
                                values.changePassword && (
                                    <React.Fragment>
                                        <InputPassword
                                            name={password.name}
                                            label={password.label}
                                            autoComplete='off'
                                        />
                                        <InputPassword
                                            name={password_confirmation.name}
                                            label={password_confirmation.label}
                                            autoComplete='off'
                                        />
                                    </React.Fragment>
                                )
                            }
                            <ButtonSave
                                onClick={handleSubmit}
                                isLoading={isLoading}
                                edit={true}
                            />
                        </Box>
                    </ContainerForm>
                )}
            </Formik>
        </CustomModal >
    )
}
