import { Box, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { CDetails } from '../../../../common/Components/CDetails'
import CustomModal from '../../../../common/Components/CustomModal'
import ModulesFormContext from '../../../../common/Contexts/ModulesFormContext'
import { ModalOpenEnum } from '../../../../common/utils/ModalOpenEnum'
import { capitalizeString } from '../../../../helpers/formatString'
import { getMonth } from '../../../../helpers/months'

export const SaleWeekDetails = ({ isOpen, setIsOpenModal, dataCell }) => {

  return (
    <CustomModal
      isOpen={isOpen}
      toggleModal={() => setIsOpenModal(false)}
      moduleLabel={'warehouse'}
      hiddenModuleLabel={true}
      actionLabel='info'
      descriptionLabel={`de Proyección semanal`}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 2,
        }}
      >
        <CDetails label={'Codigo'} value={dataCell.codigo_producto} />
        <CDetails label={'Categoria'} value={dataCell.descripcion_categoria} />
        {/* <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 2 }}>
          {`Detalle de Proyección Semanal`}
        </Typography>
        {
          dataCell?.type_unds && dataCell.type_unds.map((item, index) => {
            return (
              <CDetails label={capitalizeString(item.tipo)} value={item.unds_val} />
            )
          })
        } */}

      </Box>
    </CustomModal>
  )
}
