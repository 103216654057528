export default {
    formId: "branchCheckoutForm",
    formField: {
        branch_name: {
            name: "branch_name",
            label: "Nombre de la sucursal",
            min: 1,
            max: 20,
            minErrorMsg: "El nombre de la sucursal debe tener al menos 1 caracteres",
            maxErrorMsg: "El nombre de la sucursal debe tener menos de 20 caracteres",
            requiredErrorMsg: "El nombre de la sucursal es requerido",
        },
        branch_description: {
            name: "branch_description",
            label: "Descripción de la sucursal",
            requiredErrorMsg: "La descripción de la sucursal es requerida",
            min: 10,
            max: 100,
            minErrorMsg: "la descripción debe ser mayor o igual a 10 caracteres",
            maxErrorMsg: "la descripción debe ser menor o igual a 100 caracteres",
        },
        branch_phone: {
            name: "branch_phone",
            label: "Teléfono de la sucursal",
            min: 8,
            max: 20,
            minErrorMsg: "El teléfono de la sucursal debe tener al menos 8 caracteres",
            maxErrorMsg: "El teléfono de la sucursal debe tener menos de 20 caracteres",
            requiredErrorMsg: "El teléfono de la sucursal es requerido",
            typeErrorMsg: "El teléfono de la sucursal es invalido",
        },
        branch_contact_name: {
            name: "branch_contact_name",
            label: "Nombre de contacto de la sucursal",
            min: 3,
            minErrorMsg: "El nombre de contacto de la sucursal debe tener al menos 3 caracteres",
            requiredErrorMsg: "El nombre de contacto de la sucursal es requerido",
        },
        branch_contact_phone: {
            name: "branch_contact_phone",
            label: "Teléfono de contacto de la sucursal",
            min: 8,
            max: 20,
            minErrorMsg: "El teléfono de contacto de la sucursal debe tener al menos 8 caracteres",
            maxErrorMsg: "El teléfono de contacto de la sucursal debe tener menos de 20 caracteres",
            requiredErrorMsg: "El teléfono de contacto de la sucursal es requerido",
            typeErrorMsg: "El teléfono de contacto de la sucursal es invalido",
        },
        branch_contact_email: {
            name: "branch_contact_email",
            label: "Email de contacto de la sucursal",
            requiredErrorMsg: "El email de contacto de la sucursal es requerido",
            invalidErrorMsg: "La dirección email es invalido"
        },
        select_permission: {
            name: "select_permission",
            label: "Permisos",
            requiredErrorMsg: "Debe seleccionar al menos un permiso o crear uno seleccionando las casillas de abajo",
        },
        select_permission_branch: {
            name: "select_permission_branch",
            label: "Permisos",
            requiredErrorMsg: "Debe seleccionar al menos un permiso o crear uno seleccionando las casillas de abajo",
        },
        elements_cluster: {
            name: "elements_cluster",
            label: "Cluster",
            cod_element_logic: "1",
            cluster: "element_cluster",
            cluster_name: "cluster_name",
            cluster_id: "id_element_logic",
            requiredErrorMsg: "Debe seleccionar al menos un cluster",
        },
        rol_name: {
            name: "rol_name",
            label: "Cluster",
            min: 5,
            max: 20,
            minErrorMsg: "El nombre de la configuración debe tener al menos 5 caracteres",
            maxErrorMsg: "El nombre de la configuración debe tener menos de 20 caracteres",
            requiredErrorMsg: "El nombre de la configuración es requerido",
        },
        cluster_name: {
            name: "cluster_name",
            label: "Nombre de la configuración",
            min: 5,
            max: 20,
            minErrorMsg: "El nombre de la configuración debe tener al menos 5 caracteres",
            maxErrorMsg: "El nombre de la configuración debe tener menos de 20 caracteres",
            requiredErrorMsg: "El nombre de la configuración es requerido",
        },
        model_logic_id_branch: {
            name: "model_logic_id",
            label: "Nombre de la configuración",
            requiredErrorMsg: "Debe seleccionar un metodo",
        },
        model_logic_branch: {
            name: "model_logic_branch",
            label: "Nombre de la configuración",
            model: 'process_cluster',
            model_name: 'model_logic_name',
            model_id: 'model_id',
            cod_model_logic: "1",
            requiredErrorMsg: "Debe chequear algunas opciones",
        },
        model_logic_name: {
            name: "model_logic_name",
            label: "Nombre de la configuración",
            min: 5,
            max: 20,
            minErrorMsg: "El nombre de la configuración debe tener al menos 5 caracteres",
            maxErrorMsg: "El nombre de la configuración debe tener menos de 20 caracteres",
            requiredErrorMsg: "El nombre de la configuración es requerido",
        }
    }
}
