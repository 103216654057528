import React, { createContext,useContext } from 'react';
import { useSocket } from '../../hooks/useSocket';
import AuthContext from './AuthContext';

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
    const baseURL = "https://biwiser-dev.herokuapp.com";
    const UrlTest = "http://localhost:3000";
    const { auth } = useContext(AuthContext);
    const { socketIO, isOnline } = useSocket(`${UrlTest}`, auth);
    return (
        <SocketContext.Provider value={{ socketIO, isOnline }}>
            {children}
        </SocketContext.Provider>
    )
}

