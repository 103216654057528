import { Box } from '@mui/material'
import React, { useMemo, useEffect, useState } from 'react'
import { t } from "i18next";
import { styleSheet } from '../../../common/styles/StylesCommon';
import Title from "../../../common/Components/Title";
import RadarControlService from '../../../services/RadarControlService';
import { groupByYears, orderByMonths, orderByYearAsc, orderByYearDesc, orderByYears, orderByYearsAndMonths } from '../../../helpers/formatArrays';
import { ModulesProvider } from '../../../common/Providers/ModulesProvider';
import { BoxTitle } from '../components/BoxTitle';
import { TableVCC } from './components/TableVCC';
import { HistoryBarChart } from './components/HistoryBarChart';
import { dataTestGraph } from './json/testGraph';

export const VCCScreen = () => {

  const overallStyle = useMemo(() => styleSheet(), []);
  const [data, setData] = useState([]);
  const [dataGraph, setDataGraph] = useState({
    groupByYear: [],
    orderByMonths: [],
  });
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    // setLoading(true);
    try {
      const { data } = await RadarControlService.getVentCostContrib();
      console.log('DATA', data);
      const orderDataByYear = orderByYearsAndMonths(data);
      const orderByMonth = orderByMonths(data);
      const groupByYear = groupByYears(orderByMonth);
      const orderByYearAndMonth = Object.keys(groupByYear).map((year) => {
        return {
          ...orderByMonths(groupByYear[year]),
        };
      });
      console.log('orderDataByYear', orderDataByYear);
      console.log(data);
      setDataGraph({
        groupByYear,
        orderByMonths: orderByMonth,
      });
      setData(data);
    } catch (error) {
      console.log('ERROR - get Ciclo Historico Detalle', error);
    } finally {
      // setLoading(false);
    }

  }

  const getDataGraph = async () => {
    setLoading(true)
    try {
      const { data } = await RadarControlService.getCycleHistoric();
      setData(data);
      console.log('DATA CYCLE HISTORIC', data);
    } catch ({ response }) {
      console.log('ERROR - get Ciclo Historico Detalle', response);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getDataGraph();
  }, [])

  return (
    <ModulesProvider
      value={{
        dataGraph: data,
      }}
    >
      <Box sx={{ ...overallStyle?.container, }}>
        <Box>
          <Title name={t("titles.VCC")} />
        </Box>
        <Box
          marginX={3}
          style={{
            overflow: "auto",
            // height: "calc(100vh - 64px - 48px - 48px - 48px)",
          }}
          display="flex"
          flexDirection="column"
          justifyContent={"space-between"}
          flex={1}
        >
          <BoxTitle title={"Ciclo histórico"} />
          {
            data && data.map((item, index) => {
              return (
                <HistoryBarChart key={index} dataGraph={item} indexBy={index} />
              )
            })
          }
          {/* <HistoryBarChart dataGraph={dataTestGraph} indexBy={0} />
          <HistoryBarChart dataGraph={dataTestGraph} indexBy={1} />
          <HistoryBarChart dataGraph={dataTestGraph} indexBy={2} /> */}
          {/* TABLA DE DETALLE*/}
          <BoxTitle title={"Detalle ciclo histórico"} />
          <TableVCC dataGraph={data} />
        </Box>
      </Box>
    </ModulesProvider>
  )
}
