import authAxios from "../common/Auth/authAxios";

const ExludeGIDService = {
    getExcludeGIDByBranch: async (branch) => {
        return await authAxios.post('/inputs-generales/getGuiasDeTransito', branch)
    },
    createExcludeGID: async (GID) => {
        return await authAxios.post('/inputs-generales/addGuiasDeTransito', GID)
    },
    updateExcludeGID: async (GID) => {
        return await authAxios.patch('/inputs-generales/editGuiasDeTransito', GID)
    },
    deleteExcludeGID: async (GID) => {
        return await authAxios.delete('/inputs-generales/deleteGuiasDeTransito',
            { data: GID }
        )
    },
}

export default ExludeGIDService