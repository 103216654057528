import React, { useMemo } from 'react'
import { Box, Button, Stack } from '@mui/material'
import AddIcon from "@mui/icons-material/Add";
import { createStyles } from '@material-ui/styles'
import { styleSheet } from '../styles/StylesCommon';
import { useContext } from 'react';
import MediaContext from '../Contexts/MediaContext';
import ModulesFormContext from '../Contexts/ModulesFormContext';
import { ModalOpenEnum } from '../utils/ModalOpenEnum';

export const LayoutTable = ({ children, hasAdd, labelAction, disabledButon, isVisible= true, isTableGraph = false, heightT}) => {

    const { toggleModal, actionsEnabled, actionsEnabledModule } = useContext(ModulesFormContext);
    const { isMobile, isTablet } = useContext(MediaContext);
    const overallStyle = styleSheet(isMobile, isTablet);
    const addEnabled = actionsEnabled.filter(a => (a.action_name === 'Agregar' && a.active && actionsEnabledModule?.includes('Agregar'))).length > 0;
    const visible = (isVisible) ? "visible": "hidden";
    return (
        <Box sx={{...styles.container, heightT}}>
            <Stack direction={"row"} display={"flex"} justifyContent={"space-between"}>
                <Button
                    sx={{ ...overallStyle.buttonTable, ...styles.buttonAdd }}
                    onClick={() => toggleModal(ModalOpenEnum.EDIT)}
                    disabled={!addEnabled || disabledButon ? true : false}
                    style={{visibility: visible}}
                >
                    <AddIcon sx={{ fontSize: 15 }} />
                    {labelAction}
                </Button>
            </Stack>
            {children}
        </Box>
    )
}

const styles = createStyles({
    container: {
        backgroundColor: "transparent",
        border: "1px solid common.black",
        borderColor: "common.black",
        borderRadius: "30px",
        height: "420px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
        marginTop: 5,
        marginInline: 3
    },
    buttonAdd: {
        fontWeight: 800,
        fontSize: 13
    }
})
