import { Skeleton } from '@mui/material'
import React from 'react'

export const SkeletonView = ({ height, width, ...props }) => {
    return (
        <Skeleton
            variant="rectangular"
            width={width || "100%"}
            height={height || 100}
            animation="wave"
            {...props}
        />
    )
}
