export const actionsEnabledModule = {
    "/company": ["Agregar", "Editar", "Eliminar"],
    "/branch": ["Agregar", "Editar", "Eliminar"],
    "/users": ["Agregar", "Editar", "Eliminar"],
    "/roles": ["Agregar", "Editar", "Eliminar"],
    "/cronjobs": ["Agregar", "Editar"],
    "/logs": ["Detalle", "Eliminar"],
    "/modules": ["Agregar", "Detalle", "Editar", "Eliminar"],
    "/sections": ["Agregar", "Detalle", "Editar", "Eliminar"],
    "/warehouse": ["Agregar", "Detalle", "Editar", "Eliminar", "CMR"],
    "/distribution-centers": ["Agregar", "Detalle", "Editar", "Eliminar", "CMR"],
    "/sku-for-cd": ["Agregar", "Detalle", "Editar", "Eliminar", "CMA"],
    "/calendar": ["Agregar", "Editar", "Eliminar", "CMR"],
    "/daily-money": ["Agregar", "Editar", "Eliminar"],
    "/homologation": ["Agregar", "Editar", "Eliminar", "CMA"],
    "/constants-of-weeks": ["Editar", "CMR"],
    "/service-level": ["Editar"],
    "/mix": ["Detalle", "CMA"],//["Agregar","Detalle", "Editar", "Eliminar"],
    "/suggested-parameters": ["Agregar", "Detalle", "Editar", "Eliminar"],
    "/excluded-dates": ["Agregar", "Editar", "Eliminar", "CMR"],
    "/exclude-gid": ["Agregar", "Editar", "Eliminar", "CMR"],
    "/external-forecast": [],
    "/exclude-invoice": [],
    "/execution-of-the-suggested": ["Agregar", "Detalle"],
    "/suggested-list": ["Detalle"],//["Agregar", "Detalle", "Editar", "Eliminar"],
}