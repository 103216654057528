import React, { useContext } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  BarElement,
  PointElement,
  Filler,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Box, Divider, Modal } from "@mui/material";
import { styleSheet } from "../../../common/styles/StylesCommon";
import { MyResponsiveScatterPlot } from "./ScatterChar";
import { MyResponsiveHeatMap } from "./HeatMapChar";
import { MyResponsiveBar } from "./NivoChar";
import { MyResponsiveLine } from "./LineChar";

import CompanyContext from "../../../common/Contexts/CompanysContext";
import { dataScatter } from "./dataScatter";
import { dataExampleHeat } from "./dataHeatMap";
import { dataLinechar } from "./dataLineChar";
import { CustomTitleModal } from "../../../common/Components/CustomTitleModal";
import { ButtonCloseModal } from "../../../common/Components/ButtonCloseModal";
import { ModalOpenEnum } from "../../../common/utils/ModalOpenEnum";

const ModalChars = ({ children, isOpen, toggleModal }) => {
  const { datas } = useContext(CompanyContext);

  const styles = styleSheet();

  return (
    <Modal
      open={isOpen}
      onClose={(e, reason) => {
        if (reason === "backdropClick") {

        } else {
          toggleModal(ModalOpenEnum.GRAPHICS);
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        overflow: "auto",
      }}
    >
      <Box sx={{ ...styles.boxModalChar, height: '700px', width: '90%', marginInline: 10 }}>
        <ButtonCloseModal handleClick={() => toggleModal(ModalOpenEnum.GRAPHICS)} />
        {children}
      </Box>
    </Modal>
  );
};

export default ModalChars;
