import authAxios from "../common/Auth/authAxios";

const RadarControlService = {
  getFilterSuggester: async (filter) => {
    return await authAxios.get(`/filter/suggester/${filter}`);
  },
  getLinerChar: async () => {
    const url = "/radar-control";
    return await authAxios.get(url);
  },

  getForecastGraph: async (params) => {
    const query = params ? `/${params}` : "";
    const url = `/radar-control/graphics/forecast-resume${query}`;
    return await authAxios.get(url);
  },

  getForecast: async (params) => {
    const query = params ? `/${params}` : "";
    const url = `/radar-control/graphics/forecast${query}`;
    return await authAxios.get(url);
  },

  getProducts: async (params) => {
    const query = params ? `/${params}` : "";
    const url = `/radar-control/suggester/productos`;
    return await authAxios.get(url);
  },

  getSaleMonth: async (params) => {
    const query = params ? `/${params}` : "";
    const url = `/radar-control/tables/venta-mes${query}`;
    return await authAxios.get(url);
  },

  getProjectionMonth: async (params) => {
    const query = params ? `/${params}` : "";
    const url = `/radar-control/tables/proyeccion-mensual${query}`;
    return await authAxios.get(url);
  },

  getProyectionInv: async (params) => {
    const query = params ? `/${params}` : "";
    const url = `/radar-control/tables/inventario-proyectado${query}`;
    return await authAxios.get(url);
  },

  getRadarControlGraph: async (params) => {
    const query = params ? `/${params}` : "";
    const url = `/radar-control/graphics/evolutivo-venta${query}`;
    return await authAxios.get(url);
  },

  getHiddenSKU: async (params) => {
    const query = params ? `${params}` : "";
    const url = `/radar-control/tables/hidden-sku${query}`;
    return await authAxios.get(url);
  },

  getPorcentajeVenta: async (params) => {
    const query = params ? `${params}` : "";
    const url = `/radar-control/tables/porcentaje-venta${query}`;
    return await authAxios.get(url);
  },

  getProximoQuiebre: async (params) => {
    const query = params ? `${params}` : "";
    const url = `/radar-control/tables/proximo-quiebre${query}`;
    return await authAxios.get(url);
  },

  getTransaccionVentas: async (params) => {
    const query = params ? `${params}` : "";
    const url = `/radar-control/tables/transaccion-ventas${query}`;
    return await authAxios.get(url);
  },

  getTransitos: async (params) => {
    const query = params ? `${params}` : "";
    const url = `/radar-control/tables/transitos${query}`;
    return await authAxios.get(url);
  },

  getRankingVentas: async (params) => {
    const query = params ? `${params}` : "";
    const url = `/radar-control/tables/ranking-v-c${query}`;
    return await authAxios.get(url);
  },
  getFilterSuggester: async (params) => {
    const query = params ? `/${params}` : "";
    const url = `/radar-control/filter/suggester${query}`;
    return await authAxios.get(url);
  },
  getVentCostContrib: async (params) => {
    const query = params ? `/${params}` : "";
    const url = `/radar-control/tables/ciclo-historico-detalle${query}`;
    return await authAxios.get(url);
  },
  getCycleHistoric: async (params) => {
    const query = params ? `/${params}` : "";
    const url = `/radar-control/graphics/ciclo-historico${query}`;
    return await authAxios.get(url);
  },
};

export default RadarControlService;
