import { Box, FormControlLabel, Switch, Typography } from "@mui/material";
import { useField } from "formik";
import { styleSheet } from "../../../../common/styles/StylesCommon";

export const SwitchSeller = ({ label, title = "Tipo", ...props }) => {
  const [field] = useField(props);
  const { value } = field;
  const styles = styleSheet();
  return (
    <Box
      display={"flex"}
      width={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Typography color={"white"} sx={{ marginRight: 2, fontWeight: 10 }}>
        {title}
      </Typography>
      <FormControlLabel
        control={
          <Switch
            id={props.id}
            value={value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            size="medium"
            sx={{
              ...styles.checkRadar,
              color: "white",
            }}
            checked={field.value}
            {...props}
          />
        }
        label={
          <Typography
            color={"white"}
            variant={"h6"}
            sx={{ marginRight: 2, fontSize: 12 }}
          >{`${field.value ? "Vendedor" : "Cliente"}`}</Typography>
        }
      />
    </Box>
  );
};
