import authAxios from "../common/Auth/authAxios";

const DistributionService = {
    getDistributionCenterByBranch: async (branch) => {
        return await authAxios.post('/inputs-generales/getCentrosDeDistribucion', branch)
    },
    createDistributionCenter: async (center) => {
        return await authAxios.post('/inputs-generales/addCentrosDeDistribucion', center)
    },
    updateDistributionCenter: async (center) => {
        return await authAxios.patch('/inputs-generales/editCentrosDeDistribucion', center)
    },
    deleteDistributionCenter: async (center) => {
        return await authAxios.delete('/inputs-generales/deleteCentrosDeDistribucion',
            { data: center })
    },
}

export default DistributionService