import React, { useState, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { styleSheet } from "../../../common/styles/StylesCommon";
import { useForm } from "react-hook-form";
import LoginService from "../../../services/LoginService";
import { CToast } from "../../../common/Components/ToastNotify";
import { Form, Formik } from "formik";
import { validateChangePass } from "../../../helpers/validationScreen.schema";
import { FormTextInput } from "../../../common/Components/FormTextInput";
import { InputPassword } from "../../../common/Components/InputPassword";

const ChangePassConfirm = ({ email, setchangePassOk }) => {

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isLoadingConfirmButton, setIsLoadingConfirmButton] = useState(false);
  const styles = styleSheet();

  const initialValues = {
    code: "",
    password: "",
  };

  const onSubmit = async (data) => {
    setIsLoadingConfirmButton(true);
    const req = {
      email: email,
      tempPass: data.code,
      password: data.password,
    };

    try {
      const { data } = await LoginService.changePass(req);
      CToast("Contraseña cambiada correctamente", "success");
      setchangePassOk(true);
      setIsLoadingConfirmButton(false);
    } catch (error) {
      console.log(error);
      CToast(error.response.data.error, "error");
      setIsLoadingConfirmButton(false);
     
    }
  };

  return (
    <Box width={"80%"} display={"flex"} sx={{ flexDirection: "column" }}>
      <Box width={"100%"} display="flex" justifyContent={"center"}>
        <Typography style={styles.titleWelcome}>
          Cambie su contraseña
        </Typography>
      </Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validateChangePass}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {() => {
          return (
            <Form>
              <Box
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 10,
                }}
              >
                <FormTextInput name={"code"} label={"Codigo"} fullWidth />
                <InputPassword />

                <Button
                  type="submit"
                  variant="contained"
                  disabled={isLoadingConfirmButton ? true : false}
                  sx={{
                    ...styles.buttonLogin,
                  }}
                >
                  {isLoadingConfirmButton ? (
                    <CircularProgress />
                  ) : (
                    "Cambiar contraseña"
                  )}
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default ChangePassConfirm;
