import React from "react";
import { FormControl, MenuItem } from "@mui/material";
import { CustomSelect } from "./CustomSelect";

export const genero = [
  {
    id: 0,
    name: "Masculino",
    value: 'M'
  },
  {
    id: 1,
    name: "Femenino",
    value: 'F'
  },
];

export const SelectGender = ({name,label, gender, ...props }) => {

  return (
    <FormControl fullWidth sx={{ mt: 2, }}>
      <CustomSelect
        sx={{
          width: "100%",
          borderRadius: 20,
          height: 50,
          padding: "2px 10px",
          boxShadow: "0px 3px 6px #00000029",
        }}
        id={name}
        value={gender}
        label={label}
        labelId={label}
        name={name}
        {...props}
      >
        {genero.map((g, index) => (
          <MenuItem
            key={index}
            value={g.value}
          >{g.name}</MenuItem>
        ))}
      </CustomSelect>
    </FormControl>
  );
};
