export const dataBar =[
    {
      "fecha": "Enero",
      "año_anterior": 182,
      "Forecast": 155,
      "año_actual": 193,
    },
    {
      "fecha": "Febrero",
      "año_anterior": 85,
      "Forecast": 188,
      "año_actual": 154,

    },
    {
      "fecha": "Marzo",
      "año_anterior": 192,
      "Forecast": 191,
      "año_actual": 77,

    },
    {
      "fecha": "Abril",
      "año_anterior": 34,
      "Forecast": 166,
      "año_actual": 39,

    },
    {
      "fecha": "Mayo",
      "año_anterior": 57,
      "Forecast": 64,
      "año_actual": 182,

    },
    {
      "fecha": "Junio",
      "año_anterior":199,
      "Forecast":143,
      "año_actual":168,

    },
    {
      "fecha": "Julio",
      "año_anterior":145,
      "Forecast": 84,
      "año_actual":152,

    },
    {
      "fecha": "Agosto",
      "año_anterior":145,
      "Forecast": 84,
      "año_actual":152,

    },
    {
      "fecha": "Septiembre",
     "año_anterior":145,
     "Forecast": 84,
     "año_actual":152,

    },
    {
      "fecha": "Octubre",
      "año_anterior":145,
      "Forecast": 84,
      "año_actual":152,

    },
    {
      "fecha": "Noviembre",
      "año_anterior":145,
      "Forecast": 84,
      "año_actual":152,

    },
    {
      "fecha": "Diciembre",
      "año_anterior":145,
      "Forecast": 84,
      "año_actual":152,
    }
  ]