import React from "react";
import { CustomTable } from "../../../../common/Components/CustomTable";


export const TableExcludeDay = () => {
  const headers = [
    { field: "fecha_excluida", headerName: "Fecha Excluida" },
  ];
  const actions = ["Editar", "Eliminar"];

  return (
    <CustomTable headers={headers} actions={actions} />
  );
};
