import { TextField } from "@mui/material";
import { ErrorMessage, useField } from "formik";
import React, { useRef, useEffect } from "react";
import { styleSheet } from "../styles/StylesCommon";

export const FormTextInput = (props) => {

  const [field, meta] = useField(props);
  const styles = styleSheet();
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <>
      <TextField
        ref={inputRef}  
        sx={{
          ...styles.modalInput, 
        
        }}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth={props.fullWidth}
        onChange={props.onChange}
        inputProps={ {
          ...props.inputProps,
          style:{
            ...props.inputProps?.style,
            // WebkitBoxShadow: "0 0 0 1000px white inset",
          }
        }}
        label={props.label}
        error={meta.touched && meta.error}
        helperText={<ErrorMessage name={props.name} component='span' />}
        {...field}
        {...props}
      />
    </>
  );
};
