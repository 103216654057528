export const dataExampleHeat = [
    {
      "id": "Enero",
      "data": [
        {
          "x": "Lunes",
          "y": 52216
        },
        {
          "x": "Martes",
          "y": -7884
        },
        {
          "x": "Miercoles",
          "y": -96816
        },
        {
          "x": "Jueves",
          "y": 98147
        },
        {
          "x": "Viernes",
          "y": -78692
        },
        {
          "x": "Sabado",
          "y": -17051
        },
        {
          "x": "Domingo",
          "y": 71867
        },
      ]
    },
    {
      "id": "Febrero",
      "data": [
        {
          "x": "Lunes",
          "y": 13164
        },
        {
          "x": "Martes",
          "y": 78678
        },
        {
          "x": "Miercoles",
          "y": 30191
        },
        {
          "x": "Jueves",
          "y": -33976
        },
        {
          "x": "Viernes",
          "y": -55565
        },
        {
          "x": "Sabado",
          "y": 68497
        },
        {
          "x": "Domingo",
          "y": 83543
        },

      ]
    },
    {
      "id": "Marzo",
      "data": [
        {
          "x": "Lunes",
          "y": -67200
        },
        {
          "x": "Martes",
          "y": 18612
        },
        {
          "x": "Miercoles",
          "y": 90728
        },
        {
          "x": "Jueves",
          "y": -9555
        },
        {
          "x": "Viernes",
          "y": -13262
        },
        {
          "x": "Sabado",
          "y": -59083
        },
        {
          "x": "Domingo",
          "y": 30779
        },
 
      ]
    },
    {
      "id": "Abril",
      "data": [
        {
          "x": "Lunes",
          "y": -21283
        },
        {
          "x": "Martes",
          "y": 84493
        },
        {
          "x": "Miercoles",
          "y": -35722
        },
        {
          "x": "Jueves",
          "y": 41370
        },
        {
          "x": "Viernes",
          "y": -58849
        },
        {
          "x": "Sabado",
          "y": -27790
        },
        {
          "x": "Domingo",
          "y": 70708
        },

      ]
    },
    {
      "id": "Mayo",
      "data": [
        {
          "x": "Lunes",
          "y": 2873
        },
        {
          "x": "Martes",
          "y": 50590
        },
        {
          "x": "Miercoles",
          "y": 80117
        },
        {
          "x": "Jueves",
          "y": -27435
        },
        {
          "x": "Viernes",
          "y": 21374
        },
        {
          "x": "Sabado",
          "y": -30729
        },
        {
          "x": "Domingo",
          "y": 17391
        },

      ]
    },
    {
      "id": "Junio",
      "data": [
        {
          "x": "Lunes",
          "y": 72253
        },
        {
          "x": "Martes",
          "y": 26919
        },
        {
          "x": "Miercoles",
          "y": 3816
        },
        {
          "x": "Jueves",
          "y": -5547
        },
        {
          "x": "Viernes",
          "y": 81458
        },
        {
          "x": "Sabado",
          "y": -54213
        },
        {
          "x": "Domingo",
          "y": -32552
        },

      ]
    },
    {
      "id": "Julio",
      "data": [
        {
          "x": "Lunes",
          "y": 15785
        },
        {
          "x": "Martes",
          "y": -12811
        },
        {
          "x": "Miercoles",
          "y": 81551
        },
        {
          "x": "Jueves",
          "y": -47534
        },
        {
          "x": "Viernes",
          "y": -82601
        },
        {
          "x": "Sabado",
          "y": 28243
        },
        {
          "x": "Domingo",
          "y": 54361
        },

      ]
    },
    {
      "id": "Agosto",
      "data": [
        {
          "x": "Lunes",
          "y": 55810
        },
        {
          "x": "Martes",
          "y": -44475
        },
        {
          "x": "Miercoles",
          "y": 28684
        },
        {
          "x": "Jueves",
          "y": 76255
        },
        {
          "x": "Viernes",
          "y": -4492
        },
        {
          "x": "Sabado",
          "y": -89825
        },
        {
          "x": "Domingo",
          "y": -28075
        },

      ]
    },
    {
      "id": "Septiembre",
      "data": [
        {
          "x": "Lunes",
          "y": 55810
        },
        {
          "x": "Martes",
          "y": -44475
        },
        {
          "x": "Miercoles",
          "y": 28684
        },
        {
          "x": "Jueves",
          "y": 76255
        },
        {
          "x": "Viernes",
          "y": -4492
        },
        {
          "x": "Sabado",
          "y": -89825
        },
        {
          "x": "Domingo",
          "y": -28075
        },

      ]
    },
    {
      "id": "Octubre",
      "data": [
        {
          "x": "Lunes",
          "y": 55810
        },
        {
          "x": "Martes",
          "y": -44475
        },
        {
          "x": "Miercoles",
          "y": 28684
        },
        {
          "x": "Jueves",
          "y": 76255
        },
        {
          "x": "Viernes",
          "y": -4492
        },
        {
          "x": "Sabado",
          "y": -89825
        },
        {
          "x": "Domingo",
          "y": -28075
        },

      ]
    },
    {
      "id": "Noviembre",
      "data": [
        {
          "x": "Lunes",
          "y": 55810
        },
        {
          "x": "Martes",
          "y": -44475
        },
        {
          "x": "Miercoles",
          "y": 28684
        },
        {
          "x": "Jueves",
          "y": 76255
        },
        {
          "x": "Viernes",
          "y": -4492
        },
        {
          "x": "Sabado",
          "y": -89825
        },
        {
          "x": "Domingo",
          "y": -28075
        },
      ]
    },
    {
      "id": "Diciembre",
      "data": [
        {
          "x": "Lunes",
          "y": 55810
        },
        {
          "x": "Martes",
          "y": -44475
        },
        {
          "x": "Miercoles",
          "y": 28684
        },
        {
          "x": "Jueves",
          "y": 76255
        },
        {
          "x": "Viernes",
          "y": -4492
        },
        {
          "x": "Sabado",
          "y": -89825
        },
        {
          "x": "Domingo",
          "y": -28075
        },
      ]
    }
    
  ]