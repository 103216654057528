
import authAxios from "../common/Auth/authAxios";

const CompanysService = {
  getAllCompanys: async () => {
    return await authAxios.get('/company/getCompanys');
  },
  getAllCompanysByUser: async () => {
    return await authAxios.get('/company/getCompanysByUser');
  },
  geBranchsCompanyUser: async (company_user) => {
    return await authAxios.post('/company/getBranchsCompanyUser', company_user);
  },
  getAllCompanysAndBranchs: async () => {
    return await authAxios.get('/company/getCompanysAndBranchs');
  },
  getBranchByCompany: async (company) => {
    return await authAxios.post('/company/getBranchs', company);
  },
  createCompany: async (company) => {
    return await authAxios.post('/company/createCompany', company);
  },
  updateCompany: async (company) => {
    return await authAxios.patch('/company/editCompany', company)
  },
  deleteCompany: async (company) => {
    return await authAxios.delete('/company/deleteCompany', {
      data: company
    })
  },
  createBranch: async (branch) => {
    return await authAxios.patch('/company/addBranch', branch)
  },
  updateBranch: async (branch) => {
    return await authAxios.patch('/company/editBranch', branch)
  },
  deleteBranch: async (branch) => {
    return await authAxios.delete('/company/deleteBranch', {
      data: branch
    })
  },
  validateName: async (searchString) => {
    return await authAxios.post('/company/validateName', searchString)
  }
}

export default CompanysService

