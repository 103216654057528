export default {
    formId: 'calendarForm',
    formField: {
        dispatch_day: {
            name: 'dispatch_day',
            label: 'Día de despacho',
            headerTable: 'Día de despacho',
            requiredErrorMsg: 'El día de despacho es requerido',
        },
        lead_time: {
            name: 'lead_time',
            label: 'Lead Time',
            headerTable: 'Lead Time',
            min: 1,
            minErrorMsg: 'El Lead Time debe ser mayor a 0',
            requiredErrorMsg: 'El Lead Time es requerido',
        },
        delivery_time: {
            name: 'delivery_time',
            label: 'Tiempo de entrega',
            headerTable: 'Tiempo de entrega',
            min: 0,
            minErrorMsg: 'El tiempo de entrega debe ser mayor o igual a 0',
        },
        replacement_day: {
            name: 'replacement_day',
            label: 'Día de Reposición',
            headerTable: 'Día de Reposición',
            requiredErrorMsg: 'El día es requerido',
        },
        id_bodega: {
            name: 'id_bodega',
            label: 'Bodega',
            headerTable: 'Bodega',
            requiredErrorMsg: 'La bodega es requerida',
        },
    }
}