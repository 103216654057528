import React, { useState } from 'react'
import { t } from 'i18next';
import { useFormikContext } from 'formik';
import { Box, Stack } from '@mui/material';
import { SelectConfigMethods } from '../../../../common/Components/SelectConfigMethods';
import companyFormModel from '../../../../model/company/companyFormModel';
import { ListCheckMethods } from '../../../../common/Components/ListCheckMethods';
import { TitleStep } from '../../../../common/Components/TitleStep';

export const DataProcessBranch = ({ checkOutModel, configsMethods, getConfigsMethods, isLoading }) => {
    const { values, setFieldValue } = useFormikContext();
    const [nameConfigEqual, setNameConfigEqual] = useState("");
    const { formField } = checkOutModel;
    const {
        model_logic_id_branch,
        model_logic_branch
    } = formField;

    return (
        <React.Fragment>
            <TitleStep title={t('steps.company.step6')} />
            <Stack py={5}>
                <Box paddingX={8}>
                    <SelectConfigMethods
                        nameConfigEqual={nameConfigEqual}
                        code={1}
                        fullWidth
                        configsMethods={configsMethods}
                        name={model_logic_id_branch.name}
                        label={model_logic_id_branch.label}
                        isLoading={isLoading}
                        name_models_check={model_logic_branch}
                    />
                </Box>
                <Box paddingX={8}>
                    <ListCheckMethods
                        nameConfigEqual={nameConfigEqual}
                        setNameConfigEqual={setNameConfigEqual}
                        name_elements={model_logic_branch}
                        name_permission={model_logic_id_branch.name}
                        configsMethods={configsMethods}
                        getConfigsMethods={getConfigsMethods}
                    />

                </Box>
            </Stack>
        </React.Fragment>
    )
}
