import checkoutFormModel from "./mix.model";
import * as Yup from "yup";
const { formField: {
    minimo, estilo_color, talla, tienda, sku, cod_art, delivery
} } = checkoutFormModel;

export const validateFormMix = Yup.object().shape({
    [minimo.name]: Yup.number().required(minimo.requiredErrorMsg),
    [estilo_color.name]: Yup.string().required(estilo_color.requiredErrorMsg),
    [talla.name]: Yup.string().required(talla.requiredErrorMsg),
    [tienda.name]: Yup.string().required(tienda.requiredErrorMsg),
    [sku.name]: Yup.string().required(sku.requiredErrorMsg),
    [cod_art.name]: Yup.string().required(cod_art.requiredErrorMsg),
    [delivery.name]: Yup.string().required(delivery.requiredErrorMsg)
});