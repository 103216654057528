import React, { useState, useContext, useEffect } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { Box, Skeleton, Typography } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { styleSheet } from "../styles/StylesCommon";
import { InputGeneralsIcon } from "../utils/Icons";
import { SelectCompany } from "../Components/SelectCompany";
import { SelectBranchsSidebar } from "../Components/SelectBranchsSidebar";
import SideBarContext from "../Contexts/SidebarContext";
import BranchsContext from "../Contexts/BranchContext";
import CompanyContext from "../Contexts/CompanysContext";
import isEmpty from "lodash/isEmpty";
import MediaContext from "../Contexts/MediaContext";
var store = require("store");

const Sidebar = () => {
  const payload = store.get("user");
  const { companyID, setCompanyID, branchID, branchs } =
    useContext(BranchsContext);
  const { isLoadingSidebar } = useContext(SideBarContext);
  const { systemRules } = useContext(CompanyContext);
  const [pathSelected, setPathSelected] = useState("");
  const { isMobile, isTablet, isLargeDesktop } = useContext(MediaContext);
  const [openAdmin, setAdmin] = useState({
    "0_0": true,
    "0_1": true,
    "0_2": true,
    "1_2": true,
  });
  const navigate = useNavigate();
  const styles = styleSheet(isMobile, isTablet, isLargeDesktop);
  const prefs = payload?.prefs;

  const handleClickAdmin = (index) => {
    switch (index) {
      case 0:
        setAdmin({ ...openAdmin, "0_0": !openAdmin["0_0"] });
        break;

      case 1:
        setAdmin({ ...openAdmin, "0_1": !openAdmin["0_1"] });
        break;

      case 2:
        setAdmin({ ...openAdmin, "0_2": !openAdmin["0_2"] });
        break;

      case 3:
        setAdmin({ ...openAdmin, "1_2": !openAdmin["1_2"] });

        break;

      default:
        break;
    }
  };

  const handleNavAdmin = (path, rules) => {
    navigate(path, {
      state: {
        rules: rules,
      },
    });
  };

  return (
    <Box
      style={{
        ...styles.sidebarContainer,
        width: isTablet ? "250px" : "100%",
        WebkitScrollSnapType: "none",
        scrollbarWidth: "none",
      }}
    >
      {isLoadingSidebar ? (
        <Box sx={{ width: isTablet ? "250px" : "100%" }}>
          <Skeleton height={300} />
          <Skeleton animation="wave" height={50} />
          <Skeleton animation="wave" height={50} />
          <Skeleton animation="wave" height={50} />
          <Skeleton animation="wave" height={50} />
          <Skeleton animation="wave" height={50} />
          <Skeleton animation="wave" height={50} />

          <Skeleton height={200} />
        </Box>
      ) : (
        <Box display={"flex"} flexDirection={"column"}>
          <Box
            sx={{
              ...styles.header,
              backgroundColor: "background.paper",
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                paddingInline: 1,
              }}
            >
              {prefs === 1 ? (
                <img
                  style={styles.logo}
                  src={require("../../assets/logo.png")}
                  alt="logo-light"
                />
              ) : (
                <img
                  style={styles.logo}
                  src={require("../../assets/logo_blanco.png")}
                  alt="logo-dark"
                />
              )}
            </Box>

            <Box>
              <SelectCompany
                companyID={companyID}
                setCompanyID={setCompanyID}
                isSidebar={true}
              />

              <SelectBranchsSidebar companyID={companyID} />
            </Box>
          </Box>

          {/* MENU */}

          <List
            sx={{
              backgroundColor: "background.paper",
            }}
          >
            {/* <ListItemButton onClick={() => handleNavAdmin("")}>
              <ListItemIcon>
                <InputGeneralsIcon />
              </ListItemIcon>
              <ListItemText primary={"Módulo Radar Control"} />
            </ListItemButton> */}

            {systemRules
              ?.filter((item) => item.active)
              .map((cluster, index) => (
                <div key={`${cluster.modulo_id}-${index}`}>
                  <ListItemButton
                    onClick={() =>
                      handleClickAdmin(parseInt(cluster.modulo_id.substring(2)))
                    }
                    key={`${cluster.modulo_id}-${index}`}
                  >
                    <ListItemIcon>
                      <InputGeneralsIcon />
                    </ListItemIcon>
                    <ListItemText primary={cluster.modulo_name} />
                    {openAdmin[cluster.modulo_id] ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItemButton>

                  {/* {elementRol?.map((element, index) => ( */}
                  <Collapse
                    in={openAdmin[cluster.modulo_id]}
                    timeout="auto"
                    unmountOnExit
                  >
                    {cluster.modulo_cluster
                      .filter((item) => item.active)
                      .map((element, index) => {
                        return cluster.active === true &&
                          element.active === true ? (
                          <List key={index} component="div" disablePadding>
                            <ListItemButton
                              key={index}
                              id={element.element_id}
                              sx={{
                                ...styles.activeSidebar,
                              }}
                              selected={
                                pathSelected === element.element_path
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                setPathSelected(element.element_path);
                                handleNavAdmin(element.element_path, element);
                              }}
                            >
                              <Typography
                                pr={1}
                                sx={{
                                  color:
                                    pathSelected === element.element_path
                                      ? "common.textSidebar"
                                      : "text.primary",
                                }}
                              >
                                -
                              </Typography>
                              <ListItemText
                                primary={element.element_name}
                                primaryTypographyProps={{
                                  color:
                                    pathSelected === element.element_path
                                      ? "common.textSidebar"
                                      : "text.primary",
                                }}
                              />
                            </ListItemButton>
                          </List>
                        ) : (
                          <List key={index} component="div" disablePadding>
                            <ListItemButton
                              id={element.element_id}
                              sx={{ pl: 4 }}
                              key={index}
                            >
                              <Typography pr={1}>-</Typography>
                              <ListItemText
                                primary={element.element_name}
                                primaryTypographyProps={{ color: "#BFBDBD" }}
                              />
                            </ListItemButton>
                          </List>
                        );
                      })}
                  </Collapse>
                </div>
              ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default Sidebar;
