import moment from 'moment'

export const formatDate = (date, reverse, onlyCurrentYear) => {
    if(!date) return null;
    const currentDate = new Date();
    const d = new Date(date);
    let month = (d.getUTCMonth() + 1);
    let day = d.getUTCDate();
    const year = d.getFullYear();
    const currentYear = currentDate.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    if (date.length < 10) return (onlyCurrentYear ? [currentYear, date].join("-") : date);
    if (onlyCurrentYear) return [year, month, day].join("-");
    return reverse ? [year, month, day].join("-") : [month, day].join("-");
}

export const formatDateTime = (date) => {
    if(!date) return null;
    return moment.utc(date).format('YYYY-MM-DD HH:mm:ss');
}

export const formatDateToUTC = (date) => {
    if(!date) return null;
    return moment.utc(date).format('YYYY-MM-DD');
}

export const getCurrentDateTime = (format = 'YYYY-MM-DDTHH:mm:ss') => {
    const date = moment.utc().format(format);
    const stillUtc = moment.utc(date).toDate();
    const currentLocal = moment(stillUtc).local().format(format);
    const now = moment.utc(currentLocal, format);
    return { now, currentLocal };
}