import { createStyles } from "@mui/material";
import imgBackLogin from "../../assets/01-Login.png";

export const styleSheet = (isMobile, isTablet, isLargeDesktop, state) => {
  return createStyles({
    container: {
      margin: " 0.5rem 2rem",
      position: "relative",
      top: 0,
      left: 0,
    },
    superset: {
      width: "100%",
      height: "700px",
      border: "0",
      overflow: "hidden",
    },
    box: {
      position: "absolute",
      top: isTablet ? "10%" : "15%",
      left: isTablet ? "0%" : "10%",
      bgcolor: "background.paper",
      boxShadow: 24,
      pb: 5,
      width: isTablet ? "100%" : "80%",
      minWidth: isTablet ? "100%" : "80%",
      maxWidth: "100%",
      borderRadius: 10,
    },
    boxDelete: {
      position: "absolute",
      width: isTablet ? "100%" : "50%",
      minWidth: isTablet ? "100%" : "50%",
      left: isTablet ? "0%" : "30%",
      top: isTablet ? "20%" : "30%",
      bgcolor: "background.paper",
      boxShadow: 24,
      pb: 5,
      maxWidth: "100%",
      borderRadius: 10,
    },
    boxModalDetailContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: 2,
    },
    modalInput: {
      "& label.Mui-focused": {
        color: "common.border",
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "common.border",
        },

        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "30px",
        height: "50px",
        padding: "0 10px",
        font: "normal normal normal 16px nunito-light, sans-serif",
        width: isTablet ? "100%" : "100%",
      },
    },
    boxModalChar: {
      position: "absolute",
      top: "5%",
      bgcolor: "background.paper",
      boxShadow: 24,
      pb: 5,
      width: "100%",
      maxWidth: "100%",
      borderRadius: 10,
    },
    titleModal: {
      color: "common.title",
      font: "nunito-light",
      fontSize: "36px",
      textAlign: "center",
    },
    titleH1: {
      color: "common.white",
      fontWeight: "bold",
      fontSize: "35px",
    },
    titleH2: {
      color: "primary.main",
      fontWeight: "500",
      fontSize: "30px",
    },
    titleH3: {
      color: "main.secondary",
      fontWeight: "400",
      fontSize: "22px",
    },
    containerArrow: {
      height: 60,
      background:
        "linear-gradient(90deg, rgba(7,64,107,1) 0%, rgba(7,64,107,1) 35%, rgba(0,184,255,1) 100%)",
      borderRadius: "100%",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      color: "white",
    },
    titleH4: {
      display: "flex",
      fontWeight: "300",
      fontSize: "18px",
    },
    backgroundTitle: {
      background:
        "linear-gradient(90deg, rgba(5,85,139,1)  0%, rgba(11,59,80,1) 35%, rgba(11,59,80,1) 100%)",
      borderRadius: "18px",
      padding: "1rem",
      alignItems: "center",
      justifyContent: "space-between",
      display: "flex",
      marginBottom: 5
    },
    logo: {
      width: "100%",
      cursor: "pointer",
      marginTop: "40px",
    },
    logoLogin: {
      width: "50%",
      cursor: "pointer",
    },
    header: {
      position: "sticky",
      top: "0",
      zIndex: "1",
      padding: "0.5rem 0.8rem",
    },
    sidebarContainer: {
      //backgroundColor: "background.paper",
      display: "flex",
      flexDirection: "column",
      height: isLargeDesktop ? "100vh" : "80vh",
      overflow: "auto",
      "::WebkitScrollbar": { display: "none" },
      // "::-webkit-scrollbar": { display: "none" },
    },
    footer: {
      padding: 3,
      backgroundColor: "rgba(5, 25, 34, 1)",

      position: "sticky",
    },
    input: {
      background: "#ffffff 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 6px #00000029",
      borderRadius: "30px",
      width: "95%",
      height: "50px",
      border: "1px solid",
      padding: "0 10px",
      font: "normal normal normal 16px nunito-light, sans-serif",
      color: "rgb(102, 102, 102, 1)",
    },
    stackSearch: {
      border: 0,
      margin: "0",
      padding: "0",
      borderBottom: "1px solid #000000",
      marginRight: 5,
    },
    inputSearch: {
      border: 0,
      backgroundColor: "transparent",
    },
    inputForm: {
      marginBottom: 10,
    },
    cardLogin: {
      backgroundColor: "#FFFFFF",
      maxWidth: isMobile ? "90%" : isTablet ? "70%" : "40%",
      height: "550px",
      borderRadius: "50px",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      display: "flex",
      boxShadow: "0px 3px 6px #00000029",
    },
    containerLogin: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      backgroundImage: `url(${imgBackLogin})`,
    },
    buttonLogin: {
      background:
        "linear-gradient(90deg, rgba(7,64,107,1) 0%, rgba(7,64,107,1) 35%, rgba(0,184,255,1) 100%)",
      border: 0,
      height: "50px",
      borderRadius: 30,
      width: "100%",
      fontWeight: "bold",
      marginTop: 5,
      color: "white",
      boxShadow: "0px 3px 6px #00000029",
    },
    button: {
      background:
        "linear-gradient(90deg, rgba(5,85,139,1)  0%, rgba(11,59,80,1) 35%, rgba(11,59,80,1) 100%)",
      border: 0,
      width: "95%",
      borderRadius: 30,
      height: 50,
      color: "white",
    },

    buttonForm: {
      background:
        "linear-gradient(90deg, rgba(5,85,139,1)  0%, rgba(11,59,80,1) 35%, rgba(11,59,80,1) 100%)",
      border: 0,
      width: "100%",
      borderRadius: 30,
      height: 50,
      padding: "0 10px",
      color: "white",
    },
    buttonFormDelete: {
      backgroundColor: "error.main",
      border: 0,
      width: "100%",
      borderRadius: 30,
      height: 50,
      padding: "0 10px",
      color: "white",
    },
    buttonTable: {
      color: "common.button",
      my: 1,
      ml: 4,
    },
    boxTopTable: {
      color: "common.button",
      my: 3,
    },
    titleWelcome: {
      background: "linear-gradient(90deg, rgba(7,64,107,1), rgba(0,184,255,1))",
      WebkitBackgroundClip: "text",
      color: "transparent",
      fontSize: " 30px",
      marginTop: "30px",
    },
    table: {
      backgroundColor: "common.table",
      height: "400px",

      "& .MuiDataGrid-columnHeaders": {
        backgroundColor: "#0A1C42",
        color: "#F5F5F5",
        fontSize: 14,
      },
    },
    listItem: {
      borderTopRightRadius: 30,
      borderBottomRightRadius: 30,
      "&.Mui-selected": {
        color: "white",
      },
      "&:hover": {
        backgroundColor: "#0A1C42",
        color: "white",
      },
    },
    baseAlert: {
      marginTop: "10px",
      borderRadius: 30,
      paddingTop: 0,
      paddingBottom: 0,
      alignItems: "center",
      justifyContent: "center",
    },
    uploadButtons: {
      justifyContent: "flex-start",
      alignItems: "center",
      marginLeft: 20,
      display: "flex",
    },
    labelInputFile: {
      cursor: "pointer",
      // padding: '6px 12px',
      display: "inline-block",
      paddingTop: 20,
    },
    spanInputFile: {
      position: "relative",
      overflow: "hidden",
      cursor: "pointer",
    },
    btnInputFile: {
      position: "absolute",
      top: 0,
      right: 0,
      minWidth: "100%",
      minHeight: "100%",
      fontSize: "100px",
      textAlign: "right",
      filter: "alpha(opacity=0)",
      opacity: 0,
      outline: "none",
      background: "white",
      cursor: "pointer",
      display: "block",
    },
    boxTableLight: {
      backgroundColor: "transparent",
      height: "350px",
      "--ag-header-background-color": "#0A1C42",
      "--ag-foreground-color": "black",
      "--ag-borders": "none",
      "--ag-card-shadow": "none",
      "--ag-popup-shadow": "none",
    },
    boxTableDark: {
      backgroundColor: "transparent",
      boxShadow: "none",
      height: "350px",
      "--ag-foreground-color": "white",
      "--ag-header-background-color": "#0A1C42",
      borderBottomLeftRadius: "30px",
      borderBottomRightRadius: "30px",
      "--ag-borders": "none",
    },

    gridSidebar: {
      scrollbarWidth: "none",
      "::WebkitScrollbar": { display: "none" },
      // "::-webkit-scrollbar": { display: "none" },
    },

    boxBody: {
      height: 50,
      display: "flex",
      justifyContent: "flex-end",
    },

    boxDetails: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      width: isTablet ? "100%" : "50%",
    },
    columnDetails: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "flex-end",
    },
    typographyDetails: {
      fontWeight: 600,
      color: "black",
      marginRight: 1,
    },

    tableLight: {
      backgroundColor: "transparent",
      "--ag-header-background-color": "#0A1C42",
      "--ag-foreground-color": "black",
      "--ag-picker-field-display": "red",
      "--ag-paging-panel-background-color": "#0A1C42",
      "--ag-secondary-foreground-color": "black",
      height: "350px",
    },
    tableDark: {
      backgroundColor: "transparent",
      "--ag-foreground-color": "white",
      "--ag-odd-row-background-color": "transparent",
      "--ag-secondary-foreground-color": "white",
      height: "350px",
    },

    boxChar: {
      width: "45%",
      height: "500px",
      backgroundColor: "#FFFFFF",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
      borderRadius: "10px",
      padding: "25px",
    },

    activeSidebar: {
      pl: 4,
      "&.Mui-selected": {
        backgroundColor: "common.colorSidebar",
        marginRight: "10px",
        borderTopRightRadius: "30px",
        borderBottomRightRadius: "30px",
      },
      "&.Mui-selected:hover": {
        backgroundColor: "common.colorSidebar",
      },
    },

    select: {
      "&.MuiOutlinedInput-root": {
        "&.Mui-focused": {
          borderColor: "common.colorSidebar",
        },
      },

      borderRadius: 20,
      height: "50px",
    },

    boxMain: {
      margin: " 0.5rem 2rem",
      display: "flex",
      flexDirection: "column",
    },

    boxWhite: {
      backgroundColor: "white",
      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.15)",
      borderRadius: "15px",
    },

    checkState: {
      "& .MuiSwitch-switchBase": {
        color: "common.title",
        margin: 1,
        padding: 0,
        transform: "translateX(6px)",
        "&.Mui-checked": {
          color: "common.title",
          transform: "translateX(22px)",
          "& .MuiSwitch-thumb:before": {
            backgroundColor: "common.title",
            borderRadius: "50%",
          },
          "& + .MuiSwitch-track": {
            opacity: 1,
            backgroundColor: "common.title",
          },
        },
      },
    },

    checkRadar: {
      "& .MuiSwitch-switchBase": {
        color: "white",
        margin: 1,
        padding: 0,
        transform: "translateX(6px)",
        "&.Mui-checked": {
          color: "white",
          transform: "translateX(22px)",
          "& .MuiSwitch-thumb:before": {
            backgroundColor: "white",
            borderRadius: "50%",
          },
          "& + .MuiSwitch-track": {
            color: "white",
            opacity: 1,
            backgroundColor: "white",
          },
        },
      },
    },
    switch: {
      "& .MuiSwitch-switchBase": {
        color: "common.title",
        margin: 1,
        padding: 0,
        width: 20,
        // transform: "translateX(3px)",
        "&.Mui-checked": {
          color: "common.title",
          // transform: "translateX(20px)",
          "& .MuiSwitch-thumb:before": {
            backgroundColor: "common.title",
            borderRadius: "50%",
          },
          "& + .MuiSwitch-track": {
            color: "common.title",
            opacity: 1,
            backgroundColor: "common.title",
          },
        },
      },
    },
    cardRadar: {
      height: 145,
      border: "1px solid black",
      backgroundColor: "F5F5F5",
      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.15)",
    },

    tableRadar: {
      width: "100%",
      height: 120,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      overflow: "hidden",
    }
  });
};
