import React from "react";
import {
  FormControl,
  MenuItem,
} from "@mui/material";
import { CustomSelect } from "../../../../common/Components/CustomSelect";
import { weekdays } from "../../../../helpers/weekdays";


export const SelectDispachtDay = ({ name, label }) => {

  return (
    <>
      <FormControl fullWidth>
        <CustomSelect
          sx={{
            borderRadius: 20,
            marginBottom: 1.4,
            width: '95%',
            height: 50,
            borderColor:
              "linear-gradient(90deg, rgba(7,64,107,1) 0%, rgba(7,64,107,1) 35%, rgba(0,184,255,1) 100%)",
          }}
          id={name || "dispatch_day"}
          label={label || "Dia de despacho"}
          labelId={label || "Dia de despacho"}
          name={name || "dispatch_day"}
        >
          {weekdays.map((day) => (
            <MenuItem key={day.day_id} value={day?.day_id}>{day?.day_name}</MenuItem>
          ))}
        </CustomSelect>
      </FormControl>
    </>
  );
};
