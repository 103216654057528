
import authAxios from "../common/Auth/authAxios";

const AuthService = {
    refreshToken: async (Refresh) => {
        return await authAxios.post('/auth/refreshToken', Refresh)
    },
    validateEmail: async (searchString) => {
        return await authAxios.post('/auth/validateName', searchString)
    }
}
//Cuando te da el 401 se pide otro token, 
export default AuthService
