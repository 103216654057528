import React, { useReducer } from "react";
import { themes } from "../../Types/Types";
import ThemeContext from "../Contexts/ThemeContext";
import { themeReducer } from "../Reducers/themeReducer";

var store = require("store");

const init = () => {
  const payload = store.get("user");
  let prefs = payload?.prefs;
  return {
    theme: prefs === 0 ? 0 : 1,
  };
};


export const ThemeProvider = ({ children }) => {

  const [state, dispatch] = useReducer(themeReducer, {}, init);

  const setLight = (data) => {
    const action = {
      type: themes.light,
      payload: data,
    };

    dispatch(action);
  };

  const setDark = (data) => {
    const action = {
      type: themes.dark,
      payload: data,
    };
    dispatch(action);
  };

  return (
    <ThemeContext.Provider value={{ state, setLight, setDark }}>
      {children}
    </ThemeContext.Provider>
  );
};
