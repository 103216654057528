import { Box, Container, Typography } from "@mui/material";
import React from "react";

export const NotFound = () => {
  return (
    <Box justifyContent={'center'} height={'90vh'} alignItems={'center'} display={'flex'} flexDirection={'column'}>
      <Typography sx={{fontSize:'65px'}}>404</Typography>
      <Typography sx={{fontSize:'65px'}}>Pagina en construccion</Typography>
    </Box>
  );
};
