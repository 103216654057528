import React, { useState } from 'react'
import { t } from 'i18next'
import { Box, Stack } from '@mui/material'
import { SelectConfigMethods } from '../../../../common/Components/SelectConfigMethods'
import companyFormModel from '../../../../model/company/companyFormModel'
import { ListCheckMethods } from '../../../../common/Components/ListCheckMethods'
import { TitleStep } from '../../../../common/Components/TitleStep'

export const DataProcessCompany = ({ checkOutModel, configsMethods, isLoading, getConfigsMethods }) => {
    const { formField } = checkOutModel;
    const {
        model_logic_id,
        model_logic_company
    } = formField;
    const [nameConfigEqual, setNameConfigEqual] = useState("");

    return (
        <React.Fragment>
            <TitleStep title={t('steps.company.step3')} />
            <Stack py={5}>
                <Box paddingX={8}>
                    <SelectConfigMethods
                        code={1}
                        fullWidth
                        configsMethods={configsMethods}
                        name={model_logic_id.name}
                        label={model_logic_id.label}
                        isLoading={isLoading}
                        name_models_check={model_logic_company}
                    />
                </Box>
                <Box paddingX={8}>
                    <ListCheckMethods
                        nameConfigEqual={nameConfigEqual}
                        setNameConfigEqual={setNameConfigEqual}
                        name_elements={model_logic_company}
                        name_permission={model_logic_id.name}
                        configsMethods={configsMethods}
                        getConfigsMethods={getConfigsMethods}
                    />
                </Box>
            </Stack>
        </React.Fragment>
    )
}
