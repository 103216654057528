import React from 'react'
import { FormTextInput } from '../../../../common/Components/FormTextInput'
import checkoutForm from '../../../../model/cronJobs/cronjobsFormModel'

export const FormConsole = () => {
    const { formField } = checkoutForm;
    const { message, hora, sucursal, name: parametrosConsole } = formField.parametrosConsole;
    return (
        <React.Fragment>
            <FormTextInput
                label={message.label}
                name={`${parametrosConsole}.${message.name}`}
                fullWidth={true}
            />
            <FormTextInput
                label={hora.label}
                name={`${parametrosConsole}.${hora.name}`}
                fullWidth={true}
            />
            <FormTextInput
                label={sucursal.label}
                name={`${parametrosConsole}.${sucursal.name}`}
                style={{ marginBottom: "30px" }}
                fullWidth={true}
            />
        </React.Fragment>
    )
}
