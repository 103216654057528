import numeral from 'numeral';

export const formatMoney = (money) => numeral(money).format('0.00a');

export const formatNumber = (money) => (money ? numeral(money).format('0,0.0') : '0');
export const formatN = (money) => (money ? numeral(money).format('0.00') : '');

export const unformat = (formattedValue) => numeral(formattedValue).value();

export const formatPrice = (currency, price) =>
    `${formatMoney(price?.toString())} ${currency}`;

export const roundTwoDecimals = (num) => Math.round(num * 100) / 100;

export const roundOneDecimal = (value, precision) => {
    if (value && value > 0) {
        const multiplier = Math.pow(10, precision || 0)
        return Math.round(value * multiplier) / multiplier
    }
    return 0

}

export function roundDecimal(num) {
    return Number.parseFloat(num).toFixed(1);
}

export const numberToFixed = (value, precision) => {
    return parseFloat(value).toFixed(precision)
}
export const numberRandom = (min, max) => {
    return Math.floor((Math.random() * (max - min + 1)) + min);
}
