import React, { useContext, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import CompanyContext from "../Contexts/CompanysContext";
import BranchsContext from "../Contexts/BranchContext";

var store = require("store");

export const SelectCompany = ({
  isSidebar = false,
}) => {
  const { companys, company, setCompany } = useContext(CompanyContext);
  const { branchID, setBranchID, companyID,
    setCompanyID, branchs, setBranchs } = useContext(BranchsContext);

  const handleChangeV2 = (event) => {
    setCompanyID(event.target.value);
  };

  useEffect(() => {
    const companySelected = companys.filter(company => company.company_id === companyID)[0];
    setCompany(companySelected);
    setBranchID([]);
    setBranchs(companySelected?.branchs);
  }, [companyID]);

  return (
    <>
      {!isSidebar ? (
        <Typography mb={1} mt={2} color={"primary.main"} variant={"h6"}>
          Seleccionar una empresa
        </Typography>
      ) : (
        <></>
      )}
      <FormControl
        fullWidth
        sx={{ mb: !isSidebar ? 5 : 2, mt: !isSidebar ? 0 : 3, }}
      >
        <InputLabel id="demo-simple-select-label">Empresas</InputLabel>
        <Select
          labelId="Empresas"
          id="companyID"
          name={"companyID"}
          defaultValue={companys[0]?.company_id}
          value={companyID}
          label="Empresas"
          onChange={handleChangeV2}
          sx={{

            textAlign: 'left',
            borderRadius: 20,
            height: "50px",
            borderColor:
              "linear-gradient(90deg, rgba(7,64,107,1) 0%, rgba(7,64,107,1) 35%, rgba(0,184,255,1) 100%)",
            // maxWidth: '14rem'
          }}
        >
          {companys.map((company, index) => (
            <MenuItem key={index} value={company?.company_id}>
              {company?.company_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
