import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { MediaContextProvider } from "./common/Contexts/MediaContext";
import { AppRouter } from "./common/Routes/AppRouter";
import { AuthProvider } from "./common/Auth/AuthProvider";
import { UserProviders } from "./common/Providers/UserProviders";
import { ThemeProvider } from "./common/Providers/ThemeProviders";
import { SidebarService } from "./services/SidebarService";
import { SideBarContextProvider } from "./common/Contexts/SidebarContext";
import { ShowToast } from "./common/Components/ToastNotify";
import './i18n';

var store = require("store");

const App = () => {

  const isMobile = useMediaQuery({ maxWidth: 375 });
  const isTablet = useMediaQuery({ maxWidth: 900 });
  const isDesktop = useMediaQuery({ maxWidth: 1024 });
  const isLargeDesktop = useMediaQuery({ maxWidth: 1400 });

  const [isLoadingSidebar, setIsLoadingSidebar] = useState(false);

  const getSidebar = async () => {
    setIsLoadingSidebar(true);
    try {
      const { data } = await SidebarService.getAllModules();
      store.set("sidebar", data);
      setIsLoadingSidebar(false);
    } catch (error) {
      console.log(error);
      setIsLoadingSidebar(false);
    }
  };

  useEffect(() => {
    getSidebar();
  }, []);

  return (
    <AuthProvider>
      <UserProviders>
        <SideBarContextProvider value={{ getSidebar, isLoadingSidebar, setIsLoadingSidebar }}>
          <ThemeProvider>
            <MediaContextProvider
              value={{ isMobile, isTablet, isDesktop, isLargeDesktop }}
            >
              <AppRouter />
              <ShowToast />
            </MediaContextProvider>
          </ThemeProvider>
        </SideBarContextProvider>
      </UserProviders>
    </AuthProvider>
  );
};

export default App;
