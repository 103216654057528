import React from 'react'
import { Box, Typography } from '@mui/material';
import { createStyles } from '@material-ui/styles';

export const HeadCardInfo = ({ cardLabel }) => {
    return (
        <Box
            sx={{
                ...styles.container,
                
            }}
        >
            <Typography
                sx={{
                    color: "common.white",
                    fontWeight: "bold",
                    fontSize: 16,
                }}
            >
                {cardLabel}
            </Typography>
        </Box>
    )
}

const styles = createStyles({
    container: {
        height: 30,
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        backgroundColor: "rgba(11,59,80,1)",
    }
})
