import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

export const TablesTitles = ({ title }) => {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "rgba(11,59,80,1)",
        height: 40,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          color: "common.white",
        }}
      >
        {title}
       
      </Typography>
    </Box>
  );
};
