import { IconButton } from '@mui/material'
import React from 'react'
import CloseIcon from "@mui/icons-material/Close";

export const CloseModal = ({ handleClose }) => {
    return (
        <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
                position: "absolute",
                right: 20,
                top: 20,
                color: (theme) => theme.palette.grey[500],
            }}
        ><CloseIcon />
        </IconButton>
    )
}
