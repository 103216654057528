import checkoutFormModel from "./calendarFormModel";
import * as Yup from "yup";
const { formField: {
    dispatch_day,
    lead_time,
    replacement_day,
    id_bodega,
    delivery_time
} } = checkoutFormModel;

export const validateFormCalendar = Yup.object({
    [dispatch_day.name]: Yup.number().required(dispatch_day.requiredErrorMsg),
    [lead_time.name]: Yup.number().required(lead_time.requiredErrorMsg).min(lead_time.min, lead_time.minErrorMsg),
    [delivery_time.name]: Yup.number().min(delivery_time.min, delivery_time.minErrorMsg),
    [replacement_day.name]: Yup.number().required(replacement_day.requiredErrorMsg),
    [id_bodega.name]: Yup.string().required(id_bodega.requiredErrorMsg),
});