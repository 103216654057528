import { Box, Button, Stack } from '@mui/material'
import React from 'react'
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { LoadingButton } from '@mui/lab';
import { createStyles } from '@material-ui/styles';


export const CStepButtons = (props) => {
    return (
        <Box sx={styles.arrowButtons}>
            {
                props.removeBtnBack ? null : (
                    <Stack direction={"row"} marginLeft={5}>
                        {props.renderPreviousButton()}
                    </Stack>
                )
            }
            <Box sx={{ flex: "1 1 auto" }} />
            {
                props.removeBtnNext ? null : (
                    <Stack direction={"row"} marginRight={5}>
                        {props.renderNextButton()}
                    </Stack>
                )
            }
        </Box>
    )
}


const styles = createStyles({
    arrowButtons: {
        display: "flex",
        flexDirection: "row",
        pt: 2,
        position: "absolute",
        top: 120,
        width: "100%",
    },
    containerArrow: {
        height: 60,
        background:
            "linear-gradient(90deg, rgba(7,64,107,1) 0%, rgba(7,64,107,1) 35%, rgba(0,184,255,1) 100%)",
        borderRadius: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        color: "white",
    },
    buttonSave: {
        background:
            "linear-gradient(90deg, rgba(7,64,107,1) 0%, rgba(7,64,107,1) 35%, rgba(0,184,255,1) 100%)",
        p: 0,
        height: 70,
        width: 70,
        boxShadow: "0px 3px 6px #00000029",
    }
})