export const dataTest = [
    {
        month: 1,
        data: [
            {
                id: "venta",
                venta: 100,
                year: 2016
            },
            {
                id: "venta",
                venta: 125,
                year: 2017
            },
            {
                id: "costo",
                costo: 150,
                year: 2017
            },
            {
                id: "margen-2018-1",
                margen: 200,
                year: 2018
            }
        ]
    },
    {
        month: 2,
        data: [
            {
                id: "venta-2016-2",
                venta: 100,
                year: 2016
            },
            {
                id: "costo-2017-2",
                costo: 150,
                year: 2017
            },
            {
                id: "margen-2018-2",
                margen: 200,
                year: 2018
            }
        ]
    },
    {
        month: 3,
        data: [
            {
                id: "venta-2016-3",
                venta: 100,
                year: 2016
            },
            {
                id: "costo-2017-3",
                costo: 150,
                year: 2017
            },
            {
                id: "margen-2018-3",
                margen: 200,
                year: 2018
            }
        ]
    },
    {
        month: 4,
        data: [
            {
                id: "venta-2016-3",
                venta: 100,
                year: 2016
            },
            {
                id: "costo-2017-3",
                costo: 150,
                year: 2017
            },
            {
                id: "margen-2018-3",
                margen: 200,
                year: 2018
            }
        ]
    },
    {
        month: 5,
        data: [
            {
                id: "venta-2016-3",
                venta: 100,
                year: 2016
            },
            {
                id: "costo-2017-3",
                costo: 150,
                year: 2017
            },
            {
                id: "margen-2018-3",
                margen: 200,
                year: 2018
            }
        ]
    },
    {
        month: 6,
        data: [
            {
                id: "venta-2016-3",
                venta: 100,
                year: 2016
            },
            {
                id: "costo-2017-3",
                costo: 150,
                year: 2017
            },
            {
                id: "margen-2018-3",
                margen: 200,
                year: 2018
            }
        ]
    },
    {
        month: 7,
        data: [
            {
                id: "venta-2016-3",
                venta: 100,
                year: 2016
            },
            {
                id: "costo-2017-3",
                costo: 150,
                year: 2017
            },
            {
                id: "margen-2018-3",
                margen: 200,
                year: 2018
            }
        ]
    },
    {
        month: 8,
        data: [
            {
                id: "venta-2016-3",
                venta: 100,
                year: 2016
            },
            {
                id: "costo-2017-3",
                costo: 150,
                year: 2017
            },
            {
                id: "margen-2018-3",
                margen: 200,
                year: 2018
            }
        ]
    },
    {
        month: 9,
        data: [
            {
                id: "venta-2016-3",
                venta: 100,
                year: 2016
            },
            {
                id: "costo-2017-3",
                costo: 150,
                year: 2017
            },
            {
                id: "margen-2018-3",
                margen: 200,
                year: 2018
            }
        ]
    },
    {
        month: 10,
        data: [
            {
                id: "venta-2016-3",
                venta: 100,
                year: 2016
            },
            {
                id: "costo-2017-3",
                costo: 150,
                year: 2017
            },
            {
                id: "margen-2018-3",
                margen: 200,
                year: 2018
            }
        ]
    },
    {
        month: 11,
        data: [
            {
                id: "venta-2016-3",
                venta: 100,
                year: 2016
            },
            {
                id: "costo-2017-3",
                costo: 150,
                year: 2017
            },
            {
                id: "margen-2018-3",
                margen: 200,
                year: 2018
            }
        ]
    },
    {
        month: 12,
        data: [
            {
                id: "venta-2016-3",
                venta: 100,
                year: 2016
            },
            {
                id: "costo-2017-3",
                costo: 150,
                year: 2017
            },
            {
                id: "margen-2018-3",
                margen: 200,
                year: 2018
            }
        ]
    },

];