import { Box } from "@mui/material";
import Title from "../../../common/Components/Title";
import { ButtonsFileCSV } from "../../../common/Components/ButtonsFileCSV";
import { useContext, useState, useEffect } from 'react'
import BranchsContext from '../../../common/Contexts/BranchContext';
import ModulesFormContext from "../../../common/Contexts/ModulesFormContext";
import { useForm } from "../../../hooks/useForm";
import { ModulesProvider } from "../../../common/Providers/ModulesProvider";
import SKUForCDService from "../../../services/SKUService";
import { LayoutTable } from "../../../common/Components/LayoutTable";
import { CustomModalDelete } from "../../../common/Components/CustomModalDelete";
import { TableSKUFCD } from "./components/TableSKU";
import { t } from 'i18next'
import { SKUForm } from "./SKUForm";
import { CToast } from "../../../common/Components/ToastNotify";
import { DetailSKU } from "./components/DetailSKU";
import { ModalOpenEnum } from "../../../common/utils/ModalOpenEnum";

const SKU = () => {

    const { branchID } = useContext(BranchsContext);
    const { overallStyle } = useContext(ModulesFormContext);
    //LOGIC FORM
    const {
        isOpen,
        rowData,
        toggleModal,
        dataTable,
        setDataTable,
        isTableLoading,
        setIsTableLoading
    } = useForm();
    const [isLoadingDelet, setIsLoadingDelet] = useState(false)

    const getDataTable = async () => {
        setIsTableLoading(true);
        const req = {
            company_id: branchID,
        };
        try {
            const { data } = await SKUForCDService.getSKUForCDByBranch(req);
            setDataTable(data);
        } catch ({ response }) {
            console.log(response);
        } finally {
            setIsTableLoading(false);
        }
    }
    const handleDeleteSKU = async () => {
        setIsLoadingDelet(true);
        const req = {
            id_sku: rowData.id_sku,
            company_id: branchID,
        }
        try {
            const { data } = await SKUForCDService.deleteSKUForCD(req);
            await getDataTable();
            CToast(t('messages.success_delete'), 'success', true);
        } catch ({ response }) {
            console.log(response);
            CToast(t('messages.error_delete'), 'error', true);
        } finally {
            setIsLoadingDelet(false);
        }
    }

    useEffect(() => {
        branchID.length > 0 && getDataTable();
    }, [branchID]);


    return (
        <ModulesProvider value={{
            getDataTable,
            handleDelete: handleDeleteSKU,
            isOpen,
            rowData,
            toggleModal,
            dataTable,
            setDataTable, isTableLoading,
        }}>
            <Box sx={overallStyle?.container}>
                <Title name={t('titles.SKU_for_CD')} />
                <ButtonsFileCSV />
                <LayoutTable labelAction={'Agregar SKU'}>
                    {isOpen[ModalOpenEnum.EDIT] && <SKUForm />}
                    {isOpen[ModalOpenEnum.DETAIL] && <DetailSKU />}
                    {isOpen[ModalOpenEnum.DELETE] && (
                        <CustomModalDelete
                            descriptionLabel={rowData?.sku ? `SKU ${rowData.sku}` : ''}
                            isLoading={isLoadingDelet}
                            moduleLabel={'SKU_for_CD'}
                            hiddenModule={true}
                        />)
                    }
                    <TableSKUFCD />
                </LayoutTable>
            </Box>
        </ModulesProvider>
    )
}


export default SKU