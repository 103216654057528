export const groupByYears = (array) => array.reduce((acc, item) => {
    const key = item.year;
    if (!acc[key]) acc[key] = [];
    acc[key].push(item);
    return acc;
}, {});

export const groupByMonths = (array) => array.reduce((acc, item) => {
    const key = item.month;
    if (!acc[key]) acc[key] = [];
    acc[key].push(item);
    return acc;
}, {});


export const orderByMonths = (array) => array.sort((a, b) => a?.month > b?.month ? 1 : -1);

export const orderByYears = (array) => array.sort((a, b) => a?.year > b?.year ? 1 : -1);

export const orderByYearsAndMonths = (array) => array.sort((a, b) => a?.year > b?.year ? 1 : -1).sort((a, b) => a?.month > b?.month ? 1 : -1);