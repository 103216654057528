import { useState, useRef, useEffect } from "react";

export const useStep = (steps) => {

    const [activeStep, setActiveStep] = useState(0);
    const handleSetActiveStep = (value) => setActiveStep(value)
    const handleResetStep = () => handleSetActiveStep(0);
    const isLastStep = (activeStep === steps.length - 1) ? true : false;
    const handleNextStep = () => handleSetActiveStep((prevActiveStep) => prevActiveStep + 1);

    const isMounted = useRef(true)

    useEffect(() => {
        return () => isMounted.current = false;
    }, []);

    useEffect(() => {
        // console.log('activeStep', activeStep)
    }, [activeStep])

    return {
        activeStep,
        handleSetActiveStep,
        handleResetStep,
        isLastStep,
        handleNextStep,
    }
}