import authAxios from "../common/Auth/authAxios";

const WarehouseService = {
    getWarehouseByBranch: async (branch) => {
        return await authAxios.post('/inputs-generales/getBodegas', branch)
    },
    createWarehouse: async (warehouse) => {
        return await authAxios.post('/inputs-generales/addBodega', warehouse)
    },
    updateWarehouse: async (warehouse) => {
        return await authAxios.patch('/inputs-generales/editBodegas', warehouse)
    },
    deleteWarehouse: async (req) => {
        return await authAxios.delete('/inputs-generales/deleteBodegas',
            { data: req }
        )
    },
}

export default WarehouseService