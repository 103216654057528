import authAxios from "../common/Auth/authAxios";

const SuggestedParametersService = {
    getSuggestedParametersByBranch: async (idCompany) => {
        return await authAxios.post('/inputs-generales/getParametrosSugerido', idCompany)
    },
    createSuggestedParameters: async (parameters) => {
        return await authAxios.post('/inputs-generales/addParametrosSugerido', parameters)
    },
    updateSuggestedParameters: async (parameters) => {
        return await authAxios.patch('/inputs-generales/editParametrosSugerido', parameters)
    },
    deleteSuggestedParameters: async (parameters) => {
        return await authAxios.delete('/inputs-generales/deleteParametrosSugerido',
            { data: parameters }
        )
    },
}

export default SuggestedParametersService