import React, { useContext, useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { CustomSelect } from "../../../../common/Components/CustomSelect";


export const SelectAnalysisWeek = ({ name, label, disabled = false }) => {

  const references_week = [
    {
      references_week_id: 1,
      references_week_value: "8",
    },
    {
      references_week_id: 2,
      references_week_value: "12",
    },
    {
      references_week_id: 3,
      references_week_value: "24",
    },
  ];

  return (

    <FormControl fullWidth sx={{ mt: 2 }} disabled={disabled}>
      <CustomSelect
        sx={{
          borderRadius: 20,
          height: 50,
          width: '95%',
          marginBottom: 1.4,
          borderColor:
            "linear-gradient(90deg, rgba(7,64,107,1) 0%, rgba(7,64,107,1) 35%, rgba(0,184,255,1) 100%)",
        }}
        id={name || "reference_days"}
        label={label || "Semana"}
        labelId={label || "Semana"}
        name={name || "reference_days"}
      >
        {references_week.map((day, index) => (
          <MenuItem key={day.references_week_id} value={day?.references_week_value}>{day?.references_week_value}</MenuItem>
        ))}
      </CustomSelect>
    </FormControl>

  );
};
