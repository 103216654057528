import { Box, Stack } from '@mui/material'
import { useFormikContext } from 'formik'
import { t } from 'i18next'
import React, { useState, useContext } from 'react'
import { FormTextInput } from '../../../../common/Components/FormTextInput'
import { CToast } from '../../../../common/Components/ToastNotify'
import companyFormModel from '../../../../model/company/companyFormModel'
import CompanysService from '../../../../services/CompanysService'
import ModulesFormContext from '../../../../common/Contexts/ModulesFormContext'
import { TitleStep } from '../../../../common/Components/TitleStep'

export const DataCompany = () => {
    const { errors, validateForm, getFieldMeta, setFieldError, values, setStatus, setSubmitting } = useFormikContext();
    const { rowData: company } = useContext(ModulesFormContext);
    const [isLoadingValidate, setIsLoadingValidate] = useState(false);
    // const [errorName, setErrorName] = useState()
    const { formField } = companyFormModel;
    const {
        company_name,
        description,
        company_phone,
        contact_name,
        contact_phone,
        contact_email
    } = formField;

    const handleValidateName = async (searchString) => {
        if (company?.company_name !== values.company_name && !getFieldMeta(company_name.name).error) {
            setIsLoadingValidate(true);
            const req = { searchString }
            try {
                const res = (await CompanysService.validateName(req)).data;
                if (res.existe) {
                    setFieldError(company_name.name, t('messages.error_company_isExist'))
                    setStatus({ isValid: false })
                }
                setIsLoadingValidate(false)
            } catch ({ response }) {
                setIsLoadingValidate(false)
                // CToast(t('messages.error_validate'), 'error', true);
            }
        }
        setSubmitting(false)
    }
    return (
        <React.Fragment>
            <TitleStep title={t('steps.company.step1')} />
            <Stack
                py={5}
                direction={"row"}
                justifyContent={"space-around"}
                paddingX={8}
            >
                <Box width={"50%"}>
                    <FormTextInput
                        label={company_name.label}
                        name={company_name.name}
                        onBlur={({ target }) => target.value != '' ? handleValidateName(target.value) : validateForm()}
                        onBeforeInput={() => setSubmitting(true)}
                        autoFocus={true}
                        autoComplete='off'
                        style={{ width: "95%" }}
                        fullWidth={true}
                        helperText={
                            isLoadingValidate ? 'Validando...'
                                : (
                                    <span style={{ color: "red", fontSize: 12, textAlign: "left", }}>
                                        {errors[company_name.name]}
                                    </span>
                                )
                        }
                    />

                    <FormTextInput
                        label={description.label}
                        name={description.name}
                        style={{ width: "95%" }}
                        fullWidth={true}
                        disabled={isLoadingValidate || errors[company_name.name] ? true : false}
                    />
                    <FormTextInput
                        label={company_phone.label}
                        name={company_phone.name}
                        style={{ width: "95%" }}
                        fullWidth={true}
                        disabled={isLoadingValidate || errors[company_name.name] ? true : false}
                    />
                </Box>

                <Box width={"50%"}>
                    <FormTextInput
                        label={contact_name.label}
                        name={contact_name.name}
                        fullWidth={true}
                        disabled={isLoadingValidate || errors[company_name.name] ? true : false}
                    />
                    <FormTextInput
                        label={contact_phone.label}
                        name={contact_phone.name}
                        fullWidth={true}
                        disabled={isLoadingValidate || errors[company_name.name] ? true : false}
                    />
                    <FormTextInput
                        label={contact_email.label}
                        name={contact_email.name}
                        fullWidth={true}
                        disabled={isLoadingValidate || errors[company_name.name] ? true : false}
                    />
                </Box>
            </Stack>

            <Stack
                direction={"row"}
                justifyContent={"space-around"}
                paddingX={8}
            ></Stack>
        </React.Fragment>
    )
}
