import { Box, Stack } from '@mui/material';
import { Formik } from 'formik';
import React, { useState, useContext } from 'react'
import { t } from 'i18next';
import { ButtonSave } from '../../../common/Components/ButtonSave';
import { ContainerForm } from '../../../common/Components/ContainerForm';
import CustomModal from '../../../common/Components/CustomModal';
import { FormTextInput } from '../../../common/Components/FormTextInput';
import BranchsContext from '../../../common/Contexts/BranchContext';
import MediaContext from '../../../common/Contexts/MediaContext';
import ModulesFormContext from '../../../common/Contexts/ModulesFormContext';
import checkoutFormModel from '../../../model/excludeDays/excludeDay.model';
import { validateFormExcludedDate } from '../../../model/excludeDays/excludeDay.schema';
import { InputCalendar } from './components/InputCalendar';
import { CToast } from '../../../common/Components/ToastNotify';
import ExludeDayService from '../../../services/ExcludeDayService';
import { formatDate } from '../../../helpers/formatDate';
import { ModalOpenEnum } from '../../../common/utils/ModalOpenEnum';

export const ExcludeDateForm = () => {
    const { isMobile, isTablet } = useContext(MediaContext);
    const { branchID } = useContext(BranchsContext);
    const { isOpen, toggleModal, rowData: excludeDay, getDataTable } = useContext(ModulesFormContext);
    const [isLoading, setIsLoading] = useState(false)
    const { formId, formField: { fecha_excluida, date_excluded, isOnlyYear } } = checkoutFormModel;

    const initialValue = {
        isOnlyCurrentYear: (excludeDay?.fecha_excluida.length < 10 || !excludeDay) ? false : true,
        fecha_excluida: excludeDay?.fecha_excluida || "",
        date_excluded: excludeDay?.fecha_excluida || "",
        isEditing: excludeDay ? true : false,
        id_fechaExcluida: excludeDay?.id_fechaExcluida,
    };
    const onSave = async (value) => {
        setIsLoading(true);
        const req = {
            company_id: branchID,
            fecha_excluida: formatDate(value.fecha_excluida, false, value.isOnlyCurrentYear),
        }
        value.isEditing && (Object.assign(req, { id_fechaExcluida: value.id_fechaExcluida }));
        try {
            const { data } = value.isEditing
                ? await ExludeDayService.updateExcludeDay(req)
                : await ExludeDayService.createExcludeDay(req);
            await getDataTable();
            toggleModal(ModalOpenEnum.EDIT);
            CToast(`Fecha excluida ${value.isEditing ? 'editada' : 'creada'} correctamente`, "success", true);
        } catch ({ response }) {
            if (response?.data?.error) return CToast(response.data.error, "error", true);
            return CToast(`Error al ${value.isEditing ? 'editar' : 'crear'} la fecha excluida`, "error", true);
        } finally {
            setIsLoading(false);
        }
    }
    return (
        <CustomModal
            isOpen={isOpen[ModalOpenEnum.EDIT]}
            toggleModal={() => toggleModal(ModalOpenEnum.EDIT)}
            moduleLabel={'exclude_date'}
            actionLabel={excludeDay ? "edit" : "create"}
            descriptionLabel={excludeDay ? `${excludeDay.fecha_excluida}` : ''}
        >
            <Formik
                initialValues={initialValue}
                validationSchema={validateFormExcludedDate}
                onSubmit={(values, actions) => {
                    onSave(values);
                    actions.setSubmitting(false);
                }}
            >
                {({ handleSubmit, values, handleChange }) => (
                    <ContainerForm formID={formId}>
                        <Stack
                            direction={"column"}
                            justifyContent={"center"}
                            alignItems="center"
                            width={"100%"}
                        >
                            <Stack
                                py={3}
                                direction={"row"}
                                justifyContent={"space-between"}
                                alignItems="center"
                                width={isTablet ? "100%" : '50%'}
                                spacing={2}
                            >
                                <Box display="flex" alignItems="center" justifyContent="center">
                                    <label>{t('labels_modals.only_year')}</label>
                                    <input
                                        defaultChecked={values.isOnlyCurrentYear}
                                        name={isOnlyYear.name}
                                        onChange={handleChange}
                                        type={"checkbox"}
                                        style={{ width: 20, height: 20, borderRadius: 8 }}
                                    />
                                </Box>
                                <Box>
                                    <InputCalendar
                                        fecha_excluida={values.fecha_excluida}
                                        handleChange={handleChange}
                                        nameFormInput={date_excluded.name}
                                        nameInput={fecha_excluida.name}
                                        isOnlyCurrentYear={values.isOnlyCurrentYear}
                                    />
                                </Box>
                            </Stack>
                            <Box width={isTablet ? "100%" : '50%'}>
                                <ButtonSave
                                    onClick={handleSubmit}
                                    isLoading={isLoading}
                                    edit={excludeDay ? true : false}
                                />
                            </Box>
                        </Stack>
                    </ContainerForm>
                )}
            </Formik>
        </CustomModal>
    )
}
