import React, { useReducer, useState } from "react";
import AuthService from "../../services/AuthService";
import { types } from "../../Types/Types";
import { authReducer } from "../Auth/authReducer";
import AuthContext from "../Contexts/AuthContext";
import isEmpty from 'lodash/isEmpty'
import { getTokenTime } from "./getTokenTime";
var store = require("store");

const init = () => {
  const user = store.get("auth");
  return {
    logged: !!user,
    user,
  };
};

export const AuthProvider = ({ children }) => {
  const [auth, dispatch] = useReducer(authReducer, {}, init);
  const authStore = store.get("auth");
  const authUser = store.get("user");

  const [tokenTime, setTokenTime] = useState(auth?.user?.tokenTime);//tiempo de expiracion del token

  //console.log('VALIDAR authStore', isEmpty(authStore), authUser, auth?.user?.tokenTime)

  const login = (data) => {
    const action = {
      type: types.login,
      payload: data,
    };

    console.log('DATA LOGIN', data);
    store.set("auth", data);
    dispatch(action);
    const tokenTime = getTokenTime(data.tokenTime);
    setTokenTime(data.tokenTime);
  };

  const logout = () => {
    store.remove("auth");
    store.remove("company");
    store.remove("sidebar");
    store.remove("user");
    setTokenTime();
    const action = { type: types.logout };
    dispatch(action);
  };

  const refreshToken = async (data) => {

    const refreshId = data?.refresh_id;
    const emailUser = data?.email;
    const req = {
      email: emailUser,
      refresh_id: refreshId,
    };
    await AuthService.refreshToken(req)
      .then(({ data }) => {
        store.set("auth", data);
      })
      .catch(({ response }) => {
        console.log(response);
        // logout();
      })
      .finally(() => { });
  }


  // useEffect(() => {
  //   console.log('REFRESCAR TOKEN', tokenTime);
  //   const interval = (tokenTime > 0) && setInterval(() => {
  //     refreshToken();
  //   }, tokenTime);
  //   return () => isEmpty(authUser) && clearInterval(interval);
  // }, [tokenTime]);
  // const refreshToken = () => {

  // const refreshId = payload?.refresh_id;
  // const emailUser = payload?.user.email;

  // const req = {
  //   email: emailUser,
  //   refresh_id: refreshId,
  // };

  // let tokenExpirationsTime = payload?.tokenTime;
  // let currentTime = new Date().getTime();

  // if (currentTime > tokenExpirationsTime) {
  //     try {
  //         const data = await AuthService.refreshToken(req);
  //         console.log(data);
  //       } catch (error) {
  //         console.log(error);
  //       }

  //       console.log("Token expirado");

  // } else {
  //     console.log("Token no expirado");
  //     request.headers["x-token"] = payload?.accessToken;
  //     return request;
  // }
  //   }

  return (
    <AuthContext.Provider value={{ auth, login, logout, refreshToken, tokenTime, setTokenTime }}>
      {children}
    </AuthContext.Provider>
  );
};
