import authAxios from "../common/Auth/authAxios";

const ElementLogicService = {

    getModules: async () => {
        return await authAxios.get('element-logic/modulos')
    },

    getActions: async () => {
        return await authAxios.get('element-logic/actions')
    },

    postModules: async ({body}) => {
        return await authAxios.post('element-logic/modulos', body)
    },

    patchModules: async (params) => {
        const query = params ? `?${params}` : "";
        return await authAxios.patch(`element-logic/modulos${query}`)
    },

    deleteModules: async (params) => {
        const query = params ? `?${params}` : "";
        return await authAxios.delete(`element-logic/modulos${query}`)
    },

    getElements: async () => {
        return await authAxios.get('element-logic/elements')
    }, 

    postElements: async ({body}) => {
        return await authAxios.post('element-logic/elements', body)
    },

    patchElements: async (params) => {
        const query = params ? `?${params}` : "";
        return await authAxios.patch(`element-logic/elements${query}`)
    },

    deleteElements: async (params) => {
        const query = params ? `?${params}` : "";
        return await authAxios.delete(`element-logic/elements${query}`)
    },
}

export default ElementLogicService