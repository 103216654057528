import { Alert, Box } from '@mui/material';
import React, { useContext, useState } from 'react'
import { t } from 'i18next';
import { CheckListGroup } from '../../../../common/Components/CheckListGroup';
import { CreateConfig } from '../../../../common/Components/CreateConfig';
import ModulesFormContext from '../../../../common/Contexts/ModulesFormContext';
import checkoutFormModel from '../../../../model/roles/roles.model';
import RolService from '../../../../services/RolesService';
import { Formik, useFormikContext } from 'formik';
import { validateCreatePermission } from '../../../../model/company/company.schema';
import { CToast } from '../../../../common/Components/ToastNotify';
import { ModalOpenEnum } from '../../../../common/utils/ModalOpenEnum';

export const ListRoles = ({ getRolesAccess, rolesAccess, name_elements, name_permission, nameConfigEqual, setNameConfigEqual }) => {
    const {
        toggleModal,
        rowData: rol,
        getDataTable,
        overallStyle } = useContext(ModulesFormContext);
    const { values } = useFormikContext();
    const { formField: {
        rol_name }
    } = checkoutFormModel;
    const [existe, setExiste] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const initialValues = {
        rol_name: rol?.rol_name || "",
    };


    const onSave = async (value) => {
        setIsLoading(true)
        const req = {
            rol_name: value.rol_name,
            rol_cluster: values.rol_cluster,
        };
        if (values.rol_cluster.filter(item => item.active).length === 0) {
            CToast('Debe seleccionar al menos un módulo con sus roles', 'error')
            setIsLoading(false)
            throw ('Debe seleccionar al menos un módulo con sus roles');
        }
        values.isEditing && (Object.assign(req, { id_rol: values.id_rol }));
        try {
            const { data } = values.isEditing
                ? await RolService.updateRol(req)
                : await RolService.createRol(req);
            await getDataTable();
            toggleModal(ModalOpenEnum.EDIT);
            CToast(`Rol ${values.isEditing ? 'editado' : 'creado'}`, "success", true);
        } catch ({ response }) {
            if (response?.data?.error) return (CToast(response?.data?.error, "error", true))
            return CToast(`Error al ${values.isEditing ? 'editar' : 'crear'} el Rol`, "error", true)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <React.Fragment>
            {(values[name_permission] === "" && existe) && (
                <Box>
                    <Alert
                        severity="info"
                        style={{
                            ...overallStyle.baseAlert,
                        }}
                    >
                        {t('messages.info_exist_config')} {nameConfigEqual}
                    </Alert>
                </Box>
            )}
            <Formik
                initialValues={initialValues}
                validationSchema={validateCreatePermission}
                onSubmit={(values, actions) => {
                    onSave(values).then(() => (!isLoading && actions.resetForm()))
                }}>
                {({ handleSubmit, values: currentValue }) => {
                    return (
                        <CreateConfig
                            name={rol_name.name}
                            label={rol_name.label}
                            handleSubmit={handleSubmit}
                            isLoading={isLoading}
                            disabled={currentValue.rol_name === ""}
                            isEditing={values.isEditing}
                        />
                    );
                }}
            </Formik>
            <CheckListGroup
                name_permission={name_permission}
                name_elements={name_elements}
                compareFirstArray={rolesAccess}
                existe={existe}
                setExiste={setExiste}
                setNameConfigEqual={setNameConfigEqual}
                hasFilter={false}
            />
        </React.Fragment >
    )
}
