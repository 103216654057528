import { Box } from "@mui/material";
import React, { useContext } from "react";
import { CDetails } from "../../../../common/Components/CDetails";
import CustomModal from "../../../../common/Components/CustomModal";
import ModulesFormContext from "../../../../common/Contexts/ModulesFormContext";
import { ModalOpenEnum } from "../../../../common/utils/ModalOpenEnum";
import checkoutForm from "../../../../model/warehouse/warehouseFormModel";

export const DetailsWarehouse = () => {
  const { rowData: warehouse, isOpen, toggleModal } = useContext(ModulesFormContext);
  const { formField: {
    cod_bodega,
    bodega,
    canal,
    region,
    comuna,
    ciudad,
    direccion,
    latitude,
    longitud
  } } = checkoutForm;
  return (
    <CustomModal
      isOpen={isOpen[ModalOpenEnum.DETAIL]}
      toggleModal={() => toggleModal(ModalOpenEnum.DETAIL)}
      moduleLabel={'warehouse'}
      hiddenModuleLabel={true}
      actionLabel='info'
      descriptionLabel={`de ${warehouse.bodega}`}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 2,
        }}
      >
        <CDetails label={cod_bodega.headerTable} value={warehouse.cod_bodega} />
        <CDetails label={bodega.headerTable} value={warehouse.bodega} />
        <CDetails label={canal.headerTable} value={warehouse.canal} />
        <CDetails label={region.headerTable} value={warehouse.region} />
        <CDetails label={comuna.headerTable} value={warehouse.comuna} />
        <CDetails label={ciudad.headerTable} value={warehouse.ciudad} />
        <CDetails label={direccion.headerTable} value={warehouse.direccion} />
        <CDetails label={latitude.headerTable} value={warehouse.latitude} />
        <CDetails label={longitud.headerTable} value={warehouse.longitud} />
      </Box>
    </CustomModal>
  );
};
