import React, { useState, useRef, useEffect } from 'react';

export const useGraph = (dataObject) => {

    const isMounted = useRef(false)
    const [data, setData] = useState(dataObject);
    const [isAppReady, setIsAppReady] = useState(false);

    const handleData = (key, value) => {
        setData(prev => ({ ...prev, [key]: value }));
    }

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        }
    }, []);

    return {
        data,
        handleData,
        isAppReady,
        setIsAppReady
    }

}

