import React from 'react'
import { IconButton } from '@mui/material'
import CloseIcon from "@mui/icons-material/Close";

export const ButtonCloseModal = ({ handleClick }) => {
    return (
        <IconButton
            aria-label="close"
            onClick={handleClick}
            sx={{
                position: "absolute",
                right: 20,
                top: 20,
                color: (theme) => theme.palette.grey[500],
            }}
        >
            <CloseIcon />
        </IconButton>
    )
}
