import React, { useState, useContext, useEffect } from 'react'
import { Form, Formik } from 'formik';
import CustomModal from '../../../common/Components/CustomModal';
import BranchsContext from '../../../common/Contexts/BranchContext';
import PermissionContext from '../../../common/Contexts/PermissionContext';
import { validateFormBranch } from '../../../model/branch/branch.schema';
import { CStepper } from '../../../common/Components/stepper/CStepper';
import { CSteps } from '../../../common/Components/stepper/CSteps';
import { SidebarService } from '../../../services/SidebarService';
import CompanysService from '../../../services/CompanysService';
import { CToast } from '../../../common/Components/ToastNotify';
import { DataAccessBranch } from './screen_steps/DataAccessBranch';
import { DataProcessBranch } from './screen_steps/DataProcessBranch';
import { DataBranch } from './screen_steps/DataBranch';
import ModulesFormContext from '../../../common/Contexts/ModulesFormContext';
import { ModalOpenEnum } from '../../../common/utils/ModalOpenEnum';
import CompanyContext from '../../../common/Contexts/CompanysContext';
import branchFormModel from "../../../model/branch/branchFormModel";

var store = require("store");

export const BranchForm = () => {

  const {
    steps,
    handleResetStep,
    handleNextStep,
    activeStep,
    handleSetActiveStep,
    rowData: branch,
    isLastStep,
    isOpen,
    toggleModal,
    getDataTable,
    setDataTable
  } = useContext(ModulesFormContext);
  const { branchID, companyID } = useContext(BranchsContext);
  const { getAllCompanys } = useContext(CompanyContext);
  const { models } = useContext(PermissionContext);
  const elements = store.get("sidebar");
  const [isLoading, setIsLoading] = useState(false);
  const [configAccess, setConfigAccess] = useState({ configCompany: [], configBranch: [] });
  const [configMethods, setConfigMethods] = useState({ configCompany: [], configBranch: [] });
  let currentValidationSchema = validateFormBranch[activeStep];
  // console.log(branch)
  const initialValue = {
    branch_name: branch?.company_name || "",
    branch_description: branch?.description || "",
    branch_phone: branch?.company_phone || "",
    branch_contact_name: branch?.contact_name || "",
    branch_contact_phone: branch?.contact_phone || "",
    branch_contact_email: branch?.contact_email || "",

    select_permission_branch: branch?.id_rol || "",
    model_logic_id: branch?.model_logic_id || "",
    elements_cluster: branch?.elements_cluster || elements,

    select_element_branch: "",
    model_logic_branch: branch?.methods_cluster || models,
    isSubmit: false,
    isEditing: branch?.company_id ? true : false,
    company_id: branch?.company_id,
  }
  // console.log(initialValue)

  const getConfigsxCompany = async (code) => {
    setIsLoading(true);
    try {
      const { data } = await SidebarService.getElementsLogic();
      const configCompany = data.filter(item => item.cod_element_logic === 0);
      const configBranch = data.filter(item => item.cod_element_logic === 1);
      setConfigAccess({ configCompany, configBranch });
      setIsLoading(false);
      return data;
    } catch (error) {
      setIsLoading(false);
      console.log(error.response.data.statusCode);
    }
  };

  const getConfigsMethods = async () => {
    setIsLoading(true);
    try {
      const { data } = await SidebarService.getModelLogic();
      const configCompany = data?.data.filter(item => item.cod_model_logic === 0);
      const configBranch = data?.data.filter(item => item.cod_model_logic === 1);
      setConfigMethods({ configCompany, configBranch });
      setIsLoading(false);
      return data?.data;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getConfigsxCompany();
    getConfigsMethods();
  }, []);

  const onSave = async (value) => {
    setIsLoading(true);
    const req = {
      company: {
        company_name: value.branch_name,
        description: value.branch_description,
        company_phone: value.branch_phone,
        contact_name: value.branch_contact_name,
        contact_phone: value.branch_contact_phone,
        contact_email: value.branch_contact_email,
        active: true,
        elements_cluster: value.elements_cluster,
        methods_cluster: value.model_logic_branch,
        id_rol: value.select_permission_branch,
        model_logic_id: value.model_logic_id,
      }
    };
    if (!value.isEditing) { Object.assign(req, { company_parent_id: companyID }) };
    if (value.isEditing) { Object.assign(req.company, { company_id: value.company_id }) };
    console.log(req);
    try {
      const { data } = value.isEditing
        ? await CompanysService.updateBranch(req.company)
        : await CompanysService.createBranch(req);
      await getDataTable();
      await getAllCompanys(); // traemos nuevamente las compañias para actualizar el sidebar
      handleResetStep();
      toggleModal(ModalOpenEnum.EDIT);
      CToast(`Sucursal ${value.isEditing ? 'editada' : 'creada'} correctamente`, "success", true);
    } catch ({ response }) {

      if (response?.data?.error) return (CToast(response?.data?.error, "error", true))
      if (response?.data.statusCode === 409) return (CToast("Ya existe una sucursal con ese nombre", "error", true))
      if (response?.data.statusCode === 400) return (CToast("Hubo un error 400 (ESTANDARIZAR)", "error", true))
      return CToast("Error al crear la sucursal", "error", true)
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <CustomModal
      isOpen={isOpen[ModalOpenEnum.EDIT]}
      toggleModal={() => { handleResetStep(); toggleModal(ModalOpenEnum.EDIT); }}
      moduleLabel={'branch'}
      actionLabel={branch ? "edit" : "create"}
      descriptionLabel={branch ? `${branch.company_name}` : ''}
    >
      <Formik
        initialValues={initialValue}
        validationSchema={currentValidationSchema}
        onSubmit={(values, actions) => {
          (isLastStep) ? onSave(values) : handleNextStep()
          actions.setSubmitting(false);
        }}
      >
        {({ errors, handleSubmit, setFieldValue, isSubmitting, values }) => {
          return (
            <Form>
              <CStepper activeStep={activeStep} steps={steps} setActiveStep={handleSetActiveStep} errors={errors} isLoading={isLoading} isSubmitting={isSubmitting}>
                <CSteps label={steps[0]} onNext={handleSubmit} errors={errors} isSubmitting={isSubmitting}>
                  <DataBranch />
                </CSteps>
                <CSteps label={steps[1]} onNext={handleSubmit} errors={errors} isSubmitting={isSubmitting}>
                  <DataAccessBranch
                    configAccess={configAccess.configBranch}
                    isLoading={isLoading}
                    getConfigsxCompany={getConfigsxCompany}
                  />
                </CSteps>
                <CSteps label={steps[2]} onSubmit={handleSubmit} errors={errors} isEditing={values.isEditing}>
                  <DataProcessBranch
                    checkOutModel={branchFormModel}
                    configsMethods={configMethods.configBranch}
                    isLoading={isLoading}
                    getConfigsMethods={getConfigsMethods}
                  />
                </CSteps>
              </CStepper>
            </Form>
          );
        }}
      </Formik>
    </CustomModal>
  )
}
