import React from 'react'
import { useContext } from 'react';
import { CustomTable } from '../../../../common/Components/CustomTable';
import BranchsContext from '../../../../common/Contexts/BranchContext';

export const CronJobsTable = () => {
    const { branchID } = useContext(BranchsContext);
    const headers = [
        { field: "company_id", headerName: "company_id" },
        { field: "job_name", headerName: "job_name" },
        { field: "description", headerName: "description" },
    ];

    const actions = ["Editar"]

    return (
        <CustomTable
            actions={actions}
            headers={headers}
            disabledButton={branchID.length > 0 ? false : true}
        />
    );
}
