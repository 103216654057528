import { Box, Divider, Modal, Typography } from "@mui/material";
import React, { useContext } from "react";
import { ButtonCloseModal } from "../../../../common/Components/ButtonCloseModal";
import { CDetails } from "../../../../common/Components/CDetails";
import CustomModal from "../../../../common/Components/CustomModal";
import { CustomTitleModal } from "../../../../common/Components/CustomTitleModal";
import ModulesFormContext from "../../../../common/Contexts/ModulesFormContext";
import { styleSheet } from "../../../../common/styles/StylesCommon";
import { ModalOpenEnum } from "../../../../common/utils/ModalOpenEnum";
import checkoutForm from "../../../../model/suggestedParameters/suggestedFormModel";

export const DetailsSuggestedParameter = () => {

  const { rowData: suggestedParameter, isOpen, toggleModal } = useContext(ModulesFormContext);
  const { formField: {
    reference_days,
    classificationA,
    classificationB,
    classificationC,
    description,
    constantf1f2,
    constantvalor1,
    constantvalor2,
    soft_limit,
    quantity_fraction,
    quantity_fraction2
  } } = checkoutForm;

  return (
    <CustomModal
      isOpen={isOpen[ModalOpenEnum.DETAIL]}
      toggleModal={() => toggleModal(ModalOpenEnum.DETAIL)}
      moduleLabel={'parameters_suggested'}
      actionLabel='view'
      descriptionLabel={`Semana ${suggestedParameter.reference_days}`}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 2
        }}
      >
        <CDetails
          label={reference_days.headerTable}
          value={suggestedParameter.reference_days}
        />
        <CDetails
          label={classificationA.headerTable}
          value={suggestedParameter.classificationA}
        />
        <CDetails
          label={classificationB.headerTable}
          value={suggestedParameter.classificationB}
        />
        <CDetails
          label={classificationC.headerTable}
          value={suggestedParameter.classificationC}
        />
        <CDetails
          label={description.headerTable}
          value={suggestedParameter.description}
        />
        <CDetails
          label={constantf1f2.headerTable}
          value={suggestedParameter.constantf1f2}
        />
        <CDetails
          label={constantvalor1.headerTable}
          value={suggestedParameter.constantvalor1}
        />
        <CDetails
          label={constantvalor2.headerTable}
          value={suggestedParameter.constantvalor2}
        />
        <CDetails
          label={quantity_fraction.headerTable}
          value={suggestedParameter.quantity_fraction}
        />
        <CDetails
          label={quantity_fraction2.headerTable}
          value={suggestedParameter.quantity_fraction2}
        />
        <CDetails
          label={soft_limit.headerTable}
          value={suggestedParameter.soft_limit}
        />
      </Box>
    </CustomModal>
  );
};
