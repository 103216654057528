import React, { useContext, useState, useEffect } from 'react'
import { Box } from "@mui/material";
import { styleSheet } from "../../../common/styles/StylesCommon";
import Title from "../../../common/Components/Title";
import { ButtonsFileCSV } from "../../../common/Components/ButtonsFileCSV";
import BranchsContext from '../../../common/Contexts/BranchContext';
import { suggestedDistributionService } from '../../../services/suggestedDistributionService';
import { t } from 'i18next';
import { LayoutTable } from '../../../common/Components/LayoutTable';
import { ExecutionSugestModal } from './components/ExecutionSugestModal';
import { useForm } from '../../../hooks/useForm';
import { SuggestedTableHistorical } from './components/SuggestedTableHistorical';
import { DetailSuggestedModal } from './components/DetailSuggestedModal';
import { ModulesProvider } from '../../../common/Providers/ModulesProvider';
import { ModalOpenEnum } from '../../../common/utils/ModalOpenEnum';



const ExecutionSuggested = () => {

    const { branchID } = useContext(BranchsContext);
    const { isOpen, rowData, toggleModal } = useForm();
    const [dataTable, setDataTable] = useState([]);
    const [isTableLoading, setIsTableLoading] = useState(false);
    const overallStyle = styleSheet();

    const getDataTable = async () => {
        setIsTableLoading(true);
        // "0f6f1e89-7c79-46fc-9dc8-a4a40bec5bbd1" 
        const req = {
            "company_id": branchID
        };
        try {
            const { data } = await suggestedDistributionService.getSuggestedByHistorialId(req);
            data && setDataTable(data)
        } catch ({ response }) {
            console.log(response)
        } finally {
            setIsTableLoading(false)
        }

    };

    useEffect(() => {
        branchID.length > 0 && getDataTable();
    }, [branchID]);

    return (
        <ModulesProvider value={{
            isOpen,
            isTableLoading,
            dataTable,
            toggleModal,
            getDataTable,
            rowData,
        }}>
            <Box sx={overallStyle?.container}>
                <Title name={t('modules.executionSugested')} />
                <ButtonsFileCSV />
                <LayoutTable labelAction={'Ejecutar sugerido'}>
                    {
                        isOpen[ModalOpenEnum.EDIT] &&
                        (<ExecutionSugestModal
                            isOpen={isOpen[ModalOpenEnum.EDIT]}
                            handleModal={() => toggleModal(ModalOpenEnum.EDIT)}
                        />)
                    }
                    {
                        isOpen[ModalOpenEnum.DETAIL] && (
                            <DetailSuggestedModal />
                        )
                    }
                    <SuggestedTableHistorical />
                </LayoutTable>
            </Box>
        </ModulesProvider>
    )
}


export default ExecutionSuggested