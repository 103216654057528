import { Box } from '@mui/material'
import { t } from 'i18next';
import React, { useContext, useState, useEffect, useMemo } from 'react'
import { CustomModalDelete } from '../../../common/Components/CustomModalDelete';
import { LayoutTable } from '../../../common/Components/LayoutTable';
import Title from '../../../common/Components/Title';
import { CToast } from '../../../common/Components/ToastNotify';
import BranchsContext from '../../../common/Contexts/BranchContext';
import CompanyContext from '../../../common/Contexts/CompanysContext';
import ModulesFormContext from '../../../common/Contexts/ModulesFormContext';
import { ModulesProvider } from '../../../common/Providers/ModulesProvider';
import { styleSheet } from '../../../common/styles/StylesCommon';
import { companySteps, companyStepsEdit } from '../../../common/utils/getLabelSteps';
import { ModalOpenEnum } from '../../../common/utils/ModalOpenEnum';
import { useForm } from '../../../hooks/useForm';
import { useStep } from '../../../hooks/useStep';
import CompanysService from '../../../services/CompanysService';
import UsersService from '../../../services/UsersService';
import { CompanyForm } from './CompanyForm';
import { TableCompany } from './components/TableCompanys'


const Company = () => {
    const { companyID, branchID } = useContext(BranchsContext);
    const { companys, getAllCompanys } = useContext(CompanyContext);


    const overallStyle = useMemo(() => styleSheet(), []);

    //LOGIC FORM
    const {
        isOpen,
        rowData,
        toggleModal,
        dataTable,
        setDataTable,
        isTableLoading,
        setIsTableLoading
    } = useForm();
    //LOGIC STEPPERS
    const {
        activeStep,
        handleSetActiveStep,
        handleResetStep,
        isLastStep,
        handleNextStep,
    } = useStep(rowData ? companyStepsEdit : companySteps);

    const [isLoadingDelet, setIsLoadingDelet] = useState(false)


    const getDataTable = async () => {
        setIsTableLoading(true);
        try {
            const { data } = await UsersService.getCompaniesByUser();
            setDataTable(data);
        } catch ({ response }) {
      
        } finally {
            setIsTableLoading(false);
        }
    };


    const handeDeleteCompany = async () => {
        setIsLoadingDelet(true);
        const req = {
            company_id: rowData?.company_id,
        }
        try {
            const { data } = await CompanysService.deleteCompany(req);
            await getDataTable();
            await getAllCompanys();
            CToast(t('messages.success_delete'), 'success', true);
        } catch ({ response }) {
            CToast(t('messages.error_delete'), 'error', true);
        } finally {
            setIsLoadingDelet(false);
        }
    }

    useEffect(() => {
        companyID.length > 0 && getDataTable();
    }, [companyID]);

    return (
        <ModulesProvider value={{
            dataTable,
            getDataTable,
            isTableLoading,
            activeStep,
            isLastStep,
            handleSetActiveStep,
            handleResetStep,
            handleNextStep,
            handleDelete: handeDeleteCompany,
            steps: rowData ? companyStepsEdit : companySteps,
            isOpen, rowData, toggleModal,
        }}>
            <Box sx={overallStyle?.container}>
                <Title name={t('modules.company')} />
                <LayoutTable labelAction={'Agregar Empresa'}>
                    {
                        isOpen[ModalOpenEnum.EDIT] && (<CompanyForm />)
                    }
                    {isOpen[ModalOpenEnum.DELETE] &&
                        (<CustomModalDelete
                            moduleLabel={'company'}
                            descriptionLabel={rowData ? `${rowData.company_name}` : ''}
                            isLoading={isLoadingDelet}
                        />)}
                    <TableCompany />
                </LayoutTable>
            </Box>
        </ModulesProvider>

    )
}


export default Company