import React, { useEffect, useReducer } from "react";
import UserContext from "../Contexts/UserContext";
import { userReducer } from "../Reducers/userReducer";
import { typesUser } from "../../Types/Types";
import UsersService from '../../services/UsersService';

var store = require("store");

const init = () => {
  return {
    user: {},
  };
};

export const UserProviders = ({ children }) => {

  const [userGlobal, dispatch] = useReducer(userReducer, {}, init);

  const create = (data) => {

    const action = {
      type: typesUser.credentials,
      payload: data,
    };

    store.set("user", data);

    dispatch(action);
  };

  const refreshUserStore = (user) => {

    const action = {
      type: typesUser.refresh,
      payload: user,
    };

    store.set("user", user);

    dispatch(action);

  }
  const getRefreshUser = async (userId) => {
    const req = {
      user_id: userId,
    };
    try {
      const { data: user } = await UsersService.getUserById(req);
      refreshUserStore(user);
    } catch ({ response }) {
      console.log(response);
    }
  }

  const getUserStore = () => {
    const user = store.get("user");
    refreshUserStore(user)
  }


  useEffect(() => {
    getUserStore();
  }, []);



  return (
    <UserContext.Provider value={{ userGlobal, create, refreshUserStore, getRefreshUser }}>
      {children}
    </UserContext.Provider>
  );
};

