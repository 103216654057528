import React from "react";
import { Box } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { styleSheet } from "../../../../common/styles/StylesCommon";
import { formatMoney } from "../../../../helpers/formatNumber";
import { getMonth } from "../../../../helpers/months";
import { CLine } from "../../../../common/Components/graph/CLine";

export const ForecastGraphic = ({ data }) => {
  const overallStyle = styleSheet();
  
  const formatLabels = {
    "año_actual": "Venta año actual",
    "forecast": "Forecast",
    "año_anterior": "Venta año anterior"
  }

  const transformData = (data) => {
    return data.map((item) => {
      return {
        ...item,
        año_actual: Math.abs(item.año_actual),
        año_anterior: Math.abs(item.año_anterior),

      };
    });
  }

  return (
    <Box
      width={"100%"}
      height={'65vh'}
      sx={{
        ...overallStyle.boxWhite,
      }}
    >
      <ResponsiveBar
        data={transformData(data)}
        valueFormat={value => formatMoney(value)}
        colors={({ id, data, }) => data[`${id}Color`]}
        colorBy="forecastColor"
        innerPadding={0.5}
        tooltipLabel={function (e) {
          return `${e.id} en ${getMonth(e.indexValue)?.month_name}`;
        }}
        // maxValue={30000000}
        keys={["año_actual", "forecast"]}
        indexBy="fecha"
        margin={{ top: 30, right: 130, bottom: 60, left: 60 }}
        padding={0.5}
        groupMode="grouped"
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.5]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Meses",
          legendPosition: "middle",
          legendOffset: 32,
          format: (value) => getMonth(value)?.month_name,
        }}

        axisLeft={{
          tickSize: 5,
          tickPadding: -20,
          tickRotation: 0,
          legend: "Pesos",
          legendPosition: "middle",
          legendOffset: -40,
          format: (value) => formatMoney(value),
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        legendLabel={({ id }) => formatLabels[id]}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 1,
            itemWidth: 100,
            itemHeight: 15,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 10,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },

        ]}
        layers={["grid", "axes", "bars", "markers", "legends", CLine]}
        role="application"
        ariaLabel="Forecas Screen"
     
      />
    </Box>
  );
};
