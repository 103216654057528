import axios from "axios";

var store = require('store')

const authAxios = axios.create({
    // baseURL: "https://biwiser-dev.herokuapp.com",REACT_API_LINK-REACT_APP_API_LINK_LOCAL
    baseURL: process.env.REACT_APP_API_LINK,

});

authAxios.interceptors.request.use(

    async (request) => {

        const payload = store.get("auth");

        request.headers["x-token"] = payload?.accessToken;
        return request;

    },
    (err) => err
)

export default authAxios;

