import { useContext, useState, useEffect } from 'react'
import { Box } from "@mui/material";
import { t } from 'i18next';
import { ButtonsFileCSV } from "../../../common/Components/ButtonsFileCSV";
import BranchsContext from '../../../common/Contexts/BranchContext';
import SuggestedParametersService from '../../../services/SuggestedParameters';
import ModulesFormContext from '../../../common/Contexts/ModulesFormContext';
import { useForm } from '../../../hooks/useForm';
import { ModulesProvider } from '../../../common/Providers/ModulesProvider';
import { CustomModalDelete } from '../../../common/Components/CustomModalDelete';
import { TableSuggestedParameters } from './components/TableSuggestedParameters';
import { SuggestedParametersForm } from './SuggestedParametersForm';
import { LayoutTable } from '../../../common/Components/LayoutTable';
import Title from '../../../common/Components/Title';
import { DetailsSuggestedParameter } from './components/DetailsSuggestedParameter';
import { CToast } from '../../../common/Components/ToastNotify';
import { ModalOpenEnum } from '../../../common/utils/ModalOpenEnum';

const SuggestedParameters = () => {

    const { branchID } = useContext(BranchsContext);
    const { overallStyle } = useContext(ModulesFormContext);
    //LOGIC FORM
    const {
        isOpen,
        rowData,
        toggleModal,
        dataTable,
        setDataTable,
        isTableLoading,
        setIsTableLoading
    } = useForm();
    const [isLoadingDelet, setIsLoadingDelet] = useState(false)

    const getDataTable = async () => {
        setIsTableLoading(true);
        const req = {
            company_id: branchID,
        };
        try {
            const { data } = await SuggestedParametersService.getSuggestedParametersByBranch(req)
            setDataTable(data);
        } catch ({ response }) {
            console.log(response)
        } finally {
            setIsTableLoading(false);
        }
    };

    const handleDeleteParameters = async () => {
        setIsLoadingDelet(true);
        const req = {
            id_parametroSugerido: rowData?.id_parametroSugerido,
            company_id: branchID,
        }
        try {
            const { data } = await SuggestedParametersService.deleteSuggestedParameters(req);
            await getDataTable();
            CToast(t('messages.success_delete'), 'success', true);
        } catch ({ response }) {
            console.log(response)
            CToast(t('messages.error_delete'), 'error', true);
        } finally {
            setIsLoadingDelet(false);
        }
    }

    useEffect(() => {
        branchID.length > 0 && getDataTable();
    }, [branchID]);

    return (
        <ModulesProvider value={{
            getDataTable,
            handleDelete: handleDeleteParameters,
            isOpen,
            rowData,
            toggleModal,
            dataTable,
            setDataTable, isTableLoading,
        }}>
            <Box sx={overallStyle?.container}>
                <Title name={t('titles.parameters_suggesteds')} />
                <ButtonsFileCSV />
                <LayoutTable hasAdd={true} labelAction={'Agregar'}>
                    {isOpen[ModalOpenEnum.EDIT] && <SuggestedParametersForm />}
                    {isOpen[ModalOpenEnum.DETAIL] && <DetailsSuggestedParameter />}
                    {isOpen[ModalOpenEnum.DELETE] && (
                        <CustomModalDelete
                            descriptionLabel={rowData ? `semana ${rowData?.reference_days}` : ''}
                            isLoading={isLoadingDelet}
                            moduleLabel={'parameters_suggested'}
                        />)
                    }
                    <TableSuggestedParameters />
                </LayoutTable>
            </Box>
        </ModulesProvider>

    )
}


export default SuggestedParameters