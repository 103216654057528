export const dataExample =  [
  {
    "cab": "PALA CAJUELERA REDONDA MANGO LARGO RAYUN",
    "cod_prod": "AGRPALMUL007",
    "PALA CAJUELERA REDONDA MANGO LARGO RAYUN": 480,
    "prod_color": "hsl(204, 100%, 56%)"
  },
  {
    "cab": "FLEXIBLE GAS 3/8\" IZQ. HI X 3/8\" IZQ. HI 100 CM PROVIDUS+",
    "cod_prod": "DEPCAMFLE005",
    "FLEXIBLE GAS 3/8\" IZQ. HI X 3/8\" IZQ. HI 100 CM PROVIDUS+": 696,
    "prod_color": "hsl(204, 100%, 71%)"
  },
  {
    "cab": "GUANTE ULTRAFLEX POLIESTER PU FOAM TALLA S (7)",
    "cod_prod": "FERGUAALG080",
    "GUANTE ULTRAFLEX POLIESTER PU FOAM TALLA S (7)": 720,
    "prod_color": "hsl(204, 100%, 6%)"
  },
  {
    "cab": "BOLAS DE GOMA CAL 0.43 (BOLSA 100pcs)",
    "cod_prod": "DEPCAZRAM045",
    "BOLAS DE GOMA CAL 0.43 (BOLSA 100pcs)": 65,
    "prod_color": "hsl(204, 100%, 6%)"
  },
  {
    "cab": "LLANA DENTADA 280 X 130 MM X 8 MM RAYÜN",
    "cod_prod": "FERPLALLA102",
    "LLANA DENTADA 280 X 130 MM X 8 MM RAYÜN": 216,
    "prod_color": "hsl(204, 100%, 23%)"
  },
  {
    "cab": "BOTA DUNLOP DANE NEGRA TALLA 42",
    "cod_prod": "FERSEGBOT247",
    "BOTA DUNLOP DANE NEGRA TALLA 42": 80,
    "prod_color": "hsl(204, 100%, 69%)"
  },
  {
    "cab": "OVEROL PAPEL BLANCO TALLA L",
    "cod_prod": "FERSEGOVE039",
    "OVEROL PAPEL BLANCO TALLA L": 308,
    "prod_color": "hsl(204, 100%, 61%)"
  },
  {
    "cab": "FUNDA UMAREX NEGRA  P/REVOLVER",
    "cod_prod": "DEPCAZUMA401",
    "FUNDA UMAREX NEGRA  P/REVOLVER": 3,
    "prod_color": "hsl(204, 100%, 1%)"
  },
  {
    "cab": "PETO T/MALLA NARANJA MESH TRAIN \"M\"",
    "cod_prod": "DEPFUTPET259",
    "PETO T/MALLA NARANJA MESH TRAIN \"M\"": 90,
    "prod_color": "hsl(204, 100%, 98%)"
  },
  {
    "cab": "LENTES WIDE VISION JUNIOR",
    "cod_prod": "DEPACUANT071",
    "LENTES WIDE VISION JUNIOR": 1,
    "prod_color": "hsl(204, 100%, 97%)"
  }
  ];
 



