import { Box } from '@mui/material';
import { Formik } from 'formik';
import React from 'react'
import { useState } from 'react';
import { useContext } from 'react';
import { ButtonSave } from '../../../common/Components/ButtonSave';
import { ContainerForm } from '../../../common/Components/ContainerForm';
import CustomModal from '../../../common/Components/CustomModal'
import { FormTextInput } from '../../../common/Components/FormTextInput';
import { CToast } from '../../../common/Components/ToastNotify';
import BranchsContext from '../../../common/Contexts/BranchContext';
import MediaContext from '../../../common/Contexts/MediaContext';
import ModulesFormContext from '../../../common/Contexts/ModulesFormContext';
import { ModalOpenEnum } from '../../../common/utils/ModalOpenEnum';
import { validateFormDailyWeights } from '../../../model/dailyMoney/dailyMoney.schema';
import checkoutFormModel from '../../../model/dailyMoney/dailyMoneyFormModel';
import DailyMoneyService from '../../../services/DailyMoneyService';
import { SelectDay } from '../Calendar/components/SelectDay';

export const DailyMoneyForm = () => {
    const { isMobile, isTablet } = useContext(MediaContext);
    const { branchID } = useContext(BranchsContext);
    const { isOpen, toggleModal, rowData: dailyMoney, getDataTable } = useContext(ModulesFormContext);
    const [isLoading, setIsLoading] = useState(false)
    const { formId, formField: { dia, peso } } = checkoutFormModel;

    const initialValue = {
        peso: dailyMoney?.peso || "",
        dia: dailyMoney?.dia || "",
        isEditing: dailyMoney ? true : false,
        id_pesosDiarios: dailyMoney?.id_pesosDiarios,
    };


    const onSave = async (value) => {
        setIsLoading(true);
        const req = {
            peso: value?.peso,
            dia: value?.dia,
            company_id: branchID,
        }
        value.isEditing && (req.id_pesosDiarios = value.id_pesosDiarios);
        try {
            const { data } = value.isEditing
                ? await DailyMoneyService.updateDailyMoney(req)
                : await DailyMoneyService.createDailyMoney(req);
            await getDataTable();
            toggleModal(ModalOpenEnum.EDIT);
            CToast(`Peso diario ${value.isEditing ? 'editado' : 'creado'}`, "success", true);
        } catch ({ response }) {
            console.log(response);
            if (response?.data?.description) return (CToast(response?.data?.description, "error", true))
            if (response?.data.statusCode === 400) return (CToast("Hubo un error 400 (ESTANDARIZAR)", "error", true))
            return CToast(`Error al ${value.isEditing ? 'editar' : 'crear'} el peso diario`, "error", true)
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <CustomModal
            isOpen={isOpen[ModalOpenEnum.EDIT]}
            toggleModal={() => toggleModal(ModalOpenEnum.EDIT)}
            moduleLabel={'weights_daily'}
            actionLabel={dailyMoney ? "edit" : "create"}
            descriptionLabel={dailyMoney ? `${dailyMoney.peso}` : ''}
        >
            <Formik
                initialValues={initialValue}
                validationSchema={validateFormDailyWeights}
                onSubmit={(values, actions) => {
                    onSave(values);
                    actions.setSubmitting(false);
                }}
            >
                {({ handleSubmit, values, errors, setFieldValue, setFieldError }) => (
                    <ContainerForm formID={formId}>
                        <Box width={isTablet ? "100%" : '50%'} pt={2}>
                            <SelectDay name={dia.name} label={dia.label} />
                        </Box>

                        <Box width={isTablet ? "100%" : '50%'}>
                            <FormTextInput
                                label={peso.label}
                                name={peso.name}
                                type={"number"}
                                inputProps={{
                                    step: 0.1
                                }}
                                fullWidth={true}
                            />

                            <ButtonSave
                                onClick={handleSubmit}
                                isLoading={isLoading}
                                edit={dailyMoney ? true : false}
                            />
                        </Box>
                    </ContainerForm>
                )}
            </Formik>
        </CustomModal>
    )
}
