import { typesUser } from "../../Types/Types";


export const userReducer = (state = {}, action) => {
  switch (action.type) {
    case typesUser.credentials:
      return {
        ...state,
        ...action.payload,
      };

      case typesUser.update:
        return {
          ...state,
          ...action.payload,
        };

        case typesUser.refresh:
          return {
            ...state,
            ...action.payload,
          };
  

    default:
      return state;
  }
};
