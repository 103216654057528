import { useContext, useState, useEffect } from 'react'
import { Box } from "@mui/material";
import Title from "../../../common/Components/Title";
import { ButtonsFileCSV } from "../../../common/Components/ButtonsFileCSV";
import BranchsContext from '../../../common/Contexts/BranchContext';
import LevelService from "../../../services/ServiceLevelService";
import { useForm } from '../../../hooks/useForm';
import ModulesFormContext from '../../../common/Contexts/ModulesFormContext';
import { ModulesProvider } from '../../../common/Providers/ModulesProvider';
import { CToast } from '../../../common/Components/ToastNotify';
import { LayoutTable } from '../../../common/Components/LayoutTable';
import { TableServiceLevel } from './components/TableServiceLevel';
import { CustomModalDelete } from '../../../common/Components/CustomModalDelete';
import { ServiceLevelForm } from './ServiceLevelForm';
import { t } from 'i18next'
import { ModalOpenEnum } from '../../../common/utils/ModalOpenEnum';


const ServiceLevel = () => {

    const { branchID } = useContext(BranchsContext);
    const { overallStyle } = useContext(ModulesFormContext);
    //LOGIC FORM
    const {
        isOpen,
        rowData,
        toggleModal,
        dataTable,
        setDataTable,
        isTableLoading,
        setIsTableLoading
    } = useForm();
    const [isLoadingDelet, setIsLoadingDelet] = useState(false)

    const getDataTable = async () => {
        setIsTableLoading(true);
        const req = {
            company_id: branchID,
        };
        try {
            const { data } = await LevelService.getServiceLevelByBranch(req);
            setDataTable(data);
        } catch ({ response }) {
            console.log(response);
        } finally {
            setIsTableLoading(false);
        }
    };

    const handleDeleteServLevel = async () => {
        setIsLoadingDelet(true);
        const req = {
            id_nivelDeServicio: rowData?.id_nivelDeServicio,
            company_id: branchID,
        }
        try {
            const { data } = await LevelService.deleteServiceLevel(req);
            CToast(t('messages.success_delete'), "success");
            getDataTable();
        } catch (error) {
            CToast("Error al eliminar el nivel de servicio", "error");
        } finally {
            setIsLoadingDelet(false);
        }

    }

    useEffect(() => {
        branchID.length > 0 && getDataTable();
    }, [branchID]);

    return (
        <ModulesProvider value={{
            getDataTable,
            handleDelete: handleDeleteServLevel,
            isOpen,
            rowData,
            toggleModal,
            dataTable,
            setDataTable,
        }}>
            <Box sx={overallStyle?.container}>
                <Title name={t('titles.level_service')} />
                <ButtonsFileCSV />
                <LayoutTable
                    labelAction={'Agregar Nivel'}
                    disabledButon={branchID.length > 0 ? false : true}>
                    {isOpen[ModalOpenEnum.EDIT] && (<ServiceLevelForm />)}
                    {isOpen[ModalOpenEnum.DELETE] && (<CustomModalDelete
                        descriptionLabel={rowData?.letra ? rowData.letra : ''}
                        isLoading={isLoadingDelet}
                        moduleLabel={'level_service'}
                    />)}
                    <TableServiceLevel />
                </LayoutTable>
            </Box>
        </ModulesProvider>
    )
}


export default ServiceLevel