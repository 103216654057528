import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { formatNumber } from "../../../../helpers/formatNumber";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: 2
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const TableInfoGeneral = ({ hiddenSKU }) => {

  return (
    <TableContainer sx={{ border:1 }} component={Paper}>
      <Table sx={{height: 400, }} aria-label="customized table">
        <TableBody>
          {hiddenSKU.map((row, index) => (
            <StyledTableRow style={{display:'flex',justifyContent:'space-between',alignItems:'center'}} key={index}>
              <StyledTableCell style={{fontSize:13}} align="left" >{row.label}</StyledTableCell>
              <StyledTableCell style={{fontSize:13}} size="medium" align="center" >{formatNumber(Math.abs(row.value))}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
