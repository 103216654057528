import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { formatNumber } from "../../../../helpers/formatNumber";
import { styleSheet } from "../../../../common/styles/StylesCommon";

export const BodyCardInfo = ({ headers, rows, isNumbers = true }) => {

    const overallStyle = styleSheet();

  return (
    <TableContainer
      sx={{
            ...overallStyle.tableRadar,
            }}
    >
      <Table sx={{ height: 120, width: "100%" }} aria-label="customized table">
        <TableHead>
          <TableRow style={{ textAlign: "center", width: "100%" }}>
            {headers.map((header, index) => {
              return (
                <TableCell
                  key={index}
                  style={{
                    borderBottom:  "1px solid black",
                    borderRight: index === 0 && "1px solid black",
                  }}
                  align="center"
                >
                  {header}
                </TableCell>
              );
            })}
            {/* <TableCell style={{ fontSize: 10, }} align="center">{t('graph.sku.units_vents')}</TableCell>
                        <TableCell style={{ fontSize: 10, }} align="center">{t('graph.sku.percent')}</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow style={{ textAlign: "center", width: "100%" }}>
            {rows.map((row, index) => {
              return (
                <TableCell
                  key={index}
                  sx={{
                    fontSize: 15,
                    borderRight: index === 0 && "1px solid black",
              
                  }}
                  align="center"
                  // component="th"
                  scope="row"
                >
                  {isNumbers ? formatNumber(Math.abs(row)) : row}
                </TableCell>
              );
            })}
            {/* <TableCell style={{ fontSize: 12, }} align="center" component="th" scope="row">5424524</TableCell>
                        <TableCell style={{ fontSize: 12, }} align="center" component="th" scope="row" >1561651</TableCell > */}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
