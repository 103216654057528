
import authAxios from "../common/Auth/authAxios";

const CronjobsService = {
    getAllCronjobs: async () => {
        return await authAxios.get('/cron-jobs/getAllCronJobs')
    },
    getCronByBranch: async (branch) => {
        return await authAxios.post('/cron-jobs/getCompanyCrons', branch)
    },
    getCronCommands: async () => {
        return await authAxios.get('/cron-jobs/getCommands')
    },
    createCron: async (cron) => {
        return await authAxios.post('/cron-jobs/createCronJob', cron)
    },
    updateCron: async (cron) => {
        return await authAxios.patch('/cron-jobs/editCronJob', cron)
    },
}
export default CronjobsService
