import React, { useContext, useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Typography } from "@mui/material";

import BranchsContext from "../../../../common/Contexts/BranchContext";
import WarehouseService from "../../../../services/WarehouseService";
import { CustomSelect } from "../../../../common/Components/CustomSelect";

var store = require("store");

export const SelectWarehouse = ({ name, label, warehouses, setWarehouses }) => {

  const { branchID } = useContext(BranchsContext);
  const getWarehouses = async () => {
    const req = {
      company_id: branchID,
    };
    try {
      const { data } = await WarehouseService.getWarehouseByBranch(req);
      setWarehouses(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getWarehouses();
  }, [branchID]);

  return (
    <>
      <Typography mb={1} color={"primary.main"} variant={"h6"}>
        Seleccionar Bodega
      </Typography>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Bodegas</InputLabel>
        <CustomSelect
          sx={{
            borderRadius: 20,
            marginBottom: 1.4,
            height: 50,
            borderColor:
              "linear-gradient(90deg, rgba(7,64,107,1) 0%, rgba(7,64,107,1) 35%, rgba(0,184,255,1) 100%)",
          }}
          id={name || "id_bodega"}
          label={label || "Bodegas"}
          labelId={label || "Bodegas"}
          name={name || "id_bodega"}
        >
          {warehouses.map((warehouse, index) => (
            <MenuItem key={index} value={warehouse?.id_bodega}>
              {warehouse?.bodega}
            </MenuItem>
          ))}
        </CustomSelect>
      </FormControl>
    </>
  );
};
