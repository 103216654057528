import checkoutFormModel from "./suggestedFormModel";
import * as Yup from "yup";
const { formField: {
    reference_days,
    classificationA, classificationB, classificationC,
    description,
    constantf1f2, constantvalor1, constantvalor2,
    quantity_fraction, quantity_fraction2, soft_limit,
    confirmed
} } = checkoutFormModel;

export const validateFormSuggestedParameter = Yup.object({
    [reference_days.name]: Yup.number().required(reference_days.requiredErrorMsg),
    [classificationA.name]: Yup.number().required(classificationA.requiredErrorMsg)
        .min(classificationA.min, classificationA.minErrorMsg)
        .max(classificationA.max, classificationA.maxErrorMsg),
    [classificationB.name]: Yup.number().required(classificationB.requiredErrorMsg)
        .min(classificationB.min, classificationB.minErrorMsg)
        .max(classificationB.max, classificationB.maxErrorMsg),
    [classificationC.name]: Yup.number().required(classificationC.requiredErrorMsg)
        .default(classificationC.default),
    [description.name]: Yup.string().required(description.requiredErrorMsg),
    [constantf1f2.name]: Yup.number().required(constantf1f2.requiredErrorMsg)
        .min(constantf1f2.min, constantf1f2.minErrorMsg)
        .max(constantf1f2.max, constantf1f2.maxErrorMsg)
        .default(constantf1f2.default),
    [constantvalor1.name]: Yup.number().required(constantvalor1.requiredErrorMsg)
        .min(constantvalor1.min, constantvalor1.minErrorMsg)
        .max(constantvalor1.max, constantvalor1.maxErrorMsg)
        .default(constantvalor1.default),
    [constantvalor2.name]: Yup.number().required(constantvalor2.requiredErrorMsg)
        .min(constantvalor2.min, constantvalor2.minErrorMsg)
        .max(constantvalor2.max, constantvalor2.maxErrorMsg)
        .default(constantvalor2.default),
    [quantity_fraction.name]: Yup.number().required(quantity_fraction.requiredErrorMsg)
        .min(quantity_fraction.min, quantity_fraction.minErrorMsg)
        .default(quantity_fraction.default),
    [quantity_fraction2.name]: Yup.string().required(quantity_fraction2.requiredErrorMsg)
        .min(quantity_fraction2.min, quantity_fraction2.minErrorMsg)
        .default(quantity_fraction2.default),
    [soft_limit.name]: Yup.number().required(soft_limit.requiredErrorMsg)
        .min(soft_limit.min, soft_limit.minErrorMsg)
        .default(soft_limit.default),
    [confirmed.name]: Yup.boolean().required(confirmed.requiredErrorMsg)
        .default(confirmed.default),
    // default: Yup.boolean().required("").default(true),
});