import { Box } from '@mui/material'
import { Formik } from 'formik'
import React from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { ButtonSave } from '../../../common/Components/ButtonSave'
import { ContainerForm } from '../../../common/Components/ContainerForm'
import CustomModal from '../../../common/Components/CustomModal'
import { FormTextInput } from '../../../common/Components/FormTextInput'
import { CToast } from '../../../common/Components/ToastNotify'
import BranchsContext from '../../../common/Contexts/BranchContext'
import MediaContext from '../../../common/Contexts/MediaContext'
import ModulesFormContext from '../../../common/Contexts/ModulesFormContext'
import { ModalOpenEnum } from '../../../common/utils/ModalOpenEnum'
import { validateFormDistributionCenter } from '../../../model/centerOfDistribution/centerDistribution.schema'
import checkoutForm from '../../../model/centerOfDistribution/centerDistributionFormModel';
import DistributionService from '../../../services/DistributionCentresService'

export const DistributionCenterForm = () => {
    const { isOpen, toggleModal, rowData: distributionCenter, getDataTable, setDataTable } = useContext(ModulesFormContext);
    const { isMobile, isTablet } = useContext(MediaContext);
    const { branchID } = useContext(BranchsContext);
    const [isLoading, setIsLoading] = useState(false);
    const { formId, formField: {
        cd, cod_cd, ciudad, comuna, priority, region, latitude, longitud, direccion
    } } = checkoutForm;

    const initialValue = {
        cod_cd: distributionCenter?.cod_cd || "",
        cd: distributionCenter?.cd || "",
        priority: distributionCenter?.priority || 1,
        ciudad: distributionCenter?.ciudad || "",
        comuna: distributionCenter?.comuna || "",
        region: distributionCenter?.region || "",
        latitude: distributionCenter?.latitude || "",
        longitud: distributionCenter?.longitud || "",
        direccion: distributionCenter?.direccion || "",
        isEditing: distributionCenter ? true : false,
        id_centrodedistribucion: distributionCenter?.id_centrodedistribucion
    };

    const onSave = async (value) => {
        setIsLoading(true);
        const req = {
            cod_cd: value.cod_cd,
            cd: value.cd,
            priority: value.priority,
            ciudad: value.ciudad,
            comuna: value.comuna,
            region: value.region,
            latitude: value.latitude,
            longitud: value.longitud,
            direccion: value.direccion,
            company_id: branchID,
        };
        console.log(req);
        value.isEditing && (req.id_centrodedistribucion = value.id_centrodedistribucion);
        try {
            const { data } = value.isEditing
                ? await DistributionService.updateDistributionCenter(req)
                : await DistributionService.createDistributionCenter(req);
            await getDataTable();
            toggleModal(ModalOpenEnum.EDIT);
            CToast(`Centro ${value.isEditing ? 'editado' : 'creado'} con exito`, "success", true);
        } catch ({ response }) {
            console.log(response)
            CToast(`Error al crear el centro de distribución`, "error", true);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <CustomModal
            isOpen={isOpen[ModalOpenEnum.EDIT]}
            toggleModal={() => toggleModal(ModalOpenEnum.EDIT)}
            moduleLabel={'distribution_center'}
            actionLabel={distributionCenter ? "edit" : "create"}
            descriptionLabel={distributionCenter ? `${distributionCenter.cd}` : ''}
        >
            <Formik
                initialValues={initialValue}
                validationSchema={validateFormDistributionCenter}
                onSubmit={(values, actions) => {
                    onSave(values);
                    actions.setSubmitting(false);
                }}
            >
                {({ handleSubmit }) => (
                    <ContainerForm formID={formId}>
                        <Box width={isTablet ? "100%" : '50%'}>
                            <FormTextInput
                                label={cod_cd.label}
                                name={cod_cd.name}
                                style={{ width: "95%" }}
                                fullWidth={true}
                            />
                            <FormTextInput
                                label={cd.label}
                                name={cd.name}
                                style={{ width: "95%" }}
                                fullWidth={true}
                            />
                            <FormTextInput
                                label={priority.label}
                                name={priority.name}
                                style={{ width: "95%" }}
                                type="number"
                                fullWidth={true}
                            />
                            <FormTextInput
                                label={ciudad.label}
                                name={ciudad.name}
                                style={{ width: "95%" }}
                                fullWidth={true}
                            />
                            <FormTextInput
                                label={comuna.label}
                                name={comuna.name}
                                style={{ width: "95%" }}
                                fullWidth={true}
                            />
                        </Box>

                        <Box width={isTablet ? "100%" : '50%'}>
                            <FormTextInput
                                label={region.label}
                                name={region.name}
                                fullWidth={true}
                            />
                            <FormTextInput
                                label={latitude.label}
                                name={latitude.name}
                                fullWidth={true}
                            />
                            <FormTextInput
                                label={longitud.label}
                                name={longitud.name}
                                fullWidth={true}
                            />
                            <FormTextInput
                                label={direccion.label}
                                name={direccion.name}
                                fullWidth={true}
                            />
                            <ButtonSave
                                onClick={handleSubmit}
                                isLoading={isLoading}
                                edit={distributionCenter ? true : false}
                            />
                        </Box>
                    </ContainerForm>
                )
                }
            </Formik>

        </CustomModal>
    )
}
