import React, { useContext, useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Typography } from "@mui/material";
import BranchsContext from "../../../../common/Contexts/BranchContext";
import SuggestedParametersService from "../../../../services/SuggestedParameters";
import { CustomSelect } from "../../../../common/Components/CustomSelect";
import { CToast } from "../../../../common/Components/ToastNotify";


var store = require("store");

export const SelectParameter = ({ name, label }) => {

    const { branchID } = useContext(BranchsContext);
    const [parameters, setParameters] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const getParameter = async () => {
        setIsLoading(true);
        const req = {
            company_id: branchID,
        };
        try {
            const { data } = await SuggestedParametersService.getSuggestedParametersByBranch(req);
            setParameters(data);
        } catch ({ response }) {
            CToast('Ocurrio un error al obtener los datos del select', "error", true)
        } finally {
            setIsLoading(false)
        }
    };

    useEffect(() => {
        branchID.length > 0 && getParameter();
    }, [branchID]);

    return (
        <>
            <Typography mb={1} color={"primary.main"} variant={"h6"}>
                Seleccionar Parametro Sugerido
            </Typography>
            <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">Bodegas</InputLabel> */}
                <CustomSelect
                    sx={{
                        borderRadius: 20,
                        marginBottom: 1.4,
                        height: 50,
                        borderColor:
                            "linear-gradient(90deg, rgba(7,64,107,1) 0%, rgba(7,64,107,1) 35%, rgba(0,184,255,1) 100%)",
                    }}
                    id={name}
                    name={name}
                    labelId={label || "Parametro Sugerido"}
                    label={label || "Parametro Sugerido"}
                    isLoading={isLoading}
                >
                    {parameters.map((parameter, index) => (
                        <MenuItem key={index} value={parameter.id_parametroSugerido}>
                            {parameter.reference_days}
                        </MenuItem>
                    ))}
                </CustomSelect>
            </FormControl>
        </>
    );
};
