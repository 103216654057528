import React, { useState } from 'react'
import { Box, Checkbox, FormControlLabel, IconButton, Stack, Tooltip, tooltipClasses, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import Zoom from "@mui/material/Zoom";
import InfoIcon from "@mui/icons-material/Info";
import { ActionsCheckListGroup } from './ActionsCheckListGroup';
import { styled } from '@material-ui/styles';

var deepEqual = require("deep-equal");

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        width: 1200,
    },
});

export const CheckListGroupMethod = ({
    name_permission,
    name_elements,
    compareFirstArray,
    existe,
    setExiste,
    hasFilter = true,
    setNameConfigEqual,
    ...props
}) => {
    const { values, setFieldValue } = useFormikContext();
    const [infoMethod, setInfoMethod] = useState(false);

    const handleChecked = async (e, parentID, clusterID) => {
        let id = e.target.id;
        let parent = values[name_elements.name].filter(item => item.model_id === id || item.model_id === parentID || item.model_id === clusterID)[0];
        let process = parent?.process_cluster?.filter(proc => proc.process_id === id || proc.process_id === parentID)[0];
        let method = process?.methods_cluster?.filter(met => String(met.method_id) === id)[0];

        let arrayCluster = values[name_elements.name]
            .map((cluster) => {
                if (cluster.model_id === parent.model_id) {
                    cluster.process_cluster
                        ?.filter(e => process?.process_id ? e.process_id === process?.process_id : e)
                        .map((proc) => {
                            proc.methods_cluster
                                // ?.filter(m => method?.method_id ? m.method_id === method.method_id : m)
                                ?.map(met => met.active = e.target.checked && method?.method_id === met.method_id);
                            const isCheckMethod = proc.methods_cluster.filter(met => met.active);
                            (isCheckMethod.length === 0) && (proc.methods_cluster[0].active = e.target.checked);
                            proc.active = (isCheckMethod.length >= 0) ? e.target.checked : false;
                        });
                    const isCheckChild = cluster.process_cluster.filter(proc => proc.active);
                    cluster.active = (isCheckChild.length > 0 || !cluster.active) ? true : false;
                }
                return cluster;
            });

        setFieldValue(name_elements.name, arrayCluster);
        compare_elements(arrayCluster);
    };

    const compare_elements = (element2) => {
        const isEquals = compareFirstArray.find(item => deepEqual(item[name_elements.model], element2))?.[name_elements.model_name];
        if (isEquals) {
            setNameConfigEqual(isEquals);
        }
        setExiste(isEquals ? true : false);
        !existe && setFieldValue(name_permission, "");
        console.log('end compare', compareFirstArray, isEquals)
    };

    return (
        <React.Fragment>
            {
                values[name_elements.name] && values[name_elements.name].map((model, index) => {
                    return (!hasFilter || model?.model_id.split("_")[0] === name_elements.cod_model_logic) && (
                        <Box key={`${model?.model_id}${index}`}>
                            <Stack
                                direction={"row"}
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    pt: 1,
                                }}
                            >
                                <FormControlLabel
                                    label={model.model_alias}
                                    control={
                                        <Checkbox
                                            id={model.model_id}
                                            name={name_elements.name}
                                            sx={{
                                                "&.Mui-checked": {
                                                    color: 'common.title'
                                                }
                                            }}
                                            value={model.active}
                                            onChange={(e) => handleChecked(e, model.model_id)}
                                            checked={model.active}
                                        />
                                    }
                                />
                            </Stack>
                            {model.process_cluster.map((process, i) => {
                                return (
                                    <Stack
                                        key={i}
                                        direction={"row"}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            mb: 1,
                                        }}
                                    >
                                        <FormControlLabel
                                            key={i}
                                            label={process.process_alias}
                                            sx={{ pl: 3 }}
                                            control={
                                                <Checkbox
                                                    id={process.process_id}
                                                    value={process.active}
                                                    sx={{
                                                        "&.Mui-checked": {
                                                            color: 'common.title'
                                                        }
                                                    }}
                                                    checked={process.active}
                                                    label={process.process_alias}
                                                    onChange={(e) => handleChecked(e, model.model_id)}
                                                />
                                            }
                                        />

                                        <Box>
                                            {process.methods_cluster.map((method, j) => {
                                                return (
                                                    <>
                                                        <Stack
                                                            key={j}
                                                            direction={"row"}
                                                            sx={{ display: "flex", mb: 1 }}
                                                        >
                                                            <FormControlLabel
                                                                key={j}
                                                                label={method.method_alias}
                                                                sx={{ pl: 3 }}
                                                                control={
                                                                    <Checkbox
                                                                        id={`${String(method.method_id)}`}
                                                                        value={method.active}
                                                                        checked={method.active}
                                                                        sx={{
                                                                            "&.Mui-checked": {
                                                                                color: 'common.title'
                                                                            }
                                                                        }}
                                                                        disabled={process.active ? false : true}
                                                                        label={method.method_alias}
                                                                        onChange={(e) =>
                                                                            handleChecked(
                                                                                e,
                                                                                process.process_id,
                                                                                model.model_id
                                                                            )
                                                                        }
                                                                    />
                                                                }
                                                            />
                                                            <CustomWidthTooltip
                                                                title={method.method_description}
                                                                TransitionComponent={Zoom}
                                                            >
                                                                <IconButton
                                                                    onClick={(e) => {
                                                                        setInfoMethod(!infoMethod);
                                                                    }}
                                                                >
                                                                    <InfoIcon />
                                                                </IconButton>
                                                            </CustomWidthTooltip>
                                                        </Stack>
                                                        {infoMethod && (
                                                            <Box
                                                                sx={{
                                                                    width: "100%",

                                                                    mx: 8,
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{ fontSize: 16, color: "black", pr: 5 }}
                                                                >
                                                                    {method.method_description}
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                    </>
                                                );
                                            })}
                                        </Box>
                                    </Stack>
                                );
                            })}
                        </Box>
                    )
                })}
        </React.Fragment>
    )
}
