import React, { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import CronjobsService from "../../services/CronjobsService";
import { useField, useFormikContext } from "formik";
import { CustomSelect } from "./CustomSelect";


export const SelectCommand = ({ name, label, commands }) => {

  return (
    <FormControl fullWidth>
      <CustomSelect
        sx={{
          borderRadius: 20,
          marginBottom: 1.4,
          height: 50,
          borderColor:
            "linear-gradient(90deg, rgba(7,64,107,1) 0%, rgba(7,64,107,1) 35%, rgba(0,184,255,1) 100%)",
        }}
        id={name}
        label={label}
        labelId={label}
        name={name}
      >
        {commands.map((command, index) => (
          <MenuItem key={command.command_id} value={command.command_name}>{command.description}</MenuItem>
        ))}
      </CustomSelect>
    </FormControl>
  );
};
