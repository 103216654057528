import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { SidebarService } from "../../services/SidebarService";
import { ActionsCheckListGroup } from "./ActionsCheckListGroup";
var deepEqual = require("deep-equal");

export const CheckListGroup = ({
  name_permission,
  name_elements,
  compareFirstArray,
  existe,
  setExiste,
  hasFilter = true,
  setNameConfigEqual,
  ...props
}) => {
  const { values, handleChange, setFieldValue } = useFormikContext();

  const [configs, setConfigs] = useState([]);

  const getModulexCompany = async () => {
    try {
      const { data } = await SidebarService.getElementsLogic();

      setConfigs(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getModulexCompany();
  }, []);

  const handleChecked = async (e, parentID, clusterID) => {
    let id = e.target.id;
    let parent = values[name_elements.name].filter(
      (item) =>
        item.modulo_id === id ||
        item.modulo_id === parentID ||
        item.modulo_id === clusterID
    )[0];
    let modules = parent?.modulo_cluster?.filter(
      (elem) => elem.element_id === id || elem.element_id === parentID
    )[0];
    let actions = modules?.actions?.filter(
      (act) => String(act.action_id) === id
    )[0];

    let arrayCluster = values[name_elements.name].map((cluster) => {
      if (cluster.modulo_id === parent.modulo_id) {
        cluster.modulo_cluster
          ?.filter((e) =>
            modules?.element_id ? e.element_id === modules?.element_id : e
          )
          .map((elem) => {
            elem.actions
              ?.filter((a) =>
                actions?.action_id ? a.action_id === actions.action_id : a
              )
              .map((act) => (act.active = e.target.checked));
            const isCheckAction = elem.actions.filter((act) => act.active);
            elem.active = isCheckAction.length > 0 ? true : false;
          });
        const isCheckChild = cluster?.modulo_cluster.filter(
          (elem) => elem.active
        );
        cluster.active =
          isCheckChild.length > 0 || !cluster.active ? true : false;
      }
      return cluster;
    });

    compare_elements(arrayCluster);
    setFieldValue(name_elements.name, arrayCluster);
  };

  const compare_elements = (element2) => {
    let firstArray = compareFirstArray ? compareFirstArray : configs;
    let isEquals = firstArray?.find((item) =>
      deepEqual(item[name_elements.cluster], element2)
    );
    // console.log('config', firstArray)
    if (isEquals) {
      // console.log('isEquals', isEquals);
      setNameConfigEqual(isEquals?.[name_elements.cluster_name]);
      setFieldValue(name_permission, isEquals[name_elements.cluster_id]);
    }
    setExiste(isEquals ? true : false);
    !isEquals && setFieldValue(name_permission, "");
  };

  return (
    <React.Fragment>
      {values[name_elements.name] &&
        values[name_elements.name].map((cluster, index) => {
          return (
            (!hasFilter ||
              cluster?.modulo_id.split("_")[0] ===
              name_elements.cod_element_logic) && (
              <Box key={index}>

                <Stack
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    pt: 1,
                  }}
                >
                  <FormControlLabel
                    label={cluster.modulo_name}
                    control={
                      <Checkbox
                        id={cluster.modulo_id}
                        name={name_elements.name}
                        value={cluster.active}
                        sx={{
                          "&.Mui-checked": {
                            color: "common.title",
                          },
                        }}
                        onChange={(e) => handleChecked(e, cluster.modulo_id)}
                        checked={cluster.active}
                      />
                    }
                  />
                  {/* header icons actions */}
                  <ActionsCheckListGroup />
                </Stack>

                {cluster?.modulo_cluster &&
                  cluster.modulo_cluster.map((element, index) => (
                    <Stack
                      key={index}
                      direction={"row"}
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <FormControlLabel
                        key={index}
                        label={element.element_name}
                        sx={{ pl: 3 }}
                        control={
                          <Checkbox
                            id={element.element_id}
                            value={element.active}
                            checked={element.active}
                            sx={{
                              "&.Mui-checked": {
                                color: "common.title",
                              },
                            }}
                            label={element.element_name}
                            onChange={(e) =>
                              handleChecked(e, cluster.modulo_id)
                            }
                          />
                        }
                      />

                      <Box>
                        {element.actions.map((action, index) => {
                          return (
                            <Tooltip title={action.action_name} key={index}>
                              <Checkbox
                                id={`${String(action.action_id)}`}
                                value={action.active}
                                checked={action.active}
                                disabled={element.active ? false : true}
                                label={action.action_name}
                                sx={{
                                  "&.Mui-checked": {
                                    color: "common.title",
                                  },
                                }}
                                onChange={(e) =>
                                  handleChecked(
                                    e,
                                    element.element_id,
                                    cluster.modulo_id
                                  )
                                }
                              />
                            </Tooltip>
                          );
                        })}
                      </Box>
                    </Stack>
                  ))}
              </Box>
            )
          );
        })}
    </React.Fragment>
  );
};
