import { Box } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { countBy } from "lodash";

export const MyResponsiveBar = ({ data, keys }) => (
  <ResponsiveBar
    data={data ? data : []}
    keys={
      keys ? keys : []
    }
    indexBy="cab"
    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
    // margin={{ top: 50, right: isOpenB ? 0 : 400, bottom: 50, left: 60 }}
    padding={0.25}
    valueScale={{ type: "linear" }}
    indexScale={{ type: "band", round: true }}
    colors={({ id, data }) =>( 
      console.log(data),
      data[`${id}Color`])}
    colorBy={'id'}
    borderColor={{
      from: "color",
      modifiers: [["darker", 1.6]],
    }}
    axisTop={null}
    axisRight={null}
    axisBottom={null}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legendOffset: -40,
      legendPosition: "middle",
      renderTick: (tick) => {
        return (
          <g transform={`translate(${tick.x},${tick.y})`}>
            <text
              textAnchor="end"
              dominantBaseline="central"
              style={{
                fontSize: "10px",
                fill: "#000",
              }}
            >
              {tick.value}
            </text>
          </g>
        );
      },
      ariaHidden: true,
      // format: (value) => `${value} %`,
    }}
    labelSkipHeight={12}
    labelTextColor={{
      from: "color",
      modifiers: [[
        "darker",
        1.6,
      ]],
    }}
    labelSkipWidth={12}
    tooltipLabel={(tooltip) => {
      return <text style={{
        fontSize: "12px",
        fill: "#000",
      }}>{`${tooltip.id}`}</text>;
    }}
    // tooltip={(tooltip) => {
    //   return <text style={{
    //     fontSize: "12px",
    //     // fill: "#000",
    //   }}>{`${tooltip.id} ${tooltip.value}`}</text>;
    // }}
    defs={[
      {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: '#38bcb2',
          size: 4,
          padding: 1,
          stagger: true
      },
      {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: '#eed312',
          rotation: -45,
          lineWidth: 6,
          spacing: 10
      }
  ]}

    legends={[
      {
        anchor: "bottom-right",
        direction: "column",
        justify: false,
        translateX: 100,
        translateY: 0,
        itemsSpacing: 0,
        itemDirection: "left-to-right",
        itemWidth: 100,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: "circle",
        symbolBorderColor: "rgba(0, 0, 0, .5)",
        effects: [
          {
            on: "hover",
            style: {
              itemOpacity: 1,
              "itemBackground": "rgba(0, 0, 0, .03)",
            },
          },
        ],
      },
    ]}
    role="application"
    ariaLabel="Bar chart"
    barAriaLabel={(bar) => `${bar.id} ${bar.value}`}
  />
);
