import * as Yup from "yup";
import checkoutFormModel from "./companyFormModel";

const {
    formField: {
        company_name,
        description,
        company_phone,
        contact_name,
        contact_phone,
        contact_email,
        branch_name,
        branch_description,
        branch_phone,
        branch_contact_name,
        branch_contact_phone,
        branch_contact_email,
        select_permission,
        select_permission_branch,
        rol_name,
        model_logic_id,
        model_logic_company,
        model_logic_id_branch,
        cluster_name,
        elements_cluster,
        elements_module,
        model_logic_branch,
        model_logic_name
    }
} = checkoutFormModel;

//Validacion de formulario de las empresas
export const validateFormCompany = [
    Yup.object({
        [company_name.name]: Yup.string()
            .min(company_name.min, company_name.minErrorMsg)
            .required(company_name.requiredErrorMsg),
        [description.name]: Yup.string()
            .min(description.min, description.minErrorMsg)
            // .max(description.max, description.maxErrorMsg)
            .required(description.requiredErrorMsg),
        [company_phone.name]: Yup.string()
            .min(company_phone.min, company_phone.minErrorMsg)
            .max(company_phone.max, company_phone.maxErrorMsg)
            .required(company_phone.requiredErrorMsg)
            .typeError(company_phone.typeErrorMsg),
        [contact_name.name]: Yup.string()
            .min(contact_name.min, contact_name.minErrorMsg)
            .required(contact_name.requiredErrorMsg),
        [contact_phone.name]: Yup.string()
            .min(contact_phone.min, contact_phone.minErrorMsg)
            .max(contact_phone.max, contact_phone.maxErrorMsg)
            .required(contact_phone.requiredErrorMsg)
            .typeError(contact_phone.typeErrorMsg),
        [contact_email.name]: Yup.string()
            .required(contact_email.requiredErrorMsg)
            .email(contact_email.invalidErrorMsg),
    }),
    Yup.object({
        [select_permission.name]: Yup.string()
            .when(['rol_name', 'elements_module'], {
                is: (rol_name, elements_module) => elements_module?.filter(e => e.active).length === 0,
                then: Yup.string().required(select_permission.requiredErrorMsg),
            }),
        [rol_name.name]: Yup.string()
            .when(elements_module.name, {
                is: (elements_module) => elements_module.length === 0,
                then: Yup.string().required(elements_module.requiredErrorMsg)
            })
            .min(rol_name.min, rol_name.minErrorMsg)
            .max(rol_name.max, rol_name.maxErrorMsg),
        [elements_module.name]: Yup.array().min(0, elements_module.requiredErrorMsg),
    }),
    Yup.object({
        [model_logic_id.name]: Yup.string()
            .when(['cluster_name', 'model_logic_company'], {
                is: (cluster_name, model_logic_company) => model_logic_company?.filter(m => m.active).length === 0,
                then: Yup.string().required(model_logic_id.requiredErrorMsg),
            }),
        [model_logic_company.name]: Yup.array().min(0, model_logic_company.requiredErrorMsg),
    }),
    Yup.object({
        [branch_name.name]: Yup.string().required(branch_name.requiredErrorMsg),
        [branch_description.name]: Yup.string()
            .min(branch_description.min, branch_description.minErrorMsg)
            // .max(branch_description.max, branch_description.maxErrorMsg)
            .required(branch_description.requiredErrorMsg),
        [branch_phone.name]: Yup.string()
            .min(branch_phone.min, branch_phone.minErrorMsg)
            .max(branch_phone.max, branch_phone.maxErrorMsg)
            .required(branch_phone.requiredErrorMsg)
            .typeError(branch_phone.typeErrorMsg),
        [branch_contact_name.name]: Yup.string()
            .min(branch_contact_name.min, branch_contact_name.minErrorMsg)
            .required(branch_contact_name.requiredErrorMsg),
        [branch_contact_phone.name]: Yup.string()
            .min(branch_contact_phone.min, branch_contact_phone.minErrorMsg)
            .max(branch_contact_phone.max, branch_contact_phone.maxErrorMsg)
            .required(branch_contact_phone.requiredErrorMsg)
            .typeError(branch_contact_phone.typeErrorMsg),
        [branch_contact_email.name]: Yup.string()
            .required(branch_contact_email.requiredErrorMsg)
            .email(branch_contact_email.invalidErrorMsg),
    }),
    Yup.object({
        [select_permission_branch.name]: Yup.string()
            .when(['rol_name', 'elements_cluster'], {
                is: (rol_name, elements_cluster) => elements_cluster?.filter(e => e.active).length === 0,
                then: Yup.string().required(select_permission_branch.requiredErrorMsg),
            }),
        [elements_cluster.name]: Yup.array().of(
            Yup.object().shape({
                active: Yup.boolean().required(elements_cluster.requiredErrorMsg),
            })
        ),
    }),
    Yup.object({
        [model_logic_id_branch.name]: Yup.string()
            .when(['cluster_name', 'model_logic_branch'], {
                is: (cluster_name, model_logic_branch) => model_logic_branch?.filter(m => m.active).length === 0,
                then: Yup.string().required(model_logic_id_branch.requiredErrorMsg),
            }),
        [model_logic_branch.name]: Yup.array().of(
            Yup.object().shape({
                active: Yup.boolean().required(model_logic_branch.requiredErrorMsg),
            })
        ),
    }),
];

export const validateCreatePermission = Yup.object({
    [rol_name.name]: Yup.string()
        .min(rol_name.min, rol_name.minErrorMsg)
        .max(rol_name.max, rol_name.maxErrorMsg)
        .required(rol_name.requiredErrorMsg),
})
export const validateCreateConfig = Yup.object({
    [cluster_name.name]: Yup.string()
        .min(cluster_name.min, cluster_name.minErrorMsg)
        .max(cluster_name.max, cluster_name.maxErrorMsg)
        .required(cluster_name.requiredErrorMsg),
})