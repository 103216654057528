import { Box, FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { t } from "i18next";
import React, { useState, useContext } from "react";
import { FormTextInput } from "../../../../common/Components/FormTextInput";
import { InputPassword } from "../../../../common/Components/InputPassword";
import { SelectGender } from "../../../../common/Components/SelectGender";
import { TitleStep } from "../../../../common/Components/TitleStep";
import ModulesFormContext from "../../../../common/Contexts/ModulesFormContext";
import userFormModel from "../../../../model/user/userFormModel";
import AuthService from "../../../../services/AuthService";
import UserContext from "../../../../common/Contexts/UserContext";
import { CSwitchForm } from "../../../../common/Components/CSwitch";
import { CSwitchAdmin } from "../components/CSwitchAdmin";
import { CToast } from "../../../../common/Components/ToastNotify";
// import { CToast } from '../../../common/Components/ToastNotify';

export const DataUser = (props) => {
  const { rowData: user } = useContext(ModulesFormContext);
  const { userGlobal } = useContext(UserContext);
  // console.log(userGlobal)
  const {
    errors,
    validateForm,
    getFieldMeta,
    setFieldError,
    values,
    setSubmitting,
    setStatus,
    handleChange,
  } = useFormikContext();

  const [isLoadingValidate, setIsLoadingValidate] = useState(false);
  // const [errorEmail, setErrorEmail] = useState()
  const { formField } = userFormModel;
  const {
    first_name,
    last_name,
    email,
    isSuperAdmin,
    gender,
    password,
    password_confirmation,
    rut,
  } = formField;

  const handleValidateMail = async (searchString) => {
    if (user?.email !== values.email && !getFieldMeta(email.name).error) {
      setIsLoadingValidate(true);
      const req = { searchString };
      try {
        const res = (await AuthService.validateEmail(req)).data;
        if (res.existe) {
          setFieldError(email.name, t("messages.error_mail_isExist"));
          setStatus({ isValid: false });
        }
        setIsLoadingValidate(false);
      } catch ({ response }) {
        console.log(response);
        CToast(t('messages.error_validate'), 'error', true);
      }finally{
        setIsLoadingValidate(false);
      }
    }
    setIsLoadingValidate(false);
  };

  return (
    <React.Fragment>
      <TitleStep title={t("steps.user.step1")} />
      <Stack
        py={2}
        direction={"row"}
        justifyContent={"space-around"}
        paddingX={8}
        spacing={5}
      >
        <Stack
          width={"100%"}
          height="100%"
          direction={"column"}
          justifyContent={"space-evenly"}
        >
          <FormTextInput
            label={email.label}
            name={email.name}
            onBlur={({ target }) =>
              target.value !== ""
                ? handleValidateMail(target.value)
                : validateForm()
            }
            autoFocus={true}
            autoComplete="off"
            style={{ width: "95%" }}
            helperText={
              isLoadingValidate ? (
                "Validando..."
              ) : (
                <span style={{ color: "red", fontSize: 12, textAlign: "left" }}>
                  {errors[email.name]}
                </span>
              )
            }
          />

          <FormTextInput
            label={first_name.label}
            name={first_name.name}
            style={{ width: "95%" }}
            fullWidth={true}
            disabled={isLoadingValidate || errors[email.name] ? true : false}
          />
          <FormTextInput
            label={last_name.label}
            name={last_name.name}
            style={{ width: "95%" }}
            fullWidth={true}
            disabled={isLoadingValidate || errors[email.name] ? true : false}
          />
          {userGlobal.isSuperAdmin && (
            <Box
           
            >

                <CSwitchAdmin
                    label={isSuperAdmin.label}
                    name={isSuperAdmin.name}
                    onChange={handleChange}
                    id={values.id}
                    value={values.isSuperAdmin}
                />
      
              {/* <label>{isSuperAdmin.label}</label>
              <input
                defaultChecked={values.isSuperAdmin}
                name={isSuperAdmin.name}
                onChange={handleChange}
                type={"checkbox"}
                style={{ width: 20, height: 20, borderRadius: 8 }}
              /> */}
            </Box>
          )}
        </Stack>
        <Stack
          width={"100%"}
          height="100%"
          direction={"column"}
          justifyContent={"space-evenly"}
        >
          <SelectGender
            name={gender.name}
            label={gender.label}
            disabled={isLoadingValidate || errors[email.name] ? true : false}
          />
          <FormTextInput
            label={rut.label}
            name={rut.name}
            autoComplete="off"
            type="text"
            style={{ width: "100%" }}
            fullWidth={true}
            disabled={isLoadingValidate || errors[email.name] ? true : false}
          />
          {!values.isEditing && (
            <React.Fragment>
              <InputPassword
                name={password.name}
                label={password.label}
                autoComplete="off"
                disabled={
                  isLoadingValidate || errors[email.name] ? true : false
                }
              />
              <InputPassword
                name={password_confirmation.name}
                label={password_confirmation.label}
                autoComplete="off"
                disabled={
                  isLoadingValidate || errors[email.name] ? true : false
                }
              />
            </React.Fragment>
          )}
        </Stack>
      </Stack>
    </React.Fragment>
  );
};
