import { useContext, useState, useEffect } from 'react'
import { Box } from "@mui/material";
import Title from "../../../common/Components/Title";
import { ButtonsFileCSV } from "../../../common/Components/ButtonsFileCSV";
import BranchsContext from '../../../common/Contexts/BranchContext';
import ExludeGIDService from '../../../services/ExcludeGidService';
import ModulesFormContext from '../../../common/Contexts/ModulesFormContext';
import { useForm } from '../../../hooks/useForm';
import { t } from 'i18next'
import { CustomModalDelete } from '../../../common/Components/CustomModalDelete';
import { ExcludeGidForm } from './ExcludeGidForm';
import { LayoutTable } from '../../../common/Components/LayoutTable';
import { ModulesProvider } from '../../../common/Providers/ModulesProvider';
import { CToast } from '../../../common/Components/ToastNotify';
import { TableExcludeGid } from './components/TableExcludeGID';
import { ModalOpenEnum } from '../../../common/utils/ModalOpenEnum';



const ExcludeGID = () => {
    const { branchID } = useContext(BranchsContext);
    const { overallStyle } = useContext(ModulesFormContext);
    //LOGIC FORM
    const {
        isOpen,
        rowData,
        toggleModal,
        dataTable,
        setDataTable,
        isTableLoading,
        setIsTableLoading
    } = useForm();
    const [isLoadingDelet, setIsLoadingDelet] = useState(false)

    const getDataTable = async () => {
        setIsTableLoading(true);
        const req = {
            company_id: branchID,
        };
        try {
            const { data } = await ExludeGIDService.getExcludeGIDByBranch(req);
            setDataTable(data);
        } catch ({ response }) {
            console.log(response)
        } finally {
            setIsTableLoading(false);
        }
    };

    const handleDeleteGID = async () => {
        setIsLoadingDelet(true);
        const req = {
            id_guiasDeTransito: rowData?.id_guiasDeTransito,
            company_id: branchID,
        }
        try {
            const { data } = await ExludeGIDService.deleteExcludeGID(req);
            await getDataTable();
            CToast(t('messages.success_delete'), 'success', true);
        } catch ({ response }) {
            console.log(response)
            CToast(t('messages.error_delete'), 'error', true);
        } finally {
            setIsLoadingDelet(false);
        }
    }

    useEffect(() => {
        branchID.length > 0 && getDataTable();
    }, [branchID]);

    return (
        <ModulesProvider value={{
            getDataTable,
            handleDelete: handleDeleteGID,
            isOpen,
            rowData,
            toggleModal,
            dataTable,
            setDataTable, isTableLoading,
        }}>
            <Box sx={overallStyle?.container}>
                <Title name={t('titles.guide_transits')} />
                <ButtonsFileCSV />
                <LayoutTable labelAction={'Agregar guía'}>
                    {isOpen[ModalOpenEnum.EDIT] && <ExcludeGidForm />}
                    {isOpen[ModalOpenEnum.DELETE] && (
                        <CustomModalDelete
                            descriptionLabel={rowData?.transfer_id ? rowData.transfer_id : ''}
                            isLoading={isLoadingDelet}
                            moduleLabel={'guide_transit'}
                        />)
                    }
                    <TableExcludeGid />
                </LayoutTable>
            </Box>
        </ModulesProvider>
    )
}


export default ExcludeGID