import authAxios from "../common/Auth/authAxios"

export const SidebarService = {
    getAllModules: async () => {
        return await authAxios.get('/element-logic/modulos')
    },
    getElementsLogic: async () => {
        return await authAxios.get('/element-logic/elements-logic')
    },
    getModulesByCompany: async (company) => {
        return await authAxios.get('/element-logic/getModulosPorEmpresa', company)
    },
    getModelLogic: async (params) => {
        const query = params ? `?${params}` : '?pageNumber=1'
        return await authAxios.get(`/model-logic/getModelLogic${query}`)
    },
    getModel: async (params) => {
        const query = params ? `?${params}` : '?pageNumber=1'
        return await authAxios.get(`/model-logic/models${query}`)
    },
    createElementLogic: async (elementLogic) => {
        return await authAxios.post('/element-logic/addElementLogic', elementLogic)
    },
    createModelLogic: async (modelLogic) => {
        return await authAxios.post('/model-logic/addModelLogic', modelLogic)
    },
}