import checkoutFormModel from "./centerDistributionFormModel";
import * as Yup from "yup";
const { formField: {
    cod_cd,
    cd,
    priority,
    region,
    comuna,
    ciudad,
    direccion,
    latitude,
    longitud,
} } = checkoutFormModel;
export const validateFormDistributionCenter = Yup.object({
    [cod_cd.name]: Yup.string().required(cod_cd.requiredErrorMsg),
    [cd.name]: Yup.string().required(cd.requiredErrorMsg),
    [priority.name]: Yup.number().required(priority.requiredErrorMsg),
    [region.name]: Yup.string().required(region.requiredErrorMsg),
    [comuna.name]: Yup.string().required(comuna.requiredErrorMsg),
    [ciudad.name]: Yup.string().required(ciudad.requiredErrorMsg),
    [direccion.name]: Yup.string().required(direccion.requiredErrorMsg),
    [latitude.name]: Yup.string().required(latitude.requiredErrorMsg),
    [longitud.name]: Yup.string().required(longitud.requiredErrorMsg),
});