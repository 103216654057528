import { Box, FormControlLabel, Switch, Typography } from "@mui/material";
import { useField } from "formik";

export const CSwitchAdmin = ({ label, ...props }) => {
  const [field] = useField(props);
  const { value } = field;
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      mt={1}
      paddingX={1}
      flexDirection={"row"}
    >
      <Typography
        color={"common.title"}
        variant={"h6"}

      >
        {label}
      </Typography>
      <FormControlLabel
        control={
          <Switch
            id={props.id}
            value={field.value}
            sx={{
              "& .MuiSwitch-switchBase": {
                color: "common.title",
                margin: 1,
                padding: 0,
                transform: "translateX(6px)",
                "&.Mui-checked": {
                    color: "common.title",
                    transform: "translateX(22px)",
                    "& .MuiSwitch-thumb:before": {
                        backgroundColor: "common.title",
                        borderRadius: "50%",
                       
                    },
                    "& + .MuiSwitch-track": {
                        opacity: 1,
                        backgroundColor: "common.title",
                    },
                },
            },

  


            }}
            onChange={props.onChange}
            onBlur={field.onBlur}
            size="medium"
            checked={field.value}
            {...field}
            {...props}
          />
        }
        label={`${field.value ? "Activo" : "Inactivo"}`}
      />
    </Box>
  );
};
