import React, { useContext } from "react";
import BranchsContext from "../../../../common/Contexts/BranchContext";
import { CustomTable } from "../../../../common/Components/CustomTable";
import checkoutForm from "../../../../model/warehouse/warehouseFormModel";

export const TableWarehouse = () => {
  const { branchID } = useContext(BranchsContext);
  const { formField: {
    cod_bodega,
    bodega,
    canal,
    region,
    comuna,
    ciudad,
    direccion,
    latitude,
    longitud
  } } = checkoutForm;

  const headers = [
    { field: "cod_bodega", headerName: cod_bodega.headerTable },
    { field: "bodega", headerName: bodega.headerTable },
    { field: "canal", headerName: canal.headerTable },
    { field: "region", headerName: region.headerTable },
    { field: "comuna", headerName: comuna.headerTable },
    { field: "ciudad", headerName: ciudad.headerTable },
    { field: "direccion", headerName: direccion.headerTable },
    { field: "latitude", headerName: latitude.headerTable },
    { field: "longitud", headerName: longitud.headerTable },
  ];
  return (<CustomTable headers={headers} disabledButton={branchID.length > 0 ? false : true} />)
};
