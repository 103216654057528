import checkoutFormModel from "./cronjobsFormModel";
import * as Yup from "yup";
import isEmpty from 'lodash/isEmpty'
const {
    formField: {
        job_name,
        description,
        command,
        parametrosConsole,
        parametrosSend,
        start_time,
        cronExp,
    }
} = checkoutFormModel;
const { message, hora, sucursal } = parametrosConsole;
const { messageEmail, to, subjet } = parametrosSend;

export const validateFormCronJobs = Yup.object({
    [job_name.name]: Yup.string().required(job_name.requiredErrorMsg),
    [description.name]: Yup.string().required(description.requiredErrorMsg),
    [command.name]: Yup.string()
        .required(command.requiredErrorMsg),
    [parametrosConsole.name]: Yup.object().when(command.name, {
        is: (command) => command === parametrosConsole.name,
        then: Yup.object({
            [message.name]: Yup.string().required(message.requiredErrorMsg),
            [hora.name]: Yup.string().required(hora.requiredErrorMsg),
            [sucursal.name]: Yup.string().required(sucursal.requiredErrorMsg),
        }).required()
    }),
    [parametrosSend.name]: Yup.object().when(command.name, {
        is: (command) => command === parametrosSend.name,
        then: Yup.object({
            [to.name]: Yup.string()
                .email(to.invalidErrorMsg)
                .required(to.requiredErrorMsg),
            [messageEmail.name]: Yup.string().required(messageEmail.requiredErrorMsg),
            [subjet.name]: Yup.string().required(subjet.requiredErrorMsg),
        }).required()
    }),
    [start_time.name]: Yup.string().required(start_time.requiredErrorMsg)
});

