export default {
    formId: 'homologationsForm',
    formField: {
        old_su: {
            name: 'old_su',
            label: 'Código antiguo del producto',
            requiredErrorMsg: 'El ultimo SKU es requerido'
        },
        new_sku: {
            name: 'new_sku',
            label: 'Código nuevo del producto',
            requiredErrorMsg: 'El SKU nuevo es requerido'
        },
    }
}