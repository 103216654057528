import React, { useContext, useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Typography } from "@mui/material";
import BranchsContext from "../../../../common/Contexts/BranchContext";
import { CustomSelect } from "../../../../common/Components/CustomSelect";
import DistributionService from "../../../../services/DistributionCentresService";

var store = require("store");

export const SelectCenterDistribution = ({ name, label }) => {

  const { branchID } = useContext(BranchsContext);
  const [isLoading, setIsLoading] = useState(false);
  const [centerDistribution, setCenterDistribution] = useState([]);

  const getCentreDistribution = async () => {
    const req = {
      company_id: branchID,
    };
    try {
      setIsLoading(true);
      const { data } = await DistributionService.getDistributionCenterByBranch(req);
      setCenterDistribution(data);
    } catch ({ response }) {
      console.log(response);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCentreDistribution();
  }, [branchID]);

  return (
    <>
      <Typography mb={1} color={"primary.main"} variant={"h6"}>
        Seleccionar {label || "Centro de distribución"}
      </Typography>
      <FormControl fullWidth>
        <CustomSelect
          sx={{
            borderRadius: 20,
            marginBottom: 1.4,
            height: 50,
            borderColor:
              "linear-gradient(90deg, rgba(7,64,107,1) 0%, rgba(7,64,107,1) 35%, rgba(0,184,255,1) 100%)",
          }}
          id={name || "id_centrodedistribucion"}
          label={label || "Centro de distribución"}
          labelId={label || "Centro de distribución"}
          name={name || "id_centrodedistribucion"}
          isLoading={isLoading}
        >
          {centerDistribution.map((cd, index) => (
            <MenuItem key={index} value={cd?.id_centrodedistribucion}>
              {cd?.cd}
            </MenuItem>
          ))}
        </CustomSelect>
      </FormControl>
    </>
  );
};
