import { Box } from "@mui/material";
import React, { useState, useEffect, useContext, useMemo } from "react";
import Title from "../../../common/Components/Title";
import { styleSheet } from "../../../common/styles/StylesCommon";
import { t } from 'i18next';
import UsersService from "../../../services/UsersService";
import BranchsContext from "../../../common/Contexts/BranchContext";
import { userSteps } from "../../../common/utils/getLabelSteps";
import { useForm } from "../../../hooks/useForm";
import { TableUsers } from "./components/TableUser";
import { LayoutTable } from "../../../common/Components/LayoutTable";
import { useStep } from "../../../hooks/useStep";
import { CustomModalDelete } from "../../../common/Components/CustomModalDelete";
import { CToast } from "../../../common/Components/ToastNotify";
import { ModulesProvider } from "../../../common/Providers/ModulesProvider";
import { UserForm } from "./UserForm";
import { useLocation } from "react-router-dom";
import { ModalOpenEnum } from "../../../common/utils/ModalOpenEnum";


const Users = () => {
  //LOGIC STEPPERS
  const {
    activeStep,
    handleSetActiveStep,
    handleResetStep,
    isLastStep,
    handleNextStep,
  } = useStep(userSteps);
  const { branchID, companyID } = useContext(BranchsContext);
  const [dataTable, setDataTable] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // LOGIC FORM
  const { isOpen, rowData, toggleModal } = useForm();
  const overallStyle = useMemo(() => styleSheet(), []);


  const getDataTable = async () => {
    setIsTableLoading(true);
    const req = {
      // company_id: companyID || branchID,
      company_id: branchID.length > 0 ? branchID : companyID,
    };
    try {
      const { data } = await UsersService.getUsersByCompany(req);
      setDataTable(data);
    } catch (error) {
      console.log(error);
      setDataTable([]);
    } finally {
      setIsTableLoading(false);
    }
  };

  const handeDeleteUser = async () => {
    setIsLoading(true);
    const req = {
      user_id: rowData?.user_id,
    }
    try {
      const { data } = await UsersService.deleteUser(req);
      CToast("Usuario eliminado correctamente", "success");
      console.log(data);
      getDataTable();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      CToast(error.response.data.message[0], "error");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDataTable();
  }, [companyID, branchID]);

  return (
    <ModulesProvider value={{
      dataTable,
      getDataTable,
      isTableLoading,
      activeStep,
      isLastStep,
      handleSetActiveStep,
      handleResetStep,
      handleNextStep,
      handleDelete: handeDeleteUser,
      steps: userSteps,
      isOpen, rowData, toggleModal,
    }}>
      <Box sx={overallStyle.container}>
        <Title name={t('modules.user')} />
        {/* <ButtonsFileCSV /> */}
        <LayoutTable labelAction={'Agregar Usuario'} >
          {isOpen[ModalOpenEnum.EDIT] && (<UserForm />)}
          {isOpen[ModalOpenEnum.DELETE] &&
            (<CustomModalDelete
              moduleLabel={'user'}
              descriptionLabel={rowData ? `${rowData.first_name} ${rowData.last_name}` : ''}
              isLoading={isLoading}
            />)}

          <TableUsers />
        </LayoutTable>
      </Box>
    </ModulesProvider>
  );
};

export default Users;
