export default {
    formId: 'rolesCheckoutForm',
    formField: {
        rol_name: {
            name: "rol_name",
            label: 'Nombre de la configuracion',
            min: 5,
            max: 20,
            minErrorMsg: "El nombre del rol debe tener al menos 5 caracteres",
            maxErrorMsg: "El nombre del rol debe tener menos de 20 caracteres",
            requiredErrorMsg: "El nombre del cluster es requerido",
        },
        select_rol_name: {
            name: "select_rol_name",
            label: "Seleccionar un rol predefinido",
            requiredErrorMsg: "Debe habilitar al menos un modulo. Crear o seleccionar uno predefinido",
        },
        modulo_cluster: {
            name: "rol_cluster",
            label: "Cluster",
            cluster: "rol_cluster",
            cluster_name: "rol_name",
            cluster_id: "id_rol",
            requiredErrorMsg: "Debe seleccionar al menos un cluster",
        },
    }
}