import { Box, Stack, Typography } from "@mui/material";
import React, { useContext, useMemo } from "react";
import MediaContext from "../../../common/Contexts/MediaContext";
import { styleSheet } from "../../../common/styles/StylesCommon";

export const BoxTitle = ({ title, subtitle }) => {
  const overallStyle = useMemo(() => styleSheet(), []);
  const { isTablet } = useContext(MediaContext);
  return (
    <Stack
      sx={{
        ...overallStyle.backgroundTitle,
        paddingLeft: "40px",
      }}
      boxShadow={2}
      marginY={5}
    >
      <Typography
        style={{
          ...overallStyle.titleH2,
          color: "white",
          fontSize: isTablet ? "15px" : "18px",
        }}
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography
          style={{
            ...overallStyle.titleH2,
            color: "white",
            fontSize: isTablet ? "15px" : "18px",
          }}
        >
          {subtitle}
        </Typography>
      )}
    </Stack>
  );
};
