import React from "react";
import { CustomTable } from "../../../../common/Components/CustomTable";
import { getWeekday } from "../../../../helpers/weekdays";

export const TableDailyMoney = () => {

  const headers = [
    { field: "dia", headerName: "Dia", valueFormatter: (params) => getWeekday(params.value).day_name },
    { field: "peso", headerName: "Peso" },
  ];

  return (<CustomTable headers={headers} />);
};
