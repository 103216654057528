import React from "react";
import { CustomTable } from "../../../../common/Components/CustomTable";

export const TableElements = () => {
  const headers = [
    { field: "element_name", headerName: "Nombre" },
    { field: "element_path", headerName: "Ruta" },
  ];

  return <CustomTable headers={headers} />;
};
