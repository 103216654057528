import authAxios from "../common/Auth/authAxios";

const SKUForCDService = {
    getSKUForCDByBranch: async (branch) => {
        return await authAxios.post('/inputs-generales/getSkuFCD', branch)
    },
    createSKUForCD: async (SKU) => {
        return await authAxios.post('/inputs-generales/addSkuFCD', SKU)
    },
    updateSKUForCD: async (SKU) => {
        return await authAxios.patch('/inputs-generales/editSkuFCD', SKU)
    },
    deleteSKUForCD: async (SKU) => {
        return await authAxios.delete('/inputs-generales/deleteSkuFCD',
            { data: SKU }
        )
    },
}

export default SKUForCDService