import React, { useContext, useEffect, useState } from "react";
import { FormControl, MenuItem } from "@mui/material";
import { CustomSelect } from "../../../../common/Components/CustomSelect";
import RadarControlService from "../../../../services/RadarControlService";
import { useFormikContext } from "formik";

export const SelectDescriptionCategory = ({
  name,
  label,
  disabled = false,
  dataCodeProduct,
  handleChange,
}) => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <>
      <FormControl fullWidth sx={{ mt: 1 }} disabled={disabled}>
        <CustomSelect
          sx={{
            borderRadius: 20,
            height: 50,
            width: '80%',
            marginBottom: 1.4,
            display: "flex",
            borderColor:
              "linear-gradient(90deg, rgba(7,64,107,1) 0%, rgba(7,64,107,1) 35%, rgba(0,184,255,1) 100%)",
          }}
          id={"category"}
          label={"Categoria"}
          name={"category"}
        >
          {dataCodeProduct.map((option, idx) => {
            return (
              <MenuItem
                key={`${option._id.param}-${idx}`}
                value={option._id.param}
                defaultValue={dataCodeProduct[0]?._id.param}
                onClick={({ target }) => handleChange(target.textContent)}
              >
                {option._id.param}
              </MenuItem>
            );
          })}
        </CustomSelect>
      </FormControl>
    </>
  );
};
