import { createTheme } from '@mui/material/styles';
import "@fontsource/nunito"

const lightTheme = createTheme({
    breakpoints: {
        keys: [
            'xs',
            'sm',
            'md',
            'lg',
            'xl'
        ],
        values: {
            'xs': 0,
            'sm': 600,
            'md': 900,
            'lg': 1200,
            'xl': 1536
        },
        unit: 'px'
    },
    direction: 'ltr',
    components: {

    },
    palette: {
        mode: 'light',
        primary: {
            main: "#090979",
            light: "rgb(59, 73, 103)",
            dark: "rgb(7, 19, 46)",
            contrastText: "#fff",
            background: 'linear-gradient(90deg, rgba(7,64,107,1), rgba(0,184,255,1))'
        },

        secondary: {
            main: "#00b8ff",
            light: "rgb(51, 197, 193)",
            dark: "rgb(0, 128, 124)",
            contrastText: "rgba(0, 0, 0, 0.87)"
        },
        common: {
            black: "#000000",
            white: "#FFFFFF",
            table: "#FFFFFF",
            divide: "#FFFFFF",
            title: "#090979",
            button: "#000000",
            border: "#090979",
            colorSidebar:'#01579b',
            textSidebar:'#FFFFFF',
            txtLabels:'#000000',
        },
        error: {
            main: "#d32f2f",
            light: "#ef5350",
            dark: "#c62828",
            contrastText: "#fff"
        },
        warning: {
            main: "#ED6C02",
            light: "#ff9800",
            dark: "#e65100",
            contrastText: "#fff"
        },
        info: {
            main: "#0288d1",
            light: "#03a9f4",
            dark: "#01579b",
            contrastText: "#fff"
        },
        success: {
            main: "#2e7d32",
            light: "#4caf50",
            dark: "#1b5e20",
            contrastText: "#fff"
        },
        grey: {
            50: "#fafafa",
            100: "#f5f5f5",
            200: "#eeeeee",
            300: "#e0e0e0",
            400: "#bdbdbd",
            500: "#9e9e9e",
            600: "#757575",
            700: "#616161",
            800: "#424242",
            900: "#212121",
            A100: "#f5f5f5",
            A200: "#eeeeee",
            A400: "#bdbdbd",
            A700: "#616161"
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
        text: {
            primary: "rgba(0, 0, 0, 0.87)",
            secondary: "rgba(0, 0, 0, 0.6)",
            disabled: "rgba(0, 0, 0, 0.38)"
        },
        divider: "rgba(0, 184, 255, 1)",
        background: {
            paper: "#ffffff",
            default: "#f8f8f8"
        },
        action: {
            active: "rgba(0, 0, 0, 0.54)",
            hover: "rgba(0, 0, 0, 0.04)",
            hoverOpacity: 0.04,
            selected: "rgba(0, 0, 0, 0.08)",
            selectedOpacity: 0.08,
            disabled: "rgba(0, 0, 0, 0.26)",
            disabledBackground: "rgba(0, 0, 0, 0.12)",
            disabledOpacity: 0.38,
            focus: "rgba(0, 0, 0, 0.12)",
            focusOpacity: 0.12,
            activatedOpacity: 0.12
        }
    },
    shape: {
        borderRadius: 4
    },
    mixins: {
        toolbar: {
            minHeight: 56,
            "@media (min-width:0px) and (orientation: landscape)": {
                "minHeight": 48
            },
            "@media (min-width:600px)": {
                "minHeight": 64
            }
        }
    },
    shadows: "0px 1px 3px 0px rgba(0,0,0,0.12)",
    typography: {
        htmlFontSize: 16,
        fontFamily: ["nunito","nunito-light", "Helvetica", "Arial", "sans-serif"].join(','),
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        h1: {
            fontFamily: ["nunito","nunito-light","Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 300,
            fontSize: "6rem",
            lineHeight: 1.167,
            letterSpacing: "-0.01562em",
            color:'#F8F8F8'
        },
        h2: {
            fontFamily: ["nunito","nunito-light","Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 300,
            fontSize: "3.75rem",
            lineHeight: 1.2,
            letterSpacing: "-0.00833em",
            color:'#0A1C42'
        },
        h3: {
            fontFamily: ["nunito","nunito-light","Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 400,
            fontSize: "3rem",
            lineHeight: 1.167,
            letterSpacing: "0em",
            color:'#29b6f6'
        },
        h4: {
            fontFamily: ["nunito","nunito-light","Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 400,
            fontSize: "2.125rem",
            lineHeight: 1.235,
            letterSpacing: "0.00735em"
        },
        h5: {
            fontFamily: ["nunito","nunito-light","Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 400,
            fontSize: "1.5rem",
            lineHeight: 1.334,
            letterSpacing: "0em"
        },
        h6: {
            fontFamily: ["nunito", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 500,
            fontSize: "1.25rem",
            lineHeight: 1.6,
            letterSpacing: "0.0075em"
        },
        subtitle1: {
            fontFamily: ["nunito", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: 1.75,
            letterSpacing: "0.00938em"
        },
        subtitle2: {
            fontFamily: ["nunito", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: 1.57,
            letterSpacing: "0.00714em"
        },
        body1: {
            fontFamily: ["nunito", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: 1.5,
            letterSpacing: "0.00938em",
            color:'#666666'
            
        },
        body2: {
            fontFamily: ["nunito", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 400,
            fontSize: "0.875rem",
            lineHeight: 1.43,
            letterSpacing: "0.01071em",
            color:'#666666'
        },
        button: {
            fontFamily: ["nunito", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: 1.75,
            letterSpacing: "0.02857em",
            textTransform: "uppercase"
        },
        caption: {
            fontFamily: ["nunito", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 400,
            fontSize: "0.75rem",
            lineHeight: 1.66,
            letterSpacing: "0.03333em"
        },
        overline: {
            fontFamily: ["nunito", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 400,
            fontSize: "0.75rem",
            lineHeight: 2.66,
            letterSpacing: "0.08333em",
            textTransform: "uppercase"
        }
    },
    
    transitions: {
        easing: {
            easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
            easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
            easeIn: "cubic-bezier(0.4, 0, 1, 1)",
            sharp: "cubic-bezier(0.4, 0, 0.6, 1)"
        },
        duration: {
            shortest: 150,
            shorter: 200,
            short: 250,
            standard: 300,
            complex: 375,
            enteringScreen: 225,
            leavingScreen: 195
        }
    },
    zIndex: {
        mobileStepper: 1000,
        speedDial: 1050,
        appBar: 1100,
        drawer: 1200,
        modal: 1300,
        snackbar: 1400,
        tooltip: 1500
    }

});

const darkTheme = createTheme({

    breakpoints: {
        keys: [
            'xs',
            'sm',
            'md',
            'lg',
            'xl'
        ],
        values: {
            'xs': 0,
            'sm': 600,
            'md': 900,
            'lg': 1200,
            'xl': 1536
        },
        unit: 'px'
    },
    direction: 'ltr',
    components: {},
    palette: {
        mode: 'dark',
        primary: {
            main: "#000000",
            light: "rgb(51, 51, 51)",
            dark: "rgb(0, 0, 0)",
            contrastText: "#fff",
            mainGradient:"linear-gradient(to right, tomato, cyan)"
        },
        secondary: {
            main: "#00B7B2",
            light: "rgb(51, 197, 193)",
            dark: "rgb(0, 128, 124)",
            contrastText: "rgba(0, 0, 0, 0.87)"
        },
        common: {
            black: "#000000",
            white: "#F5f5f5",
            table: "#000000",
            divide: "#000000",
            title: "#FFFFFF",
            button: "#FFFFFF",
            border: "#E1E1E1",
            colorSidebar:'#004d7f',
            textSidebar:'#FFFFFF',
            txtLabels:'#FFFFFF',
        },
        error: {
            main: "#f44336",
            light: "#e57373",
            dark: "#d32f2f",
            contrastText: "#fff"
        },
        warning: {
            main: "#ffa726",
            light: "#ffb74d",
            dark: "#f57c00",
            contrastText: "rgba(0, 0, 0, 0.87)"
        },
        info: {
            main: "#29b6f6",
            light: "#4fc3f7",
            dark: "#0288d1",
            contrastText: "rgba(0, 0, 0, 0.87)"
        },
        success: {
            main: "#66bb6a",
            light: "#81c784",
            dark: "#388e3c",
            contrastText: "rgba(0, 0, 0, 0.87)"
        },
        grey: {
            50: "#fafafa",
            100: "#f5f5f5",
            200: "#eeeeee",
            300: "#e0e0e0",
            400: "#bdbdbd",
            500: "#9e9e9e",
            600: "#757575",
            700: "#616161",
            800: "#424242",
            900: "#212121",
            A100: "#f5f5f5",
            A200: "#eeeeee",
            A400: "#bdbdbd",
            A700: "#616161"
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
        text: {
            primary: "#ffffff",
            secondary: "rgba(255, 255, 255, 0.7)",
            disabled: "rgba(255, 255, 255, 0.5)",
            icon: "rgba(255, 255, 255, 0.5)"
        },
        divider: "rgba(0, 184, 255, 1)",
        background: {
            paper: "rgba(5, 25, 34, 1)",
            default:"rgba(11,59,80,1)"
        },
        

        action: {
            active: "#fff",
            hover: "rgba(255, 255, 255, 0.08)",
            hoverOpacity: 0.08,
            selected: "rgba(255, 255, 255, 0.16)",
            selectedOpacity: 0.16,
            disabled: "rgba(255, 255, 255, 0.3)",
            disabledBackground: "rgba(255, 255, 255, 0.12)",
            disabledOpacity: 0.38,
            focus: "rgba(255, 255, 255, 0.12)",
            focusOpacity: 0.12,
            activatedOpacity: 0.24
        }
    }, 
    shape: {
        borderRadius: 4
    },
    mixins: {
        toolbar: {
            minHeight: 56,
            "@media (min-width:0px) and (orientation: landscape)": {
                "minHeight": 48
            },
            "@media (min-width:600px)": {
                "minHeight": 64
            }
        }
    },
    shadows:'none',
    typography: {
        htmlFontSize: 16,
        fontFamily: ["nunito", "Helvetica", "Arial", "sans-serif"].join(','),
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        h1: {
            fontFamily: ["nunito", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 300,
            fontSize: "6rem",
            lineHeight: 1.167,
            letterSpacing: "-0.01562em",
            color:'#F8F8F8'
        
        },
        h2: {
            fontFamily: ["nunito", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 300,
            fontSize: "3.75rem",
            lineHeight: 1.2,
            letterSpacing: "-0.00833em",
            color:'#F8F8F8'
        },
        h3: {
            fontFamily: ["nunito", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 400,
            fontSize: "3rem",
            lineHeight: 1.167,
            letterSpacing: "0em",
            color:'#29b6f6'
        },
        h4: {
            fontFamily: ["nunito", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 400,
            fontSize: "2.125rem",
            lineHeight: 1.235,
            letterSpacing: "0.00735em",
            color:'#F8F8F8'
        },
        h5: {
            fontFamily: ["nunito", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 400,
            fontSize: "1.5rem",
            lineHeight: 1.334,
            letterSpacing: "0em",
            color:'#F8F8F8'
        },
        h6: {
            fontFamily: ["nunito", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 500,
            fontSize: "1.25rem",
            lineHeight: 1.6,
            letterSpacing: "0.0075em"
        },
        subtitle1: {
            fontFamily: ["nunito", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: 1.75,
            letterSpacing: "0.00938em"
        },
        subtitle2: {
            fontFamily: ["nunito", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: 1.57,
            letterSpacing: "0.00714em"
        },
        body1: {
            fontFamily: ["nunito", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: 1.5,
            letterSpacing: "0.00938em",
            color:'#F8F8F8'
        },
        body2: {
            fontFamily: ["nunito", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 400,
            fontSize: "0.875rem",
            lineHeight: 1.43,
            letterSpacing: "0.01071em",
            color:'#F8F8F8'
        },
        button: {
            fontFamily: ["nunito", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: 1.75,
            letterSpacing: "0.02857em",
            textTransform: "uppercase",
            color:'#F8F8F8'
            
        },
        caption: {
            fontFamily: ["nunito", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 400,
            fontSize: "0.75rem",
            lineHeight: 1.66,
            letterSpacing: "0.03333em"
        },
        overline: {
            fontFamily: ["nunito", "Helvetica", "Arial", "sans-serif"].join(','),
            fontWeight: 400,
            fontSize: "0.75rem",
            lineHeight: 2.66,
            letterSpacing: "0.08333em",
            textTransform: "uppercase"
        }
    },
    transitions: {
        easing: {
            easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
            easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
            easeIn: "cubic-bezier(0.4, 0, 1, 1)",
            sharp: "cubic-bezier(0.4, 0, 0.6, 1)"
        },
        duration: {
            shortest: 150,
            shorter: 200,
            short: 250,
            standard: 300,
            complex: 375,
            enteringScreen: 225,
            leavingScreen: 195
        }
    },
    zIndex: {
        mobileStepper: 1000,
        speedDial: 1050,
        appBar: 1100,
        drawer: 1200,
        modal: 1300,
        snackbar: 1400,
        tooltip: 1500
    }



});

const themes = {
    1: lightTheme,
    0: darkTheme,
}

export default themes;