import checkoutFormModel from "./constantsFormModel";
import * as Yup from "yup";
const { formField: { week_number, constant } } = checkoutFormModel;

export const validateFormWeekConstants = Yup.object({
    [week_number.name]: Yup.number()
        .max(week_number.max, week_number.maxErrorMsg)
        .required(week_number.requiredErrorMsg),
    [constant.name]: Yup.number()
        .required(constant.requiredErrorMsg)
        .default(constant.default),
});