import React, { useContext, useEffect, useState } from "react";
import {
  FormControl,
  MenuItem,
} from "@mui/material";
import { CustomSelect } from "../../../../common/Components/CustomSelect";

import { useFormikContext } from "formik";



export const SelectBrand = ({ disabled = false, dataSelect, handleChange, label }) => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <>
      <FormControl fullWidth sx={{ mt: 1 }} disabled={disabled}>
        <CustomSelect
          sx={{
            borderRadius: 20,
            height: 50,
            marginBottom: 1.4,
            width: '80%',
            borderColor:
              "linear-gradient(90deg, rgba(7,64,107,1) 0%, rgba(7,64,107,1) 35%, rgba(0,184,255,1) 100%)",
          }}
          id={'brand'}
          label={label || 'Marca'}
          name={'brand'}
          value={values.type}
        >
          {dataSelect && dataSelect.map((option) => {
            return (
              <MenuItem
                key={option?.id}
                value={option?.cod}
                onClick={({ target }) => handleChange(target.textContent)}
              >
                {option?.name}
              </MenuItem>
            )
          })}
        </CustomSelect>
      </FormControl>
    </>
  );
};
