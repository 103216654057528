import React from 'react'
import ModulesGraphContext from '../Contexts/GraphContext'

export const GraphProviders = ({ children, value }) => {
    return (
        <ModulesGraphContext.Provider
            value={{ ...value }}
        >
            {children}
        </ModulesGraphContext.Provider>
    )
}
