import { Box } from '@mui/material';
import React from 'react'
import { useContext } from 'react'
import { CDetails } from '../../../../common/Components/CDetails';
import CustomModal from '../../../../common/Components/CustomModal';
import ModulesFormContext from '../../../../common/Contexts/ModulesFormContext'
import { ModalOpenEnum } from '../../../../common/utils/ModalOpenEnum';
import checkoutForm from "../../../../model/mix/mix.model";

export const DetailMix = () => {
    const { rowData, isOpen, toggleModal } = useContext(ModulesFormContext);
    const { formField: {
        minimo,
        estilo_color,
        talla,
        tienda,
        sku,
        cod_art,
        delivery
    } } = checkoutForm;
    return (
        <CustomModal
            isOpen={isOpen[ModalOpenEnum.DETAIL]}
            toggleModal={() => toggleModal(ModalOpenEnum.DETAIL)}
            moduleLabel={'mix'}
            actionLabel='view'
            descriptionLabel={''}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 2,
                }}
            >
                <CDetails label={cod_art.headerTable} value={rowData?.cod_art} />
                <CDetails label={sku.headerTable} value={rowData?.sku} />
                <CDetails label={tienda.headerTable} value={rowData?.tienda} />
                <CDetails label={minimo.headerTable} value={rowData?.minimo} />
                <CDetails label={estilo_color.headerTable} value={rowData?.estilo_color} />
                <CDetails label={talla.headerTable} value={rowData?.talla} />
                <CDetails label={delivery.headerTable} value={rowData?.delivery} />
            </Box>
        </CustomModal>
    )
}
