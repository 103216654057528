export const weekdays = [
    {
        day_id: 1,
        day_name: "Domingo",
    },
    {
        day_id: 2,
        day_name: "Lunes",
    },
    {
        day_id: 3,
        day_name: "Martes",
    },
    {
        day_id: 4,
        day_name: "Miercoles",
    },
    {
        day_id: 5,
        day_name: "Jueves",
    },
    {
        day_id: 6,
        day_name: "Viernes",
    },
    {
        day_id: 7,
        day_name: "Sabado",
    },
];
const dayNames = ['', 'Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado']

export const getWeekday = (day_id) => {
    console.log("🚀 ~ file: weekdays.js:34 ~ getWeekday ~ day_id:", day_id)
    return dayNames[day_id];
}