import React from "react";
import { CustomTable } from "../../../../common/Components/CustomTable";

export const TableCompany = ({ companys }) => {

  const headers = [
    { field: "company_name", headerName: "Compania" },
    { field: "contact_name", headerName: "Contacto" },
    { field: "dataBase", headerName: "Base de datos" },
  ];

  const actions = ["Editar", "Eliminar"];

  return (
    <CustomTable
      actions={actions}
      headers={headers}
    />
  );
};
