
import { createStyles } from '@material-ui/styles';
import { Box, Button, CircularProgress, Divider,  Modal, Stack, Typography } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { t } from 'i18next';
import React, { useMemo, useContext, useState } from 'react'
import { defaultService } from '../../services/DefaultService';
import BranchsContext from '../Contexts/BranchContext';
import ModulesFormContext from '../Contexts/ModulesFormContext';
import ThemeContext from '../Contexts/ThemeContext';
import { styleSheet } from '../styles/StylesCommon';
import { CloseModal } from './CloseModal';
import { CToast } from './ToastNotify';


export const PreviewExcel = ({ isOpen, handleClose, data, configColumn, file, sendErrors,}) => {

    const { state } = useContext(ThemeContext);
    const { branchID } = useContext(BranchsContext);
    const { getDataTable, nameModule } = useContext(ModulesFormContext);
    const styles = styleSheet();
    const [isLoading, setIsLoading] = useState(false);
    let errorList = [];
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const defaultColDef = useMemo(
        () => ({
            sortable: true,
            filter: true,
            resizable: true,
            // initialWidth: 100,
            maxWidth: 250,
            minWidth: 50,
            editable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            // flex: 1,
        }),
        []
    );

    const getErrorsList = () => {
        sendErrors(errorList);
    }
    
    const handleLoadFile = async (e) => {
        setIsLoading(true);
        const params = {
            branchID: branchID,
            module: nameModule,
        }
        try {
            const data = await defaultService.loadFile(file, params);
            console.log("🚀 ~ file: PreviewExcel.jsx:54 ~ handleLoadFile ~ data:", data)
            console.log(data);
            await getDataTable();
            CToast(t('messages.successLoadFile'), t('messages.success'), true);
        } catch ({ response }) {
            if (response?.data?.error) {
                errorList = response.data.message[0].err;
                const msj = <div>{t('messages.errorLoadFile')}<br/>{response.data.error}</div>;
                await getErrorsList();
                return CToast(msj, t('messages.error'), true);
            }
            CToast(t('messages.errorLoadFile'), t('messages.error'), true)
        } finally {
            setIsLoading(false);
            handleClose();
        }
    }

    return (
        <Modal
            open={isOpen}
            onClose={(e, reason) => reason !== "backdropClick" ? handleClose() : null}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={stylesheet.center}
        >
            <Box sx={{ ...styles.box, top: 'auto', }}>
                <CloseModal handleClose={handleClose} />

                <Box marginTop={5} marginBottom={2} justifyContent={"center"} display={"flex"}>
                    <Typography sx={{ ...styles.titleModal }}>{t('labels_modals.title_modal_upload_preview')}</Typography>
                </Box>

                <Divider color="#00b8ff" style={{ marginInline: '2.5rem' }} />
                <Stack direction={"column"} paddingX={5}>
                    <Box className="ag-theme-alpine"
                        sx={state.theme === 1 ? { ...stylesheet.boxTheme1 } : { ...stylesheet.boxTheme2 }}>
                        <div style={containerStyle}>
                            <div style={gridStyle} >
                                <AgGridReact
                                    rowData={data}
                                    columnDefs={configColumn}
                                    defaultColDef={defaultColDef}
                                    animateRows={true}
                                    rowSelection="multiple"
                                />
                            </div>
                        </div>

                    </Box>
                </Stack>

                <Stack
                    display={'flex'}
                    direction={"row"}
                    paddingX={10}
                    paddingY={2}
                >
                    <Box width={"100%"} display='flex' justifyContent={"center"} >
                        <Button
                            startIcon={isLoading ? <CircularProgress size={20} style={{ color: '#FFFFFF' }} /> : null}
                            type="submit"
                            onClick={handleLoadFile}
                            disabled={isLoading ? true : false}
                            style={{ ...styles.buttonForm, marginTop: 15, width: '50%' }}

                        >{t('buttons.save')}</Button>
                    </Box>
                </Stack>
            </Box>

        </Modal>
    )
}

const stylesheet = createStyles({
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    boxTheme1: {
        backgroundColor: 'white',
        height: "450px",
        maxHeight: "500px",
        width: '100%',
        "--ag-header-background-color": "#0A1C42",
        "--ag-foreground-color": "black",
        "--ag-borders": "none",
        "--ag-card-shadow": "none",
        "--ag-popup-shadow": "none"
    },
    boxTheme2: {
        backgroundColor: "#0A1C42",
        height: "450px",
        maxHeight: "500px",
        width: '100%',
        "--ag-foreground-color": "white",
        borderBottomLeftRadius: "30px",
        borderBottomRightRadius: "30px",
        "--ag-borders": "none"
    }
})