export default {
    formId: 'dailyMoneyForm',
    formField: {
        dia: {
            name: 'dia',
            label: 'Dia',
            requiredErrorMsg: 'El dia es requerido'
        },
        peso: {
            name: 'peso',
            label: 'Peso',
            requiredErrorMsg: 'El peso es requerido'
        }
    }
}