export default {
    formId: 'excludeDayForm',
    formField: {
        fecha_excluida: {
            name: 'fecha_excluida',
            label: 'Fecha excluida',
            requiredErrorMsg: 'Fecha excluida es requerido'
        },
        date_excluded: {
            name: 'date_excluded',
            label: 'Fecha excluida',
            requiredErrorMsg: 'Fecha excluida es requerido'
        },
        isOnlyYear: {
            name: 'isOnlyCurrentYear',
            label: 'Solo por este año',
        }
    }
}