import React from 'react'
import { CustomTable } from '../../../../common/Components/CustomTable';
import { formatDateTime } from '../../../../helpers/formatDate';

export const SuggestedTable = () => {

    const headers = [
        { field: "created_at", headerName: "Fecha ", width: 180, valueFormatter: (params) => formatDateTime(params.value), },
        { field: "historical_id", headerName: "ID Historico", width: 150 },
        { field: "parameter_id", headerName: "ID Parámetro", width: 150 },
    ];

    const actions = ["Detalle"];

    return (<CustomTable headers={headers} actions={actions} />)
}

