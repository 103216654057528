import React, { useMemo, useContext, useState, useEffect } from 'react'
import { Box } from '@mui/material'
import Title from '../../../common/Components/Title';
import { styleSheet } from '../../../common/styles/StylesCommon';
import { useStep } from '../../../hooks/useStep';
import { useForm } from '../../../hooks/useForm';
import { CToast } from '../../../common/Components/ToastNotify';
import { branchSteps } from '../../../common/utils/getLabelSteps';
import { t } from 'i18next';
import { LayoutTable } from '../../../common/Components/LayoutTable';
import { TableBranchs } from './components/TableBranchs';
import { CustomModalDelete } from '../../../common/Components/CustomModalDelete';
import CompanysService from '../../../services/CompanysService';
import BranchsContext from '../../../common/Contexts/BranchContext';
import CompanyContext from '../../../common/Contexts/CompanysContext';
import { ModulesProvider } from '../../../common/Providers/ModulesProvider';
import { BranchForm } from './BranchForm';
import { ModalOpenEnum } from '../../../common/utils/ModalOpenEnum';


const Branchs = () => {
    const { branchID, companyID } = useContext(BranchsContext);
    const { getAllCompanys } = useContext(CompanyContext);
    const {
        activeStep,
        handleSetActiveStep,
        handleResetStep,
        isLastStep,
        handleNextStep,
    } = useStep(branchSteps);
    const { isOpen, rowData, toggleModal } = useForm();
    const [dataTable, setDataTable] = useState([]);
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [isLoadingDelet, setIsLoadingDelet] = useState(false)
    const overallStyle = useMemo(() => styleSheet(), []);

    const handledeleteBranch = async () => {
        setIsLoadingDelet(true);
        const req = {
            company_id: rowData?.company_id,
        }
        console.log(req)
        try {
            const { data } = await CompanysService.deleteBranch(req);
            await getDataTable();
            await getAllCompanys();
            CToast(t('messages.success_delete'), 'success', true);
        } catch ({ response }) {
   
            CToast(t('messages.error_delete'), 'error', true);
        } finally {
            setIsLoadingDelet(false);
        }
    };


    const getDataTable = async () => {
        setIsTableLoading(true);
        const req = {
            company_id: companyID,
        };
        try {
            const { data } = await CompanysService.getBranchByCompany(req);
            setDataTable(data);
            setIsTableLoading(false);
        } catch (error) {
            setIsTableLoading(false);
            console.log(error);
        }
    };

    useEffect(() => {
        companyID.length > 0 && getDataTable();
    }, [companyID]);


    return (
        <ModulesProvider value={{
            dataTable,
            setDataTable,
            getDataTable,
            isTableLoading,
            activeStep,
            isLastStep,
            handleSetActiveStep,
            handleResetStep,
            handleNextStep,
            handleDelete: handledeleteBranch,
            steps: branchSteps,
            isOpen, rowData, toggleModal,
        }}>
            <Box sx={overallStyle.container}>
                <Title name={t('modules.branch')} />
                <LayoutTable labelAction={'Agregar Sucursal'}>
                    {
                        isOpen[ModalOpenEnum.EDIT] && (<BranchForm />)
                    }
                    {isOpen[ModalOpenEnum.DELETE] &&
                        (<CustomModalDelete
                            moduleLabel={'branch'}
                            descriptionLabel={rowData ? `${rowData.company_name}` : ''}
                            isLoading={isLoadingDelet}
                        />)}
                    <TableBranchs />
                </LayoutTable>
            </Box>
        </ModulesProvider>
    )
}


export default Branchs