import { Box, Typography } from "@mui/material";
import React from "react";
import { styleSheet } from "../styles/StylesCommon";

export const TitleStep = ({
  title,
  size = "25px",
  color = "common.title",
  marginBottom = "0px",
}) => {
  const styles = styleSheet();

  return (
    <Box padding={"15px"} marginTop={"20px"} marginBottom={marginBottom}>
      <Typography
        sx={{
          ...styles.titleModal,
          fontSize: size,
          color: color,
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};
