import React from "react";
import { CustomTable } from "../../../../common/Components/CustomTable";
import checkoutForm from "../../../../model/serviceLevel/serviceLevelFormModel";

export const TableServiceLevel = () => {

  const { formField: {
    letra,
    nivel_servicio,
    descripcion
  } } = checkoutForm;

  const headers = [
    { field: "letra", headerName: letra.headerTable },
    { field: "nivel_servicio", headerName: nivel_servicio.headerTable },
    { field: "descripcion", headerName: descripcion.headerTable },
  ];

  return (<CustomTable headers={headers} />);
};
