import { ErrorMessage, useField } from 'formik';
import {  createTheme, InputLabel, Select } from '@mui/material';
import { t } from 'i18next'
import { styleSheet } from '../styles/StylesCommon';

const theme1 = createTheme({
    overrides: {
      MuiSelect: {
        select: {
          "&:focus": {
            backgroundColor: '#ffddec',
            borderColor: 'brown'
          },
          '&:before': {
            borderColor: 'orange'
          },
          '&:after': {
            borderColor: 'green',
          }
        }
      }
    }
  });

export const CustomSelect = ({ label, isLoading, ...props }) => {


    const [field, meta, helpers] = useField(props);
    const styles = styleSheet();

    
    return (
        <>
            <InputLabel htmlFor={props.id || props.name}>{isLoading ? t('labels_actions.loading') : label}</InputLabel>
            <Select
                {...field}
                {...props}
                label={label}
                labelId={props.labelId}
                id={props.id}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={meta.touched && meta.error ? true : false}
            />
            {
                meta.touched && meta.error && (<span name={props.name} style={{ color: 'red', fontSize: 12, textAlign: 'left', marginLeft: 20 }}>{meta.error}</span>)
            }
        </>
    )
}