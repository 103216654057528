export const userSteps = [
    "Crea el usuario",
    "Asignar los permisos",
    "Asignar la sucursal",
];

export const branchSteps = [
    "Crea una sucursal",
    "Asignar accesos",
    "Configuración de Procesos",
];

export const companySteps = [
    "Crea la empresa",
    "Asignar accesos",
    "Configuración de Procesos",
    "Crea una sucursal",
    "Asignar accesos",
    "Configuración de Procesos",
];

export const companyStepsEdit = [
    "Editar empresa",
    "Asignar accesos",
    "Configuración de Procesos",
]

