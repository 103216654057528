import { createStyles } from "@material-ui/styles";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import React, { useCallback, useState, useEffect, useContext, useMemo } from "react";
import { IconDownload, IconUpload } from "../utils/Icons";
import { read, utils, writeFile } from "xlsx";
import { styleSheet } from "../styles/StylesCommon";
import { PreviewExcel } from "./PreviewExcel";
import {
  formatParseHeader,
  formatParseHeading,
} from "../utils/headPreviewModal";
import MediaContext from "../Contexts/MediaContext";
import ModulesFormContext from "../Contexts/ModulesFormContext";
// import { useLocation } from "react-router-dom";
import { CToast } from "./ToastNotify";
import { AgGridReact } from 'ag-grid-react';
import ThemeContext from '../Contexts/ThemeContext';
import { CustomTable } from "../../common/Components/CustomTable";
import { LayoutErrorTable } from '../../common/Components/LayoutErrorTable';
import * as days from 'dayjs';

export const ButtonsFileCSV = () => {
  const { state } = useContext(ThemeContext);
  const { dataTable, activeLoadMasive, nameModule } = useContext(ModulesFormContext);
  const { isMobile, isTablet } = useContext(MediaContext);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dataExcel, setDataExcel] = useState([]);
  const [configColumns, setConfigColumns] = useState([]);
  const [file, setFile] = useState();
  const [showErrorList, setShowErrorList] = useState([]);
  const overallStyle = styleSheet(isMobile, isTablet);
  days.locale('es');
  const [columnDefs] = useState([
    {
      field: "error", headerName: "Error", cellRenderer: function (params) {
        let keyData = params.data.error;
        let errorSplit = keyData.split('~')
        return <>{errorSplit[0]}<strong>{errorSplit[1]}</strong>{errorSplit[2]}</>;
      }
    },
  ]);
  const containerStyle = useMemo(() => ({ width: '100%'}), []);
  const gridStyle = useMemo(() => ({ height: '530px', width: '100%' }), []);
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      resizable: true,
      // initialWidth: 100,
      // maxWidth: 250,
      // minWidth: 50,
      editable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      flex: 1,
    }),
    []
  );
  // const searchString = (arrayString = [], obj) => {
  //   let result = [];
  //   arrayString.map(item => {
  //     const keys = Object.keys(obj).filter(k => k.includes(item));
  //     console.log(keys);
  //   })
  //   console.log(result);
  // }
  // const filters = dataTable.map((item) => console.log(searchString(['id', '_at'], item)));
  // console.log(filters);

  const handleCloseModal = () => {
    setIsOpen(!isOpen);
    setDataExcel([]);
    setFile(null);
  };
  const stylesheet = styleSheet();

  const handleImport = ($event) => {
    const files = $event.target.files;
    if (files.length === 0) return CToast('No se pudo abrir el archivo', 'error', true);

    const file = files[0];
    setFile(file);
    const reader = new FileReader();
    reader.onload = (event) => {
      // console.log("🚀 ~ file: ButtonsFileCSV.jsx:102 ~ handleImport ~ event:", event.target)
      const wb = read(event.target.result, { raw: true });
      // console.log("🚀 ~ file: ButtonsFileCSV.jsx:87 ~ handleImport ~ wb:", wb)
      const sheets = wb.SheetNames;

      if (sheets.length !== 0) {
        const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
        if (rows.length === 0) return CToast('No se pudo cargar el archivo: No posee filas de datos', 'error', true);
        setConfigColumns(formatParseHeader(rows));
        setDataExcel(rows);
      }       
    };
    reader.readAsArrayBuffer(file);

  };
    

  const handleExport = () => {
    const headings = formatParseHeading(dataTable);
    let currentTime = days().format('YYYY-MM-DDTHH:mm:ss');
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, dataTable, { origin: "A2", skipHeader: true });
    utils.book_append_sheet(wb, ws, "Report");
    const csvName = nameModule + "-" +currentTime + ".csv";
    writeFile(wb, csvName);
  };

  const handleErrorsExport = () => {
    const headings = formatParseHeading(showErrorList);
    let currentTime = days().format('YYYY-MM-DDTHH:mm:ss');
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, showErrorList, { origin: "A2", skipHeader: true });
    utils.book_append_sheet(wb, ws, "Report");
    const csvName = "Errores-en-" + nameModule + "-" +currentTime + ".csv";
    writeFile(wb, csvName);
  };

  useEffect(() => {
    dataExcel.length > 0 && !isOpen && setIsOpen(true);
  }, [dataExcel]);

  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
}, []);

  const getErrors = (errorList) => {
    // const errors = [];
    // for (let i = 0; i < errorList.length; i++) {
    //   errors.push(errorList[i]);
    // }
    setShowErrorList(errorList);
  };

  var errorReport;
  if (showErrorList.length > 0) {
    const rowStyle = { fontWeight: 'light' };
    errorReport = (
      <LayoutErrorTable labelAction={'Agregar Fecha'} isVisible={false}
        disabledButon={true} style={{ ...styles.tableContainer, }}>

        <Box style={styles.rowButtons}>             {/* Download */}
          <IconButton
            onClick={handleErrorsExport}
            disabled={showErrorList && showErrorList.length > 0 ? false : true}
          >
            <IconDownload style={{}} />
            {!isTablet && (
              <Typography style={styles.labelButtons}>
                {t("buttons.download_data_errors")}
              </Typography>
            )}
          </IconButton>
        </Box>
        
        <Box className="ag-theme-alpine"
          sx={state.theme === 1 ? { ...overallStyle.tableLight } : { ...overallStyle.tableDark }}>    {/* Error Table */}
          <div style={containerStyle}>
            <div style={gridStyle} >
              <AgGridReact
                rowStyle={rowStyle}
                rowData={showErrorList}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                suppressRowClickSelection={true}
                paginationNumberFormatter={paginationNumberFormatter}
                animateRows={true}
                paginationPageSize={10}
                paginationAutoPageSize={true}
                pagination={true}
              />
            </div>
          </div>
        </Box>
      </LayoutErrorTable>
    )
    
  }
  else {
    errorReport = "";
  };

  return (
    <>
      <Stack
        style={{
          ...styles.container,
          justifyContent: isTablet ? "center" : "space-between",
        }}
      >
        <Box style={styles.rowButtons}> {/* download */}
          <IconButton
            onClick={handleExport}
            disabled={dataTable && dataTable.length > 0 ? false : true}
          >
            <IconDownload style={{}} />
            {!isTablet && (
              <Typography style={styles.labelButtons}>
                {t("buttons.download_data")}
              </Typography>
            )}
          </IconButton>
        </Box>
        <Box style={styles.rowButtons}> {/* upload */}
          <span
            id={"button-file"}
            disabled={loadingUpload}
            style={stylesheet.spanInputFile}
          >
            <IconButton style={{ cursor: "inherit" }}>
              <IconUpload style={{}} />
              {!isTablet && (
                <Typography style={styles.labelButtons}>
                  {t("buttons.upload_data_csv")}
                </Typography>
              )}
            </IconButton>
            <input
              id={"button-file"}
              disabled={loadingUpload}
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              style={stylesheet.btnInputFile}
              onChange={handleImport}
              onClick={(e) => (e.target.value = null)}
            />
          </span>
        </Box>
        </Stack>
        { errorReport }
      <PreviewExcel
        isOpen={isOpen}
        handleClose={handleCloseModal}
        configColumn={configColumns}
        data={dataExcel}
        file={file}
        sendErrors = {getErrors}
      />
    </>
  );
};

const styles = createStyles({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // width: '30%',
    maxWidth: "22rem",
    minWidth: "20%",
    paddingTop: 10,
    marginBottom: -20,
    marginInline: 20
  },
  
  errorContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "530px",
  },
  rowButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  labelButtons: {
    fontSize: 14,
    cursor: "pointer",
    fontWeight: "bold",
  },
});
