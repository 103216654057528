import { Box } from '@mui/material';
import { Formik } from 'formik';
import React, { useState, useContext } from 'react'
import { ButtonSave } from '../../../common/Components/ButtonSave';
import { ContainerForm } from '../../../common/Components/ContainerForm';
import CustomModal from '../../../common/Components/CustomModal';
import { FormTextInput } from '../../../common/Components/FormTextInput';
import { CToast } from '../../../common/Components/ToastNotify';
import BranchsContext from '../../../common/Contexts/BranchContext';
import MediaContext from '../../../common/Contexts/MediaContext';
import ModulesFormContext from '../../../common/Contexts/ModulesFormContext';
import { ModalOpenEnum } from '../../../common/utils/ModalOpenEnum';
import { validateFormSuggestedParameter } from '../../../model/suggestedParameters/suggested.schema';
import checkoutFormModel from '../../../model/suggestedParameters/suggestedFormModel';
import SuggestedParametersService from '../../../services/SuggestedParameters';
import { SelectAnalysisWeek } from './components/SelectAnalysisWeek';
import { SelectOptions } from './components/SelectOptions';

export const SuggestedParametersForm = () => {
    const { isMobile, isTablet } = useContext(MediaContext);
    const { branchID } = useContext(BranchsContext);
    const { isOpen, toggleModal, rowData: parameters, getDataTable } = useContext(ModulesFormContext);
    const [isLoading, setIsLoading] = useState(false)
    const [week, setWeek] = useState("");
    const [option, setOption] = useState("");
    const { formId, formField: {
        reference_days, classificationA, classificationB, classificationC,
        description, constantf1f2, constantvalor1, constantvalor2,
        quantity_fraction, quantity_fraction2, soft_limit, confirmed
    } } = checkoutFormModel;

    const initialValue = {
        reference_days: parameters?.reference_days || '',
        classificationA: parameters?.classificationA || 1,
        classificationB: parameters?.classificationB || 2,
        classificationC: parameters?.classificationC || 100,
        description: parameters?.description || '',
        constantf1f2: parameters?.constantf1f2 || 2,
        constantvalor1: parameters?.constantvalor1 || 3.7,
        constantvalor2: parameters?.constantvalor2 || 2.7,
        soft_limit: parameters?.soft_limit || 6,
        quantity_fraction: parameters?.quantity_fraction || 2,
        quantity_fraction2: parameters?.quantity_fraction2 || 2,
        confirmed: parameters?.options || 0,
        isEditing: parameters ? true : false,
        id_parametroSugerido: parameters?.id_parametroSugerido
    };
    
    const onSave = async (value) => {
        setIsLoading(true);
        const req = {
            reference_days: value.reference_days,
            classificationA: value.classificationA,
            classificationB: value.classificationB,
            classificationC: value.classificationC,
            description: value.description,
            constantf1f2: value.constantf1f2,
            constantvalor1: value.constantvalor1,
            constantvalor2: value.constantvalor2,
            soft_limit: value.soft_limit,
            quantity_fraction: value.quantity_fraction,
            quantity_fraction2: value.quantity_fraction2,
            options: value.confirmed,
            company_id: branchID,
            default: false,
        };
        value.isEditing && (Object.assign(req, { id_parametroSugerido: value.id_parametroSugerido }));

        try {
            const { data } = value.isEditing
                ? await SuggestedParametersService.updateSuggestedParameters(req)
                : await SuggestedParametersService.createSuggestedParameters(req);
            await getDataTable();
            toggleModal(ModalOpenEnum.EDIT);
            CToast(`Parametro del sugerido ${value.isEditing ? 'editado' : 'creado'} con exito`, "success", true);
        } catch ({ response }) {
            console.log('error', response)
            if (response?.data?.error) return (CToast(response?.data?.error, "error", true))
            if (response?.data.statusCode === 409) return (CToast("Ya existe parametros con esos valores", "error", true))
            if (response?.data.statusCode === 400) return (CToast("Hubo un error 400 (ESTANDARIZAR)", "error", true))
            return CToast(`Error al ${value.isEditing ? 'editar' : 'crear'} los parametros del sugerido`, "error", true)
        } finally {
            setIsLoading(false);
        }
    }
    return (
        <CustomModal
            isOpen={isOpen[ModalOpenEnum.EDIT]}
            toggleModal={() => toggleModal(ModalOpenEnum.EDIT)}
            moduleLabel={'parameters_suggested'}
            actionLabel={parameters ? "edit" : "create"}
            descriptionLabel={parameters ? `semana ${parameters.reference_days}` : ''}
        >
            <Formik
                initialValues={initialValue}
                validationSchema={validateFormSuggestedParameter}
                onSubmit={(values, actions) => {
                    onSave(values);
                    actions.setSubmitting(false);
                }}
            >
                {({ handleSubmit, values, setFieldValue }) => (
                    <ContainerForm formID={formId}>
                        <Box width={isTablet ? "100%" : '50%'}>
                            <SelectAnalysisWeek
                                label={reference_days.label}
                                name={reference_days.name}
                            />
                            <FormTextInput
                                label={classificationA.label}
                                name={classificationA.name}
                                type={"number"}
                                inputProps={{ min: 1, max: 98 }}
                                onClick={(e) => {
                                    values[classificationA.name] >= values[classificationB.name] &&
                                        setFieldValue(
                                            classificationB.name,
                                            values[classificationA.name] + 1
                                        );
                                }}
                                style={{ width: "95%" }}
                                fullWidth={true}
                            />
                            <FormTextInput
                                label={classificationB.label}
                                name={classificationB.name}
                                type={"number"}
                                inputProps={{ min: 1, max: 99 }}
                                style={{ width: "95%" }}
                                fullWidth={true}
                            />
                            <FormTextInput
                                disabled
                                label={classificationC.label}
                                name={classificationC.name}
                                type={"number"}
                                style={{ width: "95%" }}
                                fullWidth={true}
                            />
                            <FormTextInput
                                label={description.label}
                                name={description.name}
                                style={{ width: "95%" }}
                                fullWidth={true}
                            />
                            <FormTextInput
                                label={constantf1f2.label}
                                name={constantf1f2.name}
                                type={"number"}
                                inputProps={{ min: 0, max: 100, step: 0.1 }}
                                style={{ width: "95%" }}
                                fullWidth={true}
                            />
                        </Box>

                        <Box width={isTablet ? "100%" : '50%'}>
                            <FormTextInput
                                label={constantvalor1.label}
                                name={constantvalor1.name}
                                type={"number"}
                                inputProps={{ min: 0, max: 100, step: 0.1 }}
                                style={{ width: "95%" }}
                                fullWidth={true}
                            />
                            <FormTextInput
                                label={constantvalor2.label}
                                name={constantvalor2.name}
                                type={"number"}
                                inputProps={{ min: 0, max: 100, step: 0.1 }}
                                style={{ width: "95%" }}
                                fullWidth={true}
                            />
                            <FormTextInput
                                label={soft_limit.label}
                                name={soft_limit.name}
                                type={"number"}
                                style={{ width: "95%" }}
                                fullWidth={true}
                            />
                            <FormTextInput
                                label={quantity_fraction.label}
                                name={quantity_fraction.name}
                                type={"number"}
                                inputProps={{ min: 1, max: 100, step: 0.1 }}
                                style={{ width: "95%" }}
                                fullWidth={true}
                            />
                            <FormTextInput
                                label={quantity_fraction2.label}
                                name={quantity_fraction2.name}
                                type={"number"}
                                style={{ width: "95%" }}
                                fullWidth={true}
                            />
                            <Box pt={1.4}>
                                <SelectOptions
                                    label={confirmed.label}
                                    name={confirmed.name}
                                />
                            </Box>
                            <ButtonSave
                                onClick={handleSubmit}
                                isLoading={isLoading}
                                edit={parameters ? true : false}
                            />
                        </Box>
                    </ContainerForm>
                )}
            </Formik>
        </CustomModal>
    )
}
