export default {
    "login": {
        "errors": {
            "email": "Invalid email",
            "password": "Invalid password"
        }
    },
    "types": {
        "success": "success",
        "error": "error",
        "warning": "warning",
    }
}