import React from "react";
import { CustomTable } from "../../../../common/Components/CustomTable";



export const TableHomologations = () => {

  const headers = [
    { field: "old_su", headerName: "OLD_SU" },
    { field: "new_sku", headerName: "SKU" },
  ];

  return (<CustomTable headers={headers} />);
};
