import checkoutFormModel from "./branchFormModel";
import * as Yup from "yup";

const {
    formField: {
        branch_name,
        branch_description,
        branch_phone,
        branch_contact_name,
        branch_contact_phone,
        branch_contact_email,
        select_permission_branch,
        elements_cluster,
        rol_name,
        model_logic_branch,
        model_logic_id_branch,
        cluster_name
    }
} = checkoutFormModel;


export const validateFormBranch = [
    Yup.object({
        [branch_name.name]: Yup.string().required(branch_name.requiredErrorMsg),
        [branch_description.name]: Yup.string()
            .min(branch_description.min, branch_description.minErrorMsg)
            .max(branch_description.max, branch_description.maxErrorMsg)
            .required(branch_description.requiredErrorMsg),
        [branch_phone.name]: Yup.string()
            .min(branch_phone.min, branch_phone.minErrorMsg)
            .max(branch_phone.max, branch_phone.maxErrorMsg)
            .required(branch_phone.requiredErrorMsg)
            .typeError(branch_phone.typeErrorMsg),
        [branch_contact_name.name]: Yup.string()
            .min(branch_contact_name.min, branch_contact_name.minErrorMsg)
            .required(branch_contact_name.requiredErrorMsg),
        [branch_contact_phone.name]: Yup.string()
            .min(branch_contact_phone.min, branch_contact_phone.minErrorMsg)
            .max(branch_contact_phone.max, branch_contact_phone.maxErrorMsg)
            .required(branch_contact_phone.requiredErrorMsg)
            .typeError(branch_contact_phone.typeErrorMsg),
        [branch_contact_email.name]: Yup.string()
            .required(branch_contact_email.requiredErrorMsg)
            .email(branch_contact_email.invalidErrorMsg),
    }),
    Yup.object({
        // [select_permission_branch.name]: Yup.string().required(select_permission_branch.requiredErrorMsg),
        [select_permission_branch.name]: Yup.string()
            .when(['rol_name', 'elements_cluster'], {
                is: (rol_name, elements_cluster) => rol_name || elements_cluster.length <= 0,
                then: Yup.string().required(select_permission_branch.requiredErrorMsg),
            }),
        [rol_name.name]: Yup.string()
            .when(elements_cluster.name, {
                is: (elements_cluster) => elements_cluster.length <= 0,
                then: Yup.string().required(elements_cluster.requiredErrorMsg)
            })
            .min(rol_name.min, rol_name.minErrorMsg)
            .max(rol_name.max, rol_name.maxErrorMsg),
        [elements_cluster.name]: Yup.array().of(
            Yup.object().shape({
                active: Yup.boolean().required(elements_cluster.requiredErrorMsg),
            })
        ),
    }),
    Yup.object({
        [model_logic_id_branch.name]: Yup.string()
            .when(['cluster_name', 'model_logic_branch'], {
                is: (cluster_name, elements_cluster) => cluster_name || elements_cluster.length <= 0,
                then: Yup.string().required(model_logic_id_branch.requiredErrorMsg)
            }),
        [cluster_name.name]: Yup.string()
            .when(elements_cluster.name, {
                is: (model_logic_branch) => model_logic_branch.length <= 0,
                then: Yup.string().required(model_logic_branch.requiredErrorMsg)
            })
            .min(cluster_name.min, cluster_name.minErrorMsg)
            .max(cluster_name.max, cluster_name.maxErrorMsg),
    }),
]