import { Box, CircularProgress, Stack } from "@mui/material";
import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { ActionsCheckListGroup } from "../../../../common/Components/ActionsCheckListGroup";
import { ButtonSave } from "../../../../common/Components/ButtonSave";
import { ContainerForm } from "../../../../common/Components/ContainerForm";
import { CSwitchForm } from "../../../../common/Components/CSwitch";
import CustomModal from "../../../../common/Components/CustomModal";
import { FormTextInput } from "../../../../common/Components/FormTextInput";
import { CToast } from "../../../../common/Components/ToastNotify";
import BranchsContext from "../../../../common/Contexts/BranchContext";
import MediaContext from "../../../../common/Contexts/MediaContext";
import ModulesFormContext from "../../../../common/Contexts/ModulesFormContext";
import { ModalOpenEnum } from "../../../../common/utils/ModalOpenEnum";
import ElementLogicService from "../../../../services/ElementsLogicService";
import { ActionsElements } from "./ActionsElements";


export const ElementForm = () => {
  const { actions } = useContext(ModulesFormContext);

  const {
    isOpen,
    toggleModal,
    rowData: elementR,
    getDataTable,

    setIsTableLoading,
  } = useContext(ModulesFormContext);
  const { isMobile, isTablet } = useContext(MediaContext);
  const { branchID, companyID } = useContext(BranchsContext);
  const [isLoading, setIsLoading] = useState(false);

  const initialValue = {
    element_name: elementR?.modulo_name || "",
    element_path: elementR?.modulo_name || "",
    isEditing: elementR ? true : false,
    active: elementR?.active || true,
    actionsSection:[],
    actions: elementR?.actions || actions,
  };

  const onSave = async (value) => {
    setIsLoading(true);

    const req = {
      element_name: value.element_name,
      element_path: value.element_path,
      active: value.active,
      actions: value.actionsSection,
    };
    console.log(req);
    try {
      const { data } = value.isEditing
        ? await ElementLogicService.patchElements(elementR.element_id, req)
        : await ElementLogicService.postElements(req);
      await getDataTable();
      toggleModal("form");
      CToast(
        `Seccion ${value.isEditing ? "editado" : "creado"} correctamente`,
        "success",
        true
      );
    } catch (response) {
      console.log("error", response);
      CToast(
        `Error al crear ${JSON.stringify(response?.data.error)}`,
        "error",
        true
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CustomModal
      isOpen={isOpen[ModalOpenEnum.EDIT]}
      toggleModal={() => toggleModal(ModalOpenEnum.EDIT)}
      moduleLabel={"section"}
      actionLabel={elementR ? "edit" : "create"}
      descriptionLabel={elementR ? `${elementR.element_name}` : ""}
    >
      <Formik
        initialValues={initialValue}
        onSubmit={(values, actions) => {
          onSave(values);
          actions.setSubmitting(false);
        }}
      >
        {({ handleSubmit, values, errors, setFieldValue, setFieldError }) => (
          <ContainerForm formID={"elementForm"}>
            <Box px={8} py={1}>
              <Stack
                py={5}
                direction={isTablet ? "column" : "row"}
                justifyContent={"space-around"}
              >
                <Box width={isTablet ? "100%" : "100%"}>
                  <FormTextInput
                    label={"Nombre de la seccion"}
                    name={"element_name"}
                    type={"text"}
                    fullWidth={true}
                  />
                  <FormTextInput
                    label={"Ruta"}
                    name={"element_path"}
                    type={"text"}
                    fullWidth={true}
                  />
                <Box my={3}>
                <CSwitchForm
                    label={"Activo"}
                    name={"active"}
                    id={"active"}
                    value={values.active}
                    direction={'column'}
                  />
                </Box>
                  {actions ? <ActionsElements /> : <CircularProgress />}

                  <ButtonSave
                    onClick={handleSubmit}
                    isLoading={isLoading}
                    edit={values.isEditing ? true : false}
                  />
                </Box>
              </Stack>
            </Box>
          </ContainerForm>
        )}
      </Formik>
    </CustomModal>
  );
};
