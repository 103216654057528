import React, { useContext, useState } from "react";
import ChangePass from "./components/ChangePass";
import { Box, Button, Checkbox, FormControlLabel, Stack } from "@mui/material";
import { styleSheet } from "../../common/styles/StylesCommon";
import MediaContext from "../../common/Contexts/MediaContext";
import LoginForm from "./LoginForm";


var store = require("store");
export const Login = () => {
  const { isMobile, isTablet } = useContext(MediaContext);

  const styles = styleSheet(isMobile, isTablet);

  const [logo, setLogo] = useState(false);
  const [changePass, setChangePass] = useState(true);
  const [changePassOk, setchangePassOk] = useState(false);
  const [remember, setRemember] = useState(false);

  const handleChangePass = () => {
    setChangePass(!changePass);
  };

  const userRemember = store.get("userRemember");
  
  return (
    <>
      <div style={styles.containerLogin}>
        <div style={styles.cardLogin}>
          {!logo ? (
            <img
              style={styles.logoLogin}
              src={require("../../assets/logo.png")}
              alt="logo-light"
            />
          ) : (
            <img
              style={styles.logoLogin}
              src={require("../../assets/logo_blanco.png")}
              alt="logo-dark"
            />
          )}

          {changePass  ? (
             <LoginForm
              remember={remember}
            />
          ) : (
            <ChangePass setchangePassOk={setchangePassOk}/>
          )}

          <Stack direction={"row"} marginTop={2} ml={3}>
            {!changePass ? (
              <></>
            ) : (
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={userRemember ? true : false}
                    onChange={(e) => {
                      setRemember(e.target.checked);
                      !e.target.checked  && store.remove("userRemember") ;
                    }}
                  />
                }
                label="Recordarme"
              />
            )}
            <Button onClick={handleChangePass} variant="text">
              {changePass ? "Recuperar Contraseña" : "Volver"}
            </Button>
          </Stack>
        </div>
      </div>
    </>
  );
};
