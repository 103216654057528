import React from "react";
import { Box } from "@mui/material";
import { styleSheet } from "../../../../common/styles/StylesCommon";
import { area, curveMonotoneX } from "d3-shape";
import { ResponsiveLine } from "@nivo/line";
import { Defs } from "@nivo/core";
import { formatMoney, formatN, formatNumber } from "../../../../helpers/formatNumber";


function interpolatedXScale(xScale, x) {
  const floorX = Math.floor(x);
  const decimalPart = x - floorX;
  return (
    xScale(floorX) + (xScale(floorX + 1) - xScale(floorX)) * decimalPart
  );
}

const transformData = (data) => {
  // let newData = [];
  return data.map((item, idx) => {
    // newData.push({ x: item.fecha, y: item.stock });
    return {
      ...item,
      data: item.data.map(d => ({ x: d.fecha, y: formatN(d.stock) }))
    };
  });
}
// AreaLayer component included in layers prop array
// const AreaLayer = ({ series, xScale, yScale, innerHeight }) => {
//   // console.log(series.map(s => s.data.map(d => console.log(d.position))));
//   console.log('series', series)
//   const data = series.map(s => s.data.map(d => d.position))[1];
//   console.log('series', data)
//   const areaGenerator = area()
//     .x((d) => formatNumber(xScale(d.x)))
//     .y0(yScale(innerHeight))
//     .y1((d) => formatNumber(yScale(d.y)))
//     .curve(curveMonotoneX);

//   return (
//     <>
//       <Defs
//         defs={[
//           {
//             id: "pattern",
//             type: "patternLines",
//             background: "transparent",
//             color: "#3daff7",
//             lineWidth: 1,
//             spacing: 6,
//             rotation: -45,
//           },
//         ]}
//       />

//       <path
//         // idk how you want to compute the area with higher values or the values are higher for a particular date 
//         // so this is just an example for the blue area
//         d={areaGenerator(data)}
//         // d={areaGenerator([series[0].data[0], series[0].data[1]])}
//         fill="url(#pattern)"
//         fillOpacity={0.6}
//         stroke="#3daff7"
//         strokeWidth={3}
//       />
//     </>
//   );
// };

const Areas = ({ series, areaGenerator, xScale, yScale }) => {
  // if (series.length == 0) return null;
  return series.filter(({ id }) => id === 'Venta')?.map(({ id, data, color }) => (
    <path
      key={id}
      d={areaGenerator(
        data?.map(d => ({
          x: xScale(d.data.x),
          y: yScale(d.data.y),
        })),
      )}
      fill={color}
      fillOpacity={0.25}
    />
  ));
};

export const LinearGraphic = ({ dataGraph }) => {
  const overallStyle = styleSheet();

  return !dataGraph ? null : (
    <Box
      height={400}
      sx={{
        ...overallStyle.boxWhite,
        backgroundColor: "common.paper",
      }}
    >
      <ResponsiveLine
        data={dataGraph}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: true,
          reverse: false,
        }}
        yFormat={(e) => formatN(e)}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Prom. stock",
          legendOffset: -40,
          legendPosition: "middle",
        }}

        layers={['grid', 'markers', 'axes', Areas, 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends']}
        pointSize={10}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        enableArea={false}
        useMesh={true}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}

      />

    </Box>
  );
};

