import React, { useContext, useEffect, useMemo } from "react";
import { Typography, Box, Divider, Stack } from "@mui/material";
import { styleSheet } from "../styles/StylesCommon";
import BranchsContext from "../Contexts/BranchContext";
import CompanysService from "../../services/CompanysService";
import { useState } from "react";
import CompanyContext from "../Contexts/CompanysContext";
import isEmpty from "lodash/isEmpty";
import MediaContext from "../Contexts/MediaContext";

const Title = ({ name = "Titulo" }) => {
  const overallStyle = useMemo(() => styleSheet(), []);
  const { company } = useContext(CompanyContext);
  const { branchID, branchs } = useContext(BranchsContext);
  const { isTablet } = useContext(MediaContext);
  const [branchSelected, setBranchSelected] = useState([]);

  useEffect(() => {
    !isEmpty(branchID) && setBranchSelected(branchs.filter(branch => branch.company_id === branchID)[0]);
  }, [branchID])

  return (
    <Box sx={{ ...overallStyle.backgroundTitle, paddingLeft: "40px" }} boxShadow={2} marginX={3}>
      <Typography variant="h1" style={{ ...overallStyle.titleH1, fontSize:isTablet ? '28px' : '35px' }}>
        {name}
      </Typography>

      <Stack direction={'column'} sx={{ mt: 5, mr: 2 }}>
        <Typography variant="h1" style={{ ...overallStyle.titleH4,fontSize:isTablet ? '15px' : '18px' }} >
          {company?.company_name}
        </Typography>
        {
          !isEmpty(branchID) && (
            <Typography variant="h1" style={{ ...overallStyle.titleH4,fontSize:isTablet ? '15px' : '18px' }} >
              {branchSelected?.company_name}
            </Typography>
          )
        }
      </Stack>
    </Box>
  );
};

export default Title;
