import React, { useMemo, useContext } from "react";
import { styleSheet } from "../styles/StylesCommon";
import MediaContext from "../Contexts/MediaContext";
import ModulesFormContext from "../Contexts/ModulesFormContext";
import { useLocation } from "react-router-dom";
import { actionsEnabledModule } from "../utils/actionsEnabledModule";

export const ModulesProvider = ({ children, value }) => {
    const { isMobile, isTablet } = useContext(MediaContext);
    //STYLES  
    const overallStyle = styleSheet(isMobile, isTablet);
    const params = useLocation();
    // console.log("params", params);
    const actionsEnabled = params?.state?.rules.actions;
    const nameModule = params?.pathname.replace("/", "");
    const enabledModule = actionsEnabledModule[params?.pathname];
    const activeLoadMasive = actionsEnabledModule[params?.pathname]?.filter(a => ["CMR", "CMA"].includes(a))?.length > 0;

    return (
        <ModulesFormContext.Provider value={{
            overallStyle,
            actionsEnabled,
            nameModule,
            activeLoadMasive,
            actionsEnabledModule: enabledModule,
            ...value,
        }}>
            {children}
        </ModulesFormContext.Provider>
    );
}