export default {
    formId: 'skuForm',
    formField: {
        sku: {
            name: 'sku',
            label: 'SKU',
            headerTable: 'SKU',
            requiredErrorMsg: 'El SKU es requerido'
        },
        center_distribution: {
            name: 'center_distribution',
            label: 'Centro de distribución',
            headerTable: 'Centro de distribución',
            requiredErrorMsg: 'El centro de distribución es requerido'
        },
        isActive: {
            name: 'isActive',
            label: 'Estado',
            headerTable: 'Estado',
        }
    }
}