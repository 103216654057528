import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useContext } from "react";
import { ButtonCloseModal } from "../../../../common/Components/ButtonCloseModal";
import { ButtonSave } from "../../../../common/Components/ButtonSave";
import { CustomTitleModal } from "../../../../common/Components/CustomTitleModal";
import { LinearProgressWithLabel } from "../../../../common/Components/LinearProgressWithLabel";
import { CToast } from "../../../../common/Components/ToastNotify";
import AuthContext from "../../../../common/Contexts/AuthContext";
import BranchsContext from "../../../../common/Contexts/BranchContext";
import { SocketContext } from "../../../../common/Contexts/SocketContext";
import UserContext from "../../../../common/Contexts/UserContext";
import { styleSheet } from "../../../../common/styles/StylesCommon";
import { IconToFinalize } from "../../../../common/utils/Icons";
import { useSocket } from "../../../../hooks/useSocket";
import { ExecutionOfTheSuggestedService } from "../../../../services/ExecutionOfTheSuggestedService";
import { validateParametersExecution } from "../parameters.schema";
import { SelectParameter } from "./SelectParameter";

const sugeridos = [
  {
    id: 0,
    name: "Sugerido Proceso 0",
    inProcess: true,
    status: "ready",
    message: "Iniciando Proceso",
  },
];

export const ExecutionSugestModal = ({ handleModal, isOpen }) => {
  const styles = styleSheet();
  const { branchID } = useContext(BranchsContext);
  const { auth } = useContext(AuthContext);

  // const { socketIO, isOnline } = useContext(SocketContext);
  const baseURL = process.env.REACT_APP_API_LINK;//REACT_API_LINK-REACT_APP_API_LINK_LOCAL
  const { socketIO, isOnline } = useSocket(`${baseURL}`, auth);
  const [processSuggested, setProcessSuggested] = useState([]);
  const [processExecution, setProcessExecution] = useState();
  const [inProgress, setInProgress] = useState({
    progress: 0,
    intervalId: 0,
    interval: 5000,
  });
  const [isLoading, setIsLoading] = useState(false);
  // const [intervalId, setIntervalId] = useState(0);

  const handleLoading = (progressTotal) => {

    if (inProgress.intervalId || progressTotal >= 100) {
      // clearInterval(newIntervalId);
      setInProgress({
        progress: 100,
        intervalId: 0,
        interval: 5000,
      });
      clearInterval(inProgress.intervalId);
      return;
    }

    const newIntervalId = setInterval(() => {
      setInProgress((prevCount) => ({
        interval: (progressTotal === 100 && 300) ||
          (progressTotal < inProgress.progress)
          ? prevCount.interval + 1000
          : prevCount.interval,
        intervalId: newIntervalId,
        progress: (progressTotal !== 0 && progressTotal > prevCount.progress) ? progressTotal : prevCount.progress + 1,
      }));
    }, inProgress.interval);

  };


  console.log("SOCKET STATUS ONLINE", isOnline, socketIO);
  // console.log('SOCKET STATUS ', socketIO)
  const initialValue = {
    reference_days: "",
    id_parametroSugerido: "",
  };
  const formik = useRef();
  const handleClose = () => {
    handleModal();
    // formik.current?.handleReset();
  };

  const onExecuted = (values) => {
    setIsLoading(true);
    const req = {
      company_id: branchID,
      id_parametro_sugerido: values.id_parametroSugerido,
    };
    try {
      if (socketIO.connected) {
        socketIO.emit("initProcess", req).on("processInProgress", (progress) => {
          console.log("SOCKET - PROGRESO DE LOS PROCESOS", progress);
          const { progressTotal, subProgress } = progress;
          handleLoading(progressTotal);
        });
      }
    } catch (error) {
      setIsLoading(true);
      CToast("error", "Error", "No se pudo conectar con el servidor");
    }

  };

  //lISTEN SOCKET - UPDATE PROCESS
  useEffect(() => {

    socketIO.on("processInExecuted", (message) => {
      console.log("SOCKET - PROCESOS", message);
      setProcessExecution(message);
    });

    socketIO.once("processFinish", (suggested) => {
      console.log(
        "SOCKET - PROCESOS FINALIZADOS GET SUGERIDOS",
        suggested?.data
      );
      setIsLoading(suggested?.data.length > 0 ? false : true);
      setProcessSuggested(suggested?.data);
    });

    return () => {
      socketIO.off("processInProgress");
    }
  }, [socketIO]);

  useEffect(() => {
    if (inProgress.progress >= 100) {
      handleLoading(100);
      socketIO.off("processInExecuted");
      socketIO.off("processInProgress");
      socketIO.off("processFinish");
    };
  }, [inProgress.progress])



  return (
    <Modal
      open={isOpen}
      onClose={(e, reason) => {
        if (reason === "backdropClick") {
          console.log("backdrop");
        } else {
          handleClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        scrollbarWidth: "none",
        overflow: "scroll",
      }}
    >
      <Box sx={{ ...styles.box, backgroundColor: "#f4f4f4" }}>
        <CustomTitleModal
          module={"executionSugested"}
          action=""
          description=""
        />
        <Divider color="#00b8ff" />
        <ButtonCloseModal handleClick={handleClose} />
        <Formik
          innerRef={(ref) => (formik.current = ref)}
          initialValues={initialValue}
          onSubmit={onExecuted}
          validationSchema={validateParametersExecution}
        >
          {({ resetForm, handleSubmit }) => {
            return (
              <Form>
                <React.Fragment>
                  {!isLoading && !processExecution && (
                    <Box px={8} py={5}>
                      <SelectParameter name={'id_parametroSugerido'} label={'Parametro Sugerido'} />
                      <Stack
                        py={5}
                        direction={"row"}
                        justifyContent={"space-around"}
                      >
                        <Box width={"50%"}>
                          <ButtonSave
                            label="Ejecutar"
                            onClick={handleSubmit}
                            isLoading={isLoading}
                            disabled={isLoading || socketIO.connected === false}
                          />
                        </Box>
                      </Stack>
                    </Box>
                  )}

                  {processExecution && processSuggested.length === 0 && (
                    <React.Fragment>
                      <Box style={{ paddingInline: 30, paddingTop: 10 }}>
                        <LinearProgressWithLabel value={inProgress.progress} />
                      </Box>
                      <Stack
                        flexWrap={"wrap"}
                        flexDirection={"row"}
                        justifyContent="center"
                        alignItems={"center"}
                      >
                        {processExecution.length > 0 &&
                          processExecution.map((items, index) => {
                            return (
                              <Box
                                display="flex"
                                key={index}
                                py={2}
                                px={2}
                                my={3}
                                mx={3}
                                style={{
                                  boxShadow: "0px 3px 6px #00000029",
                                  borderRadius: 20,
                                  backgroundColor: "white",
                                  // display: items.inProcess ? 'flex' : 'none',
                                }}
                              >
                                <Card sx={{ minWidth: 275 }}>
                                  {items.inProcess && (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <CircularProgress size={30} />
                                    </Box>
                                  )}

                                  {items.status === "success" && (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <IconToFinalize style={{ fontSize: 35, paddingBottom: 5 }} />
                                    </Box>
                                  )}

                                  <CardContent>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: 18,
                                          fontWeight: "bold",
                                          color: "primary.main",
                                        }}
                                        color="text.secondary"
                                      >
                                        {items.name}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: 14,
                                          color:
                                            items.inProcess === false
                                              ? "secondary.main"
                                              : "gray",
                                        }}
                                        variant="body"
                                      >
                                        <br />
                                        {items.inProcess === false
                                          ? "Realizado"
                                          : "Realizando..."}
                                      </Typography>
                                    </Box>
                                  </CardContent>
                                </Card>
                              </Box>
                            );
                          })}
                      </Stack>
                    </React.Fragment>
                  )}
                  {processSuggested.length > 0 && (
                    <Box px={8} py={5}>
                      <Typography
                        variant="h1"
                        component="h2"
                        sx={{

                          color: "primary.main",
                          textAlign: "center",
                        }}
                      >
                        Proceso Completo
                      </Typography>
                    </Box>
                  )}
                </React.Fragment>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Modal>
  );
};
