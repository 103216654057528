import React from "react";
import { CustomTable } from "../../../../common/Components/CustomTable";


export const TableExcludeGid = () => {

  const headers = [{ field: "transfer_id", headerName: "Id Transferencia" },];
  const actions = ["Editar", "Eliminar"];

  return (
    <CustomTable headers={headers} actions={actions} />
  );
};
