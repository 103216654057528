import React, { useState, useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";

import { useFormikContext } from "formik";
import { CustomSelect } from "../../../../common/Components/CustomSelect";
import { t } from "i18next";

export const SelectRolesForm = (props) => {

  const { label = "S/A",
    code, name,
    rolesAccess, name_elements_checks,
    getRolesAccess, nameConfigEqual, isLoading }
    = props;
  // const { select_rol_name } = formField;

  const [rolID, setRolID] = useState("");

  const { setFieldValue } = useFormikContext();

  const handleChange = (event) => {
    setRolID(event.target.value);
  };

  return (
    <React.Fragment>
      <Typography mb={1} variant={"h6"} color={'common.button'}>
        {t('labels_actions.select_rol')}
      </Typography>
      <FormControl fullWidth={props.fullWidth}>
        <CustomSelect
          sx={{
            borderRadius: 20,
            height: "50px",
            borderColor: "linear-gradient(90deg, rgba(7,64,107,1) 0%, rgba(7,64,107,1) 35%, rgba(0,184,255,1) 100%)",
          }}
          labelId={label}
          id={name}
          name={name}
          label={label}
          isLoading={isLoading}
          disabled={isLoading}
        >
          {rolesAccess?.map((rol, index) => (
            <MenuItem
              key={rol.id_rol}
              onClick={() => setFieldValue(name_elements_checks.name, rol[name_elements_checks.cluster], true)}
              value={rol.id_rol}>{rol.rol_name}</MenuItem>
          ))}
        </CustomSelect>
      </FormControl>
    </React.Fragment>
  );
};
