import { Drawer, Grid, IconButton } from "@mui/material";
import React, { useState } from "react";
import { Footer } from "./Footer";
import Sidebar from "./Sidebar";
import MenuIcon from "@mui/icons-material/Menu";
import { styleSheet } from "../styles/StylesCommon";
export const CDrawer = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const styles = styleSheet();
  return (
    <Grid
      item
    
    >
      <IconButton>
        <MenuIcon size="large" onClick={() => setIsDrawerOpen(true)} />
      </IconButton>
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Sidebar />

        <Footer />
      </Drawer>
    </Grid>
  );
};
