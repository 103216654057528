import React from "react";
import {Icon} from "@mui/material";

import Config from "../../assets/icons/configuracion.svg";
import LogOut from "../../assets/icons/salir.svg";

import Company  from "../../assets/icons/empresa.svg";
import Log from "../../assets/icons/log.svg";
import Branch from "../../assets/icons/sucursales.svg";
import User from "../../assets/icons/usuario.svg";

import InputG from '../../assets/icons/inputs-generales.svg';
import ForKB from "../../assets/icons/forecast-kb.svg";
import ForKG from "../../assets/icons/forecast-kg.svg";
import ForKP from "../../assets/icons/forecast-kp.svg";
import ForKZ from "../../assets/icons/forecast-kz.svg";
import ForMonthly from "../../assets/icons/forecast-kz.svg";
import ForWeekly from "../../assets/icons/forecast-kz.svg";

import SugPurchase from "../../assets/icons/sugerido-compra.svg";
import SugDistribution from "../../assets/icons/sugerido-distribucion.svg";
import SugTwoWeek from "../../assets/icons/sugerido-dos-semanas.svg";

import TableEdit from "../../assets/icons/editar.svg";
import TableSee from "../../assets/icons/ver.svg";
import TableDelete from "../../assets/icons/eliminar.svg";

import WTableEdit from "../../assets/icons/w_editar.svg";
import WTableSee from "../../assets/icons/w_ver.svg";
import WTableDelete from "../../assets/icons/w_eliminar.svg";

import TableSearch from "../../assets/icons/buscar.svg";
import TableExpand from "../../assets/icons/expandir.svg";
import ButtonDownload from "../../assets/icons/descargar.svg";
import ButtonUpload from "../../assets/icons/cargar.svg";
import ButtonToUpdate from "../../assets/icons/actualizar.svg";

import StateSuggestedFinalize from "../../assets/icons/realizado.svg";
import StateSuggestedWait from "../../assets/icons/en-espera.svg";
import StateSuggestedDoing from "../../assets/icons/realizando.svg";






export const ConfigIcon = ({style, onClick}) => {
    return (
        <Icon style={{...style}} onClick={onClick}>
            <img style={{height: '100%', width: '100%'}} src={Config} alt="ConfigIcon"/>
        </Icon>
    )
}

export const LogOutIcon = ({style, onClick}) => {
    return (
        <Icon style={{...style}} onClick={onClick} >
            <img style={{height: '100%', width: '100%'}} src={LogOut} alt="LogOutIcon'"/>
        </Icon>
    )
}

export const ForKBIcon = ({style}) => {
    return (
        <Icon style={{...style}}>
            <img style={{height: '100%', width: '100%'}} src={ForKB} alt="ForKBIcon"/>
        </Icon>
    )
}

export const ForKGIcon = ({style}) => {
    return (
        <Icon style={{...style}}>
            <img style={{height: '100%', width: '100%'}} src={ForKG} alt="ForKGIcon"/>
        </Icon>
    )
}

export const ForKPIcon = ({style}) => {
    return (
        <Icon style={{...style}}>
            <img style={{height: '100%', width: '100%'}} src={ForKP} alt="ForKPIcon"/>
        </Icon>
    )
}

export const ForKZIcon = ({style}) => {
    return (
        <Icon style={{...style}}>
            <img style={{height: '100%', width: '100%'}} src={ForKZ} alt="ForKZIcon"/>
        </Icon>
    )
}

export const ForMonthlycon = ({style}) => {
    return (
        <Icon style={{...style}}>
            <img style={{height: '100%', width: '100%'}} src={ForMonthly} alt="ForMonthlyIcon"/>
        </Icon>
    )
}

export const ForWeeklycon = ({style}) => {
    return (
        <Icon style={{...style}}>
            <img style={{height: '100%', width: '100%'}} src={ForWeekly} alt="ForWeeklyIcon"/>
        </Icon>
    )
}

export const SugPurchaseIcon = ({style}) => {
    return (
        <Icon style={{...style}}>
            <img style={{height: '100%', width: '100%'}} src={SugPurchase} alt="SugPurchaseIcon"/>
        </Icon>
    )
}

export const SugDistributionIcon = ({style}) => {
    return (
        <Icon style={{...style}}>
            <img style={{height: '100%', width: '100%'}} src={SugDistribution} alt="SugDistributionIcon"/>
        </Icon>
    )
}

export const SugTwoWeekIcon = ({style}) => {
    return (
        <Icon style={{...style}}>
            <img style={{height: '100%', width: '100%'}} src={SugTwoWeek} alt="SugTwoWeekIcon"/>
        </Icon>
    )
}


export const InputGeneralsIcon = ({style}) => {
    return (
        <Icon style={{...style}}>
            <img style={{height: '100%', width: '100%'}} src={InputG} alt="InputGenerals"/>
        </Icon>
    )
}

export const CompanyIcon = ({style}) => {
    return (
        <Icon style={{...style}}>
            <img style={{height: '100%', width: '100%'}} src={Company} alt="Company"/>
        </Icon>
    )
}

export const LogIcon = ({style}) => {
    return (
        <Icon style={{...style}}>
            <img style={{height: '100%', width: '100%'}} src={Log} alt="Log"/>
        </Icon>
    )
}

export const BranchIcon = ({style}) => {
    return (
        <Icon style={{...style}}>
            <img style={{height: '100%', width: '100%'}} src={Branch} alt="Branch"/>
        </Icon>
    )
}

export const UserIcon = ({style}) => {
    return (
        <Icon style={{...style}}>
            <img style={{height: '100%', width: '100%', fill:'red'}} src={User} alt="User"/>
        </Icon>
    )
}

export const EditIcon = ({style, onClick}) => {
    return (
        <Icon style={{...style}} sx={{fill:'white'}} onClick={onClick}>
            <img style={{height: '100%', width: '100%'}} src={TableEdit} alt="edit"/>
        </Icon>
    )
}

export const SeeIcon = ({style, onClick}) => {
    return (
        <Icon style={{...style}} sx={{fill:'white'}} onClick={onClick}>
            <img style={{height: '100%', width: '100%'}} src={TableSee} alt="edit"/>
        </Icon>
    )
}

export const DeleteIcon = ({style}) => {
    return (
        <Icon style={{...style}}>
            <img style={{height: '100%', width: '100%'}} src={TableDelete} alt="Eliminar"/>
        </Icon>
    )
}

export const WEditIcon = ({style, onClick}) => {
    return (
        <Icon style={{...style}} sx={{fill:'white'}} onClick={onClick}>
            <img style={{height: '100%', width: '100%'}} src={WTableEdit} alt="edit"/>
        </Icon>
    )
}

export const WSeeIcon = ({style, onClick}) => {
    return (
        <Icon style={{...style}} sx={{fill:'white'}} onClick={onClick}>
            <img style={{height: '100%', width: '100%'}} src={WTableSee} alt="edit"/>
        </Icon>
    )
}

export const WDeleteIcon = ({style}) => {
    return (
        <Icon style={{...style}}>
            <img style={{height: '100%', width: '100%'}} src={WTableDelete} alt="Eliminar"/>
        </Icon>
    )
}

export const SearchIcon = ({style}) => {
    return (
        <Icon style={{...style}}>
            <img style={{height: '100%', width: '100%'}} src={TableSearch} alt="search"/>
        </Icon>
    )
}
export const ExpandIcon = ({style}) => {
    return (
        <Icon style={{...style}}>
            <img style={{height: '80%', width: '80%'}} src={TableExpand} alt="expand"/>
        </Icon>
    )
}
export const IconDownload = ({style}) => {
    return (
        <Icon style={{...style}}>
            <img style={{height: '100%', width: '100%'}} src={ButtonDownload} alt="Eliminar"/>
        </Icon>
    )
}
export const IconUpload = ({style}) => {
    return (
        <Icon style={{...style}}>
            <img style={{height: '100%', width: '100%'}} src={ButtonUpload} alt="Eliminar"/>
        </Icon>
    )
}
export const IconToUpdate = ({style}) => {
    return (
        <Icon style={{...style}}>
            <img style={{height: '100%', width: '100%'}} src={ButtonToUpdate} alt="Eliminar"/>
        </Icon>
    )
}

export const IconToFinalize = ({style}) => {
    return (
        <Icon style={{...style}}>
            <img style={{height: '100%', width: '100%'}} src={StateSuggestedFinalize} alt="finalize"/>
        </Icon>
    )
}

export const IconToDoing = ({style}) => {
    return (
        <Icon style={{...style}}>
            <img style={{height: '100%', width: '100%'}} src={StateSuggestedWait} alt="wait"/>
        </Icon>
    )
}

export const IconToWait = ({style}) => {
    return (
        <Icon style={{...style}}>
            <img style={{height: '100%', width: '100%'}} src={StateSuggestedDoing} alt="doing"/>
        </Icon>
    )
}

