import React from "react";
import { CustomTable } from "../../../../common/Components/CustomTable";



export const TableModules = () => {

    const headers = [
      { field: "modulo_name", headerName: "Nombre" },

    ];
    const actions = ["DETAIL", "DELETE"]

    return (<CustomTable 
        actions={actions} 
        headers={headers} 
        />)
  };