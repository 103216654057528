import { Box, Stack } from '@mui/material'
import React from 'react'
import { FormTextInput } from '../../../../common/Components/FormTextInput'
import { TitleStep } from '../../../../common/Components/TitleStep'
import companyFormModel from '../../../../model/company/companyFormModel'

export const DataBranch = () => {
    const { formField } = companyFormModel;
    const {
        branch_name,
        branch_phone,
        branch_description,
        branch_contact_name,
        branch_contact_phone,
        branch_contact_email
    } = formField;
    return (
        <React.Fragment>
            <TitleStep title={"Crear Sucursal"} />
            <Stack
                py={5}
                direction={"row"}
                justifyContent={"space-around"}
                paddingX={8}
            >
                <Box width={"50%"}>
                    <FormTextInput
                        label={branch_name.label}
                        name={branch_name.name}
                        style={{ width: "95%" }}
                        fullWidth={true}
                    />
                    <FormTextInput
                        label={branch_description.label}
                        name={branch_description.name}
                        style={{ width: "95%" }}
                        fullWidth={true}
                    />
                    <FormTextInput
                        label={branch_phone.label}
                        name={branch_phone.name}
                        style={{ width: "95%" }}
                        fullWidth={true}
                    />
                </Box>

                <Box width={"50%"}>
                    <FormTextInput
                        label={branch_contact_name.label}
                        name={branch_contact_name.name}
                        fullWidth={true}
                    />
                    <FormTextInput
                        label={branch_contact_phone.label}
                        name={branch_contact_phone.name}
                        fullWidth={true}
                    />
                    <FormTextInput
                        label={branch_contact_email.label}
                        name={branch_contact_email.name}
                        fullWidth={true}
                    />
                </Box>
            </Stack>
        </React.Fragment>
    )
}
