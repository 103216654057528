import { Box } from '@mui/material';
import React from 'react'
import { useMemo } from 'react';
import { useContext } from 'react';
import { CDetails } from '../../../../common/Components/CDetails';
import CustomModal from '../../../../common/Components/CustomModal';
import ModulesFormContext from '../../../../common/Contexts/ModulesFormContext';
import { styleSheet } from '../../../../common/styles/StylesCommon';
import { ModalOpenEnum } from '../../../../common/utils/ModalOpenEnum';

export const DetailSuggestedModal = () => {
    const { dataTable, isTableLoading, rowData, isOpen, toggleModal } = useContext(ModulesFormContext);

    return (
        <CustomModal
            isOpen={isOpen[ModalOpenEnum.DETAIL]}
            toggleModal={() => toggleModal(ModalOpenEnum.DETAIL)}
            moduleLabel={'executionSugested'}
            actionLabel='view'
            descriptionLabel={''}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 2,
                }}
            >
                <CDetails label="Fecha" value={rowData.created_at} />
                <CDetails label="ID Historico" value={rowData.historical_id} />
                <CDetails label="ID Parametro" value={rowData.parameter_id} />
            </Box>
        </CustomModal>
    )
}
