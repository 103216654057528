import { Box } from '@mui/material';
import { Formik } from 'formik';
import React, { useContext, useState } from 'react'
import { ButtonSave } from '../../../common/Components/ButtonSave';
import { ContainerForm } from '../../../common/Components/ContainerForm';
import CustomModal from '../../../common/Components/CustomModal';
import { FormTextInput } from '../../../common/Components/FormTextInput';
import { CToast } from '../../../common/Components/ToastNotify';
import BranchsContext from '../../../common/Contexts/BranchContext';
import MediaContext from '../../../common/Contexts/MediaContext';
import ModulesFormContext from '../../../common/Contexts/ModulesFormContext';
import { ModalOpenEnum } from '../../../common/utils/ModalOpenEnum';
import { validateFormHomologation } from '../../../model/homologations/homologations.schema';
import checkoutFormModel from '../../../model/homologations/homologationsFormModel';
import HomologationsService from '../../../services/HomologationsService';

export const HomologationsForm = () => {
    const { isMobile, isTablet } = useContext(MediaContext);
    const { branchID } = useContext(BranchsContext);
    const { isOpen, toggleModal, rowData: homologation, getDataTable } = useContext(ModulesFormContext);
    const [isLoading, setIsLoading] = useState(false)
    const { formId, formField: { old_su, new_sku } } = checkoutFormModel;

    const initialValue = {
        old_su: homologation?.old_su || "",
        new_sku: homologation?.new_sku || "",
        isEditing: homologation ? true : false,
        id_homologacion: homologation?.id_homologacion,
    };


    const onSave = async (value) => {
        setIsLoading(true);
        const req = {
            company_id: branchID,
            old_su: value?.old_su,
            new_sku: value?.new_sku,
        }
        value.isEditing && (req.id_homologacion = value.id_homologacion);
        try {
            const { data } = value.isEditing
                ? await HomologationsService.updateHomologation(req)
                : await HomologationsService.createHomologation(req);
            await getDataTable();
            toggleModal(ModalOpenEnum.EDIT);
            CToast(`Homologación ${value.isEditing ? 'editada' : 'creada'}`, "success", true);
        } catch ({ response }) {
            console.log(response);
            if (response?.data?.description) return (CToast(response?.data?.description, "error", true))
            if (response?.data.statusCode === 400) return (CToast("Hubo un error 400 (ESTANDARIZAR)", "error", true))
            return CToast(`Error al ${value.isEditing ? 'editar' : 'crear'} la homologación`, "error", true)
        } finally {
            setIsLoading(false);
        }
    }
    return (
        <CustomModal
            isOpen={isOpen[ModalOpenEnum.EDIT]}
            toggleModal={() => toggleModal(ModalOpenEnum.EDIT)}
            moduleLabel={'homologation'}
            actionLabel={homologation ? "edit" : "create"}
            descriptionLabel={homologation ? `${homologation.old_su}` : ''}
        >
            <Formik
                initialValues={initialValue}
                validationSchema={validateFormHomologation}
                onSubmit={(values, actions) => {
                    onSave(values);
                    actions.setSubmitting(false);
                }}
            >
                {({ handleSubmit }) => (
                    <ContainerForm formID={formId}>
                        <Box width={isTablet ? "100%" : '50%'}>
                            <FormTextInput
                                label={old_su.label}
                                name={old_su.name}
                                style={{ width: "95%" }}
                                fullWidth={true}
                            />
                        </Box>
                        <Box width={isTablet ? "100%" : '50%'}>
                            <FormTextInput
                                label={new_sku.label}
                                name={new_sku.name}
                                fullWidth={true}
                            />
                            <ButtonSave
                                onClick={handleSubmit}
                                isLoading={isLoading}
                                edit={homologation ? true : false}
                            />
                        </Box>
                    </ContainerForm>
                )}
            </Formik>
        </CustomModal>
    )
}
