import React from 'react'
import { styleSheet } from '../styles/StylesCommon';
import { t } from "i18next";
import { Box, Typography } from '@mui/material';

export const CustomTitleModal = React.forwardRef((props, ref) => {
    const styles = styleSheet();
    const action_new = props.type !== 0 ? 'new_two' : 'new';
    return (
        <Box ref={ref} marginTop={5} justifyContent={"center"} display={"flex"}>
            <Typography sx={{ ...styles.titleModal }}>
                {`${props.action !== '' ? t(`labels_actions.${props.action}`) : ''} ${props.hiddenModuleLabel ? '' : t(`modules.${props.module}`)} ${!['create'].includes(props.action) ? props.description : ''}`}
            </Typography>
        </Box>
    )
}
)