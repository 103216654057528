import { Formik } from 'formik';
import React, { useContext, useState } from 'react'
import { ContainerForm } from '../../../common/Components/ContainerForm';
import CustomModal from '../../../common/Components/CustomModal';
import { CToast } from '../../../common/Components/ToastNotify';
import BranchsContext from '../../../common/Contexts/BranchContext';
import MediaContext from '../../../common/Contexts/MediaContext';
import ModulesFormContext from '../../../common/Contexts/ModulesFormContext';
import checkoutFormModel from '../../../model/sku/sku.model';
import { validateFormSKU } from '../../../model/sku/sku.schema';
import SKUForCDService from '../../../services/SKUService';
import { t } from 'i18next'
import { ButtonSave } from '../../../common/Components/ButtonSave';
import { Box, Stack } from '@mui/material';
import { SelectCenterDistribution } from './components/SelectCenterDistribution';
import { FormTextInput } from '../../../common/Components/FormTextInput';
import { CSwitchForm } from '../../../common/Components/CSwitch';
import { ModalOpenEnum } from '../../../common/utils/ModalOpenEnum';

export const SKUForm = () => {
    const { isMobile, isTablet } = useContext(MediaContext);
    const { branchID } = useContext(BranchsContext);
    const { isOpen, toggleModal, rowData: dataSKU, getDataTable } = useContext(ModulesFormContext);
    const [isLoading, setIsLoading] = useState(false)
    const { formId, formField: { sku, center_distribution, isActive } } = checkoutFormModel;

    const initialValue = {
        sku: dataSKU?.sku || '',
        center_distribution: dataSKU?.cd || '',
        isActive: dataSKU?.active || false,
        isEditing: dataSKU ? true : false,
        id_sku: dataSKU?.id_sku,
        company_id: dataSKU?.company_id || branchID
    }

    const onSave = async (value) => {
        const req = {
            company_id: value.company_id,
            sku: value.sku,
            cd: value.center_distribution,
            active: value.isActive
        }
        value.isEditing && (Object.assign(req, { id_sku: value.id_sku }));
        console.log(req)
        try {
            setIsLoading(true);
            const { data } = value.isEditing
                ? await SKUForCDService.updateSKUForCD(req)
                : await SKUForCDService.createSKUForCD(req);
            await getDataTable();
            toggleModal(ModalOpenEnum.EDIT);
            CToast(`SKU ${value.isEditing ? 'editado' : 'creado'}`, "success", true);
        } catch ({ response }) {
            console.log(response);
            if (response?.data?.error) return (CToast(response?.data?.error, "error", true))
            if (response?.data.statusCode === 400) return (CToast("Hubo un error 400 (ESTANDARIZAR)", "error", true))
            return CToast(`Error al ${value.isEditing ? 'editar' : 'crear'} el SKU`, "error", true)
        } finally {
            setIsLoading(false);
        }

    }

    return (
        <CustomModal
            isOpen={isOpen[ModalOpenEnum.EDIT]}
            toggleModal={() => toggleModal(ModalOpenEnum.EDIT)}
            moduleLabel={'SKU_for_CD'}
            actionLabel={dataSKU ? "edit" : "create"}
            descriptionLabel={dataSKU ? ` SKU ${dataSKU.sku}` : ''}
            hiddenModuleLabel={dataSKU ? true : false}
        >
            <Formik
                initialValues={initialValue}
                validationSchema={validateFormSKU}
                onSubmit={(values, actions) => {
                    onSave(values);
                    actions.setSubmitting(false);
                }}
            >
                {({ handleSubmit, values }) => (
                    <ContainerForm formID={formId}>
                        <Box px={8} py={3}>
                            <SelectCenterDistribution
                                name={center_distribution.name}
                                label={center_distribution.label}
                            />

                            <Stack
                                direction={isTablet ? "column" : "row"}
                                justifyContent={"space-evenly"}
                                alignItems="center"
                                space={2}
                            >
                                <Box width={isTablet ? "100%" : "70%"}>
                                    <FormTextInput
                                        name={sku.name}
                                        label={sku.label}
                                        fullWidth={true}
                                    />
                                </Box>
                                <Box width={isTablet ? "100%" : "30%"} paddingLeft={5}>
                                    <CSwitchForm
                                        label={isActive.label}
                                        name={isActive.name}
                                        labelId={isActive.label}
                                        id={isActive.name}
                                        checked={values.isActive}
                                        label_true="Activo"
                                        label_false="Inactivo"
                                    />
                                </Box>
                            </Stack>
                            <ButtonSave
                                onClick={handleSubmit}
                                isLoading={isLoading}
                                edit={dataSKU ? true : false}
                            />
                        </Box>
                    </ContainerForm>
                )}
            </Formik>
        </CustomModal>
    )
}
