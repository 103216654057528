import { Box, IconButton, Stack, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "../../../../common/styles/styleTable.css";
import React, { useContext, useMemo, useState, useEffect } from "react";
import MediaContext from "../../../../common/Contexts/MediaContext";
import ThemeContext from "../../../../common/Contexts/ThemeContext";
import { styleSheet } from "../../../../common/styles/StylesCommon";
import { LayoutTableGraphs } from "../../components/LayoutTableGraphs";

import ModulesFormContext from "../../../../common/Contexts/ModulesFormContext";
import { getMonth, months } from "../../../../helpers/months";
import { formatMoney } from "../../../../helpers/formatNumber";
import { dataTest } from "../json/test";


export const TableVCC = ({ dataGraph }) => {

  const { isMobile, isTablet } = useContext(MediaContext);
  // const { dataGraph } = useContext(ModulesFormContext);
  const overallStyle = styleSheet(isMobile, isTablet);
  console.log(dataGraph);
  const [columnDef, setColumnDef] = useState([
    {
      field: "month",
      headerName: "Mes",
      width: 150,
      marryChildren: true,
      valueFormatter: ({ data }) => getMonth(data.month).month_name,
    },
    ...["Venta", "Costo", "Margen"].map((key) => ({
      field: key,
      headerName: key,
      children: ["2016", "2017", "2018"].map((year) => ({
        field: `${key}_${year}`,
        headerName: year,
      })),
    })),
  ]);

  // const Headers = [];

  const { state } = useContext(ThemeContext);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
      width: 120,
      minWidth: 100,
      maxWidth: 300,
      lockPinned: true,
    }),
    []
  );

  // set the column headers from the data
  useEffect(() => {
    // setColumnDef(Headers);
  }, [dataGraph]);

  return (
    <LayoutTableGraphs>
      <Box
        className="ag-theme-alpine"
        sx={
          state.theme === 1
            ? { ...overallStyle.tableLight, height: '100%' }
            : { ...overallStyle.tableDark, height: '100%' }
        }
      >
        <AgGridReact
          rowData={dataTest}
          columnDefs={columnDef}
          defaultColDef={defaultColDef}
          animateRows={true}
          suppressRowClickSelection={true}
          suppressRowTransform={true}
          groupSelectsChildren={true}
          paginationPageSize={10}
          paginationAutoPageSize={true}
          pagination={true}
          groupHeaderHeight={25}
        />
      </Box>
    </LayoutTableGraphs>
  );
};
