/* eslint-disable import/no-anonymous-default-export */
export default {
    "login": {
        "errors": {
            "email": "Email invalido",
            "password": "Password invalido"
        }
    },
    "buttons": {
        "download_data": "Descargar datos",
        "download_data_errors": "Descargar lista de errores",
        "upload_data_csv": "Carga masiva por CSV",
        "save": "Guardar",
    },
    "types": {
        "success": "success",
        "error": "danger",
        "warning": "warning",
    },
    "messages": {
        "successLoadFile": "Se cargo el archivo correctamente",
        "success_delete": "Registro eliminado correctamente",
        "error_delete": "Ocurrio un error al intentar eliminar el registro",
        "errorLoadFile": "No se pudo cargar el archivo",
        "errorLoadFileFormat": "El archivo no tiene el formato correcto",
        "errorLoadFileEmpty": "El archivo esta vacio",
        "errorLoadFileEmptyRows": "El archivo no tiene filas",
        "error_mail_isExist": 'El email ya se encuentra registrado',
        "error_company_isExist": 'Ya existe registrada una empresa con ese nombre',
        "error_branch_isExist": 'Ya existe registrada una sucursal con ese nombre',
        "error_validate": 'Ocurrió un error al validar los datos ingresados',
        "error_login": 'Ocurrió un error - Usuario o contraseña incorrectos',
        "error_get_data": 'Ocurrió un error al obtener los datos',
        "error_get_dataTable": 'Ocurrió un error al obtener los datos de la tabla ',
        "info_exist_config": "Ya existe una configuracion con esos accesos",
        "user_success_create": "Usuario creado correctamente",
        "user_success_update": "Usuario actualizado correctamente",
    },
    "labels_modals": {
        "title_modal_upload": "Carga masiva de datos",
        "title_modal_upload_preview": "Vista previa de carga masiva",
        "only_year": "Solo por este año",
        "select_one_config": "Seleccione una configuración antes de continuar",
        "select_config_and_create": "Puede seleccionar una configuración predeterminada para comenzar",
        "create_config": "Crear una nueva configuración",
        "edit_config": "Editar configuración",
        "select_config": "Seleccionar una configuración",
        "pref_user": "Preferencias de usuario",
        "change_password": "Cambiar contraseña",
        "error_file_resume": "Problemas en el archivo",
    },
    "titles": {
        "radar_control": "Radar Control",
        "VCC": "Venta/Costo/Contribución",
        "graficoForecast": "Grafico Forecast",
        "radarSku": "Radar SKU",
        "cron_jobs": "Cron Jobs",
        "wareouses": "Bodegas",
        "sections": "Secciones",
        "modules": "Modulos",
        "distribution_centers": "Centros de distribución",
        "calendar": "Calendario",
        "weights_daily": "Pesos Diarios",
        "homologations": "Homologaciones",
        "level_service": "Niveles de Servicio",
        "constant_week": "Constantes de Semanas",
        "exclude_dates": "Fechas Excluidas",
        "guide_transits": "Guías de Tránsito",
        "forecastExternals": "Forecast Externos",
        "parameters_suggesteds": "Parámetros Sugeridos",
        "listSuggestions": "Listado de Sugeridos",
        "SKU_for_CD": "SKU para CD",
        "mix": "Mix",
        "logs": "Logs",
        "roles": "Roles",
    },
    "labels_actions": {
        "add": "Agregar",
        "edit": "Editar",
        "delete": "Eliminar",
        "cancel": "Cancelar",
        "save": "Guardar",
        "Close": "Cerrar",
        "search": "Buscar",
        "clear": "Limpiar",
        "download": "Descargar",
        "upload": "Subir",
        "create": "Crear",
        "new": "Nueva",
        "new_two": "Nuevo",
        "view": "Ver",
        "update": "Actualizar",
        "export": "Exportar",
        "import": "Importar",
        "print": "Imprimir",
        "copy": "Copiar",
        "loading": "Cargando",
        "loadingRowsTable": "Cargando filas...",
        "copy_to_clipboard": "Copiar al portapapeles",
        "none": '',
        "executed": 'Ejecutar',
        "select_config": " Seleccionar configuración",
        "select_rol": " Seleccionar rol predefinido",
        "info": "Información",
    },
    modules: {
        warehouse: "Bodega",
        distribution_center: "Centro de Distribución",
        company: "Empresa",
        branch: "Sucursal",
        user: "Usuario",
        section: "Seccion",
        sections: "Secciones",
        module: "Modulo",
        modules: "Modulos",
        forecast: "Forecast",
        sku: "Radar SKU",
        users: "Usuarios",
        role: "Rol",
        permission: "Permiso",
        cron_jobs: "Cron Jobs",
        radar_control: "Radar Control",
        calendar: "Calendario",
        weights_daily: "Peso Diario",
        homologation: "Homologación",
        constant_week: "Constante de semana",
        level_service: "Nivel de Servicio",
        parameters_suggested: "Parámetros del Sugerido",
        exclude_date: "Fecha Excluida",
        guide_transit: "Guía de Tránsito",
        SKU_for_CD: "SKU para CD",
        executionSugested: "Ejecución del Sugerido",
        listSuggestions: "Lista de Sugeridos",
        register: 'Registro',
        forecastExternal: 'Forecast Externo',
        mix: 'Mix',
        logs: 'Logs',
    },
    steps: {
        company: {
            step1: "Datos de la empresa",
            step2: "Seleccionar los permisos a los que tendrá acceso",
            step3: "Seleccionar los procesos que podrá ejecutar",
            step4: "Datos de la sucursal",
            step5: "Seleccionar los permisos a los que tendrá acceso la sucursal",
            step6: "Procesos que puede ejecutar la sucursal",
        },
        user: {
            step1: "Datos del usuario",
            step2: "Seleccionar los permisos a los que tendrá acceso",
            step3: "Asignar Empresa o sucursal",
        },
        branch: {
            step1: "Datos de la sucursal",
            step2: "Seleccionar los modulos a los que tendra acceso",
            step3: "Procesos que puede ejecutar la sucursal",
        }
    },
    graph: {
        "title": "Radar Control",
        sku: {
            percent_vents: "% de la Venta",
            break_date: "Fecha Quiebre",
            transit: "Tránsito",
            rankingLastMonths: "Ranking últimos 3 meses",
            units_Transactions: "Unidades VS Transacciones",
            units_vents: "Unidades de ventas",
            percent: "Porcentaje",
            min_date: "Min. fecha",
            units_break: "Unidades de quiebre",
        }
    }
}