
import React, { useState, useContext } from "react";
import {
  Box,
  Alert,
  Divider,
} from "@mui/material";
import { Formik, useFormikContext } from "formik";
import { styleSheet } from "../styles/StylesCommon";
import { CToast } from "./ToastNotify";
import { SidebarService } from "../../services/SidebarService";
import { validateCreatePermission } from "../../model/company/company.schema";
import { CreateConfig } from "./CreateConfig";
import { CheckListGroup } from "./CheckListGroup";
import { t } from "i18next";

var deepEqual = require("deep-equal");

export const ListCheckRoles = ({ configsAccess, getConfigsxCompany, name_elements, name_permission, nameConfigEqual, setNameConfigEqual }) => {

  const { values, handleChange, setFieldValue } = useFormikContext();
  const [existe, setExiste] = useState(null);
  const [errInput, setErrInput] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const styles = styleSheet();

  const initialValues = {
    rol_name: "",
  };

  const onSave = async (value) => {
    setIsLoading(true);
    const req = {
      cluster_name: value.rol_name,
      cod_element_logic: +name_elements.cod_element_logic,
      elements_cluster: values[name_elements.name]
        .filter(i => i?.modulo_id.split("_")[0] === name_elements.cod_element_logic && i.active),
    };
    // console.log('cod_element_logic', name_elements.cod_element_logic)
    console.log(req)
    if (values[name_elements.name].filter(item => item.active).length === 0) {
      CToast('Debe seleccionar al menos un módulo con sus roles', 'error')
      setIsLoading(false)
      return
    }

    try {
      const { data } = await SidebarService.createElementLogic(req);
      CToast("Configuracion de acceso creada", "success", true);
      setExiste(null);
      getConfigsxCompany().then((res) => {
        const newconfig = res.find(r => r.id_element_logic === data.id_element_logic);
        setFieldValue(name_permission, newconfig.id_element_logic);
        setIsLoading(false);
      });
    } catch ({ response }) {
      response.data.statusCode === 409 && CToast("Ya existe una configuracion con ese nombre", "error", true);
      response.data.statusCode === 400 && CToast("El campo no puede estar vacio", "error", true);
      CToast("Error al crear la configuracion", "error", true);
      setIsLoading(false)
    }
    // await SidebarService.createElementLogic(req)
    //   .then(({ data }) => {
    //     console.log(data)
    //     CToast("Configuracion de acceso creada", "success", true);
    //     setExiste(null);
    //     getModuleCompany().then((res) => {
    //       console.log(res)
    //     });
    //     setFieldValue(name_permission, data.rol_name)
    //   })
    //   .catch(({ response }) => {
    //     console.log('ERROR POST - ElementLogic - LISTCHECKROLES', response)
    //     response.data.statusCode === 409 && CToast("Ya existe una configuracion con ese nombre", "error", true);
    //     response.data.statusCode === 400 && CToast("El campo no puede estar vacio", "error", true);
    //     CToast("Error al crear la configuracion", "error", true);
    //   })
    //   .finally(() => setIsLoading(false))
  };

  // useEffect(() => {
  //   getModulexCompany();
  // }, [existe]);

  return (
    <>
      {
        (values[name_permission] === "" && existe) && (
          <Box>
            <Alert
              severity="info"
              style={{
                ...styles.baseAlert,
              }}
            >
              {t('messages.info_exist_config')} {nameConfigEqual}
            </Alert>
          </Box>
        )
      }
      {
        (values[name_permission] === ""
          && values[name_elements.name].filter((item) => item.active).length !== 0
          && !existe) ? (
          <Formik
            initialValues={initialValues}
            validationSchema={validateCreatePermission}
            onSubmit={(value, actions) => {
              onSave(value);
              !isLoading && actions.resetForm();
            }}>
            {({ resetForm, handleSubmit, setFieldValue }) => {
              return (
                <CreateConfig
                  name={'rol_name'}
                  label='Nombre de la configuracion'
                  handleSubmit={handleSubmit}
                  isLoading={isLoading} />
              );
            }}
          </Formik>
        ) : (
          <Divider bgcolor="background.paper" style={{ paddingBlock: 60, border: 0 }} />
        )
      }
      <CheckListGroup
        name_permission={name_permission}
        name_elements={name_elements}
        compareFirstArray={configsAccess}//configsAccess--configs
        existe={existe}
        setExiste={setExiste}
        setNameConfigEqual={setNameConfigEqual}
      />
    </>
  );
};
