import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useMemo } from "react";
import { TablesTitles } from "../../components/TablesTitles";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styleSheet } from "../../../../common/styles/StylesCommon";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: 2,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const TableRanking = ({ rankingsVents }) => {

  const overallStyle = useMemo(() => styleSheet(), []);

  return (
    <Box sx={{ height: "50vh", width: '100%' }}>
      <TableContainer sx={{ border: 1, height: "50vh", width: '100%' }} component={Paper}>
        <Table
          sx={{ height: 400, width: "100%" }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <StyledTableCell style={{ fontSize: 12 }} align="left">
                Nombre
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: 12 }}
                align="center"
              >
                Venta
              </StyledTableCell>
              <StyledTableCell
                style={{
                  fontSize: 12,
                  justifyItems: "center",
                  textOverflow: "ellipsis",
                  display: "flex",
                }}
                align="right"
              >
                Porcentaje de participación
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rankingsVents.map((row, index) => (
              <StyledTableRow
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                key={index}
              >
                <StyledTableCell
                  style={{
                    fontSize: 10,
                    maxLines: 2,
                    textOverflow: "ellipsis",
                    width: "30%",
                  }}
                  align="left"
                >
                  {row?.cliente || row?.vendedor}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    fontSize: 12,
                    width: "20%",

                  }}
                  align="center"
                >
                  {row?.venta}
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: 12, width: "50%" }}
                  align="right"
                >{`${row?.porcentaje_venta}`}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>


    </Box>
  );
};
