import { Formik } from 'formik';
import React, { useContext, useState, useEffect, useMemo } from 'react'
import { ContainerForm } from '../../../common/Components/ContainerForm';
import CustomModal from '../../../common/Components/CustomModal'
import { CToast } from '../../../common/Components/ToastNotify';
import ModulesFormContext from '../../../common/Contexts/ModulesFormContext';
import RolService from '../../../services/RolesService';
import checkoutFormModel from '../../../model/roles/roles.model';
import { validateFormRol } from '../../../model/roles/roles.schema';
import { Alert, Box, Stack } from '@mui/material';
import { t } from 'i18next';
import { SelectRolesForm } from '../Users/components/SelectedRolesForm';
import { ListRoles } from './components/ListRoles';
import { ModalOpenEnum } from '../../../common/utils/ModalOpenEnum';
import { styleSheet } from '../../../common/styles/StylesCommon';
var store = require("store");

export const RolesForm = () => {
    const { isOpen, toggleModal, rowData: rol } = useContext(ModulesFormContext);
    const { formId, formField: { select_rol_name, modulo_cluster } } = checkoutFormModel;
    const [nameConfigEqual, setNameConfigEqual] = useState("");
    const [rolesAccess, setRolesAccess] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const elements = store.get("sidebar");
    const overallStyle = useMemo(() => styleSheet(), []);
    const initialValue = {
        rol_cluster: rol?.rol_cluster || elements,
        select_rol_name: rol?.id_rol || "",
        isEditing: rol ? true : false,
        id_rol: rol?.id_rol
    };

    const getRoles = async () => {
        setIsLoading(true)
        try {
            const { data } = await RolService.getRoles();
            setRolesAccess(data);
            return data;
        } catch ({ response }) {
            CToast(response?.data?.error, "error", true)
        } finally {
            setIsLoading(false)
        }
    };

    useEffect(() => {
        getRoles();
    }, []);

    return (
        <CustomModal
            isOpen={isOpen[ModalOpenEnum.EDIT]}
            toggleModal={() => toggleModal(ModalOpenEnum.EDIT)}
            moduleLabel={'role'}
            actionLabel={rol ? "edit" : "create"}
            descriptionLabel={rol ? `${rol?.rol_name}` : ''}
        >
            <Formik
                initialValues={initialValue}
                validationSchema={validateFormRol}>
                {({ values }) => (
                    <ContainerForm formID={formId}>
                        <Box width={"100%"}>
                            {!values.isEditing && values[select_rol_name.name] === "" && (
                                <Box sx={{ marginX: 8 }} paddingX={5}>
                                    <Alert
                                        severity="warning"
                                        style={{
                                            ...overallStyle.baseAlert,
                                        }}
                                    >{t('labels_modals.select_config_and_create')}</Alert>
                                </Box>
                            )}
                            {
                                !values.isEditing && (
                                    <SelectRolesForm
                                        name_elements_checks={modulo_cluster}
                                        fullWidth
                                        name={select_rol_name.name}
                                        label={select_rol_name.label}
                                        isLoading={isLoading}
                                        rolesAccess={rolesAccess}
                                        getRolesAccess={getRoles}
                                        nameConfigEqual={nameConfigEqual}
                                    />
                                )
                            }
                            <Stack py={values.isEditing ? 1 : 5} justifyContent={"space-evenly"}>
                                <ListRoles
                                    nameConfigEqual={nameConfigEqual}
                                    setNameConfigEqual={setNameConfigEqual}
                                    name_elements={modulo_cluster}
                                    cod_element_logic={modulo_cluster.cod_element_logic}
                                    name_permission={select_rol_name.name}
                                    rolesAccess={rolesAccess}
                                    getRolesAccess={getRoles}
                                />
                            </Stack>
                        </Box>
                    </ContainerForm>
                )}
            </Formik>
        </CustomModal>
    )
}
