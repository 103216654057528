import { Box } from '@mui/material';
import { Formik } from 'formik';
import React, { useContext, useState } from 'react'
import { ButtonSave } from '../../../common/Components/ButtonSave';
import { ContainerForm } from '../../../common/Components/ContainerForm';
import CustomModal from '../../../common/Components/CustomModal';
import { FormTextInput } from '../../../common/Components/FormTextInput';
import { CToast } from '../../../common/Components/ToastNotify';
import BranchsContext from '../../../common/Contexts/BranchContext';
import MediaContext from '../../../common/Contexts/MediaContext';
import ModulesFormContext from '../../../common/Contexts/ModulesFormContext';
import { ModalOpenEnum } from '../../../common/utils/ModalOpenEnum';
import checkoutFormModel from '../../../model/mix/mix.model';
import { validateFormMix } from '../../../model/mix/mix.schema';
import MixService from '../../../services/MixService';

export const MixForm = () => {
    const { isMobile, isTablet } = useContext(MediaContext);
    const { branchID } = useContext(BranchsContext);
    const { isOpen, toggleModal, rowData: mix, getDataTable } = useContext(ModulesFormContext);
    const [isLoading, setIsLoading] = useState(false)
    const { formId, formField: { minimo, estilo_color, talla, tienda, sku, cod_art, delivery } } = checkoutFormModel;

    const initialValue = {
        company_id: mix?.company_id || branchID,
        minimo: mix?.minimo || '',
        estilo_color: mix?.estilo_color || '',
        talla: mix?.talla || '',
        tienda: mix?.tienda || '',
        sku: mix?.sku || '',
        cod_art: mix?.cod_art || '',
        delivery: mix?.delivery || '',
        isEditing: mix ? true : false,
        id_cMasivaMix: mix?.id_cMasivaMix,
    }

    const onSave = async (value) => {
        const req = {
            company_id: value.company_id,
            minimo: value.minimo,
            estilo_color: value.estilo_color,
            talla: value.talla,
            tienda: value.tienda,
            sku: value.sku,
            cod_art: value.cod_art,
            delivery: value.delivery,
        }
        value.isEditing && (Object.assign(req, { id_cMasivaMix: value.id_cMasivaMix }));
        try {
            setIsLoading(true);
            const { data } = value.isEditing
                ? await MixService.updateMix(req)
                : await MixService.createMix(req);
            await getDataTable();
            toggleModal(ModalOpenEnum.EDIT);
            CToast(`Mix ${value.isEditing ? 'editado' : 'creado'}`, "success", true);
        } catch ({ response }) {
            if (response?.data?.error) return (CToast(response?.data?.error, "error", true))
            return CToast(`Error al ${value.isEditing ? 'editar' : 'crear'} el Mix`, "error", true)
        } finally {
            setIsLoading(false);
        }
    }
    return (
        <CustomModal
            isOpen={isOpen[ModalOpenEnum.EDIT]}
            toggleModal={() => toggleModal(ModalOpenEnum.EDIT)}
            moduleLabel={'mix'}
            actionLabel={mix ? "edit" : "create"}
            descriptionLabel={mix ? `${mix?.cod_art}` : ''}
        >
            <Formik
                initialValues={initialValue}
                validationSchema={validateFormMix}
                onSubmit={(values, actions) => {
                    onSave(values);
                    actions.setSubmitting(false);
                }}
            >
                {({ handleSubmit, values }) => (
                    <ContainerForm formID={formId}>
                        <Box width={isTablet ? '100%' : '50%'}>
                            <FormTextInput
                                label={minimo.label}
                                name={minimo.name}
                                type={"number"}
                                inputProps={{ min: 0, max: 100, step: 0.1 }}
                                fullWidth={true}
                            />
                            <FormTextInput
                                label={estilo_color.label}
                                name={estilo_color.name}
                                fullWidth={true}
                            />
                            <FormTextInput
                                label={talla.label}
                                name={talla.name}
                                fullWidth={true}
                            />
                            <FormTextInput
                                label={tienda.label}
                                name={tienda.name}
                                fullWidth={true}
                            />
                        </Box>
                        <Box width={isTablet ? '100%' : '50%'}>
                            <FormTextInput
                                label={sku.label}
                                name={sku.name}
                                fullWidth={true}
                            />
                            <FormTextInput
                                label={cod_art.label}
                                name={cod_art.name}
                                fullWidth={true}
                            />
                            <FormTextInput
                                label={delivery.label}
                                name={delivery.name}
                                fullWidth={true}
                            />
                            <ButtonSave
                                onClick={handleSubmit}
                                isLoading={isLoading}
                                edit={mix ? true : false}
                            />
                        </Box>
                    </ContainerForm>
                )}
            </Formik>
        </CustomModal>
    )
}
