import { Box, Typography } from "@mui/material";
import React from "react";
import { formatMoney } from "../../../../helpers/formatNumber";

export const CBoxSaleMonth = ({ ...props }) => {
  return (
    <Box
      sx={{
        color: "white",
        borderRadius: "15px",
        padding: "15px",
        paddingX:"40px",
        backgroundColor: "white",
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.15)",
        marginY: "20px",
      }}
    >
      <Typography color={"primary.main"} variant={"h5"}>
        Venta Mes:
      </Typography>
      <Typography color={"primary.main"} variant={"h5"}>
        {
          props.saleMonth
            ? formatMoney(props.saleMonth)
            : "$0"

          
        }
      </Typography>
    </Box>
  );
};
