import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import en_us from './lang/en_us';
import es_ar from './lang/es_ar';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en_us: {
                translation: en_us
            },
            es_ar: {
                translation: es_ar
            }
        },
        lng: 'es_ar',
        fallbackLng: 'es_ar',
        debug: process.env.NODE_ENV === 'development',
    });


export default i18n;
