import { Alert, Box, Divider } from '@mui/material';
import { Formik, useFormikContext } from 'formik';
import { t } from 'i18next';
import React, { useContext, useState } from 'react'
import { validateCreateConfig } from '../../model/company/company.schema';
import { SidebarService } from '../../services/SidebarService';
import { styleSheet } from '../styles/StylesCommon';
import { CheckListGroupMethod } from './CheckListGroupMethod';
import { CreateConfig } from './CreateConfig';
import { CToast } from './ToastNotify';

export const ListCheckMethods = ({ configsMethods, getConfigsMethods, name_elements, name_permission, nameConfigEqual, setNameConfigEqual }) => {

    const { values, handleChange, setFieldValue } = useFormikContext();
    const [existe, setExiste] = useState(null);
    const [errInput, setErrInput] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const styles = styleSheet();

    const initialValues = {
        cluster_name: "",
    };


    const onSave = async (value) => {
        setIsLoading(true);
        const req = {
            model_logic_name: value.cluster_name,
            cod_model_logic: 1,
            process_cluster: values[name_elements.name],
        };

        if (values[name_elements.name].filter(item => item.active).length === 0) {
            CToast('Debe seleccionar al menos un módulo con sus roles', 'error')
            setIsLoading(false)
            return
        }

        try {
            const { data } = await SidebarService.createModelLogic(req);
            CToast("Configuracion de procesos creada", "success", true);
            setExiste(null);
            getConfigsMethods().then((res) => {
                const newconfig = res.find(m => m.model_logic_id === data.model_logic_id);
                setFieldValue(name_permission, newconfig.model_logic_id);
                setIsLoading(false);
            });
        } catch ({ response }) {
            if (response.data.statusCode === 409) CToast("Ya existe una configuracion con ese nombre", "error", true);
            if (response.data.statusCode === 400) CToast("El campo no puede estar vacio", "error", true);
            setIsLoading(false)
            return CToast("Error al crear la configuracion", "error", true);
        }
    };

    return (
        <React.Fragment>
            {
                (values[name_permission] === "" && existe) && (
                    <Box>
                        <Alert
                            severity="info"
                            style={{
                                ...styles.baseAlert,
                            }}
                        >
                            {t('messages.info_exist_config')} {nameConfigEqual}
                        </Alert>
                    </Box>
                )
            }
            {
                (values[name_permission] === ""
                    && values[name_elements.name].filter((item) => item.active).length >= 0
                    && !existe) ? (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validateCreateConfig}
                        onSubmit={(values, actions) => {
                            onSave(values);
                            !isLoading && actions.resetForm();
                        }}>
                        {({ resetForm, handleSubmit, setFieldValue }) => {
                            return (
                                <CreateConfig
                                    name={'cluster_name'}
                                    label='Nombre de la configuracion'
                                    handleSubmit={handleSubmit}
                                    isLoading={isLoading} />
                            );
                        }}
                    </Formik>
                ) : (
                    <Divider bgcolor="background.paper" style={{ paddingBlock: 60, border: 0 }} />
                )
            }
            <CheckListGroupMethod
                name_permission={name_permission}
                name_elements={name_elements}
                compareFirstArray={configsMethods}
                existe={existe}
                setExiste={setExiste}
                setNameConfigEqual={setNameConfigEqual}
            />
        </React.Fragment>
    )
}
