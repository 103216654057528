import { useContext, useState, useEffect } from 'react'
import { Box } from "@mui/material";
import Title from "../../../common/Components/Title";
import { ButtonsFileCSV } from "../../../common/Components/ButtonsFileCSV";
import BranchsContext from '../../../common/Contexts/BranchContext';
import ContantsService from '../../../services/ContantsOfWeeksService';
import { CustomModalDelete } from '../../../common/Components/CustomModalDelete';
import { TableConstantWeek } from './components/TableConstans';
import { LayoutTable } from '../../../common/Components/LayoutTable';
import { ModulesProvider } from '../../../common/Providers/ModulesProvider';
import { ConstanstWeeksForm } from './ConstanstWeeksForm';
import { useForm } from '../../../hooks/useForm';
import ModulesFormContext from '../../../common/Contexts/ModulesFormContext';
import { CToast } from '../../../common/Components/ToastNotify';
import { t } from 'i18next';
import { ModalOpenEnum } from '../../../common/utils/ModalOpenEnum';



const ConstantsWeek = () => {

    const { branchID } = useContext(BranchsContext);
    const { overallStyle } = useContext(ModulesFormContext);
    //LOGIC FORM
    const {
        isOpen,
        rowData,
        toggleModal,
        dataTable,
        setDataTable,
        isTableLoading,
        setIsTableLoading
    } = useForm();
    const [isLoadingDelet, setIsLoadingDelet] = useState(false)

    const getDataTable = async () => {
        setIsTableLoading(true);
        const req = {
            company_id: branchID,
        };
        try {
            const { data } = await ContantsService.getConstantWeekByBranch(req);
            setDataTable(data);
        } catch ({ response }) {
            console.log(response);
            CToast(`Error al obtener ${JSON.stringify(response?.data.description)}`, "error", true);
        } finally {
            setIsTableLoading(false);
        }

    };

    const handleDeleteConstants = async () => {
        setIsLoadingDelet(true);
        const req = {
            id_constantesDeSemanas: rowData?.id_constantesDeSemanas,
            company_id: branchID,
        }
        try {
            await ContantsService.deleteConstantWeek(req);
            await getDataTable();
            CToast(t('messages.success_delete'), 'success', true);
        } catch ({ response }) {
            console.log(response)
            CToast(t('messages.error_delete'), 'error', true);
        } finally {
            setIsLoadingDelet(false)
        }

    }

    useEffect(() => {
        branchID.length > 0 && getDataTable();
    }, [branchID]);

    return (
        <ModulesProvider value={{
            getDataTable,
            handleDelete: handleDeleteConstants,
            isOpen,
            rowData,
            toggleModal,
            dataTable,
            setDataTable, isTableLoading,
        }}>
            <Box sx={overallStyle?.container}>
                <Title name={t('titles.constant_week')} />
                <ButtonsFileCSV />
                <LayoutTable labelAction={'Agregar Constante'}>
                    {isOpen[ModalOpenEnum.EDIT] && <ConstanstWeeksForm />}
                    {isOpen[ModalOpenEnum.DELETE] && (
                        <CustomModalDelete
                            descriptionLabel={rowData?.week_number ? rowData.week_number : ''}
                            isLoading={isLoadingDelet}
                            moduleLabel={'constant_week'}
                        />)
                    }
                    <TableConstantWeek />
                </LayoutTable>
            </Box>
        </ModulesProvider>
    )
}


export default ConstantsWeek