import React, { useMemo } from "react";
import { Box, Typography, Stack } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { LineBar } from "../../../../common/Components/graph/LineBar";
import { formatMoney, formatN } from "../../../../helpers/formatNumber";
import { getMonth } from "../../../../helpers/months";
import { styleSheet } from "../../../../common/styles/StylesCommon";
import { groupByYears } from "../../../../helpers/formatArrays";
import ModulesFormContext from "../../../../common/Contexts/ModulesFormContext";

export const HistoryBarChart = ({ dataGraph, indexBy }) => {
    // console.log("dataGraph", dataGraph, indexBy);
    const overallStyle = styleSheet();
    const groupByYear = useMemo(() => groupByYears(dataGraph?.data), []);
    // const { dataGraph } = useContext(ModulesFormContext);
    // console.log("groupByYear", groupByYear);
    return (
        <Box
            width={"100%"}
            height={'30vh'}
            sx={{
                ...overallStyle.boxWhite,
            }}
        >
            <ResponsiveBar
                data={dataGraph?.data}
                valueFormat={(value) => formatMoney(value.toString())}
                colors={(param) => {
                    // console.log('COLOR PARAMS', param)
                    return param.data[`${param.data.year}Color`];
                }}
                innerPadding={0.5}
                tooltip={(e) => {
                    return (
                        <Box key={e.indexValue} style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "100%",
                            backgroundColor: "white",
                        }}>
                            <Typography>
                                Mes de Fecha Doc: <strong>{getMonth(e.data.month)?.month_name}</strong>
                            </Typography>
                            <Typography>
                                Año de Fecha Doc: <strong>{e.data.year}</strong>
                            </Typography>
                            <Typography>
                                {e.id}: <strong>{e.formattedValue}</strong>
                            </Typography>
                        </Box>
                    );
                }}
                keys={[dataGraph?.id.toLowerCase()]}
                indexBy={"id"}
                groupMode="grouped"
                margin={{ top: 30, right: 130, bottom: 60, left: 60 }}
                padding={0.5}
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: true }}
                borderColor={{
                    from: "color",
                    modifiers: [["darker", 0.5]],
                }}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Meses",
                    legendPosition: "middle",
                    legendOffset: 32,
                    format: (value) => getMonth(value.slice(5))?.month_name_ab,
                }}
                // axisBottom={null}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: -20,
                    tickRotation: 0,
                    legend: dataGraph.id,
                    legendPosition: "middle",
                    legendOffset: -40,
                    format: (value) => formatMoney(value),
                }}
                // legendLabel={(e) => { console.log(e) }}
                legends={[
                    {
                        data: Object.keys(groupByYear).map(key => ({
                            id: key,
                            label: key,
                            color: groupByYear[key][0][`${key}Color`]
                        })),
                        anchor: "bottom-right",
                        direction: "column",
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 1,
                        itemWidth: 100,
                        itemHeight: 15,
                        itemDirection: "left-to-right",
                        itemOpacity: 0.85,
                        symbolSize: 10,
                        effects: [
                            {
                                on: "hover",
                                style: {
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    },

                ]}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                    from: "color",
                    modifiers: [["darker", 1.6]],
                }}
                layers={["grid", "axes", "bars", "markers", "legends",
                    (params) => LineBar("id", "average", params)
                ]}
                role="application"
                ariaLabel="Ciclo Histórico"
            />
        </Box>
    );
};
