export const esLocale = {
    everyText: "Cada",
    periodLabel: "Periodo",
    atEveryText: "A/Cada",
    minuteLabel: "Minutos(s)",
    atOptionLabel: "at",
    everyOptionLabel: "Cada",
    betweenText: "Entre",
    inText: "En",
    onText: "El",
    andText: "Y",
    monthLabel: "Meses(s)",
    dayOfWeekLabel: "Día de la semana",
    onEveryText: "En/Cada",
    multiDayOfMonthLabel: "Día del mes",
    dayOfMonthLabel: "Días",
    hourLabel: "Hora(s)",
    weekDaysOptions: [
        "DOMINGO",
        "LUNES",
        "MARTES",
        "MIERCOLES",
        "JUEVES",
        "VIERNES",
        "SABADO",
    ],
    periodOptions: ["hora", "día", "semana", "mes", "año"],
    shortMonthOptions: [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
    ],
    onOptionLabel: "es",
    lastDayOfMonthLabel: "Último día del mes",
    cronDescriptionText: "es",
};