import React from "react";
import { CustomTable } from "../../../../common/Components/CustomTable";
import checkoutForm from "../../../../model/sku/sku.model";


export const TableSKUFCD = () => {

  const { formField: { sku, center_distribution, isActive } } = checkoutForm;

  const headers = [
    { field: "sku", headerName: sku.headerTable },
    { field: "nombreCentro", headerName: center_distribution.headerTable },
    { field: "active", headerName: isActive.headerTable, valueFormatter: (params) => params.value ? 'Activo' : 'Inactivo' },
  ];

  return (<CustomTable headers={headers} />)
};
