import { Box, Stack } from "@mui/material";
import { Formik } from "formik";
import React, { useContext, useState } from "react";
import { ButtonSave } from "../../../../common/Components/ButtonSave";
import { ContainerForm } from "../../../../common/Components/ContainerForm";
import { CSwitchForm } from "../../../../common/Components/CSwitch";
import CustomModal from "../../../../common/Components/CustomModal";
import { FormTextInput } from "../../../../common/Components/FormTextInput";
import { CToast } from "../../../../common/Components/ToastNotify";
import MediaContext from "../../../../common/Contexts/MediaContext";
import ModulesFormContext from "../../../../common/Contexts/ModulesFormContext";
import { ModalOpenEnum } from "../../../../common/utils/ModalOpenEnum";
import ElementLogicService from "../../../../services/ElementsLogicService";

export const ModuleForm = () => {
  const {
    isOpen,
    toggleModal,
    rowData: modulesR,
    getDataTable,
  } = useContext(ModulesFormContext);
  const { isMobile, isTablet } = useContext(MediaContext);
  const [isLoading, setIsLoading] = useState(false);
  const [modules, setModules] = useState([]);

  const initialValue = {
    modulo_name: modulesR?.modulo_name || "",
    active: true,
    isEditing: modulesR ? true : false,
    isBranch: true,
  };

  const onSave = async (value) => {
    setIsLoading(true);

    const req = {
      modulo_name: value?.modulo_name,
      active: value.active,
      isBranch: false,
    };

    try {
      const { data } = value.isEditing
        ? await ElementLogicService.patchModules(modulesR.modulo_id, req)
        : await ElementLogicService.postModules(req);
      await getDataTable();
      toggleModal("form");
      CToast(
        `Modulo ${value.isEditing ? "editado" : "creado"} correctamente`,
        "success",
        true
      );
    } catch (response) {
      console.log("error", response);
      CToast(
        `Error al crear ${JSON.stringify(response?.data.error)}`,
        "error",
        true
      );
      CToast(
        `Error al crear ${JSON.stringify(response?.data.message)}`,
        "error",
        true
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CustomModal
      isOpen={isOpen[ModalOpenEnum.EDIT]}
      toggleModal={() => toggleModal(ModalOpenEnum.EDIT)}
      moduleLabel={"modules"}
      actionLabel={modulesR ? "edit" : "create"}
      descriptionLabel={modulesR ? `${modulesR.modulo_name}` : ""}
    >
      <Formik
        initialValues={initialValue}
        onSubmit={(values, actions) => {
          onSave(values);
          actions.setSubmitting(false);
        }}
      >
        {({ handleSubmit, values, errors, setFieldValue, setFieldError }) => (
          <ContainerForm formID={"moduleForm"}>
            <Box width={isTablet ? "100%" : "50%"}>
              <FormTextInput
                label={"Nombre del modulo"}
                name={"modulo_name"}
                type={"text"}
                style={{
                  marginRight: 20,
                }}
                fullWidth={true}
              />

              <Stack direction={"row"} ml={3} mt={2}>
                <Box
                  sx={{
                    width: "50%",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <CSwitchForm
                    label={"Activo"}
                    name={"active"}
                    id={"active"}
                    value={values.active}
                  />
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <CSwitchForm
                    label={"isBranch"}
                    title={"Sucursal"}
                    name={"isBranch"}
                    id={"isBranch"}
                    value={values.isBranch}
                  />
                </Box>
              </Stack>

              <ButtonSave
                onClick={handleSubmit}
                isLoading={isLoading}
                edit={values.isEditing ? true : false}
              />
            </Box>
          </ContainerForm>
        )}
      </Formik>
    </CustomModal>
  );
};
