

import { themes } from "../../Types/Types";

export const themeReducer = (state = {}, action) => {
  
    switch (action.type) {
      case themes.light:
        return {
          theme: 1,
        };
  
      case themes.dark:
        return {
          theme: 0,
        };
  
      default:
        return state;
    }
  };