export default {
    formId: "userCheckoutForm",
    formField: {
        first_name: {
            name: "first_name",
            label: "Nombre del usuario",
            min: 4,
            max: 30,
            minErrorMsg: "El nombre debe tener al menos 4 caracteres",
            maxErrorMsg: "El nombre debe tener menos de 30 caracteres",
            requiredErrorMsg: "El nombre es requerido",
        },
        last_name: {
            name: "last_name",
            label: "Apellido del usuario",
            min: 2,
            max: 30,
            minErrorMsg: "El apellido debe tener al menos 2 caracteres",
            maxErrorMsg: "El apellido debe tener menos de 30 caracteres",
            requiredErrorMsg: "El apellido es requerido"
        },
        email: {
            name: "email",
            label: "Email del usuario",
            min: 5,
            requiredErrorMsg: "El email es requerido",
            invalidErrorMsg: "La dirección email es invalido"
        },
        isSuperAdmin: {
            name: 'isSuperAdmin',
            label: 'Super Admin',
            default: false
        },
        password: {
            name: "password",
            label: "Contraseña de usuario",
            min: 8,
            max: 32,
            minErrorMsg: "La contraseña debe tener al menos 8 caracteres",
            maxErrorMsg: "La contraseña debe tener menos de 32 caracteres",
            regex: /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
            regexErrorMsg: "La contraseña debe tener al menos una mayúscula, una minúscula y un caracter especial",
            requiredErrorMsg: "La contraseña es requerida",
        },
        password_confirmation: {
            name: "password_confirmation",
            label: "Confirmación de contraseña",
            requiredErrorMsg: "La confirmación de contraseña es requerida",
            matchErrorMsg: "Las contraseñas no coinciden"
        },
        gender: {
            name: "gender",
            label: "Género",
            requiredErrorMsg: "Debe seleccionar un género",
        },
        rut: {
            name: "rut",
            label: "RUT del usuario",
            requiredErrorMsg: "El rut es requerido",
        },
        select_rol_name: {
            name: "select_rol_name",
            label: "Seleccionar un rol predefinido",
            requiredErrorMsg: "Debe habilitar al menos un modulo. Crear o seleccionar uno predefinido",
        },
        rol_name: {
            name: "rol_name",
            label: "Cluster",
            min: 5,
            max: 20,
            minErrorMsg: "El nombre del rol debe tener al menos 5 caracteres",
            maxErrorMsg: "El nombre del rol debe tener menos de 20 caracteres",
            requiredErrorMsg: "El nombre del cluster es requerido",
        },
        cluster_name: {
            name: "cluster_name",
            label: "Nombre de la configuración",
            requiredErrorMsg: "El nombre del cluster es requerido",
        },
        branchs_selected: {
            name: "branchs_selected",
            label: "Sucursales",
            requiredErrorMsg: "Debe seleccionar al menos una sucursal",
        },
        modulo_cluster: {
            name: "modulo_cluster",
            label: "Cluster",
            cluster: "rol_cluster",
            cluster_name: "rol_name",
            cluster_id: "id_rol",
            requiredErrorMsg: "Debe seleccionar al menos un cluster",
        },
        companyID: {
            name: "companyID",
            label: "Empresa",
            requiredErrorMsg: "Debe seleccionar una empresa",
        },
        branchID: {
            name: "branch",
            label: "Sucursal",
            requiredErrorMsg: "Debe seleccionar una sucursal",
        }
    }
}