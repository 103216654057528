import { Box, Stack } from "@mui/material";
import { Formik } from "formik";
import React from "react";
import { SwitchGraphs } from "./SwitchGraphs";
import { SwitchSeller } from "./SwitchSeller";
import { SwitchType } from "./SwitchTypeMed";

export const TablesActions = ({
  type = false,
  graph = false,
  seller = false,
  handleSwitch,
  valueSwitch,
}) => {

  const initialValue = {
    graph: true,
    type: true,
    seller: true,
  };

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "rgba(11,59,80,1)",
        height: 40,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack
        width={"100%"}
        marginLeft={10}
        paddingX={10}
        alignItems="center"
        flexDirection={"row"}
        justifyContent={"space-between"}
        display={"flex"}
      >
        <Formik
          initialValues={initialValue}
        >
          {({ values, handleChange, handleBlur }) => (
            (
              <React.Fragment>
                {graph && (
                  <SwitchGraphs
                    label={"Activo"}
                    name={"graph"}
                    id={"graph"}
                    value={values.graph}
                    direction={"column"}
                    onClick={() => handleSwitch(prev => !prev)}
                    onChange={handleChange}
                  />
                )}
                {type && (
                  <SwitchType
                    label={"Activo"}
                    name={"type"}
                    id={"type"}
                    value={values.graph}
                    onClick={() => handleSwitch(prev => !prev)}
                    onChange={handleChange}
                    direction={"column"}
                  />
                )}
                {seller && (
                  <SwitchSeller
                    label={"Vendedor"}
                    name={"seller"}
                    id={"seller"}
                    value={values.seller}
                    onChange={handleChange}
                    onClick={() => handleSwitch(prev => !prev)}
                    direction={"column"}
                  />
                )}
              </React.Fragment>
            )
          )}
        </Formik>
      </Stack>
    </Box>
  );
};
